$strokecolor: #0000001a;

.bot_edit {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	max-width: 100%;
}

.bot_edit__body {
	background: white;
	flex: 1;
	overflow: auto;
}

.bot_build {
	user-select: none;
	position: relative;
	width: 100%;
	margin-bottom: 100px;
	height: 100%;
	overflow: auto;

	background-color: #e6e1da;
	// background-size: 15px 15px;
	// background-attachment: local;
	// background-image: linear-gradient(90deg, #efefff 1px, #0000 0), linear-gradient(180deg,#efefff 1px, #0000 0);
}

.bot_add_action_btn {
	color: #00000033;
	font-size: 15px;
	display: flex;
	font-weight: 600;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
	width: 18px;
	height: 18px;
	border: 2px solid #00000033;
	background: transparent;
	cursor: pointer;
	transition: color 0.15s, border-color 0.15s;
	&:hover {
		color: #444;
		border-color: #444;
	}
}

.bot_add_action_btn.bot_add_action_btn__orange:hover {
	color: $orange;
	border-color: $orange;
}

.bot_add_action_btn:hover {
	color: $primary;
	border-color: $primary;
}

.bot_remove_action_btn:hover {
	color: $red;
	border-color: $red;
}

.bot_main {
	flex: 1;
	position: relative;
	overflow: hidden;
}

.bot_zoom_control {
	position: absolute;
	bottom: $spacer * 2;
	left: $spacer * 2;
	z-index: 10;
	display: flex;
	align-items: center;
}

.btn.bot_zoom_btn {
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
}

.bot_hline {
	height: 2px;
	width: 15px;
	background: $strokecolor;
}

.bot_line {
	flex-shrink: 0;
	width: 24px;
	height: 2px;
	background: $strokecolor;
}

.bot_line.bot_line__error {
	background: transparent;
	height: 40px;
}

.bot_line.bot_line__short {
	height: 10px;
}

.bot_line.bot_line__very_short {
	height: 2px;
}

.bot_condition {
	border-radius: 5px;
	z-index: 3;
	max-width: 320px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.bot_condition.bot_condition__orange {
	background: $orange;
	color: $white;
	padding: $spacer * 0.3 $spacer;
}

.bot_condition.bot_condition__green {
	background: $success;
	color: $white;
	padding: $spacer * 0.3 $spacer;
}

.bot_condition.bot_condition__yellow {
	background: #777;
	color: $white;
	padding: $spacer * 0.3 $spacer;
}

.bot_condition.bot_condition__else {
	background: #bbb;
	color: $white;

	padding: $spacer * 0.3 $spacer;
}

.bot_message_text {
	white-space: pre-wrap;
	border-radius: 10px;
	border-top-right-radius: 0;
	border-bottom-left-radius: 0;
	word-break: break-word;
	position: relative;
	padding: $spacer * 0.5 $spacer;
	overflow: auto;

	display: inline-block;

	border: 1px solid #addaff;
	color: $black;
	background: #e9f8ff;

	//color: $user-message-text-color;
	//background: $user-message-background-color;
	a {
		color: $primary;
	}
}

.bot-sidebar {
	height: 100%;
	width: 400px;
	display: flex;
	flex-direction: column;
	position: relative;
	border-left: 1px solid $section-border-color;
}

.bot-sidebar__head {
	display: flex;
	align-items: center;
	padding: $spacer $spacer * 3;
	border-bottom: 1px solid $border-color;
}

.bot-sidebar__head-title {
	font-weight: 500;
	font-size: 1rem;
	margin-right: $spacer * 2;
}

.bot-sidebar__head-back {
	cursor: pointer;
	stroke: $gray-500;
	margin-left: -8px;
	margin-right: 5px;

	&:hover {
		stroke: $gray-900;
	}
}

.bot-sidebar__body {
	padding: $spacer * 2 $spacer * 3;
	flex-shrink: 0;
	flex: 1;
	overflow-y: auto;
}

.bot-sidebar__foot {
	display: flex;
	align-items: center;
	padding: $spacer $spacer * 3;
	border-top: 1px solid $border-color;
	margin-top: auto;
}

.bot-sidebar__actions {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 10px;
}

.bot-sidebar__action {
	margin-bottom: $spacer;
	padding-right: $spacer * 0.5;
	display: flex;
	flex: 1;
	width: 200px;
	min-width: 150px;
	overflow: hidden;
	word-break: break-word;
	align-items: center;
	cursor: pointer;

	&:hover {
		.bot-sidebar__action-text {
			color: $black;
		}
	}

	&.action--disabled {
		opacity: 0.4;

		&:hover {
			cursor: default;
			.bot-sidebar__action-text {
				color: unset;
			}
		}
	}
}

.bot-sidebar__action-title {
	color: $gray-700;
	margin-bottom: $spacer;
	font-weight: 500;
}

.bot-sidebar__action-icon {
	width: 30px;
	height: 30px;
	border-radius: 5px;
	margin-right: $spacer;
	background-color: $gray-200;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

div.bot-sidebar__action-icon__tasks_remind {
	background-color: #ffce6f;
}

div.bot-sidebar__action-icon__language {
	background-color: #ffce6f;
}

div.bot-sidebar__action-icon__selling {
	background-color: #f6deff;
}

div.bot-sidebar__action-icon__conversation_settings {
	background-color: #deebff;
}

div.bot-sidebar__action-icon__user {
	background-color: #ffdede;
}

div.bot-sidebar__action-icon__bot_flow_control {
	background-color: #fff2a9;
}

div.bot-sidebar__action-icon__bot_integrate_with_other_systems {
	background-color: #ecffde;
}

.bot-sidebar__action-text {
	color: $gray-700;
}

.bot-sidebar__tab {
	display: none;

	&.tab--active {
		display: blo;
	}
}

.bot-form {
	margin-bottom: $spacer * 2;

	&.bot-form--mb-lg {
		margin-bottom: $spacer * 5;
	}

	&.bot-form--mb-sm {
		margin-bottom: $spacer * 1.5;
	}

	&.bot-form--mb-none {
		margin-bottom: 0;
	}
}

.bot-form__help {
	display: flex;
	margin-top: $spacer * 0.5;
	align-items: center;
	color: $gray-500;
	cursor: pointer;
	font-size: 14px;

	&:hover {
		color: $gray-700;
	}
}

.bot-message-edit {
	position: absolute;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
	border: 1px solid $border-color;
	right: 22px;
	top: -16px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: #fff;
	z-index: 5;
}

.bot-message-delete {
	position: absolute;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	border: 1px solid $border-color;
	right: -10px;
	top: -10px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: #fff;
	z-index: 5;
}

.bot-message-delete__icon {
	width: 14px;
	height: 14px;
	stroke-width: 2;
	stroke: $gray-500;
}

.bot-message-delete:hover .bot-message-delete__icon {
	stroke: $gray-700;
}

.bot-add-message-btn {
	display: flex;
	align-items: center;
	font-size: 1rem;
}

.bot-add-message-btn__icon {
	width: 18px;
	height: 18px;
	stroke: $primary;
	margin-right: 5px;
	margin-bottom: 2px;
}

.bot-add-message__drop-icon {
	width: 32px;
	height: 32px;
	background: $blue-700;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	margin-right: $spacer;
}

.bot-form__label {
	user-select: none;
	margin-bottom: 5px;
	font-weight: 500;
	// font-size: 1rem;
	// color: $primary;
	display: flex;
	align-items: center;

	&.label--light {
		font-size: 0.875rem;
		color: $gray-700;
	}
}

.bot-form__desc {
	margin-bottom: 5px;
	color: $gray-700;
}

.bot-response {
	margin-bottom: 20px;
	font-size: 1rem;
	font-weight: 500;
}

.bot-textarea {
	cursor: text;
	position: relative;
	border: 1px solid $input-border-color;
	background-color: $white;
	border-radius: 4px;
	display: flex;
	flex-wrap: wrap;

	&.is-invalid {
		border-color: $danger;
	}

	&:hover {
		border-color: $input-focus-border-color;
		.bot-textarea__actions {
			opacity: 1;
		}
	}
}

.bot-textarea__actions {
	display: flex;
	position: relative;
	margin: 0;
	padding: $spacer * 0.5 $spacer;
	white-space: nowrap;
	justify-content: flex-end;
	width: 100%;
	margin-top: auto;
	opacity: 0;
	transition: opacity 150ms ease;
}

.bot-textarea__action-icon {
	stroke: $gray-700;
	&:hover {
		stroke: $black;
	}
}

.bot-textarea__action-item {
	cursor: pointer;
	position: relative;
	padding: 2px 4px;
	line-height: 1;
	margin-right: $spacer * 0.5;

	&.item--active {
		background-color: $gray-200;
		box-shadow: 0 0 0 1px $gray-500;
		border-radius: 4px;
	}
}

.bot-textarea__dropdown {
	display: flex;
	flex-direction: column;
	z-index: 20;
	position: absolute;
	top: calc(100% + 5px);
	left: 0;
	min-width: 250px;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	padding: $spacer * 2;

	&.dropdown--has-arrow:before {
		position: absolute;
		content: '';
		top: -8px;
		width: 16px;
		height: 16px;
		background-color: #fff;
		transform: rotate(45deg);
		border: 1px solid transparent;
		border-top-color: $border-color;
		border-left-color: $border-color;
	}

	&.dropdown--right.dropdown--has-arrow:before {
		right: 15px;
	}
}

// custom quick response tags input
.quick-response-input .vue-tags-input {
	.ti-input {
		border-radius: 0;
		padding: 5px;
	}

	&.ti-focus .ti-input {
		border-color: $input-focus-border-color;
	}

	.ti-input .ti-tag {
		padding: $spacer * 0.5 $spacer;
		font-size: 0.875rem;
		margin: 2px 5px 2px 0;
		border-radius: 4px;
		background-color: #ebf6ff;
		color: $blue-900;

		&:hover {
			background-color: #e0f1ff;
		}
	}

	.ti-new-tag-input {
		font-size: 0.875rem;
	}

	.ti-item.ti-selected-item {
		background-color: #e0f1ff;
		color: $blue-900;
	}
}

// Bot List Page
.bot-list {
	padding: $spacer * 2;
	padding-bottom: $spacer * 5;
	height: 100%;
	max-width: 100%;
	position: relative;
	flex: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
}

.bot-list__head {
	display: flex;
	margin-bottom: $spacer * 2;
}

.bot-list__head-title {
	margin-bottom: $spacer * 0.5;
	font-weight: 500;
	line-height: 1.25;
	font-size: 1.5rem;
}

.bot-list__head-subtitle {
	font-size: 0.875rem;
	color: $gray-700;
}

.bot-list__table {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.bot-list__table-row {
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
}

.bot-list__table-cell {
	flex: 1;
	background-color: #fff;
	border-top: 1px solid #dee2e6;
	padding: $spacer;
	white-space: nowrap;
	align-items: center;
	display: flex;
	overflow: hidden;

	&.cell--header {
		background-color: #f8f9fa;
		font-weight: 500;
	}
}

.bot_target {
	padding: $spacer * 3 0;
	flex: 1;
}
.bot_target__wrapper {
	max-width: 700px;
}

.bot_target_section {
	margin-bottom: $spacer * 3;
}

.bot_target_section_title {
	font-size: 18px;
	font-weight: 500;
	color: $gray-800;
}

.bot_target_section_desc {
	color: $gray-600;
}

.bot_target_trigger {
	display: flex;
}

.bot_target_add_link {
	display: flex;
	align-items: center;
	margin-top: $spacer * 0.5;
}

.bot_target_add_link__icon {
	width: 16px;
	height: 16px;
	margin-right: $spacer * 0.5;
}

.bot_target_add_trigger {
	top: $spacer * 4;
	left: 0px;
}

.bot_target_add_trigger__dropdown_item.dropdown__item {
	display: block;
}

.bot_target_add_trigger__item_desc {
	white-space: pre-wrap;
	color: $gray-600;
}

.bot_target_add_trigger__dropdown_item:hover {
	.bot_target_add_trigger__item_desc {
		color: $white;
	}
}

.bot_target_add_trigger__item_title {
	white-space: pre-wrap;
	font-weight: 500;
}

.bot__assign__tooltip {
	width: 16px;
	height: 16px;
	margin-left: $spacer * 0.5;
	margin-top: -$spacer * 0.2;
	color: $text-muted;
}

.bot-trigger-box {
}
.bot-triggers .bot-cond__add__btn {
	margin-left: 0;
}
.bot_target_trigger {
	padding: 0 0 $spacer 0;
	display: flex;
	flex-direction: row-reverse;
	justify-content: space-between;
	align-items: center;
}
.bot-trigger__item {
	background-color: $white;
	border-radius: 4px;
	overflow: hidden;
	margin-bottom: $spacer * 1.5;

	&:last-child {
		margin-bottom: 0;
	}
}
.bot_trigger__title {
	font-weight: 500;
}

.bot-trigger__item-desc {
	color: $gray-600;
}

.bot-trigger__item-body {
	padding: 0 $spacer * 2 $spacer $spacer * 2;
	padding-left: 0;
	padding-bottom: 0;
}

.bot-trigger__item-body--none {
	display: none;
}
.bot-trigger__item-body textarea {
	width: 100%;
	resize: none;
	border: solid 1px #bfbfbf;
	border-radius: 4px;
	padding: $spacer;
}

.condition_section_content .bot_target_trigger {
	padding: 0;
	border: none;
	margin: 0;
}
/*
 * Bot Conditions
 */

.bot_graph_branching_section {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.bot_build_back_btn {
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 0 $spacer * 2;
	height: 100%;
	&:hover {
		color: $primary;
	}
}

/*
 * Select Bot Template Popup
 */
.bot-create__popup {
	height: 700px;
	position: relative;
	width: 800px;
	display: flex;
	flex-direction: column;
	max-height: 90vh;
	overflow: hidden;
}

.bot-create__close {
	position: absolute;
	text-align: right;
	top: $spacer;
	right: $spacer;
}

.bot-create__title {
	margin-top: $spacer * 3;
	text-align: center;
	font-size: 30px;
}

.bot-create__desc {
	text-align: center;
	color: $black-500;
	margin: 0 $spacer * 8 $spacer * 5 $spacer * 8;
}

.bot-create__category_title {
	font-size: 26px;
	font-weight: 500;
	margin-left: $spacer * 5;
}

.bot-create__category_desc {
	margin-left: $spacer * 5;
	font-size: 15px;
}

.bot-create__list {
	margin-top: $spacer * 2;
	margin-left: $spacer * 4;
	display: flex;
	flex: 1;
	position: relative;
	flex-wrap: wrap;
}

.bot-create__item-body-bg.bot-create__item-body-bg__1 {
	background: #001757;
}
.bot-create__item-body-bg.bot-create__item-body-bg__2 {
	background: #0b358e;
}
.bot-create__item-body-bg.bot-create__item-body-bg__3 {
	background: #ee6f57;
}
.bot-create__item-body-bg.bot-create__item-body-bg__4 {
	background: #fdca40;
}
.bot-create__item-body-bg.bot-create__item-body-bg__5 {
	background: #8d91c7;
}
.bot-create__item-body-bg.bot-create__item-body-bg__6 {
	background: #b0daf1;
}

.bot-create__item {
	border-radius: 10px;
	flex-shrink: 0;
	width: 300px;

	cursor: pointer;
	position: relative;
	background: $white;
	overflow: hidden;
	margin-bottom: $spacer * 3;
	margin-left: $spacer;
	margin-right: $spacer;
	height: 350px;
	box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
	&:hover {
		box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
	}
}

.bot-create__item:hover {
	border-color: $primary;
}

.bot-create__item-avatar {
	position: absolute;
	z-index: 3;
	border-radius: 50%;
	border: 5px solid $white;
	background: $white;
	left: 20px;
	top: 60px;
	width: 60px;
	height: 60px;
	margin: 0;
	overflow: hidden;
	// box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

.bot-create__item-tag {
	z-index: 5;
	font-size: 12px;
	position: absolute;
	right: 20px;
	background: white;
	top: 20px;
	border-radius: 5px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
	padding: $spacer * 0.5 $spacer;
	display: flex;
	align-items: center;
}

.bot-create__item-avatar img {
	max-width: 100%;
	max-width: 100%;
	object-fit: cover;
}

.bot-create__item-template-name {
	font-weight: 600;
	font-size: 18px;
}

.bot-create__item-body {
	padding-left: 20px;
	background: $white;
	height: 130px;

	margin-top: 90px;
	padding: $spacer * 4 $spacer * 2 $spacer $spacer * 2;
	background: transparent;
	height: 130px;
	position: relative;
	background: $white;
}

.bot-create__item-body-bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100px;
}

.bot-create__item-desc {
	color: $black-500;
}
.bot-create__item input {
	display: none;
}

.bot-setting {
	width: 100%;
}

.bot-setting-section {
	margin-bottom: 50px;

	&:last-child {
		margin-bottom: 0;
	}
}

.bot-setting__wrapper {
	padding: $spacer * 2 $spacer * 4;
	max-width: 700px;
}

.bot-setting__avatar {
	display: flex;
	align-items: center;
}
.bot-setting__avatar-thumb {
	width: 130px;
	height: 130px;
	border-radius: 10px;
	overflow: hidden;
	margin-right: $spacer * 2;
	position: relative;
	cursor: pointer;
	border: solid 1px #bfbfbf;
}

.bot-setting__avatar-overlay {
	position: absolute;
	top: 80%;
	left: 0;
	width: 100%;
	height: 20%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
	color: $white;
	font-size: 14px;
	cursor: pointer;
	text-align: center;
}
.bot-setting__avatar-icon {
	stroke: $white;
	width: 18px;
	height: 18px;
	margin-right: $spacer * 0.5;
}
.bot-setting__avata-input {
	padding: $spacer * 2;
	border: solid 1px #bfbfbf;
	border-radius: 4px;
	width: 100%;
}
.bot-setting__avatar-thumb img {
	width: 130px;
	height: 130px;
	object-fit: cover;
}
.bot-setting__form {
	display: flex;
	margin-top: $spacer * 1.5;
	align-items: center;
	padding-top: $spacer * 1.5;
	border-top: solid 1px #bfbfbf;
}
.bot-setting__form-label {
	margin-bottom: 2px;
}
.bot-setting__form-input {
	flex: 1;
}
.bot-setting__form-input input {
	width: 100%;
	height: 42px;
	border: solid 1px #bfbfbf;
	border-radius: 4px;
	padding: 0 $spacer * 1.5;
}
.bot-setting__avatar-list {
	display: flex;
	align-items: center;
	text-align: center;
	flex-wrap: wrap;
	margin-top: $spacer;
	max-height: 500px;
	overflow: auto;
}
.bot-setting__avatar-item {
	margin-right: $spacer * 1.5;
	margin-bottom: $spacer * 1.5;
	cursor: pointer;
	border: solid 1px transparent;
	border-radius: 8px;
	width: 60px;
	height: 60px;
	position: relative;
	transition: 0.2s;

	&:hover {
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
		transform: translateY(-1px);
	}

	&.item--no-hover:hover {
		box-shadow: none;
		transform: translateY(0px);
	}
}
.bot-setting__avatar-item--active {
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}
.bot-setting__avatar-item-thumb {
	width: 100%;
	height: 100%;
	border-radius: 8px;
	overflow: hidden;
}
.bot-setting__avatar-item-thumb img {
	max-width: 100%;
	max-height: 100%;
	object-fit: cover;
}

.bot_edit_header {
	border-bottom: 1px solid $section-border-color;
	display: flex;
	align-items: center;
	//overflow: hidden;

	background: $white;
	align-items: center;
	height: 50px;
	padding-right: $spacer * 2;
	flex-shrink: 0;

	z-index: 10; // on top of sidebar
}

.bot_edit_header__name {
	@include text-ellipsis;
	margin-left: $spacer;
	font-weight: 600;
	font-size: 15px;
	flex: 1;
	margin-right: $spacer * 2;
}

.bot-setting__popup {
	width: 700px;
	position: relative;
	padding: $spacer * 2;
}

.bot-setting__popover::after {
	opacity: 0;
	top: $spacer * 3;
	left: -9px;
}

.bot-setting__popover-title {
	font-weight: 600;
	margin-bottom: $spacer;
}
.bot-setting__popover-section:not(:first-child) {
	padding-top: $spacer * 2;
	margin-top: $spacer * 2;
	border-top: solid 1px $border-color;
}
.bot_list__table_empty {
	color: #6c757d;
	text-align: center;
	padding-top: $spacer * 4;
}
.bot_list__table_empty .bot-create__list {
	max-width: 650px;
	margin: 0 auto;
}

.bot-list__table-cell-icon {
	width: 16px;
	height: 16px;
}
.bot-setting__title {
	font-size: 16px;
	margin-bottom: $spacer * 0.5;
	font-weight: 500;
	color: $black;
}
.bot-setting__form-group {
	margin-bottom: $spacer * 2.5;
}

.bot_edit__main {
	display: flex;
	overflow: hidden;
	flex: 1;
	width: 100%;
	height: 100%;
}

.bot_step.bot_step__collapsed {
	width: 40px;
}

.bot_step {
	width: 240px;
	padding: 0 $spacer * 2;
	z-index: 1;
	border-right: 1px solid $section-border-color;
}

.bot_step__item {
	padding-top: $spacer * 2;
	padding-bottom: $spacer * 2;
	cursor: pointer;
	flex-shrink: 0;
	user-select: none;
}

.bot_step__item::after {
	content: none;
}

.bot_step__title {
	display: flex;
	font-size: 16px;
	align-items: center;
	font-weight: 500;
	color: $black-700;
}

.bot_step__index {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
	width: 25px;
	height: 25px;
	border-radius: 8px;
	background-color: $black-300;
	color: $black-600;
	margin-right: $spacer;
}
.bot_step__item.bot_step__item--active {
	.bot_step__title {
		color: $primary;
	}
	.bot_step__index {
		color: $white;
		background-color: $primary;
	}
}

.bot_step__desc {
	margin-left: 36px;
	color: $black-600;
	margin-top: 2px;
	font-weight: 400;
	font-size: 15px;
}

.btn__back_to_bot_list {
	border: solid 1px $black-500;
}

.bot-sidebar__body.bot-sidebar__widget_error {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow: hidden;
	padding: 0;
}

.bot-sidebar__widget_body {
	display: flex;
	align-items: center;
	flex: 1;
	overflow: hidden;
	padding: 0;
}

.bot_widget {
	width: 400px;
	overflow: hidden;
	background: white;
	flex-shrink: 0;
	z-index: 9;
	flex-direction: column;
	display: flex;
	border-left: 1px solid $section-border-color;
}

.bot-setting__name {
	flex: 1;
	display: flex;
	flex-direction: column;
}
.bot-setting__current-avatar {
	width: 100%;
}
.bot-setting__current-avatar--active {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.bot-setting__popover {
	width: 600px;
}
.bot-setting__upload {
	margin: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	cursor: pointer;
	border-radius: 8px;
	font-size: 14px;
	stroke: $black-500;
	outline: none;
	box-shadow: 0 0 0 1px $border-color;
}

.bot-setting__upload:hover {
	border-color: $primary;
}

.bot-setting__upload-icon {
	stroke: $black-500;
}

.bot-textarea__insert-var {
	position: absolute;
	top: 5px;
	right: 5px;
}

.bot-textarea__insert-var-button {
	line-height: 1;
	cursor: pointer;
	padding: 5px;
	border-radius: 4px;
	border: 1px solid transparent;
	background-color: transparent;
	outline: none;
	font-size: 0.675rem;
	font-weight: 600;
	letter-spacing: 1px;

	&.button--active {
		border-color: $border-color;
		background-color: $gray-100;
	}
}

.bot_graph_end_section {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;
}

.bot_graph_action.bot_graph_action__bot_end {
	width: 120px;
	text-align: center;
	background: #394960;
	color: white;
	&:hover {
		background: #394960;
		color: white;
	}
}

.bot-report {
	padding: $spacer * 2 $spacer * 4;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.bot-report-convos {
	flex: 1 0 auto;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.bot-statistic-box {
	position: relative;
	border: 1px solid $border-color;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
}

.bot-statistic-box__head {
	display: flex;
	width: 100%;
	padding: $spacer $spacer * 2;
	border-bottom: 1px solid $border-color;
}

.bot-statistic-box__body {
	min-height: 300px;
	flex: 1 0 auto;
	padding: $spacer * 2;
	display: flex;
}

.bot-statistic-overview-box {
	margin: 2px 0;
	padding: 0 $spacer * 2;
	border-right: 1px solid $border-color;

	&:first-child {
		padding-left: 0;
	}
	&:last-child {
		border: none;
	}
}

.bot-statistic-overview-box__title {
	font-size: 0.75rem;
	line-height: 1;
}

.bot-statistic-overview-box__value {
	font-weight: 600;
	font-size: 1.125rem;
	line-height: 22px;
}

.bot-convos-btn {
	border: 1px solid $border-color;
	background-color: #fff;
	display: flex;
	align-items: center;

	&:hover {
		background-color: rgba(33, 33, 33, 0.02);
		border-color: $gray-500;
	}
}

.bot_quick_replies {
	display: flex;
	flex-wrap: wrap;
}

.devider-line {
	margin-top: 15px;
	margin-bottom: 15px;
	font-size: 1rem;
	font-weight: 400;
	text-align: center;
	position: relative;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: $gray-500;

	&:before {
		content: '';
		position: absolute;
		width: 100%;
		height: 1px;
		left: 0;
		z-index: -1;
		top: 50%;
		background-color: $border-color;
	}
}

.bot-message__image-overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	font-size: 14px;
	cursor: pointer;
	text-align: center;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	overflow: hidden;
	padding: 0 4px;

	&:hover {
		color: rgba(255, 255, 255, 0.8);
	}
}

.bot-create__item-pro {
	position: absolute;
	z-index: 3;
	left: 61px;
	top: 95px;
	margin: 0;

	overflow: hidden;
	background: $yellow;
	font-size: 10px;
	padding: 0 5px;
	border-radius: 3px;
	color: $white;
}

.bot-create__item-pro-star {
	width: 12px;
	height: 12px;
	stroke: $yellow;
	margin-right: $spacer * 0.5;
	margin-bottom: 2px;
	fill: $yellow;
}

.bot_create__cards {
	display: flex;
	justify-content: center;
	flex: 1;
}

.bot_create__template_container {
	display: flex;
	flex: 1;
	overflow: hidden;
	width: 100%;
}

.bot_create__templates {
	padding-right: 10px;
	max-width: 700px;
	flex: 1;
	display: flex;
	overflow: auto;
	align-items: center;

	flex-shrink: 0;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-bottom: $spacer;
}

.bot-create__avatar-thumb {
	width: 100px;
	margin: auto;
	border-radius: 50%;
	overflow: hidden;
	position: relative;
	cursor: pointer;
}

.bot_create__preview {
	border-radius: 8px;
	flex: 1;
	overflow: hidden;
}

.bot_create__template__overlay {
	opacity: 0;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	height: 200px;

	background: #00000077;
	border-radius: 10px;
}

.bot_create__template__img {
	width: 200px;
	height: 200px;
	object-fit: cover;
	border-radius: 10px;
}

.bot_create__template {
	overflow: hidden;
	flex-shrink: 0;
	position: relative;
	margin: 0 13px $spacer * 3 13px;
	width: 200px;
	cursor: pointer;
	height: 280px;
	background: white;
	transition: all 100ms;
	color: $text-muted;

	&:hover {
		// background: $white;
		color: $gray-800;
		border-color: $black;

		.bot_create__template__overlay {
			opacity: 1;
		}
	}
}

.bot_create__card {
	width: 300px;
	display: flex;
	cursor: pointer;
	padding: $spacer * 3;
	border: 2px solid $gray-300;
	border-radius: 8px;
	margin: $spacer;
	height: 300px;
	background: white;
	transition: all 100ms;
	color: $text-muted;

	&:hover {
		// background: $white;
		color: $gray-800;

		border-color: $primary;
	}
}

.bot_create__template__title {
	font-weight: 500;
	margin-top: $spacer;
	color: $black;
}

.bot_create__card__title {
	font-size: 22px;
	color: $black;
	margin-top: $spacer * 3;
	margin-bottom: $spacer;
}

.bot-create__body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
}

.bot-create__back_btn__icon {
	color: $white;
	fill: lightgray;
	margin-right: $spacer * 0.5;
}

.bot-create__footer {
	display: flex;
	justify-content: center;
	padding: $spacer * 2 0;
}

.bot-create__back_btn {
	justify-content: center;
	cursor: pointer;
	display: flex;
	align-items: center;
	color: gray;
	width: 150px;
	&:hover {
		color: black;
		.bot-create__back_btn__icon {
			fill: gray;
		}
	}
}

.bot-triggers__tab-content {
	padding: 20px;
	border: 1px solid $border-color;
}

.bot-trigger-methods {
	display: flex;
	margin-bottom: $spacer;
	align-items: center;
}

.bot-trigger-method-box {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	flex-direction: column;
	width: 180px;
	height: 120px;
	padding: $spacer * 1.5;
	border: 1px solid $primary;
	border-radius: 4px;
	cursor: pointer;
	margin-right: $spacer * 1.5;
	color: $gray-600;

	&.method--active {
		background-color: rgba(2, 101, 255, 0.1);
		box-shadow: 0 0 0 1px $primary;
	}
}

.bot-trigger-method-box__cb {
	position: absolute;
	top: $spacer;
	right: $spacer * 1.5;
}

.bot-clone-page {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: #fff;
	z-index: 999999;
}

.bot-warning-icon {
	width: 18px;
	height: 18px;
	fill: $warning;
	stroke: $white;
}

.bot-warning-content {
	position: absolute;
	width: 200px;
	top: 100%;
	right: 0;
	background: $white;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	padding: $spacer $spacer * 1.5;
	display: none;
	z-index: 99;
	white-space: normal;
}

.bot-warning-container {
	position: relative;

	&:hover .bot-warning-content {
		display: block;
	}

	&:hover:after {
		content: '';
		position: absolute;
		top: 0;
		left: -10px;
		background-color: transparent;
		width: 150px;
		height: 25px;
	}
}

.bot-template-hero {
	color: $white;
	text-align: center;
	padding-top: 200px;
	padding-bottom: 100px;
	position: relative;
	background-image: linear-gradient(180deg, rgba(0, 23, 87, 0.75), rgba(0, 23, 87, 0.75)),
		url('../img/bot/template/cover.jpg');
	background-position: 0 0, 30% 30%;
	background-size: auto, cover;
}

.bot-template-hero__title {
	font-weight: 600;
	font-size: 3rem;
	line-height: 1.25;
	margin-bottom: 5px;
}

.bot-template-hero__subtitle {
	font-size: 1.125rem;
	line-height: 2rem;
	font-weight: 400;
	max-width: 1;
}

.bot-template-content {
	padding: 20px 0;
}

.bot-template-container {
	max-width: 1140px;
	margin: auto;
}

.bot-template-section {
	padding-top: 30px;
	padding-bottom: 30px;
	position: relative;

	&.section--light {
		background-color: $gray-100;
	}
}

.bot-template-section-title {
	font-weight: 600;
	font-size: 2.25rem;
	line-height: 3rem;

	&.title--bold {
		font-weight: 400;
		font-size: 1.75rem;
	}
}

.bot-template-list {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -10px;
}

.bot-template-list__col {
	flex: 0 0 25%;
	max-width: 25%;
}

.bot-template-card {
	padding: 0 10px;
	display: flex;
	flex-direction: column;
	margin-bottom: 30px;
}

.bot-template-card__thumb {
	width: 100%;
	height: 260px;
	position: relative;
	border-radius: 4px;
	overflow: hidden;

	&:hover .bot-template-card__thumb-overlay {
		opacity: 1;
		pointer-events: all;
	}
}

.bot-template-card__thumb-img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.bot-template-card__thumb-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	pointer-events: none;
	background-color: rgba(0, 0, 0, 0.6);
}

.bot-template-card__name {
	margin-top: 5px;
	margin-bottom: 0;
	font-weight: 500;
	font-size: 1.125rem;
	line-height: 2rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.bot-template-card__desc {
	font-size: 1rem;
	line-height: 1.5;
}

.bot-template-images {
	display: flex;
	flex: 1 0 700px;
	align-items: center;
	overflow-x: auto;
	padding: 20px 0px;
	user-select: none;
}

.bot-template-img {
	display: block;
	margin: 0 20px;
	height: 500px;
	width: 300px;
	border-radius: 4px;
	max-width: 100%;
	position: relative;
	flex-shrink: 0;
	overflow: hidden;
	box-shadow: 11px 3px 30px 0 rgba(0, 0, 0, 0.13);
}

.bot-template-image-add {
	border: 2px dashed;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	min-width: 300px;
	background-color: $white;
	max-width: 300px;

	&:hover {
		background-color: $gray-100;
	}
}

.bot-template-img__actions {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.25);
	color: $white;
	display: flex;
	justify-content: center;
	padding: 10px 0;
}

.bot-template-img__action {
	cursor: pointer;
	width: 16px;
	height: 16px;

	&:hover {
		color: $gray-200;
	}
}

.bot-template-tab {
	padding: 0 10px;
	font-size: 1.25rem;
}

.bot-template-tab-content {
	display: flex;
	margin-top: 20px;
}

.bot-template-description {
	flex: 0 0 50%;
	padding-right: 50px;
}

.bot-template-features {
	margin-left: 100px;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.bot-template-feature-item {
	padding: 10px;
	background-color: $gray-100;
	border-bottom: 1px solid $border-color;
	font-weight: 500;
	border: 1px solid $border-color;
	border-bottom: none;
	display: flex;
	align-items: center;

	&:first-child {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	&:last-child {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		border-bottom: 1px solid $border-color;
	}
}

.bot-template-feature-item__actions {
	margin-left: auto;
}

.bot-template-feature-item__action {
	width: 14px;
	height: 14px;
}

.bot-template-support {
	padding: 20px;
	border-radius: 4px;
	border: 1px solid $border-color;
}

.bot-template-faqs {
	margin-top: 30px;
}

.bot-template-faqs__title {
	font-size: 1.25rem;
	font-weight: 600;
	line-height: 2rem;
	margin-bottom: 5px;
}

.bot-template-faqs__row {
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;
}

.bot-template-faqs__col {
	padding: 0 10px;
	flex: 0 0 50%;
	margin-bottom: 20px;
}

.bot-template-hero__edit-thumb {
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
}

.bot-template-navbar {
	position: absolute;
	top: 0;
	width: 100%;
	padding: 15px 0;
	color: #fff;
	background-color: rgba(0, 23, 87, 0.5);
	z-index: 1;
}

.bot-template-navbar__links {
	margin-left: auto;
	display: flex;
	align-items: center;
}

.bot-template-navbar__item {
	margin-right: 10px;
	color: $gray-200;
	font-weight: 500;
	padding: 10px;

	&:hover {
		color: $white;
	}
}

.bot-template-footer {
	width: 100%;
	background-color: $gray-900;
	padding: 15px 0;
}

.bot-template-footer__logo {
	width: 110px;
	height: 30px;
}

.bot-template-footer__copyright {
	color: #ecf0f1;
}

.bot-template-footer__link {
	margin-left: 10px;
	width: 24px;
	height: 24px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #7f7f7f;

	&:hover {
		background-color: $white;
	}

	svg {
		fill: $gray-900;
		stroke-width: 1;
		width: 14px;
		height: 14px;
		stroke: $gray-900;
	}
}

.bot-template {
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: auto;
	height: 100%;
}

.bot-template-tags {
	max-width: 600px;
	margin: auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 50px;
	margin-bottom: -80px;
}

.bot-template-tag {
	border-radius: 10px;
	padding-left: 10px;
	padding-right: 10px;
	margin: 0 5px 10px;
	cursor: pointer;

	&:hover {
		background-color: $gray-300;
	}
}

.bot-template-actions {
	padding: 30px 0;
	width: 100%;
	background-color: $gray-100;
}

.bot-template-created-by {
	margin-top: 10px;
	margin-bottom: 15px;
	font-size: 1.75rem;
	font-weight: 400;
}

.bot-template-faqs__head {
	flex: 1 0 33.33%;
	padding-right: 20px;
}

.bot-template-faqs__icon {
	width: 56px;
	height: 56px;
	background-color: $primary;
	margin-bottom: 10px;
	border-radius: 50%;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.bot-template__paragraph {
	font-size: 1rem;
}

.bot-template-newsletter__bg {
	margin-right: 60px;
}

.bot-template-newsletter {
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
}

.bot-template-introduce {
	max-width: 350px;
	margin-right: 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.bot-template-features-images {
	display: flex;
}

.bot-template-features-images__col {
	display: flex;
	flex-direction: column;
	margin-right: 40px;

	&:last-child {
		margin-top: 40px;
	}
}

.bot-conditions {
	padding: $spacer * 2 $spacer * 4;
	max-width: 700px;
}

.bot-conditions-section {
	margin-top: 15px;

	&:first-child {
		margin-top: 30px;
	}
}

.bot-conditions-card {
	width: 100%;
	position: relative;
	border: 1px solid $border-color;
	border-radius: 4px;
}

.bot-conditions-card__content {
	position: relative;
	padding: $spacer * 2 $spacer * 2;

	&.condition--negative {
		background-color: $gray-100;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
	}
}

.bot-conditions-card__content.condition--negative .bot-conditions-card__title {
	border-top-color: transparent;
	border-right-color: transparent;
	background-color: $gray-100;
}

.bot-conditions-card__title {
	position: absolute;
	top: -18px;
	left: -1px;
	background: $white;
	padding: 5px $spacer * 2 0;
	font-weight: 500;
	color: $gray-600;
	border: 1px solid $border-color;
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
	border-bottom: none;

	&:after {
		content: '';
		position: absolute;
		background-color: inherit;
		width: 10px;
		height: 10px;
		bottom: 0;
		right: -1px;
	}
}

.bot-conditions-card__cell {
	margin-right: $spacer * 2;
	display: flex;
	flex-direction: column;

	&:last-child {
		margin-right: 0;
	}
}

.bot-template-features__img {
	width: 300px;
	height: 300px;
	margin-bottom: 20px;
	border-radius: 8px;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
}

.bot-template-features__item {
	margin-bottom: 20px;
	font-size: 1rem;

	&:last-child {
		margin-bottom: 0;
	}
}

.bot-template-features__items {
	margin-top: 20px;
	margin-bottom: 30px;
}

.bot-conditions-devider {
	padding: 8px 0 8px 6px;
	font-size: 12px;
	color: $gray-500;
	width: fit-content;
	position: relative;
	line-height: 1;

	&:before {
		content: '';
		left: 10px;
		top: 0;
		position: absolute;
		height: 4px;
		width: 1px;
		background-color: $gray-500;
	}

	&:after {
		content: '';
		left: 10px;
		bottom: 0;
		position: absolute;
		height: 4px;
		width: 1px;
		background-color: $gray-500;
	}

	&.devider--lg {
		padding: 15px 0 15px 6px;
		font-size: 1rem;
	}
}

.bot-conditions__title {
	font-size: 1.25rem;
	color: $black;
	margin-bottom: 0;
	line-height: 1.2;
	font-weight: 500;
}

.bot-conditions__subtitle {
	color: $gray-700;
	margin-bottom: $spacer;
}

.bot-conditions__seperator-line {
	width: 1px;
	height: 40px;
	margin-left: 25px;
	line-height: 40px;
	background-color: $gray-300;

	&.line--short {
		height: 20px;
		line-height: 20px;
	}

	&.line--small {
		height: 30px;
		line-height: 30px;
		font-size: 12px;
		margin-left: 15px;
	}
}

.bot-conditions__seperator-text {
	background: $white;
	color: $gray-500;
	left: -5px;
	position: relative;
	padding: 2px 0;
}

.bot-conditions__btn {
	display: inline-flex;
	align-items: center;
	padding: $spacer * 0.5 $spacer;
	border: 1px dashed $link-color;
	border-radius: 4px;

	&:hover {
		border-color: $link-hover-color;
	}
}

.bot-conditions-card__remove {
	top: $spacer * 1.5;
	right: $spacer * 1.5;
	position: absolute;
	color: $gray-500;
	cursor: pointer;
	width: 16px;
	height: 16px;
	outline: none;

	&:hover {
		color: $gray-700;
	}
}

.bot-run-options {
	margin-bottom: 30px;
}

.bot-run-option__box {
	padding: $spacer $spacer * 1.5;
	border: 1px solid $border-color;
	border-radius: 8px;
	margin-bottom: $spacer;

	&:last-child {
		margin-bottom: 0;
	}

	&.box--active {
		border: 1px solid $primary;
	}
}

.bot-run-option__head {
	display: flex;
	align-items: center;
}

.bot-run-option__label {
	margin-bottom: 0;
	margin-left: $spacer;
	cursor: pointer;
}

.bot-run-option__content {
	padding-top: 10px;
	padding-left: 26px;
}

.bot-cond-url-row {
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: 5px;

	&:before {
		content: '';
		position: absolute;
		right: 100%;
		top: 50%;
		width: 13px;
		height: 1px;
		background-color: $gray-300;
	}
}

.bot-cond-url-inputs {
	margin-top: 5px;
	padding-left: 15px;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		left: 2px;
		bottom: 10px;
		width: 1px;
		height: calc(100% - 10px);
		background-color: $gray-300;
	}
}

.bot-cond-url__add-btn {
	width: 20px;
	height: 20px;
	background-color: $white;
	border-radius: 50%;
	border: 1px dashed $border-color;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.bot-run-option__channel {
	margin-bottom: 10px;

	&:last-child {
		margin-bottom: 0;
	}
}

.bot-call-btn-editor {
	position: relative;
	border: 1px solid $input-border-color;
	border-radius: 4px;
	padding: $spacer * 2;
}

.bot-list__table-body.bot-list__table-body__empty {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.bot_graph_action {
	// box-shadow: 0 2px 15px #00000012;
	// margin-left: 5px;
	// margin-right: 5px;

	cursor: pointer;
	display: flex;
	align-items: center;
	border: 1px solid transparent;
	width: 180px;
	flex-shrink: 0;
	padding: 2px 15px;

	// overflow: hidden;

	border-radius: 30px;
	height: 40px;
	background: $white;
	position: relative;
}

.bot_graph_action.bot_graph_action__ask_question {
}

.bot_graph_action.bot_graph_action__jump {
	background: #394960;
	color: white;

	width: 160px;
}

.bot_graph_action.bot_graph_action__error {
	background: $danger !important;
	color: white !important;
	-webkit-box-shadow: 0px 0px 16px 4px $danger !important;
	-moz-box-shadow: 0px 0px 16px 4px $danger !important;
	box-shadow: 0px 0px 16px 4px $danger !important;
}

div.bot_graph_action.bot_graph_action__jump_to {
	-webkit-box-shadow: 0px 0px 16px -4px $warning;
	-moz-box-shadow: 0px 0px 16px -4px $warning;
	box-shadow: 0px 0px 16px -4px $warning;
}

.bot_graph_action.bot_graph_action__passed {
	background: $success;
	color: white;
}

div.bot_graph_action.bot_graph_action__active.bot_graph_action__active_run {
	background: $success;
	color: white;
	-webkit-box-shadow: 0px 0px 16px 4px $success;
	-moz-box-shadow: 0px 0px 16px 4px $success;
	box-shadow: 0px 0px 16px 4px $success;
}

div.bot_graph_action.bot_graph_action__active {
	position: relative;
	// box-shadow: 0px 0px 19px -8px $primary;
}

div.bot_graph_action.bot_graph_action__active::after {
	top: -15px;
	left: -15px;
	width: 210px;
	height: 70px;
	content: '';
	z-index: -1;
	border: 2px dashed #0000007d;
	border-radius: 70px;
	position: absolute;
}

.bot_graph_action__icon {
	margin-right: 10px;
	width: 20px;
	height: 20px;
	display: block;
}

.bot_graph_action__name {
	overflow: hidden;
	font-size: 14px;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-weight: 500;
	flex: 1;
	display: block;
}

.bot_condition_label {
	max-width: 150px;
	height: 24px;
	display: block;
	align-items: center;
	flex-shrink: 0;
	line-height: 1;
	background: #ffeeba;
	color: #333;
	font-size: 13px;
	border-radius: 0px;
	border-bottom-right-radius: 10px;
	border-top-right-radius: 10px;
	// border-radius: 7px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding: 6px 10px 0 5px;
	cursor: pointer;
}

.bot_condition_label.bot_condition_label__fallback {
	background: #394960;
	color: white;
}

.bot_condition_label.bot_condition_label__undefined {
	background: #f44336;
	color: white;
}

.bot_graph_action_container {
	z-index: 0;
	position: relative;
	display: flex;
	align-items: center;
	&:hover {
		.bot_graph_action_menu {
			opacity: 1;
			visibility: visible;
		}
	}
}

.bot_graph_action_sub {
	position: absolute;
	top: -26px;
	z-index: -1;
	color: #00000044;
	margin-left: 35px;
	user-select: none;
	pointer-events: none;
	width: 170px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 14px;
}

.bot_graph_action_menu {
	opacity: 0;
	visibility: hidden;
	position: absolute;

	padding: 0;
	left: 50%;

	margin-left: 10px;
	top: -45px;
	transition: opacity 500ms, visibility 800ms;
}

.bot_graph_action_menu_container {
	background: white;
	left: -50%;
	justify-content: center;
	display: flex;
	align-items: center;
	padding: 0 10px;
	position: relative;
	border-radius: 8px;
	box-shadow: 0px 0px 19px -8px #00000044;
}

.bot_graph_action_menu__item {
	cursor: pointer;
	width: 34px;
	height: 34px;
	padding: 8px 5px;
	opacity: 0.7;
	&:hover {
		opacity: 1;
	}
}

.bot_graph_add_menu {
	position: absolute;
	background: white;
	width: 250px;
	z-index: 10;
	border-radius: 10px;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.15);
	height: 350px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	visibility: visible;
	transition: opacity 100ms, visibility 200ms;
}

.bot_graph_add_menu__header {
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #ddd;
}

.bot_graph_add_menu.bot_graph_add_menu__hidden {
	opacity: 0;
	visibility: hidden;
}

.bot_graph_analytic_badge {
	background: $white;
	position: absolute;
	top: $spacer * 2;
	left: $spacer * 2;
	display: flex;
	align-items: center;
	color: $gray-600;
	padding: $spacer * 0.5 $spacer * 1.5;
	border-radius: $spacer;
	box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.15);
	z-index: 99;
}

.add_user_label_btn {
	margin: $spacer * 0.5 $spacer * 0.5 $spacer * 0.5 0;
	display: inline-block;
	padding: 0 $spacer * 0.5;
	font-size: 12px;
	line-height: 18px;
	border: 1px dashed $primary;
	color: $primary;
	cursor: pointer;
	position: relative;

	&:hover {
		border-color: $blue-600;
		color: $blue-600;
	}
}

.create_bot_modal_wrapper {
	width: 800px;
	height: 600px;
	overflow: hidden;
	position: relative;
}

.create_bot_modal_inner {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	transition: 0.2s;
}

.create_bot_modal_sidebar {
	width: 200px;
	padding: 20px;
	border-right: 1px solid $border-color;
	background-color: $gray-100;
	flex-shrink: 0;
}

.create_bot_modal_sidebar_link {
	cursor: pointer;

	&:hover {
		color: #000;
	}

	&.active {
		color: #000;
		font-weight: 600;
	}
}

.create_bot_modal_content {
	flex: 1;
	padding: 20px;
	overflow: auto;
	display: flex;
	flex-direction: column;

	&.wide {
		padding-left: 40px;
		padding-right: 40px;
	}
}

.create_bot_modal_templates {
	width: 100%;
	display: grid;
	grid-gap: 20px;
	grid-template-columns: repeat(2, 1fr);
}

.create_bot_template_card {
	border-radius: 8px;
	border: 2px solid $border-color;
	cursor: pointer;
	padding: 15px 20px;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	&:hover {
		border-color: $primary;

		.create_bot_template_btn {
			color: $white;
			background-color: $primary;
			border-color: $primary;

			&:hover {
				background-color: $blue-700;
			}
		}
	}
}

.create_bot_template_avatar {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	flex-shrink: 0;
	object-fit: cover;
}

.create_bot_template_channel_img {
	width: 13px;
	height: 13px;
	margin-right: 5px;

	&.md {
		width: 16px;
		height: 16px;
		margin-right: 8px;
	}

	&.lg {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}

	&:last-child {
		margin-right: 0;
	}
}

.create_bot_modal_widget_wrapper {
	width: 350px;
	flex-shrink: 0;
	border-left: 1px solid $border-color;
	background-color: $gray-100;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.create_bot_modal_widget_header {
	background-color: $white;
	display: flex;
	align-items: center;
	padding: 15px 20px;
	flex-shrink: 0;
	border-bottom: 1px solid $border-color;
	width: 100%;
}

.create_bot_modal_widget {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.bot-textarea {
	cursor: text;
	position: relative;
	border: 1px solid $input-border-color;
	border-radius: 4px;
	display: flex;
	flex-wrap: wrap;

	.bot-textarea__input {
		font-family: unset;
		font-size: unset;
		width: 100%;
		overflow: auto;
		max-height: 300px;
		min-height: unset;

		.ProseMirror {
			overflow: auto;
			word-break: break-word;

			line-height: 1.42;
			height: 100%;
			outline: 0;
			overflow-y: auto;
			padding: 12px 15px;
			tab-size: 4;
			text-align: left;
			white-space: pre-wrap;
			word-wrap: break-word;

			p {
				margin: 0;
				padding: 0;
				counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
			}

			a {
				cursor: pointer !important;
			}
		}
	}
}

.bot_agent_reply_time_dropdown {
	display: inline;
}
