//
// Text truncation
//

.text-truncate {
	@include text-truncate();
}

// stylelint-disable declaration-no-important

//
// Text
//

// Alignment

.text__nowrap {
	white-space: nowrap !important;
}
.text__truncate {
	@include text-truncate;
}

// Responsive alignment

// @each $breakpoint in map-keys($grid-breakpoints) {
//   @include media-breakpoint-up($breakpoint) {
//     $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

//     .text#{$infix}-left   { text-align: left !important; }
//     .text#{$infix}-right  { text-align: right !important; }
//     .text#{$infix}-center { text-align: center !important; }
//   }
// }
.text__left {
	text-align: left !important;
}
.text__right {
	text-align: right !important;
}
.text__center {
	text-align: center !important;
}
.text__justify {
	text-align: justify !important;
}

// Transformation

.text__lowercase {
	text-transform: lowercase !important;
}
.text__uppercase {
	text-transform: uppercase !important;
}
.text__capitalize {
	text-transform: capitalize !important;
}

// Weight and italics

// .font-weight-light  { font-weight: $font-weight-light !important; }
// .font-weight-normal { font-weight: $font-weight-normal !important; }
// .font-weight-bold   { font-weight: $font-weight-bold !important; }
// .font-italic        { font-style: italic !important; }

// Contextual colors

// .text-white { color: #fff !important; }

@each $color, $value in $theme-colors {
	.text__#{$color} {
		color: $value !important;
	}
	// @include text-emphasis-variant(".text-#{$color}", $value);
}

.text__muted {
	opacity: 0.7;
	// color: $text-muted !important;
}

// Misc

// .text-hide {
//   @include text-hide();
// }

.text__semibold {
	font-weight: 500 !important;
}
.text__bold {
	font-weight: 600 !important;
}
.text__italic {
	font-style: italic !important;
}
.text__underline {
	text-decoration: underline !important;
}
.text__sm {
	font-size: 14px !important;
}
.text__xs {
	font-size: 13px !important;
}
.text__xxs {
  font-size: 10px !important;
}
.text__lg {
  font-size: 18px !important;
}
.text__xl {
  font-size: 20px !important;
}
.text__xxl {
  font-size: 24px !important;
}
.text__3xl {
  font-size: 28px !important;
}
.section__header {
	font-weight: 500;
	font-size: 16px;
}
