//
// Textual form controls
//

.form-control {
	display: block;
	width: 100%;
	min-height: $input-height;
	padding: $spacer * 0.5 $spacer;
	font-family: $input-font-family;
	font-size: $input-font-size;
	font-weight: $input-font-weight;
	line-height: $input-line-height;
	color: $input-color;
	background-color: $input-bg;
	background-clip: padding-box;
	border: $input-border-width solid $input-border-color;

	// Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
	border-radius: $input-border-radius;

	@include box-shadow($input-box-shadow);

	// Unstyle the caret on `<select>`s in IE10+.
	&::-ms-expand {
		background-color: transparent;
		border: 0;
	}

	// Customize the `:focus` state to imitate native WebKit styles.
	&.focus,
	&:focus,
	&:hover {
		color: $input-focus-color;
		background-color: $input-focus-bg;
		border-color: $input-focus-border-color;
		outline: 0;
	}

	&:disabled:hover,
	&[readonly]:hover {
		border: $input-border-width solid $input-border-color;
	}

	// Placeholder
	&::placeholder {
		color: $gray-500;
		// Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
		opacity: 1;
	}

	// Disabled and read-only inputs
	//
	// HTML5 says that controls under a fieldset > legend:first-child won't be
	// disabled if the fieldset is disabled. Due to implementation difficulty, we
	// don't honor that edge case; we style them as disabled anyway.
	&:disabled,
	&[readonly] {
		background-color: $input-disabled-bg;
		border-color: $input-disabled-border-color;
		// iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
		opacity: 1;
	}
}

// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
	display: block;
	width: 100%;
	padding: $input-padding-y 0;
	margin-bottom: 0; // match inputs if this class comes on inputs with default margins
	line-height: $input-line-height;
	color: $input-plaintext-color;
	background-color: transparent;
	border: solid transparent;
	border-width: $input-border-width 0;

	&.form-control-sm,
	&.form-control-lg {
		padding-right: 0;
		padding-left: 0;
	}
}

// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// Repeated in `_input_group.scss` to avoid Sass extend issues.

.form-control-sm {
	min-height: $input-height-sm;
	padding: $input-padding-y-sm $input-padding-x-sm;
	font-size: $input-font-size-sm;
	@include border-radius($input-border-radius-sm);
}

.form-control-lg {
	min-height: $input-height-lg;
	padding: $input-padding-y-lg $input-padding-x-lg;
	font-size: $input-font-size-lg;
	@include border-radius($input-border-radius-lg);
}

.form-control-color {
	max-width: 3rem;
	padding: $input-padding-y;
}

.form-control-color::-moz-color-swatch {
	@include border-radius($input-border-radius);
}

.form-control-color::-webkit-color-swatch {
	@include border-radius($input-border-radius);
}

.form-control-group {
	display: flex;

	.form-control:not(:first-child) {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: 0;
	}
	.form-control:not(:last-child) {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: 0;
	}
	.btn:first-child {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
	.btn:last-child {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.btn.btn__white {
		box-shadow: none;
		border-color: $input-border-color;
	}
}
