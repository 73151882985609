.avatar__online {
	position: absolute;
	background: #02ca37;
	border: 1px solid #b2fa57;
	border-radius: 50%;
	right: 0;
	bottom: 0;
}

.avatar {
	overflow: hidden;
	flex-shrink: 0;
}

.avatar_group {
	display: flex;
	align-items: center;
}

.avatar_group__item {
	margin-right: 3px;
}

.avatar_group__item:first-child {
	margin-right: 0;
}

.avatar_group__item_overlap:not(:nth-last-child(1)) {
	margin-left: -6px;
}

.avatar_group__item_overlap.avatar_group__item_overlap__24:not(:nth-last-child(1)) {
	margin-left: -10px;
}

.avatar_group__items {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
}

.avatar_group__item img {
	height: 50px;
}

.avatar_group__item_collapse {
	height: 50px;
	width: 50px;
	margin-left: 3px;
	text-align: center;
	line-height: 50px;
	border: 1px dashed #bbb;
	box-sizing: border-box;
	border-radius: 3px;
	box-shadow: 3px 0px 0px #ffffff;
}
.avatar_group__item_collapse__20 {
	font-size: 10px;
}
.avatar_group__item_collapse__16 {
	font-size: 8px;
}
.user_avatar_blur {
	filter: grayscale(1);
}
