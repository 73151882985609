.rule__assign {
	padding: 0;
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	margin-bottom: 30px;
}

.rule__minus-icon {
	outline: none;
	transition: all 0.1s ease;
	width: 20px;
	height: 20px;
	cursor: pointer;
	margin-left: 5px;
	position: absolute;
	stroke: $gray-500;
	top: 10px;
	right: 15px;
	z-index: 15;
	&:hover {
		stroke: $gray-800;
	}
}

.rule__assign__label__title {
	font-size: $font-size-lg;
	font-weight: 500;
}

.rule__assign__switch {
	margin-top: $spacer;
}

.rule__assign__tooltip {
	vertical-align: text-top;
	width: 16px;
	height: 16px;
	margin-left: $spacer * 0.5;
	stroke: $gray-600;
	&:hover {
		stroke: $gray-800;
	}
}

.add-rule__dropdown:hover {
	background-color: $gray-200;
	color: $body-color;
}

.rule_channel_img {
	width: 20px;
	height: 20px;
	margin-right: $spacer * 0.5;
}

.rule__actions-bar {
	background-color: $white;
	width: 100%;
	flex-shrink: 0;
	border-top: 1px solid $section-border-color;

	display: flex;
	flex-direction: column;
	transition: 0.3s;
}

.rule__actions-bar__content {
	margin-left: $spacer * 4;
	margin-top: $spacer;
	margin-bottom: $spacer;
	width: 600px;
	max-width: 100%;
}

.rule_assign_strategy {
	margin-bottom: $spacer;
	border: 1px solid $gray-200;
	padding: $spacer;
	border-radius: 5px;
}

.rule_assign_strategy.rule_assign_strategy__active {
	border-color: $gray-500;
}

.rule_assign_strategy__detail {
	margin-left: 28px;
	// margin-top: $spacer;
}

.rule_assign_strategy__label {
	user-select: none;
	padding-left: $spacer;
}

.rule_assign_strategy__header {
	display: flex;
	align-items: center;

	.form-check-input {
		flex-shrink: 0;
		margin-top: -2px;
	}
}

.rule__container {
	min-height: 100%;
	width: 100%;
	overflow: hidden;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: #f8f9fa;
}

.rule__content_wrap {
	overflow: auto;
	width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	background: #fff;
}

.rule__content {
	width: 600px;
	padding-left: $spacer * 4;
	padding-top: 25px;
}

.detail_rule__title {
	color: $gray-700;
	font-weight: 500;
	margin-bottom: $spacer * 0.5;
}

.detail_rule__box {
	position: relative;
	border-radius: 5px;
	display: flex;
	margin-bottom: $spacer;
	flex-direction: column;
	padding: $spacer * 1.5;
	background: #f2f7fb;

	&.detail_rule__box--choosen {
		//margin-left: -$spacer * 3;
		//margin-right: -$spacer * 3;
		//padding: 10px $spacer * 4.5 20px;
		background-color: $yellow-100;
		border-radius: 0;

		.rule__minus-icon {
			opacity: 0;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.detail_rule__trigger_desc {
	user-select: none;
	align-items: center;
	color: $gray-600;
}

.detail_rule {
	max-width: 100%;
}

.detail_rule__channel_condition {
	display: flex;
	align-items: center;
}

.all_channel_icon {
	stroke: $success;
	width: 20px;
	margin-right: $spacer * 0.5;
}

// rule setting

.rule_list__table {
	margin-top: $spacer;
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	overflow: auto;
	// border: 1px solid $gray-200;
}

.rule_list__table_empty {
	display: flex;
	flex-direction: column;
	flex: 1;
	align-items: center;
	justify-content: center;
	color: $text-muted;
}

.rule_list__table_header {
	border-top: 1px solid $section-border-color;
	background: $gray-100;
	font-weight: 500;
}

.rule_list__table_body {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.rule_list__table_row__wrap {
	display: flex;
	flex: 1;
	flex-direction: column;
}

.rule_list__table_row__dragging_last {
	opacity: 0;
	flex-grow: 1;
	margin: 0;
	padding: 0;
	display: flex;
	flex: 1;
}

.rule_list__table_row.rule_list__table_row__dragging {
	margin-top: -1px;
	// border: 1px dashed $gray-400;
	background: $gray-200;
	opacity: 0.8;
	// color: transparent;
	height: 46px;
}

.rule_list__table_row {
	display: flex;
	align-items: center;
	justify-content: space-between;

	position: relative;
	border-bottom: 1px solid $section-border-color;
	border-left: 1px solid $section-border-color;
	border-right: 1px solid $section-border-color;
	user-select: none;
}

.rule_list__rule_detail {
	padding: $spacer;
	width: 100%;
	display: flex;
	align-items: center;
}

.rule_list__order_number {
	cursor: grab;
	display: flex;
	align-items: center;
	width: $spacer * 2;
	opacity: 0.3;
	&:hover {
		opacity: 0.7;
	}
}

.rule_list__rule_name {
	flex: 1;
	align-items: center;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.rule_list__agent_group_icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	background: $white;
	border: 1px solid $gray-200;
	border-radius: 50%;
	margin-right: $spacer;
	color: $primary;
}

.rule_list__rule_channel {
	width: 22%;
	overflow: hidden;
}

.rule_list__rule_assign_to {
	display: flex;
	align-items: center;
	width: 22%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.rule_list__status {
	width: 10%;
}

.rule_list__action {
	cursor: pointer;
	width: 10%;
	min-width: 150px;
	display: flex;
	justify-content: flex-end;
}

.rule_list__action__link {
	padding: 0 $spacer * 0.5;
	position: relative;
	display: flex;
	align-items: center;

	&:last-child {
		padding-right: 0;
		border-right: none;
	}

	&:not(:last-child):before {
		position: absolute;
		content: '';
		width: 2px;
		height: 2px;
		border-radius: 50%;
		background-color: $gray-500;
		right: 0;
		top: 50%;
		transform: translate(50%, -50%);
	}
}

.rule_list__library {
	display: flex;
	padding-top: $spacer;
	flex-wrap: wrap;
	margin: 0 auto;
}

.rule_list__library_item {
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 350px;
	border: 1px solid $gray-400;
	border-radius: $spacer * 0.5;
	margin-right: $spacer;
	margin-top: $spacer;
	padding: $spacer * 2;
	box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

.rule_list__library_icon_item {
	padding: $spacer * 2;
	// background: $gray-100;
	border-radius: $spacer * 0.5;
}

.rule_list__library_item:hover {
	cursor: pointer;
	background: $gray-200;
}
// Detail rule

.detail_rule__custime_time__disabled {
	pointer-events: none;
	opacity: 0.4;
	filter: grayscale(1);

	&:hover {
		opacity: 1;
	}
}

.rule-cond__card {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	margin-right: 20px;
	margin-bottom: 10px;
	width: 280px;
	border: 1px solid $border-color;
	// box-shadow: 0 3px 8px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	min-height: 70px;

	&:nth-child(even) {
		margin-right: 0;
	}

	&.rule-cond__card--disabled {
		opacity: 0.65;
		pointer-events: none;
	}
}

.rule-conds__select {
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
}

.rule-cond__card__content {
	align-self: flex-start;
	padding-right: 10px;
}

.rule-cond__card__text {
	margin-bottom: 5px;
	font-size: 1rem;
}

.rule-cond__card__sub {
	font-size: 0.875rem;
	color: $gray-500;
}

.rule-cond__card__action {
	cursor: pointer;
	display: flex;
	align-items: center;
	border: 1px solid $border-color;
	justify-content: center;
	padding: 5px;
	border-radius: 4px;
}

.cond-select__wrapper {
	overflow: auto;
	width: 300px;
	height: 100%;
	position: relative;
	padding: $spacer * 2;
}

.modal.modal__right.cond-select__modal .modal__background {
	transition: 0.3s;
	transform: translateX(400px);
}

.modal.modal__right.cond-select__modal.cond-select__modal--active .modal__background {
	transform: translateX(0);
}

.modal.modal__right.cond-select__modal {
	z-index: -99999;
	opacity: 0;

	&.cond-select__modal--active {
		z-index: 1050;
		opacity: 1;
	}
}

.cond-select__section {
	margin-bottom: 8px;
}

.cond-select__title {
	font-size: 1rem;
	font-weight: 500;
}

.cond-select__item {
	display: flex;
	padding: 8px;
	color: $gray-900;
	align-items: center;
	cursor: pointer;
	position: relative;

	svg {
		stroke: $gray-900;
	}

	&.cond-select__item--disabled {
		cursor: default;
		opacity: 0.35;
		width: fit-content;
	}

	&:hover {
		color: $link-hover-color;

		svg {
			stroke: $link-hover-color;
		}
	}
}

.detail_rule__source {
	margin-bottom: $spacer * 0.5;
	display: flex;
	position: relative;
	align-items: center;
}

.detail_rule__source_img {
	width: 30px;
	height: 30px;
	display: block;
	border-radius: 4px;
	margin-right: $spacer;
}

.detail_rule__remove_condition_btn {
	outline: none;
	transition: all 0.1s ease;
	stroke: $gray-500;
	border-radius: 50%;
	cursor: pointer;
	margin-left: $spacer;

	z-index: 100;
	&:hover {
		stroke: $gray-800;
	}
}

.detail_rule__source_type_img {
	position: absolute;
	width: 18px;
	height: 18px;
	border: 3px solid $white;
	border-radius: 50%;
	bottom: -2px;
	right: 3px;
}

.rule-cond__add__btn {
	display: inline-flex;
	align-items: center;
	padding: $spacer * 0.5 $spacer;
	border: 1px dashed $link-color;
	border-radius: 4px;

	&:hover {
		border-color: $link-hover-color;
	}
}

.cond-select__header {
	font-size: 1.25rem;
	margin-bottom: $spacer * 1.5;
}

.detail_rule__offline_icon {
	width: 16px;
	fill: $gray-500;
	stroke: $black;
	margin-top: -4px;
	margin-right: 5px;
}

.rule__navigation__icon {
	width: 16px;
	height: 16px;
	margin-bottom: 2px;
	margin-left: -4px;
}

.rule-list {
	padding: $spacer * 2 $spacer * 4;
	max-width: 100%;
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.rule_channel_type {
	display: flex;
}

.rule_channel_type_card {
	border: 2px solid #ddd;
	flex: 1;
	border-radius: 8px;
	padding: 10px 15px;
	cursor: pointer;
	&:hover {
		border: 2px solid #aaa;
	}
}

.rule_channel_type_card.rule_channel_type_card__active {
	border: 2px solid $primary;
}


.list_rule_table .sbz_table_cell.cell_grip {
  padding-left: 20px;
  width: 40px;
}

