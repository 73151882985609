@use "sass:color";
@use "sass:math";

$section-border-color: #e5e5e5;

.profile {
	// depend on .ticket_detail_page_right_col
	border-left: 1px solid $border-color;
	overflow-y: scroll;
	overflow-x: hidden;
	max-width: 440px;
	min-width: 200px;
	flex: 3;
	display: flex;
	flex-direction: column;
	position: relative;
	min-height: 100%;
	background: white;

	&.access_deny {
		> *:not(.profile_error_container) {
			filter: blur(3px);
		}
	}
}

.profile_error_container {
	background-color: $white;
	position: absolute;
	inset: 0;
	padding-top: 100px;
	padding-left: 40px;
	padding-right: 40px;
	z-index: 200;
}

.user_info {
	display: flex;
	flex-direction: column;
}

.user_info__head {
	justify-content: space-between;
	display: flex;

	padding-left: $spacer * 2;
	padding-right: $spacer * 2;

	user-select: none;
}

.user_info__intro {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;
}

.user_info__fullname {
	font-size: $font-size-lg;
}

.user_info__body {
	padding-bottom: $spacer;
	padding-top: $spacer;
}

.user_info__line {
	padding-left: $spacer * 2;
	padding-right: $spacer * 2;

	display: flex;
	align-items: center;
}

.user_info__basic_info {
	margin-left: $spacer;
}

.user_info__clickable {
	display: flex;
	align-items: center;
	color: $gray-600;
	cursor: pointer;
	&:hover {
		color: $primary;
	}
}

.user_info__clickable.user_info__clickable__danger {
	color: color.adjust($red, $lightness: -20%);
	&:hover {
		color: $red;
	}
}

.user_info__field {
	display: flex;
	margin-top: 5px;
	flex-direction: row;
	margin-left: $spacer * 2;
	margin-right: $spacer;
	align-items: center;
}

.user_info__field.user_info__field__fullname {
	margin-bottom: 0;
	margin-top: 10px;
	font-size: 20px;
	font-weight: 500;
	width: 100%;
	margin-left: 0;
	margin-right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.user_info__field.user_info__field__description {
	display: flex;
	margin-top: 0;
	flex-direction: row;
	margin-right: 0;
	margin-bottom: 0;
	white-space: nowrap;
}

.user_info__field.user_info__field__actions {
	display: flex;
	margin-top: 0;
	flex-direction: row;
	margin-bottom: 0;
}

.user_info__field_label {
	height: 31px;
	padding-top: 4px;
	@include text-truncate();

	min-width: 90px;
	color: #777;
	margin-right: $spacer;

	//  display: flex;
	align-items: center;
	flex-shrink: 0;
}

.user_info__field_label_icon {
	flex-shrink: 0;
	margin-top: -4px;
	color: #dadada;
	margin-right: 5px;
	width: 16px;
}

.user_info__field_label.user_info__field_label__icon {
	align-self: center;
	min-width: 16px;
	color: $gray-600;
	fill: $gray-200;
}

.user_info__add_field_btn {
	border-radius: 5px;
	border: 2px dashed $gray-300;
	margin-left: $spacer * 2;
	margin-right: $spacer * 2;
	margin-top: $spacer;
	cursor: pointer;
	padding: $spacer * 0.5;
	color: $gray-700;
	text-transform: uppercase;
	text-align: center;
}

.user_info__add_field_btn:hover {
	color: $gray-800;
	border: 2px dashed $gray-500;
}

.user_events {
	display: flex;
	flex-direction: column;
}

.user_events__time {
	color: $gray-600;
	display: flex;
	align-items: center;
	font-size: 14px;
	flex-shrink: 0;
}

.user_events__body {
	margin-top: $spacer;
	display: flex;
	flex-direction: column;
}

.profile_section_title {
	@include text-truncate();
	font-weight: 500;
	color: #222;

	&.attribute_label {
		width: 90px;
		margin-right: 10px;
	}
}

.profile_section_handle {
	font-weight: 400;
	text-transform: lowercase;
	user-select: none;
	display: flex;
	align-items: center;
	white-space: nowrap;
	color: $gray-600;
	cursor: pointer;
	&:hover {
		color: $gray-900;
	}
}

//.user_events__link {
//flex: 1;
//@include text-truncate();
//}
.user_events__link {
	display: flex;
	align-items: center;
	margin-top: $spacer * 0.5;
}

.user_events__link_title {
	margin-left: $spacer * 0.5;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-right: $spacer;
	flex: 1;
}

.user_trace {
	border-top: 1px solid $section-border-color;
	margin-bottom: $spacer * 2;
}

.user_trace__location {
	white-space: nowrap;
	display: flex;
	align-items: center;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.campaign_name.campaign_name__organic {
	border-color: #2fa84f;
	color: #2fa84f;
}

.campaign_name.campaign_name__direct {
	border-color: #367bf5;
	color: #367bf5;
}

.campaign_name.campaign_name__referring {
	border-color: #8719e0;
	color: #8719e0;
}

.campaign_name.campaign_name__advertising {
	border-color: $orange;
	color: $orange;
}

.campaign_name.campaign_name__social {
	border-color: $gray-700;
	color: $gray-700;
}

.campaign_name {
	display: inline-block;
	border-radius: 8px;
	background: $white;
	padding: 0px $spacer * 0.5;
	max-width: 150px;
	border: 1px solid $white;
	font-size: 14px;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	flex-shrink: 0;
}


.online-dot {
	width: 10px;
	height: 10px;
	flex-shrink: 0;
	background-color: $green;
	border-radius: 10px;
	margin-right: $spacer * 0.5;
	display: inline-block;
}

.offline-dot {
	width: 10px;
	height: 10px;
	border: 1px solid #b3b3b3;
	background-color: #cacaca;
	border-radius: 10px;
	margin-right: $spacer * 0.5;
}

.activities-other-convo--icon-chanel {
	margin-right: 11px;
	white-space: nowrap;
}

.activities-user-info--open-link {
	margin: 0px 4px;
	vertical-align: text-bottom;
}

.dropdown_container .profile_section__more {
	//margin-left: $spacer * 2;
	//margin-top: $spacer * 0.5;
	cursor: pointer;
	color: $gray-600;
	user-select: none;
	font-size: $font-size-sm;
	border: none;
	&:hover {
		color: $black;
		border: none;
	}
}

.profile_section__more {
	margin-top: $spacer * 0.5;
	cursor: pointer;
	color: $gray-600;
	user-select: none;
	font-size: $font-size-sm;
	border: none;
	&:hover {
		color: $black;
		border: none;
	}
}

.user_convos {
	display: flex;
	flex-direction: column;
	padding-bottom: $spacer;
	border-top: 1px solid $section-border-color;
	// border-bottom: 1px solid $section-border-color;
}

.user_convos__body {
	margin-left: $spacer * 2;
	margin-right: $spacer * 2;
	margin-top: 5px;
	display: flex;
	flex-direction: column;
}

.user_convos__convo_title {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-right: $spacer;
}

.user_convos__convo_title.user_convos__convo_title__clickable {
	cursor: pointer;
	color: $link-color;
	&:hover {
		color: $link-hover-color;
	}
}

.user_convos__convo {
	color: $black;
	margin: $spacer * 0.5 0 0 0;
	display: flex;
	align-items: center;
}

.user_convos__convo_icon {
	position: relative;
	display: inline-block;
}

.user_convos__undone_icon {
	position: absolute;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	bottom: 5px;
	right: 2px;
	background: $blue;
}

.user_orders {
	display: flex;
	flex-direction: column;
	padding-bottom: 10px;
	border-top: 1px solid $section-border-color;
}

.user_profile_labels {
	display: flex;
	flex-direction: column;
	padding-bottom: $spacer;
	border-top: 1px solid $section-border-color;
}

.user_tasks {
	display: flex;
	flex-direction: column;
	padding-bottom: $spacer;
	border-top: 1px solid $section-border-color;
}

.user_tasks__body {
	margin-left: 20px;
	margin-right: 20px;
}

.user_tickets {
	display: flex;
	flex-direction: column;
	padding-bottom: $spacer;
	border-top: 1px solid $section-border-color;
}

.user_notes {
	display: flex;
	flex-direction: column;
	padding-bottom: $spacer;
	border-top: 1px solid $section-border-color;
}

.user_labels__body {
	margin-top: math.div($spacer, 2);
	margin-left: $spacer * 2;
	min-height: 30px;
	margin-right: $spacer * 2;
}

.user_notes__body {
	margin-left: $spacer * 2;
	margin-right: $spacer * 2;
	display: flex;
	flex-direction: column;
}

.user_notes__note_content {
	word-break: break-word;
	white-space: pre-wrap;
}

.user_notes__note.user_notes__note__lock {
	user-select: none;
	opacity: 0.6;
	pointer-events: none;
}

.user_notes__note {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	margin-bottom: $spacer;

	&:last-child {
		margin-bottom: 0;
	}

	&.note__new {
		padding: 0;
	}
	//border-bottom: 1px dashed $section-border-color;
}

.user_notes__note_actions {
	display: flex;
	justify-content: flex-end;
	//align-items: flex-end;
	&:hover {
		.user_notes__note_actions__actions {
			opacity: 1;
			cursor: pointer;
		}

		.user_notes__note_action__edit {
			width: 100%;
			padding-top: 10px;
		}
	}
}

.user_notes__note_content_more {
	cursor: pointer;
	display: inline-block;
	color: $gray-600;
	&:hover {
		color: $black;
	}
}

.user_notes__note_action {
	user-select: none;
	color: $gray-600;
	padding: 0 $spacer * 0.5;
	&:hover {
		color: $primary;
	}
}

.user_notes__note_actions__actions {
	display: flex;
	opacity: 1;
	transition: opacity 0.1s ease;
	white-space: nowrap;
}

.user_notes__note_author {
	color: $gray-600;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	font-size: $font-size-sm;
}

.user_notes__input {
	position: relative;
	width: 100%;
	border-radius: 3px;
	min-height: 70px;
	resize: vertical;
	max-height: 150px;
}

.user_info__avatar_section {
	padding: $spacer $spacer * 2;
	margin-bottom: 15px;
	padding-top: 10px;
	padding-bottom: 20px;
	border-bottom: 1px solid $border-color;
	display: flex;
}

.user_info__avatar_wrapper.not_lead {
	filter: grayscale(1);
}

.user_info__basic_section {
	overflow: hidden;
}

.user_info__avatar {
	flex-shrink: 0;
}

.user_profile__head {
	margin: $spacer $spacer * 2 0 $spacer * 2;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
}

.user_profile__create_convo_btn {
	display: flex;
	white-space: nowrap;
	align-items: center;
}

.order_modal {
	position: fixed;
	overflow: hidden;
	display: flex;
	//	flex-direction: column;
	height: 0;
	overflow: hidden;
	background: white;
	bottom: 0;
	width: 0;
	z-index: 1000; // below z index of light gallerty
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	//	transition: all 250ms linear;
}

.order_modal.full_page {
	max-height: 100%;
	height: 100%;
	border-radius: 0;
	position: absolute;
	right: 0;
	box-shadow:
		0px 1px 8px rgb(0 0 0 / 6%),
		0px 4px 32px rgb(0 0 0 / 6%),
		0px 8px 64px rgb(0 0 0 / 8%);
}

.order_modal.full_page .order_modal__header {
	background-color: $white;
	border-color: $border-color;
}

.order_modal__show {
	right: 20px;
	height: 1000px;
	max-height: 85vh;
	width: unset;

	box-shadow:
		rgb(0 0 0 / 10%) 0px 0px 0px 1px,
		rgb(0 0 0 / 4%) 0px 3px 6px,
		rgb(0 0 0 / 20%) 0px 0px 30px;
}

.order_modal__header {
	background: white;
	padding: 8px 15px 8px 15px;
	display: flex;
	height: 50px;
	border-bottom: 1px solid #dddddd;
	justify-content: space-between;
	align-items: center;
}

.order_modal__footer {
	background: white;
	padding: 10px 20px 10px 20px;
	display: flex;
	border-top: 1px solid #dee2e6;
	align-items: center;
}

.order_modal__header_title {
	flex: 1;
	font-size: 17px;
}

.order_progress {
	padding-right: 10px;
	width: 100%;
	position: relative;
	display: flex;
	height: 27px;
}

.order_progress_region_bg {
	background: #dfdfdf;
	z-index: 3;
	padding: 0 0 0 18px;
	@include text-truncate();
	display: flex;
	align-items: center;
	overflow: hidden;
	justify-content: center;
	width: 100%;
	height: 100%;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
}

.order_progress_region_count {
	display: inline-block;
	padding: 3px 6px;
	font-size: 11px;
	font-weight: 700;
	line-height: 1;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 5px;
	background-color: #00000014;
	margin-left: 6px;
	color: #929292;
}

.order_progress_region {
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-grow: 1;
	padding-right: 17px;
	color: black;
	cursor: pointer;

	&::after {
		border-top-right-radius: 4px;
		width: 19px;
		height: 19px;
		content: '';
		position: absolute;
		background: #dfdfdf;
		right: 7px;
		box-shadow: 0 0 0 3px #efefef;

		top: 4px;
		z-index: 2;

		transform: rotate(45deg);
	}
	&:first-child {
		.order_progress_region_bg {
			padding-left: 10px;
		}
	}

	&:hover {
		.order_progress_region_bg {
			background: #d2d2d2;
		}
		&::after {
			background: #d2d2d2;
		}
	}

	&.order_progress_region__active {
		cursor: default;
		.order_progress_region_bg {
			background: $primary;
			color: white;
			font-weight: 500;
		}

		.order_progress_region_count {
			color: white;
		}

		&::after {
			background: $primary;
		}

		&:hover {
			.order_progress_region_bg {
				background: $primary;
			}
			&::after {
				background: $primary;
			}
		}
	}
}

.order_modal_region {
	display: flex;
}

.order_modal_region_left {
	flex: 2;
	padding-left: 20px;
	flex-shrink: 0;
}

.order_modal_region_right {
	flex: 3;
	flex-shrink: 0;
	margin-left: 20px;
}

.order_modal_region_header {
	margin-top: 20px;
	background: #f5f5f5;
	color: #848484;
	padding: 6px 20px;
}

.order_item_input {
	padding: 0;
	display: block;
	width: 100%;
	background: transparent;
	border: none;
	outline: none;
	&:hover {
	}

	&:active,
	&:focus {
		outline: none;
		// background: #edf4ff;
	}
}

.order_item_price_input {
	font-size: 14px;
	border: 0;
	width: auto;
	border-radius: 5px;
	width: 95px;
	background: white;
	padding: 1px 3px;
	border: 1px solid transparent;
	&:hover,
	&:active,
	&:focus {
		background: #e8e8e8;
		// background: #edf4ff;
		outline: 0;
	}
}

.order_item_price_input::-webkit-outer-spin-button,
.order_item_price_input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
.order_item_price_input[type='number'] {
	-moz-appearance: textfield;
}

.order_item_price_input:empty:before {
	content: attr(placeholder);
	color: gray;
}

.order_item_price_input br {
	display: none;
}

.order_item_quantity {
	border: 0;
	background: white;
	width: auto;
	border-radius: 5px;
	width: 100%;

	&:hover {
		background: #e8e8e8;
		// border-bottom: 1px dashed $primary;
	}

	&:active,
	&:focus {
		background: #e8e8e8;
		// border-bottom: 1px dashed $primary;
		outline: 0;
	}
}

.order_item_quantity::-webkit-outer-spin-button,
.order_item_quantity::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.order_product_suggest_dropdown {
	top: 100%;
	left: 0;
	width: 100%;
	z-index: 20;
	background: white;
	overflow: hidden;
	position: absolute;
	box-shadow:
		0px 1px 8px rgb(0 0 0 / 10%),
		0px 4px 32px rgb(0 0 0 / 10%),
		0px 8px 64px rgb(0 0 0 / 12%);
}

.order_product_suggest_head {
	margin: 0 0 0 0;
	padding: 5px 10px 5px 10px;
	background: white;
	display: flex;
	justify-content: space-between;
	flex-shrink: 0;
	width: 100%;
	border-bottom: 1px solid #e5e5e5;
}

.order_product_suggest_body {
	overflow-y: scroll;
	overflow-x: hidden;
	max-height: 300px;
}

.order_user_suggest_dropdown {
	max-height: 300px;
	background: white;
	z-index: 3;
	overflow: auto;
	position: absolute;
	width: 100%;
	box-shadow:
		0px 1px 8px rgb(0 0 0 / 10%),
		0px 4px 32px rgb(0 0 0 / 10%),
		0px 8px 64px rgb(0 0 0 / 12%);
}

.order_suggest_user {
	position: relative;
	align-items: center;
	display: flex;
	overflow: hidden;
	width: 100%;
	padding: 3px 10px;
	cursor: pointer;
	&:hover {
		background: #edf4ff;
	}
}

.order_product_suggest_product {
	position: relative;
	align-items: center;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 46px;
	padding: 5px 10px;
	cursor: pointer;
	&:hover {
		background: #edf4ff;
	}
}

.order_item_shipping_price {
	border: 0;
	width: 100%;
	background: transparent;
	padding: 0px;
	text-align: right;
	// border-bottom: 1px dashed #aaa;
	&:hover {
		// border-bottom: 1px dashed $primary;
	}
	&:active,
	&:focus {
		// border-bottom: 1px dashed $primary;
		outline: 0;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

.order_info_form_control {
	border: 0;
	background: transparent;

	padding: 0px;
	// border-bottom: 1px dashed #aaa;

	&:hover {
		// border-bottom: 1px dashed $primary;
		text-decoration: underline;
	}

	&:active,
	&:focus {
		// border-bottom: 1px dashed $primary;
		outline: 0;
	}
}

.order_note {
	width: 100%;
	border: 0;
	overflow: hidden;
	padding: 5px 10px;
	min-height: 40px;
	background: whitesmoke;
	font-size: 14px;
	border-bottom-right-radius: 20px;
	resize: none;

	&:hover {
		outline: 0;
	}

	&:active,
	&:focus {
		// border-bottom: 1px dashed $primary;
		outline: 0;
	}
}

.order_shipping_address {
	border: 2px solid #c2c2c2;
	width: 100%;
	padding: 10px 15px;
	border-radius: 10px;
	margin-bottom: 10px;
	background: white;
}

.order_shipping_address.order_shipping_address__active {
	border: 2px solid $primary;
}

.order_shipping_address_label {
	width: 90px;
	margin-top: 5px;
	margin-right: 10px;
}

.order_shipping_address.order_shipping_address__new {
	border: none;
	background: transparent;
	padding: 0;
	margin-bottom: 0;
}

.order_shipping_address_input {
	width: 100%;
	border: 0;
	overflow: hidden;
	padding: 3px 10px;
	color: black;
	background: #f5f5f5;
	border-radius: 4px;

	&:hover {
		outline: 0;
		background: #f0f0f0;
	}

	&:active,
	&:focus {
		background: #f0f0f0;
		// border-bottom: 1px dashed $primary;
		outline: 0;
	}
}

.order_shipping_address_suggestion {
	font-size: 14px;
	border: 1px solid #c2c2c2;
	width: 100%;
	padding: 5px 5px;
	border-radius: 3px;
	margin-bottom: 5px;
	background: white;
	&:hover {
		border: 1px solid $primary;
	}
}

.order_adjustment_input {
	width: 100%;
	border: 1px dashed #888;
	overflow: hidden;
	padding: 2px 5px;
	color: black;
	background: transparent;
	border-radius: 4px;

	&:hover {
		outline: 0;
		background: #f0f0f0;
	}

	&:active,
	&:focus {
		background: #f0f0f0;
		// border-bottom: 1px dashed $primary;
		outline: 0;
	}

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

.dropdown_link__selected.salesperson_selected {
	border: none;
	background: white;
	padding: 2px 8px;
	border-radius: 3px;
	&:hover {
		color: unset;
		border: none;
	}
}

.dropdown_link__selected.tax_selected {
	border: none;
	color: #6c757d;
	&:hover {
		color: $black;
		border: none;
	}
}

.order_modal_label {
	display: inline-block;
	flex-shrink: 0;
	width: 95px;
}

.invoice_sublabel {
	width: 100px;
}

.order_print_sidebar__label {
	font-weight: 500;
	margin-top: 20px;
	margin-bottom: 2px;
}

.user_info_date_input {
	padding-top: 0;
	padding-left: 0;
	padding-bottom: 0;
	flex: 1;
	overflow: hidden;
	border: none;
	box-shadow: none;
	outline: none !important;

	&::placeholder {
		color: $gray-400;
	}

	&:hover::placeholder {
		color: $gray-500;
	}
}

.user_avatar_online_dot {
	width: 20px;
	height: 20px;
	position: absolute;
	bottom: 5px;
	right: 5px;
	border: 3px solid $white;
	background-color: $success;
	border-radius: 50%;
}

.user_avatar_offline_badge {
	position: absolute;
	bottom: 3px;
	right: 0px;
	height: 22px;
	border: 2px solid $white;
	background-color: #e6ffe2;
	color: $success;
	border-radius: 11px;
	padding: 0 5px;
	font-size: 10px;
	line-height: 1;
	display: flex;
	align-items: center;

	&.grey {
		background-color: $gray-100;
		color: $gray-700;
	}
}

.user_trace_container {
	padding: $spacer $spacer * 2;
	border: 2px solid $border-color;
	border-radius: 8px;
	margin: $spacer $spacer * 2;
}

.user_first_event_link_title {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.user_convo_item {
	margin: 0 -10px;
	padding: 6px 10px;
	border-radius: 6px;
	cursor: pointer;

	&:hover {
		background-color: $gray-100;
	}

	&.active {
		background-color: rgba($blue-100, 0.3);
		cursor: auto;
	}
}

.user_convo_item_call_icon {
	&.icon-tabler path:last-child {
		fill: currentColor;
	}
}

.user_convo_item_channel_img {
	position: relative;

	img {
		width: 16px;
		height: 16px;
		overflow: hidden;
		border-radius: 4px;
	}

	&:after {
		content: '';
		border: 1px solid $gray-500;
		width: 8px;
		height: 8px;
		top: 28px;
		left: 6px;
		border-top: none;
		border-right: none;
		position: absolute;
		border-bottom-left-radius: 4px;
	}
}

.user_note_text_action {
	color: $gray-900;
	font-weight: 500;
	cursor: pointer;
	display: inline;
	white-space: nowrap;

	&:hover {
		text-decoration: underline;
	}
}

.user_notes__note_time {
	font-size: 14px;
	color: $gray-600;
}

.user_notes__note_actions__actions {
	font-size: 14px;
	display: flex;
	align-items: center;
	opacity: 0;
	z-index: 1;
	transition: 0.2s;
	min-width: 120px;
}

.user_notes__note:hover .user_notes__note_actions__actions {
	opacity: 1;
}

.user_convo_item_avatar {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	overflow: hidden;
	align-self: center;
	object-fit: cover;
	flex-shrink: 0;

	&.inside {
		width: 30px;
		height: 30px;
		position: absolute;
		border: 2px solid $white;
		z-index: 2;
		bottom: 0;
		left: 0;

		&:last-child {
			top: 0;
			z-index: 1;
			right: 0;
			left: unset;
			bottom: unset;
		}
	}
}

.user_convo_item_avatar_wrapper {
	width: 40px;
	height: 40px;
	align-self: center;
	position: relative;
	flex-shrink: 0;
}

.user_info_order {
	margin-top: $spacer;
	background: whitesmoke;
	border-radius: 8px;
	padding: $spacer $spacer * 1.5;
	cursor: pointer;
	&:hover {
		background: #ececec;
	}
}

.user_info_order_info {
	display: flex;
	align-items: center;
	overflow: hidden;

	&.canceled {
		opacity: 0.8;
		filter: grayscale(1);
		text-decoration: line-through;
		color: $gray-600;
	}
}

.user_info_order_item_img {
	width: 20px;
	height: 20px;
	border-radius: 4px;
	margin-right: $spacer;
	flex-shrink: 0;
}

.user_info_order_items {
	margin-top: 5px;
}

.user_info_order_item {
	display: flex;
	align-items: center;
	font-size: 14px;
	margin-bottom: 5px;

	&:last-child {
		margin-bottom: 0;
	}
}

.user_info_order_item_more {
	background-color: $gray-200;
	border-radius: 8px;
	color: $gray-600;
	padding: 3px 8px;
	font-size: 14px;
	display: inline;
}

.user_profile_count_badge {
	padding: 3px 6px;
	border-radius: 5px;
	background-color: white;
	color: $gray-500;
	line-height: 1;
	font-size: 14px;
	margin-left: 5px;
}

.user_info_assignee_avatar {
	width: 22px;
	height: 22px;
	border-radius: 50%;
	margin-left: -5px;
	border: 1px solid #f8f8f8;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 9px;
	line-height: 10px;
	position: relative;

	&:first-child {
		margin-left: 0;
		z-index: 2;
	}

	&:nth-child(2) {
		z-index: 1;
	}

	&:last-child {
		z-index: 0;
	}

	&.more {
		background-color: #c4c4c4;
		color: $gray-700;
		cursor: default;
	}
}

.user_info_source_badge {
	font-size: 14px;
	line-height: 1;
	font-weight: 500;
	background-color: $gray-200;
	border-radius: 8px;
	color: $gray-600;
	padding: 3px 8px;
}

.user_channel_icon {
	width: 24px;
	height: 24px;
	border-radius: 4px;
}

.user_action_icon {
	width: 32px;
	height: 32px;
	display: flex;
	border-radius: 4px;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&.icon__phone {
		background-color: $success;
		color: $white;
	}
}

.user_action_icon_img {
	width: 20px;
	height: 20px;
	object-fit: cover;
	filter: grayscale(1);
}

.user_action_icon_button {
	width: 32px;
	height: 32px;
	display: flex;
	border-radius: 4px;
	padding: 0;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&.btn {
		padding: 0;
	}

	&.icon__phone {
		background-color: $success;
		color: $white;
	}
}

.user_action_icon_wrapper {
	margin-right: $spacer;

	&:last-child {
		margin-right: 0;
	}
}

.user_info_dropdown_row {
	margin-bottom: $spacer;
	display: flex;
	align-items: center;

	&:last-child {
		margin-bottom: 0;
	}
}

.user_info_dropdown_header {
	padding: $spacer $spacer * 1.5;
	border-bottom: 1px solid $border-color;
	display: flex;
	align-items: center;
	width: 350px;
}

.user_info_dropdown_content {
	padding: $spacer * 1.5;
	width: 350px;
}

.content_view_line {
	position: relative;
	width: 10px;
	border-left: 2px solid #e0e0e0;
	margin-left: 6px;
}

.content_view_dot {
	width: 10px;
	height: 10px;
	background: #e0e0e0;
	border-radius: 50%;
	position: absolute;

	top: 50%;
	left: -6px;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
}

.content_view__time {
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	color: $gray-600;
}

.content_view_line {
	position: relative;
	width: 10px;
	border-left: 2px solid #e0e0e0;
	margin-left: 6px;
}

.content_view_dot {
	width: 10px;
	height: 10px;
	background: #e0e0e0;
	border-radius: 50%;
	position: absolute;

	top: 50%;
	left: -6px;
	-webkit-transform: translate(0, -50%);
	-ms-transform: translate(0, -50%);
	transform: translate(0, -50%);
}

.content_view__time {
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	color: $gray-600;
}

.page_item {
	// border: 2px solid whitesmoke;
	padding: 10px 15px;
	border-radius: 8px;
	display: flex;
	background: whitesmoke;
	margin-bottom: 10px;
	height: 80px;
}

.page_item:hover {
	// border: 2px solid #d9d9d9;
}

.page_item.page_item__selected {
	background: #eef7ff;
	// border: 2px solid #eef7ff;
}

.primary_star_icon {
	stroke: $gray-500;
	cursor: pointer;
	&:hover {
		stroke: $gray-600;
	}

	&.active {
		stroke: #ffc107;
		cursor: default;
		&:hover {
			stroke: #ffc107;
		}

		path:last-child {
			fill: #ffeb3b;
		}
	}
}

.contact_profile_btn_wrapper {
	margin-right: $spacer;
	margin-bottom: $spacer;
	line-height: 1;

	&:last-child {
		margin-right: 0;
	}
}

.contact_profile_btn {
	width: 22px;
	height: 22px;
	object-fit: cover;
	overflow: hidden;
}

.contact_profile_dropdown_header {
	padding: 8px 15px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid $border-color;
}

.contact_profile_dropdown_avatar_wrapper {
	flex-shrink: 0;
	position: relative;
}

.contact_avatar_channel_wrapper {
	width: 24px;
	height: 24px;
	border-radius: 9999px;
	background-color: $white;
	position: absolute;
	bottom: -6px;
	right: -6px;
	z-index: 1;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
}

.contact_avatar_channel {
	width: 18px;
	height: 18px;
	border-radius: 9999px;
	object-fit: cover;
}

.contact_profile_dropdown_body {
	padding: 8px 15px;
}

.contact_profile_info_label {
	width: 140px;
	flex-shrink: 0;
	@extend .text__truncate;
	@extend .text__muted;
}

.contact_profile_dropdown {
	position: relative;
	width: 400px;
}

.user_info__field_primary_input_wrapper {
	@extend .text__truncate;
	flex: 1;
}

.user_info__field_primary_input {
	//display: inline-block;
	@extend .link;
  @extend .action;
}

.true_user_profile_card {
	border: 1px solid $border-color;
	border-radius: 8px;
}

.true_user_profile_section {
	padding-left: 20px;
	padding-right: 20px;
	margin-bottom: 15px;
}

.true_user_profile_card_header {
	display: flex;
	padding: 8px 15px;
	overflow: hidden;
}

.true_user_profile_card_body {
	padding: 8px 15px;
	border-top: 1px solid $border-color;
}

.user_info__field_label.primary_attr_label {
	align-self: flex-start;
}

.user_attr_cb_wrapper {
	width: calc(50% - 10px);
	overflow: hidden;
	margin-right: 10px;
	margin-bottom: 5px;

	&:nth-child(2n) {
		margin-right: 0;
	}
}
