.main {
	display: flex;
	overflow: auto;
	position: relative;
	flex: 1;
	display: flex;
	justify-content: space-between;
}

.setting {
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;
	overflow-y: auto;
}

.setting_container {
	padding: $spacer * 2 $spacer * 4;
}

.setting__main {
	position: relative;
	display: flex;
	flex: 1;
	flex-direction: column;
	min-width: 1px;
	min-height: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
}

.setting__title {
	font-size: 20px;
	font-weight: $font-weight-bold;
	color: $primary;
	margin-bottom: 20px;
}

.setting_general_label {
	margin-top: 20px;
	margin-bottom: 5px;
}

// general setting
.general_setting__logo {
	position: relative;
	width: 150px;
	height: 150px;
}

.general_setting__logo_url {
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 10px;
	border: 2px solid #e0e0e0;

	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.general_setting__logo_background {
	position: absolute;
	text-align: center;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.72);
	border-radius: 10px;
	visibility: hidden;
}

.general_setting__logo:hover .general_setting__logo_background {
	visibility: visible;
}

.general_setting__logo_background {
	color: #fff;
	font-size: 10px;
	cursor: pointer;
}

.general_setting_icon_social {
	position: absolute;
	padding-left: $spacer * 3;
}

// message template setting
.list_template_table {
	min-width: 400px;

	.list_template_table__name {
		width: 35%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		min-width: 100px;
		max-width: 200px;
	}

	.list_template_table__type {
		width: 15%;
	}

	.list_template_table__shortcut {
		width: 30%;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		min-width: 100px;
		max-width: 200px;
	}

	.list_template_table__scope {
		width: 10%;
	}

	.list_template_table__action {
		width: 10%;
	}
}

// My profile
.my_profile__logo {
	position: relative;
	width: 100px;
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.my_profile__logo_url {
	position: absolute;

	max-width: 100%;
	max-height: 100%;
	border-radius: 10px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

// Notification setting
.notification_setting__dnd {
	font-size: $font-size-base;
	display: flex;
	margin-left: $spacer;
	font-weight: normal;
	position: relative;
	cursor: pointer;
	color: $gray-600;
	stroke: $gray-600;

	.dropdown {
		color: $black;
	}

	&:hover {
		color: $black;
		stroke: $black;
	}
}

.notification_setting__container {
	margin-top: $spacer;
	border: 1px solid #ddd;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	padding: $spacer * 2;
}

.notification_setting__title {
	font-weight: 500;
}

.notification_setting {
	margin: $spacer * 3;
	max-width: 600px;
	padding: $spacer $spacer * 3;
}

.notification_setting__section {
	margin-top: $spacer * 3;
	font-size: $h5-font-size;
	display: flex;
	justify-content: space-between;
}

.endchat_setting_tab__channel_icon {
	width: 18px;
}

.setting_footer {
	display: flex;
	border-top: 1px solid $section-border-color;
	padding: $spacer $spacer * 3;
	opacity: 1;
	transition: all 150ms ease-out;
	overflow: hidden;
	visibility: visible;
}

.setting_footer.setting_footer__hidden {
	height: 0px;
	padding-top: 0;
	padding-bottom: 0;
	visibility: hidden;
}

.setting {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.setting_body {
	flex: 1;
	overflow: auto;
	margin: $spacer * 2 $spacer * 4;
}

.sidebar_setting {
	background-color: #f7f5f2;
	border-right: 1px solid #a6917133;
	// border-right: 1px solid $section-border-color;
	z-index: 1;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	min-width: 240px;
	max-width: 270px;
	overflow-y: auto;
	padding-bottom: 30px;
}

.sidebar_setting__menu {
	margin-left: $spacer * 3;
	padding-top: $spacer * 2;
	font-weight: 500;
}

.sidebar_setting__menu_profile {
	text-align: center;
	padding: 30px 10px;
	border-bottom: 1px #ddd solid;
}

.sidebar_setting__menu_label {
	color: #838d94;
	font-weight: 500;
	font-size: 15px;
	text-transform: uppercase;
	word-wrap: break-word;
	cursor: pointer;
}

.sidebar_setting__sub_menu {
	display: flex;
	flex-direction: column;
	margin-left: $spacer * 3;
	color: $body-color;
}

.sidebar_setting__sub_menu_label {
	padding-bottom: 2px;
	padding-top: 2px;
	cursor: pointer;
	color: $body-color;
	display: flex;
	align-items: center;
}

.item-row.item-row__disabled {
	opacity: 0.6;
}

.item-row {
	border-bottom: 1px solid #dee2e6;
	display: flex;
	padding: $spacer 0;
	align-items: center;
	transition: all 150ms ease-out;

	&:not(.iten-row__header):hover {
		background-color: whitesmoke;
	}

	&:hover {
		.item-row__musthover {
			visibility: visible;
		}
	}
}

.item-row.item-row__hot {
	background: #fff8ef;
}

.item-row__musthover {
	visibility: hidden;
}

.item-row.item-row__header {
	user-select: none;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	color: $text-muted;

	&:hover {
		background-color: transparent;
	}
}

.webhook_code {
	overflow: auto;
	background: whitesmoke;
	padding: $spacer;
	border-radius: 5px;
	white-space: pre-wrap;
	font-size: 14px;
	font-family: $font-family-monospace;
	position: relative;

	&:hover {
		.webhook_code_copy_icon {
			display: block;
		}
	}
}

.webhook_code_copy_icon {
	position: absolute;
	top: 15px;
	right: 15px;
	display: none;
	cursor: pointer;
}

.fanpage-settings-sidebar {
	height: 100%;
	width: 500px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.fanpage-settings-sidebar__head {
	display: flex;
	align-items: center;
	padding: 10px 30px;
	border-bottom: 1px solid $border-color;
}

.fanpage-settings-sidebar__body.fanpage-settings-sidebar__body__disabled {
	opacity: 0.5;
	pointer-events: none;
	user-select: none;
}

.fanpage-settings-sidebar__body {
	padding: $spacer * 2 $spacer * 3;
	flex-shrink: 0;
	flex: 1;
	overflow-y: auto;
}

.fanpage-settings-sidebar__foot {
	display: flex;
	align-items: center;
	padding: $spacer $spacer * 3;
	border-top: 1px solid $border-color;
	opacity: 0;
	pointer-events: none;

	&.active {
		opacity: 1;
		pointer-events: auto;
	}
}

.fanpage-settings-sidebar__title {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.fanpage-settings-sidebar__section {
	margin-bottom: $spacer * 3;

	&:last-child {
		margin-bottom: 0;
	}
}

.fanpage-settings-sidebar__section-title {
	// font-size: 16px;
	// text-transform: uppercase;
	font-weight: 500;
	// color: $primary;
	display: flex;
	align-items: center;
}

.fanpage-settings-sidebar__section-head {
	display: flex;
	align-items: center;
	margin-bottom: $spacer;
}

.fanpage-settings-sidebar__section-subtitle {
	color: $gray-600;
	max-width: 80%;
}

.fanpage-settings-sidebar__form {
	margin-bottom: $spacer * 2;

	&:last-child {
		margin-bottom: 0;
	}
}

.fanpage-settings-sidebar__form-head {
	display: flex;
	align-items: center;
}

.fanpage-settings-sidebar__form-title {
	line-height: 1.3;
	display: flex;
	user-select: none;
	align-items: center;
	margin-bottom: 0;
}

.fanpage-settings-sidebar__form-subtitle {
	color: $gray-600;
	margin-top: $spacer * 0.5;
}

.fanpage-settings-sidebar__form-content {
	margin-top: $spacer;
	margin-left: 28px;
}

.fanpage-post {
	border: 1px solid $border-color;
	border-radius: 8px;
	margin-bottom: $spacer;

	&.post--small {
		border: none;
		marg-bottom: none;
		border-radius: 0;
		border-top: 1px solid $border-color;
	}

	&:last-child {
		marggin-bottom: 0;
	}

	&.selected {
		border-color: $primary;
		box-shadow: inset 0 0 0 1px $primary;
	}

	&:hover .fanpage-post__link {
		opacity: 1;
	}
}

.fanpage-post__link {
	opacity: 0;
	transition: 0.3s;
}

.fanpage-post__list {
	flex: 1;
	overflow-y: scroll;
	overflow-x: hidden;
	padding-right: $spacer * 0.5;
}

.fanpage-post__img {
	max-width: 100%;
	max-height: 200px;
	margin-top: $spacer;
}

.fanpage-post__content {
	padding: $spacer * 2 $spacer * 1.5;

	&.post--small {
		padding: $spacer 0 $spacer 0;
		font-size: 12px;
	}
}

.fanpage-post__footer {
	padding: $spacer $spacer * 1.5;
	border-top: 1px solid $border-color;
	display: flex;
	align-items: center;
	font-size: 12px;
	justify-content: space-between;
}

.fanpage-post-select__head {
	padding: $spacer $spacer * 2;
	border-bottom: 1px solid $border-color;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.fanpage-post-select__body {
	padding: $spacer * 2;
	flex: 1;
	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
}

.fanpage-post-select__footer {
	padding: $spacer $spacer * 2;
	border-top: 1px solid $border-color;
	text-align: right;
}

.fanpage-post-select {
	height: 80vh;
	display: flex;
	flex-direction: column;
}

.web_check__status_tag {
	padding: 2px 5px;
	font-weight: 500;
	font-size: 12px;
	display: inline-block;
	text-transform: uppercase;

	&.big {
		padding: 8px 15px;
		font-size: 18px;
	}

	&.up {
		background-color: $green-500;
		color: white;
	}

	&.decreased {
		background-color: #fff3ce;
		color: $yellow-600;
	}

	&.down {
		//background-color: #fbf0f1;
		background-color: $red-500;
		color: $white;
	}
}

.calendar_dot__row {
	display: flex;
	flex-wrap: wrap;
	width: 150px;
}

.calendar_dot {
	width: 12px;
	height: 12px;
	margin-right: 2px;
	margin-bottom: 2px;
	background-color: #eeeeef;
	border: 1px solid #dadada;
	border-radius: 2px;
	cursor: pointer;

	&.disabled {
		opacity: 0;
		pointer-events: none;
	}

	&.small {
		margin-right: 0;
		margin-bottom: 0;
	}

	&.up {
		background-color: #5ec278;
		border-color: #2fa84f;

		&.white {
			background-color: $white;
		}
	}

	&.down {
		background-color: #ff5a4d;
		border-color: #ea3d2f;

		&.white {
			background-color: $white;
		}
	}

	&.decreased {
		background-color: #cddc39;
		border-color: #adb930;

		&.white {
			background-color: $white;
		}
	}
}

.web_check__report_card__title {
	color: $gray-600;
	font-size: 15px;
	text-transform: uppercase;
}

.web_check__report_card__value {
	font-size: 40px;
	font-weight: 300;
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;

	&.success {
		color: $success;
	}

	&.danger {
		color: $danger;
	}

	&.warning {
		color: $warning;
	}
}

.web_check__report_card__unit {
	font-size: 15px;
	color: $gray-600;
	position: relative;
	top: 8px;
}

.web_check__report_card {
	min-width: 350px;
	min-height: 140px;
	width: 30%;
	border-radius: 8px;
	padding: $spacer * 1.5 $spacer * 2;
	text-align: center;
	box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
	display: flex;
	flex-direction: column;
}

.web_check_report {
	padding: $spacer * 2;
	font-size: 16px;
	max-width: 1140px;
}

.web_check_report__url {
	color: $gray-600;
	font-size: 24px;
	cursor: pointer;
	display: flex;
	align-items: center;
}

.web_check_report__text_lighter {
	color: $gray-500;
}

.web_check_report__main_uptime {
	font-size: 60px;
	font-weight: 300;
	line-height: 1.1;
}

.web_check_report__main_uptime_sub {
	font-size: 16px;
	font-weight: normal;
	color: $gray-500;
}

.web_check__timeline {
	height: 20px;
	font-size: 10px;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;

	&.up {
		//border-top: 2px solid $success;
		background-color: #e6f7e3;
		color: $success;
	}

	&.down {
		//border-top: 2px solid $danger;
		background-color: $danger;
		color: $danger;
	}

	&.missing {
		//border-top: 2px solid $gray-700;
		background-color: $gray-200;
		color: $gray-700;
	}
}

.lang_textarea {
	border: 0;
	resize: none;
	// border-left: 1px solid red;
	border-radius: 5px;
	//border-top: 1px solid red;
	background: $gray-200;
	margin: 3px;
	flex: 1;
	flex-shrink: 0;

	&:hover,
	&:focus,
	&:active {
		background: $gray-300;
	}
}

.lang_setting__locale {
	display: flex;
	align-items: center;
	font-size: 16px;
	margin-right: 20px;
	user-select: none;
	cursor: pointer;
	color: #333;

	&:hover {
		color: $black;
		filter: none;
	}
}

.lang_edit_body {
	padding: 0;
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: hidden;
}

.setting_lang_add {
	width: 470px;
	height: 600px;
	position: relative;
	overflow: auto;
	background: white;
	display: flex;
	flex-direction: column;
}

.lang_edit_footer {
	background: #f8f8f8;
	padding: 10px 20px;
	padding-right: 28px;
	display: flex;
	border-top: 1px solid #dfdfdf;
	justify-content: flex-end;
}

.lang_textarea.lang_textarea__dirty {
	background: #fffac9;

	&:hover,
	&:focus,
	&:active {
		background: #fff6a1;
	}
}

.rule_sublabel {
	font-weight: 500;
	font-size: 16px;
}

.device_row.device_row__expired {
	background-color: #ffe8e8;
}

.device_row {
	display: flex;
	margin-top: 10px;
	background: #f5f5f5;
	transition: background 150ms;
	border-radius: 5px;
	align-items: center;
	padding: 10px 20px;

	.device_row__end_btn {
		opacity: 0;
	}

	&:hover {
		.device_row__end_btn {
			opacity: 1;
		}
	}
}

.device_row.device_row__loggingout {
	background: #ffeeee;
}

.setting_section_label {
	font-size: 19px;
	margin-top: 20px;
}

.profile_show_password {
	background: white;
	font-size: 14px;

	height: 26px;
	width: 40px;
	text-align: center;
	display: block;
	margin-left: auto;
	margin-right: 3px;
	color: #495057;
	margin-top: -28px;
	cursor: pointer;
}

.outgoing_email_type_radio_box {
	border-radius: 8px;
	flex: 1;
	overflow: hidden;
	margin-bottom: 20px;
	border: 1px solid $border-color;
	padding: 12px 20px;
	display: flex;
	cursor: pointer;

	&.active {
		border-color: $primary;
		box-shadow: 0 0 0 1px $primary;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.email_step_picture {
	max-width: 100%;
	max-height: 220px;
	border: 1px solid #dbdbdb;
	border-radius: 8px;
}

.dns_record_name {
	word-break: break-word;
	width: 100%;
	white-space: pre-wrap;
}

.setting_fb_comment_text_input {
	.lexical_editor_wrapper {
		background-color: whitesmoke;
		border-radius: 8px;
		border: none;

		.lexical_editor_toolbar {
			border: none;
		}
	}

	.lexical_editor_input {
		min-height: 50px;
	}

	.lexical_editor_toolbar_btn {
		background-color: whitesmoke;
		color: $gray-600;

		&:hover {
			background-color: whitesmoke;
			color: #000;
		}
	}
}

.setting_perm_type {
	.perm_type_setting_option {
		height: 40px;
	}
}

.perm_setting_title {
	font-size: 18px;
	font-weight: 600;
}

.perm_setting_item_container {
	border: 1px solid #dee2e6;
	border-radius: 4px;
	padding: 10px 20px;
	margin: 10px 0px;

	.perm_setting_item_header {
		display: flex;
		padding-right: 8px;
		padding-bottom: 4px;
		align-items: center;
		justify-content: space-between;
	}

	.perm_setting_item_header_title {
		font-size: 16px;
		font-weight: 600;
	}

	.perm_setting_item_content {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 4px 0px;
	}

	.perm_setting_item_label {
		display: flex;
		align-items: center;
		font-weight: 600;
		max-width: 60%;
	}

	.perm_setting_dropdown {
		border: none;
		height: 30px;
		padding-right: 30px;
		font-weight: 500;
		background-position: top 10px right 0.75rem;
	}

	.selected_all {
		font-weight: 600;
		color: #0d6efd;
	}
}
.container_focus {
	border: 0.5px solid #c3c7cb;
}

.disable_setting_perm {
	pointer-events: none;
}

.setting_ticket_type_menu_wrapper {
	display: flex;
	align-items: center;
	overflow: hidden;
	padding-right: 10px;
	padding: 2px 0;
	color: $gray-700;

	&:hover,
	&.active {
		color: $primary;

		.sidebar_setting__sub_menu_label {
			color: $primary;
		}
	}
}

.setting_ticket_type_menu_caret {
	color: $gray-600;
}

.reply_editor {
	.ProseMirror {
		border: 1px solid #cacaca;
		border-radius: 0.25rem;
		padding: 5px 10px;
		min-height: var(--editor-height);
	}

	p {
		margin: 0;
	}
}

.reply_actions {
	display: flex;
	justify-content: end;
	padding: 8px 0px;
}

.email_satisfy_title {
	.ProseMirror {
		outline: none;
		min-height: 30px;
		.ProseMirror-widget {
			opacity: 0.5;
		}
	}
}

.invite_agent_body {
	padding: 0 30px;
}

.invite_agent_footer {
	padding: 10px 30px 0 30px;
	display: flex;
	align-items: center;
}

.invite_agent_link {
	border: 1px solid #dfdfdf;
	border-radius: 4px;
	padding: 8px 10px;
	line-height: 19px;
	// width: 300px;
	// white-space: nowrap;
	// overflow: hidden;
	// text-overflow: ellipsis;
}

.form_invite_agent_container {
	height: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	line-height: 19px;
	padding: 10px 0px;
	overflow: hidden;
}
.form_invite_agent_container.email {
	border-top: 1px solid #dee2e6;
	height: 100%;
}

.input_invite_email {
	width: 100%;
	padding: 0 30px;
	margin-bottom: 5px;
}

.input_email_error {
	border: 1px solid #ea3d2f;
}

.invite_email_body {
	padding: 0 30px;
}

.img_card_brand {
	max-width: 40px;
	max-height: 30px;
	object-fit: cover;
}

.credit_card_item {
	display: flex;
	align-items: center;
	padding: 6px 15px;
	border: 1px solid #dee2e6;
	border-radius: 4px;
	margin: 5px 0px;
}

.action_card_payment {
	padding: 4px;
	cursor: pointer;
	display: flex;
	align-items: center;
}
