.user_label__delete {
	opacity: 0.6;
	&:hover {
		opacity: 1;
	}
}

.user_label__container {
	background: white;
	margin: 5px 5px 5px 0;
	border-radius: 4px;
	&:only-child {
		margin: 0 5px 0 0;
	}
}

.user_label {
	margin: 1px 5px 1px 0;
	display: inline-flex;
	align-items: center;
	padding: 2px 7px;
	border-radius: 10px;
	font-size: 13px;
	font-weight: 500;
	background: #f0f0f0;
	white-space: nowrap;
	color: #000;

	cursor: pointer;
	user-select: none;
}

.user_label__title {
	white-space: nowrap;
	line-height: 1.2;
	overflow: hidden;
	max-width: 150px;
	text-overflow: ellipsis;
}

.user_label.user_label__add_btn {
	border: 1px dashed $gray-600;
	color: $gray-800;
	&:hover {
		border: 1px dashed $black;
		color: $black;
	}
}

.user_labels {
	display: flex;
	align-items: center;
	width: 100%;
	flex-wrap: wrap;
}

.dropdown.convo_header__tag_dropdown__full {
	left: 0;
	.dropdown__item.dropdown__item__thin {
		padding: 2px 10px;
	}

	.dropdown__item:hover {
		color: white;
		background: #c4d0e6;
	}
}
