.channel_picker__channel {
	display: flex;
	position: relative;
	cursor: pointer;
	user-select: none;
	&:hover {
		.channel_picker__channel__remove_btn {
			height: 20px;
			width: 20px;
			opacity: 1;
			transition: all 0.1s ease;
			right: 2px;
			top: -3px;
		}
	}
}

.channel_picker__channel.channel_picker__channel__disabled {
	opacity: 0.5;
	filter: grayscale(100%);
	&:hover {
		filter: grayscale(0%);
		opacity: 0.7;
	}
}

.channel_picker__channel__remove_btn {
	outline: none;
	transition: all 0.1s ease;
	height: 0px;
	width: 0px;
	opacity: 0;
	position: absolute;
	stroke: $gray-700;
	background-color: $white;
	border-radius: 50%;
	cursor: pointer;
	padding: 2px;

	right: 5px;
	top: 10px;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	z-index: 100;
}
