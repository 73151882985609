.input_search {
  position: relative;
  display: flex;
  align-items: center;
}

.input_search__icon_search {
  position: absolute;
  left: 10px;
	margin-top: -2px;
	color: $gray-600;
}

.input_search__icon_close {
  position: absolute;
  right: 10px;
  cursor: pointer;
  width: 16px;
  height: 16px;
	padding: 2px;
	background: $gray-300;
  stroke: $white;
	border-radius: 50%;
	stroke-width: 3px;
	transition: all 150ms ease-out;
  &:hover {
		background: $gray-500;
  }
}

.input_search__input {
  padding-left: 30px;
  padding-right: 30px;
}

.input_search__input.input_search__input__active {
	border: solid 1px transparent;
	box-shadow: 0 0 0 1px $primary;
}

.input_search__icon_loading {
  position: absolute;
  right: 10px;
}
