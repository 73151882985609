.pipeline_stages_container {
	display: flex;
	flex: 1;
	width: 100%;
	overflow-y: auto;
}

.pipeline_stage_column {
	display: flex;
	flex-direction: column;
	width: 300px;
	margin-right: $spacer * 2;
	border-radius: 8px;
	overflow: hidden;
	flex-shrink: 0;

	&:last-child {
		margin-right: 0;
	}
}

.pipeline_stage_header {
	display: flex;
	align-items: center;
	background-color: $gray-200;
	padding: $spacer;
	font-weight: 500;
}

.pipeline_stage_order_count {
	margin-left: 8px;
	padding: 2px 5px;
	line-height: 1;
	font-size: 10px;
	background-color: $gray-400;
	color: $gray-700;
	font-weight: 400;
	border-radius: 4px;
	align-self: center;
  flex-shrink: 0;
}

.pipeline_stage_order_total {
	margin-left: auto;
	text-transform: initial;
	font-size: 15px;
	font-weight: 400;
  flex-shrink: 0;
}

.pipeline_stage_dropzone {
	background-color: #eee;
	flex: 1;
	padding: $spacer;
	overflow-y: auto;

	&.dragin {
		background-color: #f3f3f3;
	}
}

.pipeline_order_card {
	background-color: $white;
	position: relative;
	border-radius: 8px;
	padding: $spacer;
	margin-bottom: $spacer;
	display: flex;
	align-items: center;
	transition: background 0.2s;
	cursor: pointer;

	&.just-dragin {
		background-color: $yellow-100;
	}

	&.dragging {
		opacity: 0.5;
		cursor: grabbing;
		//display: none;
		//visibility: hidden;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.pipeline_order_card_content {
	flex: 1;
	margin-left: $spacer;
	font-size: 14px;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.pipeline_order_card_agent {
	border-radius: 50%;
	width: 20px;
	height: 20px;
	object-fit: cover;
}

.edit_pipeline_stage_bar {
	background: $gray-200;
	margin-bottom: $spacer * 1.5;

	&:last-child {
		margin-bottom: 0;
	}
}

.edit_pipeline_stage_bar_header {
	display: flex;
	align-items: center;
	padding: $spacer;
}

.edit_pipeline_stage_bar_content {
	padding: $spacer $spacer * 2;
	border-top: 1px solid $border-color;
}

.pipeline_order_card_due_date {
	display: flex;
	align-items: center;
	margin-left: auto;
	flex-shrink: 0;
	align-self: flex-end;

	&.overdue {
		padding: 2px 6px;
		border-radius: 4px;
		background-color: rgba($red-400, 0.1);
		color: $danger;
		font-size: 12px;
	}
}
