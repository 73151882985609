.toaster {
	display: flex;
	align-items: center;
	padding: 10px 15px;
	box-shadow:
		rgba(67, 90, 111, 0.3) 0px 0px 1px,
		rgba(67, 90, 111, 0.47) 0px 8px 10px -4px;
	border-radius: 5px;
}
.toaster__content {
	padding: 2px 15px;
	min-width: 300px;
	width: 100%;
}
.toaster__icon_status__success {
	background-image: url('../img/success.svg');
	background-repeat: no-repeat;
}
.toaster__icon_status__warning,
.toaster__icon_status__hellobar {
	background-image: url('../img/warning.svg');
	background-repeat: no-repeat;
}
.toaster__icon_status__error {
	background-image: url('../img/error.svg');
	background-repeat: no-repeat;
}
.toaster__border__success {
	background: #2fa84f;
}
.toaster__border__warning,
.toaster__border__hellobar {
	background: #f3aa18;
}
.toaster__border__hellobar {
	white-space: nowrap;
}
.toaster__border__error {
	background: #ea3d2f;
}
.toaster__title {
	font-weight: 500;
	color: #ffffff;
}
.toaster__descrption {
	font-weight: nomal;
	color: #ffffff;
}
.toaster__icon_status {
	width: 25px;
	height: 20px;
}
.toaster__iconclose {
	color: #ffffff;
	cursor: pointer;
	width: 20px;
	height: 20px;
}

// this is css for new standard noti message
.noti_message_item_wrapper {
	padding: 15px;
	width: 360px;
	overflow: hidden;
	margin: -20px 0;

	&:last-child {
		margin-bottom: 0;
	}

	&.overlap {
		cursor: pointer;
	}
}

.noti_message_item {
	width: 100%;
	display: flex;
	padding: 12px 16px;
	background-color: $white;
	border-radius: 16px;
	box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
	position: relative;
	//overflow: hidden;
	// dont set overflow hidden because it break overlap noti css

	&.danger {
		background-color: $danger;
		color: $white;
		.noti_message_item_icon {
			color: $danger;
		}

		.noti_message_item_primary_btn {
			background-color: rgba(0, 0, 0, 0.25);
			&:hover {
				background-color: rgba(0, 0, 0, 0.35);
			}
		}
	}

	&.success {
		background-color: #333;
		color: #eee;
		.noti_message_item_icon {
			color: #333;
		}

		.noti_message_item_primary_btn {
			background-color: rgba(0, 0, 0, 0.25);
			&:hover {
				background-color: rgba(0, 0, 0, 0.35);
			}
		}
	}

	&.warning {
		background-color: #ffaa00;
		color: black;
		.noti_message_item_icon {
			color: #ffaa00;
		}

		.noti_message_item_primary_btn {
			background-color: rgba(0, 0, 0, 0.25);
			&:hover {
				background-color: rgba(0, 0, 0, 0.35);
			}
		}
	}
}

.noti_message_item_icon_wrapper {
	flex-shrink: 0;
	width: 20px;
	height: 20px;
	margin-right: 10px;
	border-radius: 50%;
	background-color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	top: 2px;

	&.big {
		width: 32px;
		height: 32px;
		top: 0;

		.noti_message_item_icon {
			width: 24px;
			height: 24px;
			stroke-width: 2;
		}
	}
}

.noti_message_item.success {
	.noti_message_item_icon_wrapper {
		background-color: $success;
		color: white;
	}
}

.noti_message_item.warning {
	.noti_message_item_icon_wrapper {
		background-color: black;
		color: white;
	}
}

.noti_message_item_icon {
	width: 14px;
	height: 14px;
	stroke-width: 2.5;
}

.noti_message_item_content {
	flex: 1;
	overflow: hidden;
}

.noti_message_item_buttons {
	margin-top: 10px;
	display: flex;
	align-items: center;
	font-size: 14px;
}

.noti_message_item_primary_btn {
	background-color: $primary;
	border-radius: 4px;
	padding: 4px 12px;
	color: $white;
	margin-right: 8px;
	font-weight: 500;
	cursor: pointer;

	&:hover {
		background-color: $blue-600;
	}
}

.noti_message_item_secondary_btn {
	cursor: pointer;
	color: $primary;

	&:hover {
		background-color: $blue-600;
	}
}

.noti_message_item_close_btn {
	flex-shrink: 0;
	margin-left: 5px;
	width: 24px;
	height: 24px;
	cursor: pointer;
	border-radius: 50%;
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	color: $gray-600;
	top: 2px;

	&:hover {
		color: $gray-700;
		background-color: rgba(0, 0, 0, 0.4);
	}
}

.noti_message_item.warning,
.noti_message_item.success,
.noti_message_item.danger {
	.noti_message_item_close_btn {
		background-color: rgba(0, 0, 0, 0.25);
		color: $white;

		&:hover {
			background-color: rgba(0, 0, 0, 0.35);
			color: $white;
		}
	}
}

.noti_message_item_img_wrapper {
	width: 50px;
	flex-shrink: 0;
	margin-right: 10px;
}

.noti_message_item_img {
	width: 100%;
	object-fit: cover;
}

.noti_message_item_wrapper.overlap .noti_message_item:before {
	content: '';
	position: absolute;
	top: 6px;
	left: 6px;
	z-index: -1;
	width: 100%;
	height: 100%;
	background: $white;
	border-radius: 8px;
	box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
}
.noti_message_item_wrapper.overlap.overlap_double .noti_message_item:after {
	content: '';
	position: absolute;
	top: 12px;
	left: 12px;
	z-index: -2;
	width: 100%;
	height: 100%;
	background: $white;
	border-radius: 8px;
	box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
}

.noti_message_item_overdue_task_desc {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	white-space: pre-wrap;
	font-size: 14px;
}

.noti-msg-list-enter-active {
	transition: all 0.3s;
}
.noti-msg-list-leave-active {
	transition: all 0.3s;
	position: absolute;
}

.noti-msg-list-enter,
.noti-msg-list-leave-to {
	opacity: 0;
	transform: translateY(100px);
}

.noti_message_clear_all_btn.btn {
	margin-left: 15px;
	margin-top: -10px;
	padding-left: 12px;
	padding-right: 12px;
	opacity: 0.8;
	transition: 0.2s;
	pointer-events: auto;
	border-radius: 15px;
	font-weight: 500;
	box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);

	&.hidden {
		opacity: 0;
		pointer-events: none;
		display: none;
	}
}
