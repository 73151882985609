.call_settings_sidebar {
	width: 500px;
	height: 100%;
	position: relative;
	overflow: hidden;
}

.call_settings_sidebar_inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	transition: 0.3s;
}

.call_settings_sidebar_header {
	display: flex;
	align-items: center;
	padding: $spacer $spacer * 3;
	border-bottom: 1px solid $border-color;
	flex-shrink: 0;
}

.call_settings_sidebar_body {
	flex: 1;
	overflow: auto;
	padding: $spacer * 2 $spacer * 2;
}

.call_settings_sidebar_footer {
	display: flex;
	padding: $spacer $spacer * 3;
	border-top: 1px solid $border-color;
	flex-shrink: 0;
}

.call_settings_sidebar_agent_row {
	display: flex;
	align-items: center;
	padding: 2px 0;
	margin-bottom: $spacer;

	.grab_icon {
		color: $gray-500;
	}
}

.call_settings_sidebar_section {
	border-radius: 8px;
	border: 1px solid $border-color;
	overflow: hidden;
	margin-top: $spacer * 2;

	&.checked {
		border: 2px solid $primary;
	}
}

.call_settings_sidebar_section_sw {
	position: relative;
	top: 3px;
}

.call_settings_sidebar_section_header {
	background: white;
	display: flex;
	padding: $spacer * 1.5;
}

.call_settings_sidebar_section_body {
	background: white;
	padding: $spacer * 1.5;
	&.action_body {
		padding-left: 40px;
		padding-top: 0;
		margin-top: -10px;
		border-top: none;
	}
}

.call_settings_sidebar_menus_table {
	max-width: 500px;
	margin-top: $spacer;
}

.call_settings_sidebar_menus_table_row {
	display: flex;
	margin-bottom: $spacer;

	&.header {
		font-size: 13px;
		color: $gray-600;
		text-transform: uppercase;
		font-weight: 500;
	}
}

.call_settings_sidebar_menus_table_cell {
	display: flex;
	align-items: center;

	&.key_cell {
		width: 100px;
		flex-shrink: 0;
	}

	&.action_cell {
		flex: 1;
		margin-left: $spacer * 2;
		margin-right: $spacer;
	}

	&.remove_cell {
		width: 20px;
		justify-content: flex-end;
		flex-shrink: 0;
	}
}

.call_convo_card {
	width: 550px;
	border-radius: 8px;
	border: 1px solid $border-color;
	box-shadow: 0 3px 8px $border-color;
	overflow: hidden;
	background-color: $white;
}

.call_convo_card_header {
	display: flex;
	align-items: center;
	padding: $spacer * 1.5;
	border-bottom: 1px solid $border-color;
}

.call_convo_card_body {
	padding: $spacer * 1.5;
}

.call_convo_card_footer {
	display: flex;
	padding: $spacer * 1.5;
	background-color: #424156;
	color: $white;

	&.ongoing {
		background-color: $success;
	}
}

.call_convo_card_info_row {
	display: flex;
}

.call_convo_card_info_row_label {
	font-weight: 500;
	width: 150px;
	flex-shrink: 0;
}

.call_convo_card_info_row_content {
	flex: 1;
	overflow: hidden;
}

.call_convo_card_info_row {
	margin-bottom: 10px;
	&:last-child {
		margin-bottom: 0;
	}
}

.call_record_audio {
	display: flex;
	flex-direction: column;
}

.call_convo_card_audio_duration {
	font-size: 40px;
	font-weight: 200;
	line-height: 1;
}

.call_duration {
	font-size: 30px;
	font-weight: 500;
}

.call_modal_header {
	display: flex;
	padding: 5px 20px 0px 20px;
	align-items: center;
	font-family: 'Roboto';
	font-weight: 600;
	font-size: 16px;
	line-height: 19px;
	color: black;
}

.call_modal_input.form-control {
	font-size: 20px;
	border: 1px solid #f5f5f5;
	outline: none;
	border-radius: 4px;
	background-color: #f5f5f5;
	padding: 10px;
	padding-top: 25px;
	width: 100%;
	color: #000;

	&:hover,
	&:focus {
		background-color: #f5f5f5;
		color: #000;
	}

	&.is-invalid {
		border-color: $danger;
	}
}

.phone_fill_icon path:last-child {
	fill: currentColor;
}

.call_center_modal_header {
	font-size: 20px;
	font-weight: 500;
}

.call_center_modal_section_title {
	font-size: 16px;
	font-weight: 500;
}

.call_center_modal_section_subtitle {
	color: $gray-600;
}

.call_center_modal_section {
	margin-bottom: 25px;
}

.call_center_modal_info_input {
	padding: 5px 10px;
	background-color: #eee;
	border-radius: 5px;
	color: $gray-600;
	display: flex;
	align-items: center;

	&.empty {
		padding-left: 0;
		background-color: $white;
		font-style: italic;
	}
}

.call_center_modal_step_bar {
	width: 50px;
	height: 6px;
	border-radius: 9999px;
	background-color: #eee;
	margin-right: 10px;

	&:last-child {
		margin-right: 0;
	}

	&.active {
		background-color: $gray-700;
	}
}

.call_center__confirm_continue_call_modal {
	width: 90%;
	height: 150px;
	background-color: white;
	padding: 10px;
	border-radius: 8px;
	flex-direction: column;
	justify-content: space-between;
	box-shadow:
		0px 0px 0px 0px,
		rgb(0 0 0 / 22%) 0px 10px 40px 0px,
		rgb(0 0 0 / 17%) 0px 0px 16px 0px;
}

.integrate_phone_number_sample {
	padding: $spacer 15px;
	border-radius: 12px;
	background-color: #f8dcff;
	font-weight: 600;
	font-size: 24px;
	position: relative;
	transform: rotate(-5deg);
	display: flex;
	align-items: center;
	line-height: 1;
	align-self: center;
	margin-bottom: 30px;
	margin-top: 20px;
}

.integrate_phone_number_sample_icon {
	width: 28px;
	height: 28px;
	background-color: #917ccc;
	border-radius: 9999px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #917ccc;

	.icon-tabler-phone path:last-child {
		fill: #f8dcff;
	}
}

.integrate_phone_number_sample_done {
	width: 28px;
	height: 28px;
	background-color: $success;
	border: 2px solid $white;
	border-radius: 9999px;
	color: $white;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	bottom: -10px;
	right: -10px;
}

.greeting_audio_name_wrapper {
	margin-left: 10px;
	margin-right: 20px;
	display: flex;
	flex: 1;
	overflow: hidden;
	align-items: center;
	position: relative;

	&:hover .greeting_audio_name_edit_icon {
		display: block;
	}

	input.form-control {
		padding-right: 40px;
	}
}

.greeting_audio_name_edit_icon {
	display: none;
}

.greeting_audio_name_edit_buttons {
	display: flex;
	position: absolute;
	top: 6px;
	right: 4px;
}

.choose_audio_modal_table .sbz_table_row {
	.sbz_table_cell:first-child {
		padding-left: 0;
	}
	.sbz_table_cell:last-child {
		padding-right: 0;
	}
}
