.invite-agent {
	height: 100vh;
	overflow: auto;
	display: flex;
	justify-content: center;

	.login-container {
		background-color: white;
		padding: 40px 30px;
		border-radius: 8px;
		padding-top: 15vh;
		width: 450px;
	}

	.logo {
		width: 50px;
		height: 50px;
		border-radius: 8px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto 20px;
	}

	.form-control.wrong {
		border-color: #ea3d2f;
	}

	.auth-form__break {
		margin: 0;
		width: 390px;
	}

	.password-condition.required {
		color: #e81a1a;
		span {
			color: #e81a1a;
		}
	}

	.password-condition {
		color: #dad9d9;
		span {
			color: #999999;
		}
	}

	.password-condition.required.satified,
	.password-condition.satified {
		color: #11b936;
		span {
			color: #11b936;
		}
	}

	.verification-code {
		display: flex;
		justify-content: space-between;
	}
	.verification-code input {
		width: 50px;
		height: 60px;
		text-align: center;
		font-size: 25px;
		font-weight: 600;

		margin-bottom: 10px;
		border: 1px solid #dfdfdf;
		border-radius: 4px;
		box-sizing: border-box;
	}

	.verification-code.wrong input {
		border: 1px solid #e81a1a;
		color: #e81a1a;
	}
}
