@use 'sass:math';

.i18n-tabs {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 1px;
  z-index: 5;
  left: 1px;
  font-size: 14px;
}

.i18n-input__wrapper.focus .i18n-tab__item {
  border-bottom-color: #646464;

  &.active {
    border-color: #646464;
    box-shadow: -1px 0 0 0 #646464;
  }
}

.i18n-tab__item {
  padding: math.div($spacer,4) $spacer;
  color: $gray-500;
  background-color: $gray-100;
  border: 1px solid #cacaca;
  border-left: none;
  box-shadow: -1px 0 0 0 #cacaca;
  cursor: pointer;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:first-child {
    border-top-left-radius: 4px;
  }

  &:last-child {
    border-top-right-radius: 4px;
  }

  &.active {
    background-color: $white;
    color: $gray-700;
    border-bottom: none;
    z-index: 2;

    .flag-icon {
      filter: grayscale(0);
    }
  }

  .flag-icon {
    filter: grayscale(0.35);
  }
}

.i18n-input {
  position: relative;

  .form-control {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.i18n-tab__item-delete {
  opacity: 0;
  transition: 0.3s;
}

.i18n-tab__item:hover .i18n-tab__item-delete {
  opacity: 1;
}
