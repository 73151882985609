.accounts {
	padding-top: 10px;
	border-right: 1px solid whitesmoke;
	flex-shrink: 0;
	overflow-y: scroll;
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.account_item.account_item__inactive {
	background: whitesmoke;
	&:hover {
		background: #f1f7ff;
	}
}

.account_item.account_item__selected {
	background: #dceaff;
	&:hover {
		background: #ccdaef;
	}
}

.account_item {
	cursor: pointer;
	border-bottom: 1px solid whitesmoke;
	display: flex;
	padding: 10px 20px;
	background: white;
	&:hover {
		background: #f1f7ff;
	}
}

.account_item__desc {
	flex: 1;
	overflow: hidden;
	margin-left: 15px;
}

.account_item__avatar {
	display: flex;
	align-items: center;
	justify-content: center;
	background: whitesmoke;
	flex-shrink: 0;
	width: 46px;
	height: 46px;
	border-radius: 10px;
	border: 1px solid whitesmoke;
}

.account_item__name {
	font-weight: 500;
	@include text-truncate();
	margin-bottom: 3px;
}

.column {
	overflow: hidden;
}

.acc_credit_box {
	border: 1px solid #ffc10745;
	background: white;
	padding: 5px 10px;
	display: flex;
	border-radius: 20px;
	font-size: 13px;
	align-items: center;
	margin-right: 5px;
	margin-top: 10px;

	&:hover {
		cursor: pointer;
		background: #f5f5f5;
	}
}

.acc_sidebar {
	width: 400px;
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
	overflow-y: auto;
	background: #f7f5f2;
	border-left: 1px solid rgba(166, 145, 113, 0.2);
	padding-top: 15px;
	padding-bottom: 15px;
	padding-left: 20px;
}

.acc_sidebar_label {
	color: #777;
	display: flex;
	align-items: center;
	margin-top: 10px;
}
