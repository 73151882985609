.detail_rule__trigger_desc {
	align-items: center;
	color: $gray-600;
}

.form-label {
	margin-top: 10px;
	margin-bottom: 5px;
	font-size: 15px;
	display: block;
}

.campaign-design--button-action.campaign-design--button-action--error {
	border: 1px solid #ff3f1d;
	color: #ff3f1d;
}

.campaign-design--url-open-url-url {
	vertical-align: text-bottom;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.campaign-design--button-action {
	display: flex;
	align-items: center;
	padding: 5px $spacer;
	background: white;
	border: 2px solid transparent;
	cursor: pointer;
	position: relative;
	background: #fff8e3;
	margin-right: 5px;
	margin-bottom: 5px;
	max-width: 100%;
}

.campaign-design--button-action-open-url {
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
}

.campaign-design--url-open-url {
	white-space: nowrap;
	margin-right: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.campaign-design--button-action.campaign-design---add-action-button {
	border-style: dashed;
	border-width: 2px;

	cursor: pointer;
	display: inline-block;
	position: relative;
	user-select: none;
	padding: 5px;
	&:hover {
		border-color: $gray-700;
	}
}

.campaign-design---button-actions {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	max-width: 262px;
}

.campaign-design--url-edit--hide {
	display: none;
}

.campaign-design--url-edit {
	position: absolute;
	bottom: -40px;
	left: 0px;
	width: 268px;
	padding: 10px;

	box-shadow: $modal-shadow;
	transform: translateY(-4px);
	user-select: none;

	background-color: #fff;
	border-radius: 7px;
	z-index: 10;
}

.campaign-design--url-edit-button {
	margin-top: 12px;
}

/* ========== Campaign Design Color Picker ======= */
.campaign-design-color-picker {
	display: inline-block;
	vertical-align: bottom;
	margin-left: 5px;
	position: relative;
	float: right;
}

.campaign-design-color-picker__picker {
	position: absolute !important;
	z-index: 10;
	top: 20px;
	right: 0px;
}

.campaign-design-color-picker__picker--hide {
	display: none;
}

/* ==================== SECTION ============ */
.campaign_target__remove_condition_btn {
	cursor: pointer;
	stroke: $gray-600;
	outline: none;
	&:hover {
		stroke: $black;
	}
}

.campaign_type_radio {
	width: 330px;
	margin-right: 20px;
	margin-bottom: 20px;
	border-radius: 8px;
	border: 1px solid $border-color;
	display: flex;
	cursor: pointer;
	padding: 12px 15px;

	&.active {
		border-color: $primary;
		box-shadow: 0 0 0 1px $primary;
	}

	&:nth-child(2n) {
		margin-right: 0;
	}
}

.campaign_type_radio_img {
	width: 32px;
	height: 32px;
	object-fit: cover;
}

.campaign_item_row {
	height: 55px;
}

.campaign_edit_design {
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 100%;
	position: relative;
}

.campaign_edit_design_messages {
	flex: 1;
	padding-top: 30px;
	padding-bottom: 30px;
	display: flex;
	justify-content: center;
	background-color: #f5f5f5;
	overflow-y: auto;
	position: relative;
}

.campaign_edit_design_message_box.campaign_edit_design_message_box__real {
	background-color: $white;
	border-style: solid;
	margin-bottom: 0;
}

.campaign_edit_design_message_box_header {
	padding: 8px 20px;
	display: flex;
	align-items: center;
	border-bottom: 1px solid $border-color;
}

.campaign_edit_design_message_box_content {
	padding: 10px 20px;
}

.campaign_edit_design_message_box_seperator_line_text {
	position: absolute;
	top: 50%;
	left: calc(50% + 10px);
	transform: translateY(-50%);
	display: flex;
	align-items: center;
}

.campaign_edit_design_message_box_seperator {
	height: 48px;
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	&.has_content {
		height: 60px;
	}
}

.campaign_edit_design_message_box_seperator_box {
	padding: 2px 10px;
	border-radius: 9999px;
	background-color: $white;
}

.campaign_edit_design_message_box_seperator_line {
	margin-top: 5px;
	margin-bottom: 5px;
	flex: 1;
	width: 2px;
	background-color: #d4d4d4;
}

.campaign_edit_design_message_box {
	width: 320px;
	border-radius: 8px;
	position: relative;
	border: 1px dashed $border-color;
	background: rgb(236 236 236);
	cursor: pointer;
	margin-bottom: 20px;

	transition: all 150ms ease-out;
	&:hover {
		border-color: #888;
		background-color: white;
	}
	&.active {
		border-width: 1px;
		border-style: solid;
		background-color: $white;
		border-color: $primary;
		box-shadow: 0 0 8px $primary;
	}
}

.campaign_edit_design_sidebar {
	width: 540px;
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	flex-shrink: 0;
	border-left: 1px solid $border-color;
}

.campaign_edit_design_sidebar_header {
	padding: 12px 20px;
	height: 56px;
	border-bottom: 1px solid $border-color;
	align-items: center;
	display: flex;
}

.campaign_edit_design_sidebar_content {
	flex: 1;
	overflow: auto;
	padding: 12px 20px;

	.message_editor.message_editor__html .message_editor__input.ql-container {
		max-height: unset;
		height: unset;
		min-height: unset;
		flex: 1;
		overflow: hidden;
	}
}

.campaign_edit_design_sidebar_footer {
	padding: 12px 20px;
	border-top: 1px solid $border-color;
	align-items: center;
	display: flex;
}

.campaign_edit_design_sidebar_channel_radio {
	border-radius: 8px;
	border: 1px solid $border-color;
	padding: 10px 15px;
	padding-left: 11px;
	margin-bottom: 15px;
	cursor: pointer;

	&.active {
		border-color: $primary;
		box-shadow: 0 0 0 1px $primary;
		opacity: 1;
	}

	&.disabled {
		cursor: initial;
		opacity: 0.7;

		.campaign_edit_design_sidebar_channel_radio_img {
			filter: grayscale(1);
		}
	}
}

.campaign_design_timming_week_days {
	.form-check-inline {
		width: 120px;

		&:nth-child(3n) {
			margin-right: 0;
		}
	}
}

.campaign_edit_design_sidebar_channel_radio_img_wrapper {
	width: 32px;
	flex-shrink: 0;
	display: flex;
	justify-content: center;
}

.campaign_edit_design_sidebar_channel_radio_img {
	width: 24px;
	align-self: flex-start;
}

.campaign_segment_body {
	display: flex;
	flex: 1;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.campaign_segment_section {
	margin-bottom: 20px;
}

.campaign_segment_cout {
	margin-left: 40px;
	padding: 25px;
	width: 250px;
	height: 290px;
	background: #ffffff;
	border: 1px solid #e4e4e4;
	border-radius: 5px;
}

.option_select_user_campaign {
	width: 530px;
	height: 70px;

	flex-direction: row;
	justify-content: center;
	align-items: center;
	background: #ffffff;
	border: 1px solid #e2e2e2;
	border-radius: 6px;
	padding: 0 20px;
	margin-bottom: 15px;

	&:hover {
		cursor: pointer;
		border: 1px solid $primary;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.campaign_edit_report {
	flex: 1;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	position: relative;
}

.campaign_edit_report_tab {
	padding-left: 20px;
	padding-right: 20px;
	flex-shrink: 0;
	align-items: center;
	display: flex;

	&.tab {
		margin-bottom: 0;
	}
}

.campaign_edit_report_container {
	padding: 20px;
	overflow-y: auto;
	overflow-x: hidden;
	flex: 1;
	//display: flex;
	background-color: #fafafa;
}

.campaign_edit_report_left {
	flex: 8;
	overflow: hidden;
	margin-right: 20px;
}

.campaign_edit_report_right {
	flex: 3;
	overflow: hidden;
}

.campaign_report_card {
	border-radius: 8px;
	border: 1px solid $border-color;
	background-color: $white;
}

.campaign_report_card_header {
	padding: 8px 15px;
	border-bottom: 1px solid $border-color;
	font-weight: 500;
}

.campaign_report_card_content {
	padding: 10px 15px;
}

.campaign_report_card_content_label {
	width: 140px;
	display: flex;
	align-items: center;
	flex-shrink: 0;
	margin-right: 15px;
}

.campaign_report_card_header_dot.dot {
	width: 16px;
	height: 16px;
	border-radius: 4px;
	margin-top: 0;
}

.campaign_report_error_message_row.item-row {
	border-bottom: none;
	border-top: 1px solid $border-color;

	&.item-row__header {
		border-bottom: 1px solid $border-color;
	}

	&:not(.item-row__header) {
		cursor: pointer;
		&:hover {
			background-color: $gray-100;
		}
	}
}

.telesale_campaign_modal_content {
	overflow: hidden;
	display: flex;
	height: 100%;
}

.telesale_campaign_modal_flow {
	flex: 1;
	overflow-y: auto;
	padding: 12px 20px;
}

.telesale_campaign_modal_flow_item {
	border: 2px dashed $border-color;
	margin-bottom: 10px;
	padding: 8px 15px;
	cursor: pointer;
	font-size: 14px;
	border-radius: 5px;
	position: relative;

	&.active {
		border-color: $primary;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.telesale_campaign_modal_edit {
	padding: 12px 20px;
	width: 280px;
	border-left: 1px solid $border-color;
	flex-shrink: 0;
	overflow-y: auto;
}

.campaign_telesale_execute {
	padding: 20px;
	overflow: hidden;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
}

.campaign_telesale_execute_inner {
	flex: 1;
	display: flex;
	overflow: hidden;
}

.campaign_telesale_execute_list_entries_wrapper {
	flex-shrink: 0;
	width: 400px;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	&.full {
		width: 100%;
	}
}

.campaign_telesale_execute_list_entries {
	flex: 1;
	border-radius: 8px;
	border: 1px solid $border-color;
	overflow: hidden;
	display: flex;
	background-color: $white;
	flex-direction: column;
}

.campaign_telesale_execute_list_entries_header {
	flex-shrink: 0;
	padding: 0px 15px;
	padding-left: 0;
	border-bottom: 1px solid $border-color;
	display: flex;

	.tab {
		margin-bottom: 0;
		border-bottom: none;
	}

	.tab__item {
		margin-right: 15px;

		&:last-child {
			margin-right: 0;
		}
	}
}

.campaign_telesale_execute_list_entries_content {
	flex: 1;
	overflow-y: scroll;
	position: relative;
}

.campaign_telesale_execute_list_entries_footer {
	padding: 8px 15px;
	display: flex;
	align-items: center;
	flex-shrink: 0;
	border-top: 1px solid $border-color;
}

.campaign_telesale_call_entry_item {
	padding: 8px 15px;
	padding-left: 0;
	background-color: $white;
	cursor: pointer;
	position: relative;
	//margin: 10px 15px;
	//border-radius: 8px;
	//border: 1px solid $border-color;

	&.read {
		background-color: #eaeaea;
	}

	&.checked,
	&.read.checked {
		background-color: #b9d5ff;
	}

	&.active,
	&.read.active,
	&.checked.active {
		background-color: $primary;
		border-color: $primary;
		color: $white;
		opacity: 1;
	}

	&.is_next_call {
		background-color: #eeffeb;
	}

	&.soft_selected {
		&:after {
			content: '';
			position: absolute;
			background-color: $primary;
			left: 0;
			top: 0;
			bottom: 0;
			width: 6px;
		}
	}

	&.item_calling {
		background-color: $success;
		color: $white;
		&:after {
			z-index: -1;
		}
	}
}

.campaign_telesale_execute_entry_detail {
	flex: 1;
	overflow: hidden;
	display: flex;
}

.campaign_telesale_execute_entry_detail_header {
	flex-shrink: 0;
	display: flex;
	overflow: hidden;
	border-bottom: 1px solid $border-color;
}

.campaign_telesale_execute_entry_detail_user_header {
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 18px;
	width: 361px;
	flex-shrink: 0;
	border-left: 1px solid $border-color;
	padding: 0 20px;
}

.campaign_telesale_execute_entry_detail_wrapper {
	flex: 1;
	overflow: hidden;
	margin-left: 20px;
	border: 1px solid $border-color;
	background-color: $white;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	position: relative;
}

.campaign_telesale_call_entry_detail_form {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	flex: 1;
}

.campaign_telesale_call_entry_detail_form_header {
	display: flex;
	align-items: center;
	padding: 10px 20px;
	flex: 1;
	overflow: hidden;
}

.campaign_telesale_call_entry_detail_form_footer {
	padding: 12px 20px;
	display: flex;
	align-items: center;
	border-top: 1px solid $border-color;
	flex-shrink: 0;
}

.campaign_telesale_call_entry_detail_form_content {
	flex: 1;
	overflow: auto;
	padding: 15px 20px;
	position: relative;
}

.campaign_telesale_call_entry_detail_user {
	flex-shrink: 0;
	border-left: 1px solid $border-color;
	overflow-y: hidden;
	display: flex;
	flex-direction: column;
}

.campaign_telesale_call_entry_detail_user_header {
	padding: 12px 20px;
	border-bottom: 1px solid $border-color;
	flex-shrink: 0;
	height: 50px;
}

.campaign_item {
	padding: 5px 10px;
	background-color: #f6f6f6;
	border: 1px solid #ededed;
	border-radius: 8px;
	margin-top: 10px;
	margin-bottom: 10px;
	margin-right: 10px;
	cursor: pointer;
	&:hover {
		border: 1px solid #bdbdbd;
	}
}

.campaign_item__header {
	display: flex;
	overflow: hidden;
	align-items: center;
}

.campaign_design__loading_label {
	opacity: 0.6;
	position: absolute;
	bottom: 20px;
	left: 20px;
	display: flex;
	align-items: center;
	padding: 5px 10px;
	color: white;
	background: #00000088;
	border-radius: 8px;
}

.campaign_design__nuser {
	display: flex;
	align-items: center;
	margin-top: 5px;
	position: absolute;
	top: 6px;
	right: 20px;
}

// custom css for email editor
.campaign_design_email_editor.lexical_editor_tabs_wrapper {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.lexical_editor_wrapper {
		flex: 1;
		max-height: unset;
	}
}

.broadcast_import_type_radio {
	width: 600px;
	padding: 20px 40px;
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: 8px;
	border: 1px solid $border-color;
	background-color: $white;
	margin-bottom: 30px;
	transition: 0.15s;

	&:last-child {
		margin-bottom: 0;
	}

	&:hover {
		background-color: #fafafa;
		border-color: #888;
	}
}

.survey_result_item_container {
	width: 360px;
	margin-right: 30px;
	margin-bottom: 30px;
	overflow: hidden;

	&:nth-child(3n) {
		margin-right: 0;
	}
}

.telesale_field_design_box {
	position: relative;
	border: 1px dashed $border-color;
	border-left: 0;
	border-right: 0;
	background: $white;
	cursor: pointer;
	transition: all 150ms ease-out;

	&:after {
		content: '';
		position: absolute;
		top: -1px;
		left: 0;
		bottom: -1px;
		width: 0px;
		z-index: 1;
		background-color: $primary;
		transition: all 150ms ease-out;
	}

	&:hover {
		border-color: #888;
		background-color: $white;
	}
	&.active {
		//background-color: $white;
		//border-color: $primary;
		//box-shadow: 0 0 8px $primary;
		&:after {
			width: 5px;
		}
	}
}

.campaign_telesale_execute_wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	background-color: #fafafa;
}

.my_activities_grid {
	overflow: hidden;
	display: grid;
	grid-gap: 5px;
	grid-template-columns: repeat(10, 1fr) 30px;
}

.my_activities_grid_item {
	position: relative;
	padding-top: 100%;
}

.my_activities_grid_item_inner {
	border-radius: 4px;
	border: 1px solid $border-color;
	background-color: #ebedf0;
	position: absolute;
	inset: 0;

	&.active {
		border-color: $gray-700;
	}
}

.my_activities_grid_calendar_label {
	font-size: 12px;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	@extend .text__muted;
}

.campaign_telesale_next_call_card {
	background-color: $white;
	//width: 640px;
	flex-shrink: 0;
	//border: 1px solid $border-color;
	//border-top: none;
	//position: fixed;
	//bottom: 20px;
	//left: 50%;
	//transform: translateX(-50%);
	//box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.1), 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
	//border-bottom-left-radius: 8px;
	//border-bottom-right-radius: 8px;
	//z-index: 5;
}

.campaign_telesale_next_call_card_inner {
	padding: 12px 0;
	display: flex;
}

.campaign_telesale_next_call_card_section {
	padding: 0px 20px;
	border-right: 1px solid #e3e3e3;

	&:last-child {
		border-right: 0;
	}
}

.campaign_telesale_next_call_card_section_title {
	@extend .text__muted;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
}

.campaign_telesale_next_call_card_section_value {
	font-size: 20px;
	font-weight: 500;
}

.telesale_script_field_box {
	padding: 10px 15px;
	border: 2px dashed $border-color;
	border-radius: 8px;
	margin-bottom: 10px;
	white-space: pre-wrap;

	&.disabled {
		border: 2px solid #f6f6f6;
		background-color: #f6f6f6;
	}
}

.outbound_call__field {
	display: flex;
	margin: 5px 0;
	flex-direction: row;
	align-items: center;
	flex: 1 1 0;
}

.outbound_call__field_title {
	@include text-truncate();

	min-width: 110px;
	color: #777;
	margin-right: $spacer;

	align-items: center;
	flex-shrink: 0;
	align-self: flex-start;
}

.outbound_call__field_content {
	//  display: flex;
	align-items: center;
	flex-shrink: 0;
}

.campaign_telesale_execute_list_entries_row {
	display: flex;
	cursor: pointer;

	&.header {
		cursor: auto;
		font-size: 13px;
		color: $gray-600;
		text-transform: uppercase;
		font-weight: 500;
	}
  
  &:not(.header):hover .campaign_telesale_execute_list_entries_cell {
    background-color: $gray-100;
  }
}

.campaign_telesale_execute_list_entries_cell {
	padding: 8px 10px;
	border-bottom: 1px solid $border-color;
	flex-shrink: 0;
	overflow: hidden;
	flex-grow: 1;
	overflow: hidden;
	width: 150px;

	&.entry_customer {
		width: 200px;
		max-width: 200px;
		padding-left: 5px;
	}

	&.entry_calls_count {
		width: 100px;
		max-width: 100px;
	}

	&.entry_recorded {
		width: 100px;
		max-width: 100px;
	}

	&.entry_duration {
		width: 110px;
		max-width: 110px;
	}

	&.entry_cb {
		width: 40px;
		max-width: 40px;
		padding-left: 15px;
		display: flex;
		align-items: center;

		input[type='checkbox'] {
			margin: 0;
		}
	}
}

.campaign_telesale_entry_checkbox_wrapper {
	width: 45px;
	display: flex;
	align-items: center;
	cursor: pointer;
	padding-left: 15px;

	input[type='checkbox'] {
		margin: 0;
	}
}

.call_entry_item_status_wrapper {
	flex-shrink: 0;
}

.call_entry_item_actions_wrapper {
	display: none;
	align-items: center;
	flex-shrink: 0;
}

.campaign_telesale_call_entry_item:hover {
	.call_entry_item_status_wrapper {
		display: none;
	}

	.call_entry_item_actions_wrapper {
		display: flex;
	}
}

.campaign_telesale_call_entry_item.active .call_entry_item_actions_btn:hover {
	background-color: $blue-700;
}

.call_entry_item_actions_btn {
	width: 28px;
	height: 28px;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	cursor: pointer;
	margin-right: 5px;

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		background-color: $gray-200;
	}
}

.list_entries_filters {
	padding-top: 10px;
	padding-left: 15px;
	display: flex;
	flex-wrap: wrap;
	border-bottom: 1px solid $border-color;
}
