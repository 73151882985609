.modal {
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	z-index: $zindex-modal;
	width: 100%;
	height: 100%;
	overflow: hidden;
	// Prevent Chrome on Windows from adding a focus outline. For details, see
	// https://github.com/twbs/bootstrap/pull/10951.
	outline: 0;
	// We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
	// gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
	// See also https://github.com/twbs/bootstrap/issues/17695

	display: flex;
	align-items: center;
	justify-content: center;
	visibility: visible;
}

.modal.modal__local {
	position: absolute;
}

.modal.modal__right {
	align-items: center;
	justify-content: flex-end;
}

.modal.modal__right.modal__hide {
	left: 1000px;
}

.modal.modal__hide {
	opacity: 0;
	visibility: hidden;
	display: none;
}

.newmodal {
	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	z-index: $zindex-modal;
	width: 100%;
	height: 100%;
	overflow: hidden;
	// Prevent Chrome on Windows from adding a focus outline. For details, see
	// https://github.com/twbs/bootstrap/pull/10951.
	outline: 0;
	// We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
	// gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
	// See also https://github.com/twbs/bootstrap/issues/17695

	display: flex;
	opacity: 0;
	align-items: center;
	justify-content: center;
	visibility: visible;
	transform: translate(0, -1rem);
	transition: all 0.1s ease-out;
}

.modal.modal__hide {
	.modal__overlay {
		opacity: 0;
		transition: opacity 100ms;
	}
}

.newmodal__show.newmodal__overlay {
	opacity: 1;
	//-webkit-backdrop-filter: blur(4px);
	//backdrop-filter: blur(4px);
}

.newmodal.newmodal__show {
	opacity: 1;
	transform: none;
}

.newmodal__overlay {
	z-index: $zindex-modal;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	// background: #000000;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.23);
	transition: all 0.15s linear;
	-webkit-backdrop-filter: none;
	backdrop-filter: none;
}

.modal__overlay {
	z-index: -1;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	// background: #000000;
	// opacity: 1;
	background-color: rgba(0, 0, 0, 0.23);
	transition: opacity 0.15s linear;
}

.modal__container {
	overflow: hidden;
	overflow-x: hidden;
	overflow-y: auto;
	outline: 0;
	max-height: 90vh;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow:
		rgba(0, 0, 0, 0) 0px 0px 0px 0px,
		rgba(0, 0, 0, 0) 0px 0px 0px 0px,
		rgba(0, 0, 0, 0.12) 0px 10px 40px 0px,
		rgba(0, 0, 0, 0.08) 0px 0px 16px 0px;
	border-radius: 10px;
}

.modal__container.modal__container__full {
	height: 100vh;
	max-height: 100vh;

	.modal__background {
		border-radius: 0;
	}
}

.modal__background {
	background: #ffffff;
	// box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.06), 0px 4px 32px rgba(0, 0, 0, 0.06), 0px 8px 64px rgba(0, 0, 0, 0.08);

	// box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px;

	border-radius: 8px;

	&.modal__background--no-border-radius {
		border-radius: 0;
	}

	&.modal__background--border-radius-left {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}
}

.modal_content {
	z-index: $zindex-modal;
	background-color: #fff;
	margin: auto;
	width: 840px;
	border-radius: 5px;
}

.modal_content__header {
	position: relative;
	width: 100%;
	background: $white;
	padding: $spacer * 1.5 $spacer * 2;
	border-bottom: 1px solid #e9e9e9;
	font-size: 18px;
	font-weight: 500;
	//color: $gray-600;
	border-radius: 8px 8px 0 0;
}

.modal_content__main {
	position: relative;
	width: 100%;
	padding: $spacer * 2 $spacer * 2;
}

.modal_content__footer {
	border-radius: 0px 0px 8px 8px;
	background: $gray-100;
	padding: $spacer $spacer * 2;
	border-top: 1px solid #e6e6e6;
	text-align: right;
}

.modal_content__icon_close {
	display: flex;
	align-items: center;
	cursor: pointer;
}

// Animated modal right, left
.modal.modal--left,
.modal.modal--right {
	z-index: -999999;
	opacity: 0;
	align-items: center;
}

.modal.modal--right {
	justify-content: flex-end;
}

.modal.modal--left {
	justify-content: flex-start;
}

.modal.modal--left.modal--active,
.modal.modal--right.modal--active {
	z-index: $zindex-modal;
	opacity: 1;
}

.modal.modal--right .modal__background {
	left: 800px;
	transition: 0.3s;
}

.modal.modal--left .modal__background {
	left: -800px;
	transition: 0.3s;
}

.modal.modal--left.modal--active .modal__background,
.modal.modal--right.modal--active .modal__background {
	left: 0;
}

.modal.modal--right.modal--active.modal--fadeout .modal__background {
	left: 800px;
}

.modal.modal--left.modal.--active.modal--fadeout .modal__background {
	left: -800px;
}

.modal.modal--right.modal--active.modal--fadeout .quickview__btn_close,
.modal.modal--left.modal--active.modal--fadeout .quickview__btn_close {
	display: none;
}

.modal.modal--active ~ .modal.modal--active .modal__overlay {
	background-color: transparent;
}
