.agents_picker__dropdown {
	cursor: default;
	bottom: 100%;
	width: 250px;
	top: auto;
	right: auto;

  display: flex;
  flex-direction: column;
  height: 300px;
  max-height: 300px;
}

.agents_picker__dropdown_agents {
  flex: 1;
	overflow: auto;
}

.agents_picker__agent {
	margin-left: $spacer * 0.5;
	display: flex;
	position: relative;
	cursor: pointer;
	&:hover {
		.agents_picker__agent__remove_btn {
			height: 20px;
			width: 20px;
			opacity: 1;
			transition: all 0.1s ease;
			right: -5px;
			top: 0;
		}
	}
}

.agents_picker__agent__remove_btn {
	outline: none;
	transition: all 0.1s ease;
	height: 0px;
	width: 0px;
	opacity: 0;
	position: absolute;
	stroke: $gray-700;
	background-color: $white;
	border-radius: 50%;
	cursor: pointer;
	padding: 2px;

	right: 5px;
	top: 10px;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
}

.agents_picker__filter {
	padding: $spacer;
  flex-shrink: 0;
}
.agents_picker__filter_input {
}

.agents_picker__add_btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	user-select: none;
	background: $gray-100;

	border: 1px dashed $gray-500;
	cursor: pointer;
	transition: all 0.1s ease;
	&:hover {
		background: $white;
		border: 1px dashed $gray-600;
		.agents_picker__add_btn_icon {
			stroke: $gray-600;
		}
	}
}

.agents_picker__add_btn.agents_picker__add_btn__sm {
	width: 26px;
	height: 26px;
}

.agents_picker__add_btn_icon {
	stroke: $gray-500;
}
