@use 'sass:color';

// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
	display: inline-block;
	padding: $badge-padding-y $badge-padding-x;
	font-size: $badge-font-size;
	font-weight: $badge-font-weight;
	line-height: 1;
	color: $badge-color;
	text-align: center;
	white-space: nowrap;
	font-weight: 600;
	vertical-align: baseline;
	@include border-radius($badge-border-radius);

	// Empty badges collapse automatically
	&:empty {
		display: none;
	}
}

// Quick fix for badges in buttons
.btn .badge {
	position: relative;
	top: -1px;
}
@each $color, $value in $theme-colors {
	.badge__#{$color} {
		color: $value;
		background-color: rgba($value, 0.15);
	}
}

.badge.badge__light {
	color: $gray-700;
  background-color: $gray-100;
}
