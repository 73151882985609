input[type='range'].multirange {
	padding: 0;
	margin: 0;
	display: inline-block;
	vertical-align: top;
	-webkit-appearance: none; /* Override default CSS styles */
	appearance: none;
	width: 100%;
	height: 1px;
	background: lightgray;
}
input[type='range'].multirange::-moz-range-thumb {
	transform: scale(1); /* FF doesn't apply position it seems */
}

input[type='range'].multirange.original {
	position: absolute;
}

input[type='range'].multirange.original::-webkit-slider-thumb {
	position: relative;
	z-index: 2;
}

input[type='range'].multirange.original::-moz-range-thumb {
	z-index: 2;
}

input[type='range'].multirange::-moz-range-track {
	border-color: transparent; /* needed to switch FF to "styleable" control */
}

input[type='range'].multirange.middle {
	position: absolute;
	-webkit-appearance: none;
}
input[type='range'].multirange.middle:focus {
	outline: none;
}
input[type='range'].multirange.middle::-moz-range-thumb {
	position: relative;
	z-index: 1;
	height: 9px;
	width: var(--size);
	margin-top: 6px;
	margin-bottom: 6px;
	background: transparent;
	cursor: pointer;
	border: 0;
}
input[type='range'].multirange.middle::-webkit-slider-thumb {
	position: relative;
	z-index: 1;
	height: 9px;
	width: var(--size);
	margin-top: 6px;
	margin-bottom: 6px;
	background: transparent;
	-webkit-appearance: none;
	cursor: pointer;
}

input[type='range'].multirange.ghost {
	position: relative;
	background: var(--track-background);
	--track-background: linear-gradient(
			to right,
			transparent var(--low),
			var(--range-color) 0,
			var(--range-color) var(--high),
			transparent 0
		)
		no-repeat 0 9px / 100% 1px;
	--range-color: gray;
}

input[type='range'].multirange.ghost::-webkit-slider-runnable-track {
	background: var(--track-background);
}

input[type='range'].multirange.ghost::-moz-range-track {
	background: var(--track-background);
}
input[type='range'].multirange.ghost::-webkit-slider-thumb {
	position: relative;
	z-index: 2;
}
input[type='range'].multirange.ghost::-moz-range-thumb {
	z-index: 2;
}

.multirange::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 18px;
	height: 19px;
	border: 0;
	background: url('../img/multirange_handle.svg');
	cursor: pointer;

}

.multirange::-moz-range-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 18px;
	height: 19px;
	border: 0;
	background: url('../img/multirange_handle.svg');
	cursor: pointer;
}
