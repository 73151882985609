.product-page {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

.product_list_pagination {
	display: flex;
	align-items: center;
	padding: $spacer;
	border-top: 1px solid $border-color;
	margin-top: auto;
}

.product_filter_sidebar {
	width: 220px;
	height: 100%;
	overflow: auto;
	padding: 8px $spacer * 2;
	flex-shrink: 0;
	border: 1px solid $border-color;
	border-right: none;
	border-bottom: none;
	background-color: whitesmoke;
}

.product_table_header_row {
	background-color: $white;
	border-top: 1px solid $border-color;
}

.product_table_td_container {
	overflow: auto;
	max-height: 100%;
	background-color: $white;
}

.product_preview_card__head {
	font-size: 14px;
	display: flex;
	overflow: hidden;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 7px;
}

.product_preview_card {
	width: 260px;
	background: $white;
	padding: $spacer;
	box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	font-size: 13px;
	cursor: initial;
	color: $gray-700;
	z-index: 99;
}

.product_preview_card_title {
	font-size: 15px;
	font-weight: 500;
	line-height: 1.25;
}

.product_preview_card_description {
	font-size: 13px;
	color: $gray-600;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre-wrap;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.product_preview_card_image {
	width: 100%;
	height: 150px;
	border-radius: 5px;
	overflow: hidden;
	margin-bottom: $spacer;
	border: 1px solid $border-color;
	overflow: hidden;
}

.product_price_edit_text {
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}

.product_price_edit_input {
	padding: 0;
	border: none;
	outline: none;
	max-width: 60px;
	background: transparent;
	text-align: right;
}

.product_stock_edit_input {
	padding: 0;
	border: none;
	outline: none;
	max-width: 40px;
	background: transparent;
	text-align: right;
}

.product_table_row {
	cursor: pointer;
	&:hover {
		background-color: $gray-100;
	}

	&.expandable {
		cursor: pointer;
	}

	&.item-row {
		padding: 6px 0;
	}

	&.variant_row {
	}
}

.variant_row_seperator.first:before {
	height: 36px;
	top: -14px;
}

.variant_row_seperator {
	position: relative;
	height: 32px;

	&:before {
		content: '';
		width: 1px;
		height: calc(100% + 13px);
		top: -28px;
		left: calc(50% - 1px);
		background-color: $gray-600;
		position: absolute;
	}

	&:after {
		content: '';
		width: 50%;
		height: 1px;
		top: 50%;
		left: calc(50% - 1px);
		background-color: $gray-600;
		position: absolute;
	}
}

/* Chrome, Safari, Edge, Opera */
input.product_price_edit_input::-webkit-outer-spin-button,
input.product_price_edit_input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input.product_price_edit_input[type='number'] {
	-moz-appearance: textfield;
}

// product edit page

.product_edit_main {
	display: flex;
	flex-direction: column;
	overflow: auto;
	flex: 1;
	width: 100%;
	height: 100%;
	background-color: $white;
}

.product_tab_content {
	overflow: auto;
}

.product_edit_left_col {
	flex: 3;
	margin-right: $spacer * 6;
}

.product_edit_right_col {
	flex: 3;
}

.product_edit_container {
	max-width: 900px;
	margin: 0 auto;
	margin-top: 24px;
	margin-bottom: $spacer * 2;
}

.product_edit_header {
	font-size: 16px;
}

.product_edit_images_grid {
	display: grid;
	grid-gap: $spacer;
	grid-template-columns: repeat(3, 1fr); // 3 cols, 2 rows
	position: absolute;
	inset: 0;
}

.product_edit_image__first {
	grid-column-start: 1;
	grid-column-end: span 2;
	grid-row-start: 1;
	grid-row-end: span 2;
}

.product_edit_media_upload {
	width: 100%;
	border-radius: 5px;
	//border: 2px dashed $border-color;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: absolute;
	inset: 0;
	color: $gray-500;
	background-color: $gray-100;

	&:hover {
		background-color: $gray-200;
	}

	a {
		color: $gray-500;
		&:hover {
			color: $gray-600;
		}
	}
}

.product_media_wrapper {
	position: relative;
	width: 100%;
	font-size: 13px;

	&:before {
		content: '';
		display: block;
		padding-top: 40%;
	}

	&.ratio_1_1:before {
		padding-top: 100%;
	}

	&.ratio_2_1:before {
		padding-top: 50%;
	}

	&.ratio_4_3:before {
		padding-top: 75%;
	}

	&.ratio_16_9:before {
		padding-top: 56.25%;
	}

	&.ratio_3_1:before {
		padding-top: 33.33%;
	}
}

.product_edit_section_seperator {
	margin-top: $spacer * 4;
	margin-bottom: $spacer * 4;
}

.product_edit_sub_title {
	color: $gray-600;
	font-size: 14px;
}

.product_edit_price_unit {
	font-size: 12px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: $spacer;
	color: $gray-500;
}

input.product_edit_price_input::-webkit-outer-spin-button,
input.product_edit_price_input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input.product_edit_price_input[type='number'] {
	-moz-appearance: textfield;
}

.product_edit_price_input {
	padding-right: 32px;
}

.product_edit_image_item {
	position: absolute;
	inset: 0;
	overflow: hidden;
	border-radius: 5px;
	box-shadow: 0 0 0 1px $border-color;
}

.product_edit_image_img {
	width: 100%;
	height: 100%;
}

.product_edit_image_item_loading {
	position: absolute;
	inset: 0;
	z-index: 2;
	background-color: rgba(255, 255, 255, 0.65);
	display: flex;
	align-items: center;
	justify-content: center;
	pointer-events: none;
}

.product_edit_image_item_overlay {
	position: absolute;
	right: 5px;
	top: 5px;
	z-index: 1;
	opacity: 0;
	pointer-events: none;
}

.product_edit_image_item:hover .product_edit_image_item_overlay {
	opacity: 1;
	pointer-events: auto;
}

.product_edit_image_item_actions {
	display: flex;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-right: 5px;
	padding-left: 5px;
	align-items: center;
	user-select: none;
}

.product_edit_image_item_action_icon {
	color: $white;
	cursor: pointer;
	user-select: none;

	&:hover {
		color: $gray-200;
	}
}

.product_category_select_wrap {
	position: relative;
	border: 1px solid $border-color;
	overflow: hidden;
}

.product_category_select_container {
	width: 1300px;
	display: flex;
	position: relative;
	transition: 0.3s;
}

.product_category_select_column {
	flex: 1;
	flex-shrink: 0;
	overflow: hidden;
	height: 320px;
	overflow: auto;
	border-right: 1px solid $border-color;

	&:last-child {
		border-right: none;
	}
}

.product_category_select_item {
	display: flex;
	align-items: center;
	color: $gray-600;
	padding: 8px 15px;
	cursor: pointer;

	&:hover {
		background-color: $gray-100;
	}

	&.selected {
		background-color: $primary;
		color: $white;
	}
}

.product_category_select_nav {
	position: absolute;
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 50%;
	background-color: $gray-400;
	color: $white;
	top: 50%;
	transform: translateY(-50%);
	box-shadow: 0 15px 50px rgba(0, 0, 0, 0.15);
	z-index: 99;
	cursor: pointer;

	&:hover {
		background-color: $gray-500;
	}

	&.left {
		left: 0;
	}
	&.right {
		right: 0;
	}
}

.product_edit_limit_container {
	width: 900px;
	margin: 0 auto;
}

.product_edit_actions_bar {
	border-top: 1px solid $border-color;
	background-color: $white;
	height: 50px;
	z-index: 101;
	display: flex;
	align-items: center;
	padding-right: 8px;
}

.product_import_dropzone {
	height: 250px;
	border: 2px dashed $border-color;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $white;
	cursor: pointer;
	color: $gray-600;
	border-radius: 5px;

	&:hover {
		background-color: $gray-100;
	}
}

.product_edit_additional_images_grid {
	display: grid;
	grid-template-columns: auto auto auto;
	grid-gap: 10px;
}

.product_import_sidebar {
	width: 250px;
	flex-shrink: 0;
	padding: $spacer * 3 0;
	background: #f7f5f2;
	border-right: 1px solid #a6917133;
}

.product_import_sidebar_item {
	padding: 5px $spacer * 3;
	display: flex;
	align-items: center;
	cursor: pointer;
	color: #555;

	&.active {
		color: black;
		font-weight: bold;
	}

	&:hover {
		color: black;
	}
}

.product_import_sidebar_item_img {
	width: 18px;
	height: 18px;
	margin-right: $spacer;
	object-fit: contain;
}

.product_import_container {
	padding: $spacer * 2;
	flex: 1;
	height: 600px;
	overflow: hidden;
}

.product_edit_label {
	margin-top: 20px;
	font-weight: 500;
}

.product_import_content {
	padding: 30px;
	height: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	overflow: auto;
}

.product_import_step {
	display: flex;
	align-items: center;
	padding: $spacer * 0.5 0;
}

.product_import_step_number {
	width: 20px;
	height: 20px;
	margin-right: $spacer;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	line-height: 1;
	background-color: $gray-200;
	font-weight: 500;
	font-size: 14px;
}

.product_import_upload_zone {
	width: 100%;
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 50px;
}

.product_import_uploadable_div {
	height: 250px;
	border: 2px dashed $border-color;
	color: $gray-600;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	border-radius: 8px;

	&:hover {
		background-color: rgba(245, 245, 245, 0.4);
		border-color: $gray-500;
	}
}

.product_import_error {
	background-color: $yellow-100;
	border-radius: 8px;
	padding: $spacer $spacer * 2;
	display: flex;
	max-height: 172px;
	overflow: auto;
}

.product_res_container {
	display: block;
	overflow-y: scroll;
	height: 100%;
	padding-left: 10px;
	padding-right: 10px;
}

.product_res {
	border-radius: 5px;
	position: relative;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 65px;
	padding: 10px;
	background: #f2f2f2;
	margin-bottom: 5px;
	cursor: pointer;
}

.product_res__price {
	@include text-truncate;
	color: $text-muted;
}

.product_res.product_res__active {
	background: $secondary;
	color: white;
	.product_res__status {
		background: $secondary;
	}
	.product_res__price {
		color: white;
	}

	&:hover {
		background: $secondary;
		color: white;

		.product_res__status {
			background: $secondary;
		}
	}
}

.product_res__status {
	position: absolute;
	bottom: 7px;
	width: 16px;
	height: 16px;

	left: 37px;
	border-radius: 50%;
	background: #f2f2f2;
	padding: 3px;
}

.product_res__dot {
	border-radius: 50px;
	width: 100%;
	height: 100%;
	background: #bebebe;
}

.product_res__dot.product_res__dot__success {
	background: #19b600;
}

.product_res:hover {
	background: #e2e2e2;
	.product_res__status {
		background: #e2e2e2;
	}
}

.product_res__img {
	width: 40px;
	height: 40px;
	margin-right: 10px;
	border-radius: 5px;
	flex-shrink: 0;
	display: block;
	object-fit: cover;
}

.product_select__col {
	background: white;
	overflow: auto;
	flex-shrink: 0;
	border-right: 1px solid $section-border-color;
	overflow: hidden;
	min-height: 200px;
}

.product_select__label {
	font-weight: 500;
	margin: $spacer * 4 0 $spacer 0;
	color: $gray-600;
}

.product_sel__img {
	height: 150px;
	overflow: hidden;
	width: 100%;
	object-fit: contain;
	border-radius: 8px;
}

.product_sel {
	padding-left: 10px;
	height: 100%;
	overflow: auto;
}

.product_sel__title {
	margin-top: 15px;
	margin-bottom: 10px;
	font-size: 16px;
	display: flex;
	align-items: center;
}

.product_sel__name {
	@include text-truncate;
	margin-top: 10px;
	font-weight: 500;
	font-size: 16px;
	align-items: center;
}

.product_sel__cat {
	margin-top: 15px;
	margin-bottom: 10px;
	color: $text-muted;
}

.product_sel__price {
	color: $success;
	font-weight: 500;
}

.product_sel__label {
	font-weight: 500;
	margin-top: 10px;
}

.product_sel__subtitle {
	font-size: 14px;
	color: $gray-600;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	white-space: pre-wrap;
	overflow: hidden;
}

.product_sel_gallery_ele {
	height: 100px;
	width: 50%;
	padding-right: 5px;
	padding-left: 0;
	display: block;
	overflow: hidden;
	margin-bottom: 10px;
	position: relative;
}

.product_sel_gallery_ele:nth-child(2n) {
	padding-left: 5px;
	padding-right: 0;
}

.product_sel_gallery_ele__img {
	background: whitesmoke;
	border-radius: 6px;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.product_sel_gallery_ele_send_btn {
	cursor: pointer;
	opacity: 0;
	position: absolute;
	font-size: 12px;
	padding: 4px 10px;
	right: 10px;
	top: 10px;
	border-radius: 4px;
}

.product_sel_gallery_ele:hover {
	.product_sel_gallery_ele_send_btn {
		opacity: 0.5;
		&:hover {
			opacity: 1;
		}
	}
}

.product_sel__prop {
	display: flex;
	padding: 2px 0;
	overflow: hidden;
}

.product_sel__avalability_tag {
	top: 10px;
	font-size: 11px;
	left: 10px;
	padding: 2px 5px;
	border-radius: 5px;
	background: #000000aa;
	color: white;
	display: flex;
	align-items: center;
}

.product_select_modal {
	padding: 0;
	z-index: 99;
	width: 40%;
	margin-bottom: 10px;
	position: fixed;

	bottom: 38px;
	right: 15vw;
	.product_select_body {
		border-radius: 10px;
		border-top: 1px solid #d5d5d5;
		border-bottom: 1px solid #d5d5d5;
		transition: height 120ms ease-out;

		height: 50vh;
		display: flex;
		overflow: auto;

		box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	}
}

.product_select_close {
	cursor: pointer;
	font-size: 12px;
	top: -25px;
	right: 0;
	border-radius: 7px;
	background: #555555dd;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	color: $white;
	position: absolute;
	display: flex;
	align-items: center;
	padding: 3px 10px;
	justify-content: center;
	&:hover {
		background: $black;
	}
}

.product_select_modal__hide {
	padding-top: 0;
	margin-bottom: 0px;
	.product_select_body {
		border-top: 0px;
		border-bottom: 0px;
		margin-bottom: 0px;
		height: 0px;
		box-shadow: none;
	}
	.product_select_close {
		display: none;
	}
}

.product_select_body.product_select_body__preview {
	border-top: none;
	border-bottom: none;
	display: flex;
	background: white;
}

.product_back_btn {
	position: absolute;
	top: 28px;
	left: 10px;
	cursor: pointer;
	color: $gray-800;
	&:hover {
		color: $primary;
	}
}

/* Hide scrollbar for Chrome, Safari and Opera */
.product_table_th_container::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.product_table_th_container {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
	overflow: auto;
}

.product_variant_menu_link {
	display: flex;
	align-items: center;
	margin-left: $spacer * 1.5;
}

.product_variant_menu_text {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	font-size: 13px;
	color: $gray-600;
}

.product_variant_menu_link.active .product_variant_menu_text {
	color: $primary;
}

.product_variant_menu_link:hover .product_variant_menu_text {
	color: $primary;
}

.product_variant_menu_img {
	width: 32px;
	height: 32px;
	margin-right: $spacer;
	flex-shrink: 0;
	object-fit: cover;
	border-radius: 5px;
	overflow: hidden;
}

.product_variant_menu_add_variant_img {
	width: 32px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px dashed $gray-600;
	margin-right: $spacer;
	color: $gray-600;
	border-radius: 5px;
}

.product_variant_menu_link.active .product_variant_menu_add_variant_img {
	border-color: $primary;
	color: $primary;
}

.product_variant_menu_link:hover .product_variant_menu_add_variant_img {
	border-color: $primary;
	color: $primary;
}

.collection_products_container {
	flex: 1;
	border: 1px solid $border-color;
	display: flex;
	overflow: hidden;
	min-height: 356px;
}

.collection_products_left {
	flex: 1;
	border-right: 1px solid $border-color;
	overflow: auto;
	position: relative;
	display: flex;
	flex-direction: column;
}

.collection_products_right {
	width: 200px;
	flex-shrink: 0;
	padding: $spacer $spacer * 2;
	background-color: $gray-100;
	overflow: auto;
}

.collection_product_item {
	padding: $spacer * 0.5 0;
	display: flex;
	align-items: center;

	&.clickable {
		cursor: pointer;
		color: $gray-600;

		&:hover {
			color: $gray-700;
		}
	}
}

.collection_product_item_img {
	width: 32px;
	height: 32px;
	object-fit: cover;
	margin-right: $spacer;
	border-radius: 5px;
}

.collection_product_item_text {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	flex: 1;
}

.collection_products_search_wrapper {
	flex: 1;
	overflow: auto;
	padding: $spacer $spacer * 2;
	position: relative;
}

.collection_main_products_container {
	flex: 1;
	border: 1px solid $border-color;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.collection_main_products_wrapper {
	flex: 1;
	overflow: auto;
	padding: $spacer $spacer * 2;
	position: relative;
	min-height: 356px;
	overflow: hidden;
}

.import_product_preview_table {
	width: 100%;
	overflow: auto;
	font-size: 13px !important;
	margin-bottom: $spacer * 2;
	flex: 1;
}

.product_table_image {
	width: 32px;
	height: 32px;
	flex-shrink: 0;
	object-fit: cover;
	border-radius: 5px;

	&.small {
		width: 24px;
		height: 24px;
	}
}

.edit_product_modal_sidebar {
	width: 250px;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	border-right: 1px solid #a6917133;
	background-color: #f7f5f2;
}

.edit_product_modal_sidebar_item {
	display: flex;
	padding: $spacer $spacer * 3;
	align-items: center;
	cursor: pointer;
	font-size: 16px;
	color: #666;
	&.active {
		background-color: #0000000a;
		color: black;
	}

	&:hover {
		color: black;
	}
}

.edit_product_modal_sidebar_step.edit_product_modal_sidebar_step__active {
	color: white;
	background: #000000ad;
	// color: white;
}

.edit_product_modal_sidebar_step {
	width: 24px;
	height: 24px;
	margin-right: $spacer;
	border-radius: 5px;
	background-color: #0000000d;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1;
	font-size: 13px;
	font-weight: 500;
	color: $gray-700;
}

.edit_product_modal_content {
	padding: 0;
	height: 680px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	flex: 1;
	position: relative;
}

.edit_product_modal_content_inner {
	padding: $spacer * 3;
	flex: 1;
	overflow: auto;
}

.product_variant_table_add_image {
	color: $gray-600;
	width: 100%;
	height: 32px;
	justify-content: center;
	align-items: center;
	display: flex;
	cursor: pointer;
	background-color: $gray-200;

	&:hover {
		color: $gray-700;
	}
}

.product_variant_table_preview_image {
	width: 160px;
	height: 160px;
	position: absolute;
	background: $white;
	padding: $spacer;
	border: 1px solid $border-color;
	box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	z-index: 99;
	cursor: initial;
}

.variant_table_image_plus {
	position: absolute;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background-color: $success;
	color: $white;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.edit_product_modal_footer {
	padding: 12px 30px;
	display: flex;
	align-items: center;
	margin-top: auto;
	border-top: 1px solid #e7e1d9;
}

.xcel-row.is-invalid .xcel {
	background-color: #ea3d2f15;
}

.shopee_shop {
	display: flex;
	margin-bottom: 10px;
	margin-top: 10px;
	align-items: center;
	padding: 10px 20px;
	background: whitesmoke;
	max-width: 300px;
	border-radius: 5px;
	&:hover {
		background: #ededed;
	}
}

.shopee_shop__img {
	margin-right: 10px;
	height: 32px;
	width: 32px;
	object-fit: cover;
	border-radius: 5px;
}
