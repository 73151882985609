.call_search__body {
	margin-top: 10px;
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: auto;
	padding: 0 20px;
}

.call_search__body.call_search__body__disabled {
	opacity: 0.8;
	user-select: none;
}

.call_modal_body {
	display: flex;
	flex-direction: column;
	padding: 0; // 20px 20px 20px;
	overflow: auto;
	flex: 1;
	// overflow-x: hidden;
	// overflow-y: auto;
}

.call_modal_input {
	width: 100%;
	margin-top: 0;
	border: none;
	font-size: 26px;
	font-weight: medium;
	height: 50px;
	background: whitesmoke;
	outline: 0;
	padding: 0 10px;
	border-radius: 8px;
	color: #555;
	&:hover,
	&:active {
		background: #e7e7e7;
	}
}

.call_search__item {
	user-select: none;
	flex-shrink: 0;
	width: 100%;
	cursor: pointer;
	display: flex;
	align-items: center;
	border-radius: 4px;
	padding: 6px 0px;
	overflow: hidden;
	word-break: break-all;
	white-space: nowrap;
	text-overflow: ellipsis;
	border-bottom: 1px solid whitesmoke;
}

.cal_modal_sub_label {
	font-weight: 500;
	font-size: 13px;
	padding: 0;
	line-height: 14px;
	/* identical to box height */
	text-transform: uppercase;
	color: #888888;
}

.tab__name {
	font-weight: 500;
	font-size: 18px;
	line-height: 21px;
}

.count__devices {
	height: 19px;
	font-size: 13px;
	line-height: 1;
	font-weight: 400;
	color: #7a7a7a;
	background-color: #ebebeb;
	border-radius: 4px;
	padding: 3px 5px;
	margin-top: 12px;
}

.device__item {
	flex-direction: column;
	padding-top: 8px;
	padding-bottom: 8px;
	border-top: 1px solid rgb(235 235 235);
}

.device_item__row {
	flex-direction: row;
	justify-content: space-between;
}

.error__connected {
	display: inline-block;
	padding-top: 4px;
	line-height: 1;
	padding-left: 5px;
	font-size: 11px;
	font-weight: 600;
	padding-right: 5px;
	border-radius: 6px;
	background: $warning;
	text-transform: uppercase;
	height: 18px;
	color: white;
}

.device__id {
	color: #717171;
	font-size: 13px;
	font-weight: 400;
	font-family: $font-family-monospace;
	cursor: pointer;
}

.device__name {
	@include text-truncate();
	cursor: pointer;
	font-size: 15px;
	font-weight: 400;
}

.device__name:hover {
	color: $primary;
}

.device__lastupdate {
	color: #717171;
	font-size: 15px;
	font-weight: 400;
}

.call_modal_content {
	background: white;
	display: flex;
	flex-direction: column;
	height: 400px;
	transition: background 0.5s ease;
}
