// Utilities

$utilities: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-merge(
	(
		'display': (
			responsive: true,
			print: true,
			property: display,
			class: d,
			values: none inline inline-block block table flex,
		),
		'border': (
			property: border,
			values: (
				null: $border-width solid $border-color,
				0: 0,
			),
		),
		// Sizing utilities
		'width':
			(
				property: width,
				class: w,
				values: (
					25: 25%,
					50: 50%,
					75: 75%,
					100: 100%,
					auto: auto,
				),
			),
		'max-width': (
			property: max-width,
			class: mw,
			values: (
				100: 100%,
			),
		),
		'height': (
			property: height,
			class: h,
			values: (
				25: 25%,
				50: 50%,
				75: 75%,
				100: 100%,
				auto: auto,
			),
		),
		// Flex utilities
		'flex':
			(
				responsive: true,
				property: flex,
				values: (
					fill: 1 1 auto,
				),
			),
		'flex-direction': (
			responsive: true,
			property: flex-direction,
			class: flex,
			values: row column row-reverse column-reverse,
		),
		'flex-shrink': (
			responsive: true,
			property: flex-shrink,
			class: flex,
			values: (
				shrink-0: 0,
				shrink-1: 1,
			),
		),
		'flex-wrap': (
			responsive: true,
			property: flex-wrap,
			class: flex,
			values: wrap nowrap wrap-reverse,
		),
		'justify-content': (
			responsive: true,
			property: justify-content,
			values: (
				start: flex-start,
				end: flex-end,
				center: center,
				between: space-between,
				around: space-around,
			),
		),
		'align-items': (
			responsive: true,
			property: align-items,
			values: (
				start: flex-start,
				end: flex-end,
				center: center,
				baseline: baseline,
				stretch: stretch,
			),
		),
		// Margin utilities
		'margin-top':
			(
				responsive: true,
				property: margin-top,
				class: mt,
				values:
					map-merge(
						$spacers,
						(
							auto: auto,
						)
					),
			),
		'margin-right': (
			responsive: true,
			property: margin-right,
			class: mr,
			values:
				map-merge(
					$spacers,
					(
						auto: auto,
					)
				),
		),
		'margin-bottom': (
			responsive: true,
			property: margin-bottom,
			class: mb,
			values:
				map-merge(
					$spacers,
					(
						auto: auto,
					)
				),
		),
		'margin-left': (
			responsive: true,
			property: margin-left,
			class: ml,
			values:
				map-merge(
					$spacers,
					(
						auto: auto,
					)
				),
		),
		// Padding utilities
		'padding-top':
			(
				responsive: true,
				property: padding-top,
				class: pt,
				values: $spacers,
			),
		'padding-right': (
			responsive: true,
			property: padding-right,
			class: pr,
			values: $spacers,
		),
		'padding-bottom': (
			responsive: true,
			property: padding-bottom,
			class: pb,
			values: $spacers,
		),
		'padding-left': (
			responsive: true,
			property: padding-left,
			class: pl,
			values: $spacers,
		),
		// Text
		'font-weight':
			(
				property: font-weight,
				values: (
					light: $font-weight-light,
					lighter: $font-weight-lighter,
					normal: $font-weight-normal,
					bold: $font-weight-bold,
					bolder: $font-weight-bolder,
				),
			),
		'text-transform': (
			property: text-transform,
			class: text,
			values: lowercase uppercase capitalize,
		),
		'text-align': (
			responsive: true,
			property: text-align,
			class: text,
			values: left right center,
		),
		'color': (
			property: color,
			class: text,
			values:
				map-merge(
					$theme-colors,
					(
						'white': $white,
						'body': $body-color,
						'muted': $text-muted,
						'black-50': rgba($black, 0.5),
						'white-50': rgba($white, 0.5),
						'reset': inherit,
					)
				),
		),
		'text-decoration': (
			property: text-decoration,
			values: none underline line-through,
		),
		'font-style': (
			property: font-style,
			class: font,
			values: italic normal,
		),
		'word-wrap': (
			property: word-wrap,
			class: text,
			values: (
				break: break-word,
			),
		),
		'font-family': (
			property: font-family,
			class: font,
			values: (
				monospace: $font-family-monospace,
			),
		),
	),
	$utilities
);

// Loop over each utility property
@each $key, $utility in $utilities {
	// The utility can be disabled with `false`, thus check if the utility is a map first
	// Only proceed if responsive media queries are enabled or if it's the base media query
	@if type-of($utility) == 'map' and (map-get($utility, responsive)) {
		@include generate-utility($utility, '');
	}
}

.d-inline-flex {
	display: inline-flex;
}

.no_border {
	border: none !important;
}
