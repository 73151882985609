.live_container {
	display: flex;
	background: #f7f5f2;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
}

.live_box {
	border-bottom: 1px solid #eeeeee;
	padding-bottom: 5px;
	margin-top: 15px;
}

.live_box__empty_body {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: $spacer * 4 0;
}

.live_box__header {
	margin-left: 15px;
	display: flex;
	align-items: center;
	margin-right: 15px;
	font-size: 17px;
	font-weight: 700;
}

.live_box_table {
	height: 100%;
	background: white;
	border-radius: 5px;
	padding-top: 15px;
	border: 1px solid #eeeeee;
	border-bottom: 1px solid #eeeeee;
	padding-bottom: 5px;
}

.live_box_table .live_box__header {
	border-bottom: 1px solid #eeeeee;
	margin: 0 $spacer;
	padding: 5px $spacer;
	font-weight: 400;
}

.live_box__row {
	margin: 0 $spacer * 2;
	padding: $spacer 0;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #eeeeee;
	align-items: center;
	&:last-child {
		border-bottom: none;
	}
}

.live_box__row.live_box__row__clickable {
	margin: 0 $spacer;
	padding: $spacer;
	cursor: pointer;
	&:hover {
		background: whitesmoke;
	}
}

.live_box__col {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-left: $spacer;
	&:first-child {
		margin-left: 0;
	}
}

.live_view__row.live_view__row__hover {
	cursor: pointer;
	&:hover {
		background: $gray-200;
	}
}

div.live_view__row.live_view__row__selected {
	background: $gray-400;
}

.live_view__row {
	display: flex;
	background: white;
	justify-content: space-between;
	border-bottom: 1px solid #eeeeee;
	align-items: center;
}

.live_view__header {
	display: flex;
	background: white;
	justify-content: space-between;
	border-bottom: 1px solid #e5e5e5;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 500;
	border-top: 1px solid #dfdfdf;
	align-items: center;
}

.live_view__col {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	padding-left: $spacer;
	margin-top: $spacer;
	margin-bottom: $spacer;
	//max-width: 600px;
	width: 30px;
	min-width: 20px;
}

.live_view__col.live_view__col__fullname {
	display: flex;
	width: 120px;
	align-items: center;
}

.live_view__col.live_view__col__device {
	display: flex;
	width: 100px;
	align-items: center;
}

.live_view__col.live_view__col__url {
	flex: 1;
}

.live_view__col.live_view__col__ip {
	cursor: default;
	width: 190px;
	align-items: center;
}

.live_view__col.live_view__col__location {
	width: 170px;
	align-items: center;
}

.live_view__col.live_view__col__source {
	display: flex;
	width: 160px;
	gap: 8px;
	align-items: center;
}

.live_view__col.live_view__col__action {
	cursor: default;
	width: 140px;
	align-items: center;
}

/*
border: 1px solid #D8D8D8;
		background: #fff;
		box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
*/

td.live_dropdown_container {
	position: relative;
	overflow: unset;
}

.live_dropdown {
	position: absolute;
	min-width: 200px;
	max-width: 100%;
	overflow: hidden;

	z-index: 2;
	top: 100%;
	right: 0;

	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
	// box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.06), 0px 4px 32px rgba(0, 0, 0, 0.06), 0px 8px 64px rgba(0, 0, 0, 0.08);

	border-radius: 8px;
	border-top-right-radius: 0;
}

.live_dropdown.live_dropdown__viewing {
	width: 100%;
}

.live_dropdown__item {
	font-weight: normal;
	padding: $spacer * 0.5 $spacer;
	display: block;
	align-items: center;
	cursor: pointer;
	background: $white;
	position: relative;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	&:first-child {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding-top: $spacer * 0.6;
	}

	&:last-child {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		padding-bottom: 0.6 * $spacer;
	}

	&:hover {
		color: white;
		background: $primary; // #E8F0FF;
		.convo_header__dropdown_icon {
			color: white;
		}
	}
}

.live_dropdown__item.live_dropdown__item__no_select {
	&:hover {
		color: unset;
		background: $white; // #E8F0FF;
	}
}

.live_dropdown__item.live_dropdown__item__viewing {
	display: flex;
}

.live_dropdown__item__loading {
	position: absolute;
	top: 15px;
	right: 20px;
}

.live_filter__button_x {
	color: $gray-500;
	stroke: $gray-500;
	cursor: pointer;
	&:hover {
		stroke: $black;
		color: $black;
	}
}

.live_filter__header {
	color: $gray-700;
	display: flex;
	align-items: center;
	font-weight: 500;
}

.live_filter__header.live_filter__header__clickable {
	cursor: pointer;
	&:hover {
		color: $black;
	}
}

.live_view__user_name {
	color: $black;
	@include text-truncate();
}

.live_view__user_email {
	color: $gray-600;
	font-size: smaller;
}

.live_filter__viewing_filter_text {
	color: $primary;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;

	max-width: 150px;
}

.live_filter__orderby {
	display: flex;
	position: relative;
	cursor: pointer;
	align-items: center;
	color: $gray-600;
	stroke: $gray-600;
	&:hover {
		color: $black;
		stroke: $black;
	}
}

.live_view__empty {
	display: block;
	margin: $spacer * 4 auto;
}
.live_view__loading {
	padding-top: 40px;
}

.live_view__table {
	width: 100%;
}

.live_view__viewing_url {
	color: $gray-600;
	font-size: 14px;

	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	&:hover {
		color: $black;
	}
}

.live_view__viewing_title {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.live_number__total {
	font-size: 48px;
	color: $success;
	font-weight: 600;
	padding: $spacer;
}

.live_number.live_number__increase.live_number__total {
}

.live_view__result_count {
	align-items: center;
	display: flex;
}

.live_view__order_by {
	align-items: center;
	display: flex;
}

.live_view__user_online {
	flex-shrink: 0;
	display: inline-block;
	align-items: center;
	color: #00a808;
	text-transform: lowercase;
}

.live_view__upgrade {
	display: flex;
	align-items: center;
	background: linear-gradient(5.28deg, #f98006 34.25%, #fa681f 65.75%);
	height: 60px;
	color: $white;
	justify-content: center;
}

.live_line {
	padding: 3px 15px;

	.live_line__progress {
		display: flex;
		align-items: center;
		flex-shrink: 0;
		flex-wrap: nowrap;
	}

	.live_line__action {
		overflow: hidden;
		display: none;
	}

	&:hover {
		background: whitesmoke;

		.live_line__progress {
			display: none;
		}

		.live_line__action {
			display: flex;
			align-items: center;
			flex-shrink: 0;
			flex-wrap: nowrap;
		}
	}
}

.live_sidebar {
	border-top: 1px solid #dfdfdf;
	border-right: 1px solid #dfdfdf;
	height: 100%;
	display: flex;
	flex-direction: column;
	max-width: 350px;
	background: white;
	overflow-y: hidden;
	flex: 1;
}

.live_sidebar.live_sidebar__disabled {
	filter: grayscale(1);
}

.live_progress {
	background: gray;
	height: 100%;
	width: 0px;
	transition: width 2s linear;
}

.live_line_title {
	@include text-truncate();
	font-size: 15px;
	margin-right: 10px;
	margin-top: 2px;
	margin-bottom: 2px;
	cursor: pointer;
}

.live_list-move,
.live_list-enter-active,
.live_list-leave-active {
	transition: all 0.5s ease;
}
.live_list-enter-from,
.live_list-leave-to {
	opacity: 0;
	transform: translateX(30px);
}

.live_view__col:hover .live_hidden_icon {
	visibility: visible;
}

.live_view__col .live_hidden_icon {
	visibility: hidden;
}

.live_view__row.live_view__row__active {
	background: #fafffa;
}

.live_view__row.live_view__row__inactive {
	background: whitesmoke;
}
