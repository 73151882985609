.color_picker__colors {
	display: flex;
	flex-wrap: wrap;
	cursor: pointer;
}

.color_picker__color {
	flex-shrink: 0;
	margin-right: $spacer * 0.5;
	width: 20px;
	height: 20px;
	margin-bottom: $spacer * 0.5;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $white;
}

.color_picker__icon {
	transition: 0.3s;
	opacity: 0;
}

.color_picker__color.color_picker__color__custom {
	position: relative;
}

.widget-website {
	overflow-y: auto;
}

.widget-website__head {
	margin-bottom: $spacer * 1.5;
}

.widget-website-platform {
	border: 1px solid $border-color;
	border-radius: 8px;
	margin-bottom: $spacer * 1.5;

	&:last-child {
		margin-bottom: 0;
	}
}

.widget-website-platform__head {
	display: flex;
	align-items: center;
	padding: $spacer $spacer * 2;
	cursor: pointer;
}

.widget-website-platform__img {
	max-height: 50px;
	object-fit: cover;
	margin-right: $spacer;
}

.widget-website-platform__nav-icon {
	margin-left: auto;
	transition: 0.3s;
	width: 24px;
	height: 24px;
}

.widget-website-platform.active .widget-website-platform__nav-icon {
	transform: rotate(90deg);
}

.widget-website-platform__body {
	padding: $spacer * 1.5;
	border-top: 1px solid $border-color;
}

.subiz-code__container {
	max-height: 150px;
	overflow-y: auto;
	position: relative;
	background: $gray-100;
	white-space: pre-wrap;
	border-radius: 4px;
}

.subiz-code {
	font-family: monospace;
	padding: $spacer * 1.5;
	padding-right: 115px;
}

.subiz-code__copy {
	position: absolute;
	top: $spacer * 1.5;
	right: $spacer * 1.5;
}

.widget-website-platform__step {
	border-radius: 50%;
	display: flex;
	font-weight: 700;
	background-color: $blue-100;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 2px;
	margin-right: $spacer;
	width: 18px;
	height: 18px;
	font-size: 12px;
}

.widget-form-settings__section {
	margin-bottom: 30px;
	display: flex;
	font-size: 14px;
	max-width: 550px;

	&:last-child {
		margin-bottom: 0;
	}
}

.widget-form-settings__sw {
	margin-right: $spacer * 1.5;
	align-self: flex-start;
}

.widget-form-settings__section-title {
	line-height: 1;
	font-size: 15px;
}

.widget-form-settings__section-subtitle {
	margin-top: $spacer * 0.25;
	color: $gray-600;
}

.widget-form-settings__form {
	padding-top: $spacer;
}

.widget-form-settings__field {
	margin-bottom: $spacer;
	padding: $spacer;
	border-radius: 4px;
	background-color: rgb(192 192 192 / 15%);

	&.ghost {
		opacity: 0.7;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

.widget-form-settings__group-list {
	display: flex;
	flex-direction: column;
}

.widget-form-settings__chat-group {
	display: flex;
	align-items: center;
	margin-left: $spacer * 1.5;
	margin-bottom: $spacer * 0.5;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		left: -15px;
		top: calc(50% - 40px);
		width: 10px;
		height: 40px;
		border-left: 1px solid $gray-400;
		border-bottom: 1px solid $gray-400;
		border-bottom-left-radius: 3px;
	}

	&.group--first:before {
		height: 15px;
		top: calc(50% - 15px);
	}

	&.group--add {
		width: fit-content;
		position: relative;
		color: $gray-600;
		cursor: pointer;
		margin-left: 14px;
		border: 1px dashed;
		width: 20px;
		height: 20px;
		background-color: $white;
		border-radius: 50%;
		justify-content: center;

		&:hover {
			color: $gray-700;
		}
	}
}

.widget-chat-btn__item {
	width: 60px;
	height: 60px;
	margin-right: $spacer * 2;
	margin-bottom: $spacer * 2;
	cursor: pointer;
	border-radius: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.widget-chat-img-btn__item {
	width: 200px;
	height: 125px;
	margin-right: $spacer * 4;
	margin-bottom: $spacer * 4;
	cursor: pointer;
	border-radius: 0;

	&.small {
		width: 180px;
		height: 115px;
		margin-right: $spacer * 3;
		margin-bottom: $spacer * 3;
	}
}

.widget-chat-btn-select__title {
	display: flex;
	align-items: center;
	position: sticky;
	top: 0;
	z-index: 100;
	cursor: pointer;
	width: 100%;
	background-color: $white;
}

.widget-display-settings__form {
	margin-bottom: 15px;
	max-width: 550px;
}

.widget-display-settings__title {
	font-weight: 500;
	font-size: 15px;
}

.widget-form-settings__input {
	max-width: 180px;
}

.widget-form-settings__radio,
.widget-form-settings__checkbox {
	width: 14px;
	height: 14px;
}

.widget-display-settings-lang {
	display: flex;
}

.widget-display-settings-lang__radio {
	border: 1px solid $gray-400;
	max-width: 250px;
	flex: 1;
	padding: $spacer * 0.5 $spacer;
	background-color: $white;
	border-left: none;
	cursor: pointer;

	&:first-child {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		border-left: 1px solid $gray-400;

		&.active {
			box-shadow: none;
		}
	}

	&:last-child {
		border-top-right-radius: 8px;
		border-bottom-right-radius: 8px;
	}

	&.active {
		background-color: rgba(164, 200, 255, 0.15);
		border-color: $blue-100;
		box-shadow: -1px 0 0 0 $blue-100;
	}
}
