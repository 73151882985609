.message_editor {
	cursor: text;
	position: relative;
	border: 1px solid #aaa;
	border-radius: 7px;
	background-color: white;
	display: flex;

	transition:
		background-color 200ms ease,
		border 500ms ease;
	&:hover {
		border-color: $black;
	}
}

.message_editor__author {
	align-items: center;
	display: flex;
	position: relative; // for emoji picker
	overflow: hidden;
	flex: 1;
	padding: 0 $spacer 0 $spacer;
	white-space: nowrap;
	@include text-truncate();
}

.message_editor__actions {
	display: flex;
	position: relative; // for emoji picker
	padding: 0 $spacer 0 $spacer;
	white-space: nowrap;
}

.message_editor__action_icon {
	stroke: $gray-600;
	color: $gray-600;
	fill: $gray-100;
	&:hover {
		stroke: $black;
		color: $black;
	}
}

.message_editor__action_item {
	cursor: pointer;
	position: relative;
	margin: 0 0 $spacer 1.2 * $spacer;
	display: flex;
	align-items: center;
}

.message_editor__action_item.message_editor__action_item__send {
	margin-bottom: 5px;
}

.message_editor.message_editor__plain {
	flex-wrap: wrap;
	justify-content: flex-end;
	border: none;
	background-color: #f4f4f4;
	&:hover,
	&:active {
		background: #efefef;
	}
}

.message_editor.message_editor__html {
	flex-wrap: wrap;
	flex-direction: column-reverse;
	align-items: flex-start;
	background: $gray-100;

	.message_editor__actions {
		position: absolute;
		bottom: 100%;
		right: 0;
	}
}

.message_editor__attachments__image {
	display: flex;
	flex-wrap: wrap;
	border-top: 1px solid $gray-300;
	max-height: 120px;
	overflow: auto;
	width: 100%;
	padding-bottom: $spacer;
}

.message_editor__attachment {
	margin: $spacer 0 0 $spacer;
	position: relative;
}

.message_editor__attachment_image {
	max-width: 60px;
	height: 60px;
	object-fit: cover;
	border-radius: 10px;
	user-select: none;

	&.image--small {
		width: 60px;
		height: 60px;
	}
}

.message_editor__attachment_x {
	position: absolute;
	right: 5px;
	top: 5px;
	color: $gray-300;
	background: #0000007a;
	padding: 4px;
	border-radius: 100%;
	cursor: pointer;
	&:hover {
		color: white;
		background: #000000da;
	}

	&.x--small {
		top: 0;
		right: 0;
	}
}

.message_editor__remove_file {
	color: $gray-400;
	flex: 0 0 30px;
	margin-left: $spacer;
	padding: 4px;
	border-radius: 100%;
	cursor: pointer;
	&:hover {
		color: black;
	}
}

.message_editor__attachments__file {
	display: flex;
	flex-wrap: wrap;
	border-top: 1px solid $gray-300;
	width: 100%;
	padding-bottom: $spacer;
}

.message_editor__attachment_file {
	display: flex;
	max-width: 250px;
	object-fit: cover;
	border-radius: 10px;
	border: 1px solid $gray-300;
	align-items: center;
	padding: $spacer;
	user-select: none;
}

.message_editor__attachment_file_logo {
	flex: 0 0 30px;
	border-right: 1px solid $gray-300;
	display: block;
	fill: #ebebeb;
	padding-right: $spacer;
}

.message_editor__attachment_file_name {
	font-weight: 500;
}

.add-agent {
	cursor: pointer;
}

.modal-main .addtags .label {
	width: unset;
}

.message_editor__attachment_order {
	border: 1px solid #aaa;
	background: white;
	border-radius: 8px;
	width: 200px;
	height: 200px;
	overflow-y: auto;
	overflow-x: hidden;
	padding: 10px;
}

.message_editor__attachment_order_label {
	font-size: 13px;
	color: #777;
}

.message_editor__attachment_order_note {
	font-size: 14px;
	white-space: pre-wrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.message_editor__attachment_order_product_img {
	width: 50px;
	height: 50px;
	margin-right: 10px;
	border-radius: 5px;
	flex-shrink: 0;
	display: block;
	object-fit: contain;
	background: whitesmoke;
}

/////////////////////template picker/////////////////////
.template_picker {
	position: absolute;
	bottom: 100%;
	background: white;
	padding: 0px 0px 0px 0px;
	z-index: 99;

	width: 100%;
	background: white;
	border-radius: 8px 8px 0px 0px;
	margin-bottom: 0px;
	box-shadow:
		0px 1px 8px rgba(0, 0, 0, 0.1),
		0px 4px 32px rgba(0, 0, 0, 0.1),
		0px 8px 64px rgba(0, 0, 0, 0.12);
	max-height: 60vh;
	//overflow: auto;

	display: flex;
	flex-direction: column;

	&.template_picker__hide {
		display: none;
	}
}

.template_picker__template {
	color: black;
	cursor: pointer;
	padding-top: 8px;
	padding-bottom: 8px;
	&:hover {
		background: #e0f0ff;
	}
}

.template_picker__header {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	padding-top: 10px;
	padding-right: 15px;
	padding-left: 20px;
	padding-bottom: 10px;
	border-bottom: 1px solid #e2e2e2;
}

.template_picker__templates {
	flex: 1;
	background: #f3f3f3;
	overflow-y: auto;
	overflow-x: hidden;
}

.template_picker__template_label_wrapper {
	margin-left: $spacer * 2;
	display: flex;
	padding-bottom: 5px;
	overflow: hidden;
}

.template_picker__template_label {
	margin-right: 5px;
	display: inline-block;
	align-self: center;
	//font-weight: 700;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	padding: 2px 5px;
	background-color: #d9e6f2;
	color: #1c2d8c;
	font-weight: 500;
	font-size: 13px;
	border-radius: 4px;
}

.template_picker__template_summary {
	font-size: 15px;
	margin-left: 20px;
	margin-right: 10px;
	line-height: 18px;
	color: #333;
	word-break: break-word;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
}

.template_picker__template.template_picker__template__active {
	background: rgba($blue-100, 0.5);
	//color: white;
}

.template_picker__template_label.template_picker__template_label__active {
	//color: white;
}

.template_picker__template_summary.template_picker__template_summary__active {
	//color: #eee;
}

.template_picker__upgrade {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: $spacer;
}

/////////////////////suggestion/////////////////////
.suggestion {
	position: absolute;
	bottom: 100%;
	background: white;
	padding: 0px 0px 0px 0px;
	z-index: 99;

	left: 0;
	max-width: 100%;
	background: white;
	border-radius: 8px 8px 0px 0px;
	margin-bottom: 0px;
	max-height: 250px;
	overflow: auto;

	box-shadow:
		0px 1px 8px rgba(0, 0, 0, 0.1),
		0px 4px 32px rgba(0, 0, 0, 0.1),
		0px 8px 64px rgba(0, 0, 0, 0.12);
}

.suggestion__item {
	color: black;
	cursor: pointer;
	display: flex;
	min-width: 150px;
	align-items: center;
	justify-content: space-between;
	padding: $spacer 2 * $spacer;

	&:hover {
		background: #e0f0ff;
	}
}

.suggestion__item__text {
	flex: 1;
	display: flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.suggestion__item__help {
	opacity: 0;
	justify-self: flex-end;
	margin-left: $spacer * 2;
	code {
		color: $white;
	}
}

.suggestion__item.suggestion__item__active {
	background: $primary;
	color: white;

	.suggestion__item__help {
		opacity: 1;
	}
}

////////////////////email header ////////////////////////
.email_header {
	// border: 1px solid #f8f9fa;
	position: relative;
	width: 100%;
	background: white;
	border-top: none;
	border-radius: 5px;
}
.email_header .vue-tags-input {
	background: #324652;
}

.email_header .vue-tags-input .ti-new-tag-input {
	margin-top: -2px;
	background: transparent;
	color: black;
}

.email_header .vue-tags-input .ti-input {
	padding: 0;
	transition: border-bottom 200ms ease;
}

.email_header div.vue-tags-input {
	max-width: unset;
}

.email_header li.ti-new-tag-input-wrapper {
	margin: 0;
	margin-bottom: $spacer * 0.25;
	margin-top: $spacer * 0.25;
	min-height: 26px;
	.ti-new-tag-input {
		width: 0px;
	}
}

.email_header input.ti-tag-input {
	font-size: $font-size-base;
	margin-top: -2px;
}

.email_header .ti-icon-undo {
	display: none;
}

.email_header .input-subject {
	margin-top: 5px;
	margin-bottom: 5px;
	height: 28px;
	font-size: $font-size-base;
	width: 100%;
	border: none;
}

.email_header__label {
	margin-top: 3px;
	color: #757575;
	margin-right: $spacer;
	white-space: nowrap;
}

.email_header .input-label {
	color: #757575;
	font-size: $font-size-base;
	font-weight: 600;
	margin-top: 10px;
	margin-bottom: 10px;
}

.email_header .ti-new-tag-input {
	font-size: $font-size-base;
}

.email_header .minimize-btn,
.email_header .expand-btn {
	position: absolute;
	right: $spacer;
	top: $spacer;
	cursor: pointer;
	color: gray;
	user-select: none;
	z-index: 5;
	&:hover {
		color: black;
	}
}

.email_header__subject_input {
	width: 100%;
	border: none;
	&:focus {
		outline: none;
	}
}

.email_header__subject {
	display: flex;
	align-items: center;

	padding: $spacer 0;
	margin: 0 $spacer;
	border-bottom: 1px solid #eee;
}

.email_header__from {
	display: flex;
	align-items: center;

	padding: $spacer 0;
	margin: 0 120px 0 $spacer;
}

.email_header__to.email_header__to__mini {
	display: flex;
	align-items: center;

	padding: $spacer 0;
	margin: 0 10px 0 $spacer;
	border-top: none;
}

.email_header__to {
	display: flex;
	align-items: flex-start;
	padding: $spacer 0;
	margin: 0 $spacer;
	border-top: 1px solid #eee;
	border-bottom: 1px solid #eee;
}

.email_header .subject-input {
	display: flex;
	border-bottom: 1px solid #ebebeb;
}

.email_header div.ti-input {
	border: none;
}

.email_header .vue-tags-input .ti-autocomplete {
	background: white;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	border: 1px solid #cacaca;
	border-top: none;
}

.email_header .vue-tags-input .ti-item {
	padding-top: 5px;
	padding-bottom: 5px;
	padding-left: 5px;

	div {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

/* the selected item in the autocomplete layer, should be highlighted */
.email_header .vue-tags-input .ti-item.ti-selected-item {
	background: #d4eafe;
	color: black;
}

/* style the placeholders color across all browser */
.email_header .vue-tags-input ::-webkit-input-placeholder {
	color: #a4b1b6;
}

.email_header .vue-tags-input ::-moz-placeholder {
	color: #a4b1b6;
}

.email_header .vue-tags-input :-ms-input-placeholder {
	color: #a4b1b6;
}

.email_header .vue-tags-input :-moz-placeholder {
	color: #a4b1b6;
}

.email_header .vue-tags-input .ti-tag.ti-invalid {
	color: #750000;
}

.email_header .vue-tags-input .ti-tag.ti-invalid .ti-icon-close {
	color: #fff0f0;
	&:hover {
		color: white;
	}
}

.email_header .ti-icon-close {
	padding-top: 1px;
	color: #abb8ff;
	&:hover {
		color: black;
	}
}

.email_header .vue-tags-input .ti-tag.ti-editing {
	background: #fffbde;
}

/* default styles for all the tags */
.email_header .vue-tags-input .ti-tag {
	border-radius: 7px;
	background: #ebf6ff;
	font-size: $font-size-base;
	color: #010d75;
	position: relative;
	margin: 0;
	margin-right: $spacer * 0.5;
	cursor: pointer;
	margin-top: $spacer * 0.25;
	margin-bottom: $spacer * 0.25;
	&:hover {
		background: #e0f1ff;
	}

	.ti-tag-center {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 200px;
	}
}

/* we defined a custom css class in the data model, now we are using it to style the tag */
.email_header .vue-tags-input .ti-tag.custom-class {
	background: transparent;
	border: 1px solid #ebde6e;
	color: #ebde6e;
	margin-right: 4px;
	border-radius: 0px;
	font-size: 13px;
}

/* the styles if a tag is invalid */
.email_header .vue-tags-input .ti-tag.ti-invalid {
	background-color: #ffcaba;
	&:hover {
		background-color: #ffbaaa;
	}
}

/* if the user input is invalid, the input color should be red */
.email_header .vue-tags-input .ti-new-tag-input.ti-invalid {
	// color: #e88a74;
}

.email_header .vue-tags-input .ti-new-tag-input.ti-valid {
	color: #010d75; //same as tag color
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.email_header .vue-tags-input .ti-duplicate span,
.vue-tags-input .ti-new-tag-input.ti-duplicate {
	text-decoration: line-through;
}

.email_header .vue-tags-input li.ti-tag.ti-deletion-mark {
	background: #ffd7d7;
	color: #750000;
}

.dynamic-field.ProseMirror-selectednode {
	border: 1px dashed $yellow-900;
	background-color: $yellow-400;
}

.dynamic-field {
	position: relative;
	background-color: #ffc1073b;
	color: $yellow-800;
	border-radius: 4px;
	padding: 0 4px;
	border: 1px dashed $yellow-600;
	cursor: pointer;
	text-transform: lowercase;
	display: inline;
	&:hover {
		background-color: $yellow-400;
		color: $yellow-900;
		transition: background-color 150ms ease;
	}
}

.message_editor__tab {
	border-radius: 5px;
	background: #ececec;
	padding: 2px 5px;
	margin-bottom: 5px;
	margin-right: 5px;
	cursor: pointer;
}

.mention {
	color: #104c7b;
	background: #dff1ff;
	padding: 0 2px;
	border-radius: 4px;
}

.message_editor__action_item_dot {
	position: absolute;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: $red;
	border: 2px solid;
	border-color: inherit;
	top: 4px;
	left: 17px;
}

// css for lexical editor
.lexical_editor_input {
	outline: none;
	padding: 5px 10px;
	overflow-y: auto;
	width: 100%;
	//height: 100%;
	min-height: 80px;
	max-height: 200px;

	&.html {
		min-height: unset;
		max-height: unset;
		height: 100%;
	}
}

.convo_input_wrapper.email .lexical_editor_input.html {
	height: unset;
	min-height: 180px;
	max-height: 360px;
}

.lexical_editor_wrapper {
	border: 1px solid $input-border-color;
	border-radius: $input-border-radius;
	//height: 180px;
	//min-height: 180px;
	//max-height: 300px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	background-color: $white;

	&.focus,
	&:hover {
		border-color: $input-focus-border-color;
		outline: none;
	}

	.lexical_editor_input_wrapper {
		position: relative;
		overflow: hidden;
		flex: 1;
	}

	.lexical_editor_toolbar {
		flex-shrink: 0;
		padding: 5px 0;
		border-top: 1px solid #ececec;
		flex-wrap: nowrap;
		display: flex;
		justify-content: flex-end;
	}

	.lexical_editor_toolbar_btn_group {
		padding: 0 10px;
		border-right: 1px solid #ececec;
		display: flex;
		align-items: center;

		&:last-child {
			border-right: none;
		}
	}

	.lexical_editor_toolbar_btn_wrapper {
		margin-right: 5px;
		line-height: 1;

		.lexical_editor_toolbar_btn {
			margin-right: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	.lexical_editor_toolbar_btn {
		padding: 5px 5px;
		opacity: 0.8;
		border-radius: 4px;
		background-color: $white;
		transition: 0.15s;
		overflow: hidden;
		display: inline-flex;
		align-items: center;
		cursor: pointer;
		margin-right: 5px;
		outline: none;

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			background-color: #ececec;
			opacity: 1;
		}
		&.active {
			opacity: 1;
			background-color: #ececec;
			font-weight: 500;
		}
	}
}

.sbz-dynamic-field {
	cursor: pointer;
	padding: 0px 2px;
	border-radius: 4px;
	background-color: $yellow-200;
	text-transform: lowercase;
}

.lexical_editor_attachments {
	flex-shrink: 0;
	border-top: 1px solid #ececec;
	padding: 10px;
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 0;
}

.lexical_editor_tabs {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}

.lexical_editor_tabs .btn.lexical_editor_tab_item {
	font-size: 12px;
	padding: 2px 6px;
	flex-grow: unset;
}

.lexical_editor_preview_block {
	position: absolute;
	inset: 0;
	border: none;
	border-radius: $input-border-radius;
	z-index: 999;
	background-color: $white;
	overflow: hidden;

	&:hover .lexical_editor_preview_block_full_screen_btn {
		opacity: 1;
	}
}

.lexical_editor_preview_block_innner {
	overflow: auto;
	width: 100%;
	height: 100%;
}

.lexical_editor_preview_block_full_screen_btn {
	background-color: $dark;
	width: 24px;
	height: 24px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $white;
	transition: 0.3s;
	position: absolute;
	top: 10px;
	opacity: 0;
	right: 10px;
	z-index: 99;
}

.lexical_editor_preview_full_screen {
	position: fixed;
	inset: 0;
	z-index: 999999;
	background-color: $dark;
	display: flex;
	align-items: center;
	justify-content: center;

	&.d-none {
		display: none;
	}
}

// css for lexical editor themes. Note that BE and FE always understand this logic
.lexical_editor_input,
.lexical-preview-div {
	p {
		margin-bottom: 0;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-size: initial;
	}

	ul,
	ol {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.sbz_lexical_text__bold {
		font-weight: 700;
	}
	.sbz_lexical_text__italic {
		font-style: italic;
	}
	.sbz_lexical_text__underline {
		text-decoration: underline;
	}
}

.lexical_editor_placeholder {
	color: #999;
	overflow: hidden;
	position: absolute;
	font-style: italic;
	text-overflow: ellipsis;
	top: 5px;
	left: 10px;
	right: 10px;
	user-select: none;
	white-space: nowrap;
	display: inline-block;
	pointer-events: none;

	&.hidden {
		display: none;
	}
}

.lexical_editor_attachments .task_detail_attachment {
	margin-right: 10px;
	margin-bottom: 10px;
}

// custom css in message template edit modal
.message_template_edit_modal.lexical_editor_tabs_wrapper {
	.lexical_editor_wrapper {
		max-height: unset;
		min-height: unset;
		height: 350px;
	}
}

.convo_email_editor {
	.lexical_editor_wrapper {
		min-height: unset;
		max-height: unset;
		height: unset;
		display: block;
	}

	.lexical_editor_input {
		min-height: 120px;
		max-height: 240px;
	}
}

.facebook_private_reply_convo_email_editor {
	.lexical_editor_wrapper {
		flex: 1;
		max-height: unset;
		min-height: unset;
		height: unset;
	}

	.lexical_editor_tabs_wrapper {
		display: flex;
		flex-direction: column;
		height: 560px;
	}
}

// custom css for plaintext convo
.lexical_input__white {
	.lexical_editor_wrapper {
		border: none;
		height: unset;

		&:hover {
			border: none;
		}
	}

	.lexical_editor_input {
		min-height: 42px;
	}

	.lexical_editor_toolbar {
		border: none;
	}
}

.lexical_editor_tabs_wrapper.just_focus .lexical_editor_wrapper {
	background-color: rgba(255, 244, 166, 0.5);

	.lexical_editor_toolbar_btn {
		background-color: rgba(255, 244, 166, 0.5);
	}
}

.lexical_editor_float_text_format_popup {
	position: absolute;
	z-index: 9999;
	bottom: 0;
	border: 1px solid $border-color;
	background-color: $white;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	border-radius: 8px;
	height: 36px;
	opacity: 0;
	transition: opacity 0.3s ease;
	// set user-select is none so we dont fire selctoirn change event when click action in popup
	user-select: none;
	pointer-events: none;

	&.active {
		opacity: 1;
		pointer-events: auto;
	}
}

.lexical_editor_float_text_format_popup_inner {
	padding: 4px;
	display: flex;
	align-items: center;
	height: 100%;
}

.lexical_editor_float_text_format_popup_link_edit_actions {
	padding-left: 8px;
	display: flex;
	align-items: center;
	height: 100%;
	flex-shrink: 0;
}

.lexical_editor_float_popup_btn_group {
	padding-right: 8px;
	padding-left: 8px;
	border-right: 1px solid #ececec;
	display: flex;
	align-items: center;

	&:last-child {
		border-right: none;
		padding-right: 4px;
	}

	&:first-child {
		padding-left: 4px;
	}
}

.lexical_editor_float_popup_btn {
	padding: 2px 5px;
	font-size: 14px;

	border-radius: 4px;
	background-color: $white;
	transition: 0.15s;
	overflow: hidden;
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	margin-right: 5px;
	outline: none;

	.icon-tabler {
		width: 18px;
		height: 18px;
	}

	&:last-child {
		margin-right: 0;
	}

	&:hover {
		background-color: #ececec;
	}
	&.active {
		background-color: #ececec;
		font-weight: 500;
	}
}
