.promotion-program {
	table {
		width: 100%;
		border-collapse: separate;
		border: 1px solid #ddd;
		border-spacing: 0px;
	}
	th,
	td {
		padding: 8px 8px;
		text-align: left;
	}
	th {
		background-color: white;

		border-bottom: 1px solid #ddd;
	}
	tr:not(:last-child) td {
		border-bottom: 1px solid #ddd;
	}

	.chart {
		width: 50px;
		height: 20px;
		display: inline-block;
		vertical-align: middle;
	}
	.chart-bar {
		display: inline-block;
		width: 2px;
		margin-right: 1px;
		background-color: #4caf50;
		vertical-align: bottom;
	}
	.view-link {
		color: #1a73e8;
		text-decoration: none;
	}
}

.promocode_td {
	width: 600px;
}
