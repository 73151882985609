.lexical_editor {
	width: 1000px;
}

.content_editor {
	margin-left: 10px;
	margin-top: 10px;
	padding: 15px;
	width: 100%;
	height: 500px;
	border: none;
	border-radius: 5px;
	background: rgb(241, 241, 241);
}

.toolbar {
	display: flex;
	margin-bottom: 1px;
	background: #fff;
	padding: 4px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	vertical-align: middle;
	border-bottom: 1px solid gray;
}

.toolbar button.toolbar-item {
	border: 0;
	display: flex;
	background: none;
	border-radius: 10px;
	padding: 8px;
	cursor: pointer;
	vertical-align: middle;
}

.toolbar button.toolbar-item:disabled {
	cursor: not-allowed;
}

.toolbar button.toolbar-item.spaced {
	margin-right: 2px;
}

.toolbar button.toolbar-item i.format {
	background-size: contain;
	display: inline-block;
	height: 18px;
	width: 18px;
	margin-top: 2px;
	vertical-align: -0.25em;
	display: flex;
	opacity: 0.6;
}

.toolbar button.toolbar-item:disabled i.format {
	opacity: 0.2;
}

.toolbar button.toolbar-item.active {
	background-color: #8993ab4d;
}

.toolbar button.toolbar-item.active i {
	opacity: 1;
}

.toolbar .toolbar-item:hover:not([disabled]) {
	background-color: #eee;
}

i.bold {
	background-image: url(../img/type-bold.svg);
}

i.italic {
	background-image: url(../img/type-italic.svg);
}

i.underline {
	background-image: url(../img/type-underline.svg);
}

i.strikethrough {
	background-image: url(../img/type-strikethrough.svg);
}

i.code {
	background-image: url(../img/code.svg);
}

i.link {
	background-image: url(../img/link.svg);
}

.editor-text-bold {
	font-weight: bold;
}

.editor-text-italic {
	font-style: italic;
}

.editor-text-underline {
	text-decoration: underline;
}

.editor-text-strikethrough {
	text-decoration: line-through;
}

.editor-text-underlineStrikethrough {
	text-decoration: underline line-through;
}

.editor-text-code {
	background-color: rgb(221, 221, 221);
	padding: 1px 0.25rem;
	font-family: Menlo, Consolas, Monaco, monospace;
	font-size: 94%;
}

.link-editor {
	position: absolute;
	z-index: 100;
	top: -10000px;
	left: -10000px;
	margin-top: -6px;
	max-width: 300px;
	width: 100%;
	opacity: 0;
	background-color: #fff;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
	border-radius: 8px;
	transition: opacity 0.5s;
}

.link-editor .link-input {
	display: block;
	width: calc(100% - 24px);
	box-sizing: border-box;
	margin: 8px 12px;
	padding: 8px 12px;
	border-radius: 15px;
	background-color: #eee;
	font-size: 15px;
	color: rgb(5, 5, 5);
	border: 0;
	outline: 0;
	position: relative;
	font-family: inherit;
}

.link-editor div.link-edit {
	background-image: url(../img/pencil-fill.svg);
	background-size: 16px;
	background-position: center;
	background-repeat: no-repeat;
	width: 35px;
	vertical-align: -0.25em;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	cursor: pointer;
}

.link-editor .link-input a {
	color: rgb(33, 111, 219);
	text-decoration: none;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	margin-right: 30px;
	text-overflow: ellipsis;
}

.link-editor .link-input a:hover {
	text-decoration: underline;
}

.link-editor .button {
	width: 20px;
	height: 20px;
	display: inline-block;
	padding: 6px;
	border-radius: 8px;
	cursor: pointer;
	margin: 0 2px;
}

.link-editor .button.hovered {
	width: 20px;
	height: 20px;
	display: inline-block;
	background-color: #eee;
}

.link-editor .button i,
.actions i {
	background-size: contain;
	display: inline-block;
	height: 20px;
	width: 20px;
	vertical-align: -0.25em;
}

.lexical_editor_error_overlay {
	position: absolute;
	z-index: 2;
	padding: 20px;
	background: #000000aa;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.sbz_lexical_img_wrapper_loading {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: calc(50% - 10px);
		left: calc(50% - 10px);
		z-index: 2;
		border: 2px solid #f3f3f3;
		animation: spin 1s linear infinite;
		border-top: 2px solid #555;
		border-radius: 50%;
		width: 20px;
		height: 20px;
	}

	img {
		opacity: 0.5;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

// this css will show image placholder when fetch image request is pending
.lexical_editor_input span[is-image] img {
  min-height: 20px;
	background-color: whitesmoke;
}

.lexical_editor_quote_wrapper {
	padding: 5px 10px;
	border-bottom: 1px solid $border-color;

	.btn__close {
		width: 20px;
		height: 20px;
		position: relative;
		top: 2px;
	}
}
