$header-bg: #0f457d; // #0b4e95;
$header-height: 50px;

.root {
	display: flex;
	flex: 1;
	//background: $header-bg;
	flex-direction: column;
	overflow: hidden;
	width: 100vw;
	height: 100vh;
}

.root__header {
	flex-shrink: 0;
}

.root__body {
	display: flex;
	flex: 1;
	flex-direction: row;
	overflow: hidden;
}

.root_content {
	background: white;
	position: relative;
	overflow: hidden;
	flex: 1;
}

.sidebar {
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	width: 45px;
	position: relative;
	height: 100%;
	background: $white;
	z-index: 100;
	border-right: 1px solid $border-color;
}

.sidebar__item_label {
	color: #444;
	@include text-truncate();
	margin-left: 10px;
}

.popup_notification {
	position: absolute;
	z-index: 9999;
	right: 50%;
	left: 50%;
}

.popup_notification__item {
	display: flex;
	justify-content: center;
	border-radius: 5px;
	max-width: 80vw;
	margin: 10px auto;
	padding: 7px 20px;
	color: #fff;
}

.modal__container.notify-popup {
	position: fixed;
	top: 10px;
	left: 20px;
	z-index: 150;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0px 1px 8px rgb(0 0 0 / 10%), 0px 4px 32px rgb(0 0 0 / 10%), 0px 8px 64px rgb(0 0 0 / 12%);
}

.notify-popup__head {
	width: 497px;
	height: 300px;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.notify-popupw .modal-main {
	display: grid;
	grid-template-rows: 35% 65%;
	background: rgba(67, 129, 248, 0.1);
}

.notify-popup .body {
	padding: 0 30px;
	text-align: center;
	font-size: 14px;
	line-height: 18px;
	color: #000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #fff;
}

.notify-popup__title {
	font-size: 22px;
	font-weight: 700;
	text-transform: capitalize;
}
.notify-popup .modal-content {
	background: #fff;
	width: 497px;
	border-radius: 8px;
}

.notify-popup__desc {
	font-size: 18px;
	opacity: 0.8;
}

.nav_my_profile {
	border-radius: 12px;
	top: calc(100% + 13px);
	right: 0;
	position: absolute;
	width: 330px;
	background-color: #fff;
	border: #e5e5e5 1px solid;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	z-index: 2147483647;
  max-height: 80vh;
  overflow: auto;
}

.nav_my_profile__info {
	padding: 8px 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #e5e5e5;
	cursor: pointer;
	&:hover {
		background-color: #ededed;
	}
}

.help_wave.help_wave__hidden {
	opacity: 0;
}

.help_wave {
	cursor: pointer;
	position: absolute;
	right: 40px;
	width: 250px;
	bottom: 157px;
	background: $primary;
	user-select: none;
	box-shadow: 0px 1px 8px rgb(0 0 0 / 10%), 0px 4px 32px rgb(0 0 0 / 10%), 0px 8px 64px rgb(0 0 0 / 12%);
	padding: 10px;
	border-radius: 8px;
	color: white;
	transition: all 0.2s;
	z-index: 2147483647;
	// transform: rotate(-90deg);
	&.has_phone {
		right: 44px;
	}
}

.error_settings_dropdown {
	position: absolute;
	right: 7px;
	width: 335px;
	top: 6px;
	user-select: none;
	background-color: #ea3d2f;
	color: white;
	box-shadow: 0 2px 20px rgb(0 0 0 / 44%);
	padding: 10px;
	border-radius: 8px;
	transition: all 0.2s;
	z-index: 905;
}

.activity__root {
	display: flex;
	overflow: hidden;
	height: 100%;
}

.main {
	display: flex;
	overflow: auto;
	position: relative;
	display: flex;
	height: 100%;
	justify-content: space-between;
}

.quickview {
	width: 100%;
	position: relative;
	height: 100%;
	display: flex;
}

.quickview__btn_close {
	position: absolute;
	z-index: 10;
	flex-shrink: 0;
	margin-right: $spacer;
	margin-top: $spacer;
	top: 10px;
	right: 10px;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: white;

	cursor: pointer;
	.quickview__btn_close__icon {
		stroke: $gray-700;
	}
	&:hover {
		.quickview__btn_close__icon {
			stroke: $black;
		}
	}
}

.header_bar.header_bar__churned {
	background-color: #848484;
}

.header_bar {
	background-color: $header-bg;

	height: $header-height; // see .root__header
	padding: 0 $spacer * 2;
	display: flex;
}

.header_bar_left {
	display: flex;
	align-items: center;
	flex-wrap: none;
}

.header_bar_center {
	flex: 1;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
}

.header_bar_logo {
	margin-right: $spacer * 2;
	width: 80px;
	opacity: 0.9;
	object-fit: cover;
	align-self: center;
	cursor: pointer;
}
.header_bar_logo:hover {
	opacity: 1;
}

.header_bar_menu_link {
	font-weight: 500;
	font-size: 16px;
	height: 36px;
	flex-shrink: 0;
	color: #ffffffaa;
	padding: 6px 10px;
	margin: 0 5px;

	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;

	&:hover {
		color: white;
		border-radius: 8px;
		background: rgba(0, 0, 0, 0.2);
	}

	&.active {
		background: rgba(0, 0, 0, 0.5);
		color: white;
		border-radius: 8px;
		//margin-top: 2px;
		// border-bottom: 2px solid $primary;
		&:after {
			width: 100%;
			transition: width ease 150ms;
		}

		.sidebar__icon_active_subiz_live {
			border: 3px solid #09183c;
		}
	}

	&:last-child {
		margin-right: 0;
	}

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		bottom: -1px;
		width: 50%;
		transition: all ease 250ms;
	}

	// for dropdwon
	.dropdown_item {
		color: initial;
	}
}

.header_bar_menu_link .progress_circle {
	background: radial-gradient(closest-side, $header-bg 0 70%, transparent 75% 100%),
		conic-gradient(#19b600 var(--progress), #e9ecef 0);
}
.header_bar_menu_link:hover .progress_circle {
	background: radial-gradient(closest-side, #0c3764 0 70%, transparent 75% 100%),
		conic-gradient(#19b600 var(--progress), #e9ecef 0);
}
.header_bar_menu_link.active .progress_circle {
	background: radial-gradient(closest-side, #07223e 0 70%, transparent 75% 100%),
		conic-gradient(#19b600 var(--progress), #e9ecef 0);
}

.header_bar_menu_link_icon {
	display: block;
	stroke-width: 1.5;
	outline: none;
}

.header_menu_icon {
	font-weight: 500;
	font-size: 16px;
	flex-shrink: 0;
	color: #ffffffbb;
	padding: 6px;
	margin: 0 2.5px;

	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	&:hover {
		border-radius: 50%;
		color: white;
		background: rgba(0, 0, 0, 0.2);
	}
	&.active {
		background: rgba(0, 0, 0, 0.5);
		color: white;
		border-radius: 50%;
		//margin-top: 2px;
		// border-bottom: 2px solid $primary;
		&:after {
			width: 100%;
			transition: width ease 150ms;
		}
	}
}
.header_bar_message_count_badge {
	padding: 0 5px;
	background-color: #ea3d2f;
	color: #fff;
	font-size: 10px;
	top: 24px;
	position: absolute;
	font-weight: 500;
	margin-left: 5px;
	left: 8px;
	border-radius: 15px;
}

.header_bar_task_count_badge {
	padding: 0 5px;
	background-color: #838383;
	color: #fff;
	font-size: 10px;
	top: 24px;
	position: absolute;
	font-weight: 500;
	margin-left: 5px;
	left: 8px;
	border-radius: 15px;
}

.header_bar_noti_count_badge {
	background-color: $danger;
	color: $white;
	padding: 3px 6px;
	border-radius: 10px; // height of badge is 20px (include 2px border top, bottom)
	font-size: 10px;
	font-weight: 700;
	border: 2px solid $header-bg;
	position: absolute;
	top: -8px;
	line-height: 1;
	right: 0;
	min-width: 22px;
	text-align: center;
	transform: translateX(50%);
}

.header_bar_call_dot {
	width: 15px;
	height: 15px;
	border-radius: 50%;
	border: 3px solid $header-bg;
	position: absolute;
	bottom: 12px;
	right: 2px;
	align-items: center;
}

.status_agent_dot {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 2px solid #ffffff;
	align-items: center;
	position: absolute;
	bottom: 3px;
	left: 28px;
}

.item_unavailable {
	opacity: 0.5;
}

.header_bar_right {
	display: flex;
	margin-left: auto;
	align-items: center;
}

.header_bar_agent_avatar_container {
	align-self: center;
	max-height: 36px;
	margin-left: 5px;
	position: relative;
	padding: 6px 10px;
	display: flex;
	align-items: center;
	&:hover {
		background: rgba(0, 0, 0, 0.2);
		border-radius: 6px;
	}
	&.active {
		background: rgba(0, 0, 0, 0.5);
		border-radius: 6px;
	}
}

.account_info_name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.header_bar_agent_avatar {
	width: 100%;
	height: 100%;
	object-fit: cover;
	cursor: pointer;
	border-radius: 50%;
}

@keyframes status-pulsate-tertiary {
	25% {
		transform: scale(1, 1);
	}

	80% {
		transform: scale(3, 3);
		opacity: 0;
	}

	100% {
		transform: scale(3, 3);
		opacity: 0;
	}
}

.sidebar__icon_active_subiz_live {
	position: absolute;
	background: #19b600;
	border-radius: 50%;
	border: 3px solid $header-bg;
	left: 23px;
	width: 14px;
	height: 14px;
	top: 21px;
}

.sidebar__icon_active_subiz_live.play {
	&::before {
		content: '';
		position: absolute;
		inset: 0;
		z-index: 0;
		background: inherit;
		border-radius: inherit;
		opacity: 1;

		animation-duration: 2s;
		animation-name: status-pulsate-tertiary;
	}
}

.more_menu_dropdown {
	border-radius: 8px;
	top: calc(100% + 10px);
	padding: $spacer;
	right: -25px;
	position: absolute;
	width: 350px;
	background-color: #fff;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	z-index: $zindex-dropdown;
	&:after {
		border: solid transparent;
		//content: ' ';
		position: absolute;
		pointer-events: none;
		border-color: hsla(0, 0%, 100%, 0);
		border-bottom-color: $white;
		border-width: 10px;
		top: -18px;
		right: 8px;
	}
}

.more_menu_order_dropdown {
	border-radius: 8px;
	top: calc(100% + 10px);
	padding: $spacer;
	left: 0px;
	position: absolute;
	width: 280px;
	background-color: #fff;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	z-index: $zindex-dropdown;
	&:after {
		border: solid transparent;
		//content: ' ';
		position: absolute;
		pointer-events: none;
		border-color: hsla(0, 0%, 100%, 0);
		border-bottom-color: $white;
		border-width: 10px;
		top: -18px;
		right: 8px;
	}
}

.more_menu_items_container {
}

.more_menu_item {
	background-color: transparent;
	border-radius: 8px;
	cursor: pointer;
	color: $gray-700;
	padding: $spacer;
	display: flex;

	&:hover {
		background-color: $gray-100;
	}
}

.more_menu_item_icon {
	width: 30px;
	color: $gray-600;
	margin-right: $spacer;
}

.more_menu_item_header {
	font-weight: 500;
	color: $gray-600;
}

.more_menu_item_sub {
	color: $gray-600;
	font-size: 15px;
	font-weight: 400;
}

.root_content_inner {
	width: 100%;
	height: 100%;
	overflow: hidden hidden;
	position: relative;
}

.call_online_dot {
	background: #19b600;
}

.call_offline_dot {
	background: #ffbb28;
}

.call_unavailable_dot {
	background: rgb(160, 160, 160);
}

.call_item {
	display: flex;
}

.call_icon {
	color: #ffffff;
}

.calling_number_modal__key {
	width: 60px;
	font-family: $font-family-monospace;
	height: 60px;
	background: #282d4e;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	margin-bottom: 10px;
	cursor: pointer;
	font-size: 22px;
	font-weight: 500;
	margin-left: 15px;
	&:hover {
		background: #16192c;
	}
}

.calling_number_modal__row {
	padding-right: 15px;
	display: flex;
	align-items: center;
}

.calling_number_modal__input {
	font-family: $font-family-monospace;
	border: none;
	background: transparent;
	outline: none;
	width: 210px;
	color: white;
	font-size: 24px;
}

.calling_number_modal {
	color: white;
	position: fixed;
	z-index: $zindex-dropdown + 11;
	overflow-x: hidden;
	top: 90px;
	right: 80px;

	background: #33395e;
	// border: 1px solid #d1d1d1;
	border-radius: 14px;
	padding: 15px 5px;
	box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 10px 40px 0px,
		rgb(0 0 0 / 8%) 0px 0px 16px 0px;
}

.calling_transfer_modal {
	position: fixed;
	z-index: $zindex-dropdown + 11;
	overflow-x: hidden;
	top: 90px;
	right: 100px;
	width: 220px;
	background: #33395e;
	border-radius: 14px;
	padding: 15px 5px;
	box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 10px 40px 0px,
		rgb(0 0 0 / 8%) 0px 0px 16px 0px;
}

.calling_modal_box_warning {
	background-color: $warning;
	padding: 2px 10px;
	text-align: center;
}

.calling_modal_warning {
	color: $black;
	font-size: 14px;
	font-weight: 500;

	&:hover {
		color: #403737;
	}
}

.calling_modal {
	color: white;
	z-index: $zindex-modal;
	position: fixed;
	z-index: $zindex-dropdown;
	overflow-x: hidden;
	top: 60px;
	right: 67px;
	width: 360px;

	background: #33395e;
	// border: 1px solid #d1d1d1;
	border-radius: 14px;
	box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 10px 40px 0px,
		rgb(0 0 0 / 8%) 0px 0px 16px 0px;
}

.calling {
	color: white;
	z-index: $zindex-modal;
	position: fixed;
	overflow-x: hidden;
	bottom: 74px;
	right: 50px;
	width: 330px;
	align-items: flex-end;
	// border: 1px solid #d1d1d1;
	border-radius: 14px;

	box-shadow: 0px 0px 0px 0px, rgb(0 0 0 / 22%) 0px 10px 40px 0px, rgb(0 0 0 / 17%) 0px 0px 16px 0px;
}

.btn_transfer {
	width: 125px;
	height: 34px;
	background: rgba(0, 0, 0, 0.12);
	border-radius: 6px;
	border: none;

	&:hover {
		background: rgba(0, 0, 0, 0.22);
	}
}

.btn_call_later {
	background: #3b3b3b;
	color: #ffffff;

	&:hover {
		background: #3b3b3b69;
		color: rgba(255, 255, 255, 0.788);
	}
}

.calling_modal_minimize {
	height: 40px;
	justify-content: center;
	align-items: center;
	padding: 0 15px;
	cursor: pointer;
}

.calling_modal_content {
	display: flex;
	align-items: center;
	height: 75px;
	padding: 12px 15px;
}

.calling_modal_header {
	padding: 0 15px;
	height: 45px;
	display: flex;
	align-items: center;
	background: #282d4e;
}

.call_icon_option {
	margin-left: 10px;
	border: none;
	border-radius: 6px;
}

.minimize_icon {
	width: 24px;
	height: 24px;
	border-radius: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	user-select: none;
	background-color: rgba(0, 0, 0, 0.17);
	font-weight: 500;
	transifion: background-color 150s ease-out;
	&:hover {
		background-color: rgba(0, 0, 0, 0.4);
	}
}

.calling_user_name {
	font-weight: 500;
	font-size: 20px;
	@include text-truncate();
	color: white;
}

.calling_user_number {
	font-weight: 400;
	font-size: 18px;
	color: white;
	@include text-truncate();
}

.webphone_disconnected {
	z-index: 1000;
	border-radius: 50%;
	top: -11px;
	right: -33px;
	position: absolute;
	background-color: $warning;
	border: 2px solid white;
	transform: rotate(-90deg);
	width: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 18px;

	font-size: 13px;
	line-height: 1;
	font-weight: 700;
}

.global_help_button {
	box-shadow: 0px 1px 8px rgb(0 0 0 / 10%), 0px 4px 32px rgb(0 0 0 / 10%), 0px 8px 64px rgb(0 0 0 / 12%);
	transform: rotate(-90deg) translate(-100%, -50%);
	width: 120px;
	height: 50px;
	display: flex;
	padding-top: 5px;
	align-items: flex-start;
	font-size: 13px;
	background-color: $primary;

	color: $white;
	border-radius: 0;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	z-index: 999;
	font-weight: 500;
	position: absolute;
	bottom: 260px;
	right: -80px;
	display: flex;
	justify-content: center;
	transition: 0.25s;
}

.global_help_button.global_help_button__enabled {
	cursor: pointer;
	&:hover {
		transform: rotate(-90deg) translate(-100%, -70%);
	}
}

.global_call_button {
	box-shadow: 0px 1px 8px rgb(0 0 0 / 10%), 0px 4px 32px rgb(0 0 0 / 10%), 0px 8px 64px rgb(0 0 0 / 12%);
	transform: rotate(-90deg) translate(-100%, -50%);
	width: 70px;
	height: 50px;
	display: flex;
	padding-top: 5px;
	align-items: flex-start;
	font-size: 13px;
	background-color: $success;

	color: $white;
	border-radius: 0;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	z-index: 999;
	font-weight: 500;
	position: absolute;
	bottom: 100px;
	right: -55px;
	display: flex;
	justify-content: center;
	transition: 0.25s;

	cursor: pointer;
	&:hover {
		transform: rotate(-90deg) translate(-100%, -70%);
	}

	.icon-tabler-phone {
		stroke: transparent;
	}
	.icon-tabler-phone path:last-child {
		fill: currentColor;
	}

	&.global_call_button__muted {
		background-color: $dark;
	}
}

.global_call_button.global_call_button__disabled {
	cursor: default;
	background-color: $gray-400;
}

.global_call_button.global_call_button__warning {
	background-color: $warning;
}

.link.link__dark {
	color: white;
	stroke: $white;
	&:hover {
		color: white;
		stroke: $white;
	}
}

.modal_content.onboarding_modal_content {
	width: 500px;
	position: relative;
}

.onboarding_modal_content_inner {
	padding: 15px 20px;
	padding-bottom: 0;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.onboarding_modal_x {
	width: 28px;
	height: 28px;
	background-color: $white;
	color: $gray-500;
	cursor: pointer;
	border-radius: 50%;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	top: 20px;
	right: 20px;
	border: 1px solid $border-color;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
	z-index: 1;

	&:hover {
		color: $gray-600;
	}
}

.onboarding_modal_nav_dot {
	width: 16px;
	height: 16px;
	cursor: pointer;
	position: relative;

	&.active {
		cursor: auto;

		&:before {
			background-color: $primary;
		}
	}

	&:before {
		content: '';
		position: absolute;
		width: 8px;
		height: 8px;
		border-radius: 9999px;
		background-color: $gray-400;
		top: 4px;
		left: 4px;
	}
}

.onboarding_modal_img_wrapper {
	height: 370px;
	overflow: hidden;
	position: relative;
	display: flex;
}

.onboarding_modal_img_inner {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.onboarding_modal_content_wrapper {
	position: relative;
	height: 120px;
	overflow: hidden;
}

.onboarding_modal_content_container {
	position: absolute;
	top: 0;
	left: 0;
	transition: 0.5s;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.onboarding_modal_img {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: 0.5s;
	z-index: -1;
}

.onboarding_modal_img_navigation {
	cursor: pointer;
	position: absolute;
	z-index: 1;
	color: rgba(255, 255, 255, 0.6);
	top: 50%;
	transform: translateY(-50%);

	&:hover {
		color: rgba(255, 255, 255, 0.85);
	}

	&.left {
		left: 0;
	}

	&.right {
		right: 0;
	}
}

@media only screen and (min-width: 500px) and (max-width: 1281px) {
	.onboarding_modal_img_wrapper {
		height: 280px;
		justify-content: center;
		background: whitesmoke;
	}

	.onboarding_modal_img_inner {
		width: 400px;
	}

	.onboarding_modal_img_navigation {
		color: rgba(0, 0, 0, 0.3);
		&:hover {
			color: rgba(0, 0, 0, 0.5);
		}
	}
}

.onboarding_modal_title {
	font-size: 24px;
	font-weight: 500;
}

.onboarding_modal_desc {
	margin-top: 0;
	@extend .text__muted;
}

.global_email_button {
	box-shadow: 0px 1px 8px rgb(0 0 0 / 10%), 0px 4px 32px rgb(0 0 0 / 10%), 0px 8px 64px rgb(0 0 0 / 12%);
	transform: rotate(-90deg) translate(-100%, -50%);
	width: 70px;
	height: 50px;
	display: flex;
	padding-top: 5px;
	align-items: flex-start;
	font-size: 13px;
	background-color: #d44638;

	color: $white;
	border-radius: 0;
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	z-index: 999;
	font-weight: 500;
	position: absolute;
	bottom: 320px;
	right: -55px;
	display: flex;
	justify-content: center;
	transition: 0.25s;

	cursor: pointer;
	&:hover {
		transform: rotate(-90deg) translate(-100%, -70%);
	}
}

.global_email_window {
	width: 600px;
	height: 600px;
	position: fixed;
	background-color: $white;
	bottom: 0;
	z-index: 170; // z-index bigger than quickview z-index
	right: 60px;
	overflow: hidden;
	box-shadow: 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12),
		0px 5px 5px -3px rgba(0, 0, 0, 0.2);
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	overflow: hidden;

	display: flex;
	flex-direction: column;

	&.hidden {
		display: none;
	}

	&.mini {
		width: 300px;
		height: unset;

		.global_email_window_content {
			display: none;
		}

		.global_email_window_footer {
			display: none;
		}
	}
}

.global_email_window_header {
	padding: 4px 10px;
	background-color: $gray-100;
	display: flex;
	align-items: center;
	flex-shrink: 0;
}

.global_email_window_content {
	flex: 1;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.lexical_editor_tabs_wrapper {
		flex: 1;
		overflow: hidden;
		border-top: 1px solid $border-color;
	}

	.lexical_editor_wrapper {
		border: none;
		outline: none;
		height: 100%;
	}
}

.global_email_window_content_info_wrapper {
	display: flex;
	align-items: center;
	padding: 4px 0px;
	margin-left: 10px;
	margin-right: 10px;
	border-bottom: 1px solid $border-color;

	&:last-child {
		border-bottom: none;
	}
}

.global_email_window_footer {
	padding: 4px 10px;
	background-color: $gray-100;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex-shrink: 0;
}

.warning_modal_wrapper {
	width: 420px;
	position: relative;

	.warning_modal_header {
		background-color: #9d9d9d;
		height: 200px;

		justify-content: center;
		align-items: center;
	}

	.warning_modal_content_wrapper {
		padding: 10px 30px;

		.warning_modal_content_title {
			font-size: 20px;
			font-weight: 700;
			margin: 10px;
		}
	}
}

.bg-success {
	background-color: $success;
}

.bg-secondary {
	background-color: $secondary;
}

.bg-warning {
	background-color: $warning;
}

.bg-danger {
	background-color: $danger;
}

.bg-dark {
	background-color: $dark;
}

.hello-bar.bg-warning {
	background-color: #fe9a28; // more gold
}

.hello-bar {
	color: white;
	height: 24px;
	font-size: 12px;
	line-height: 1;
	padding: 6px 0;
	font-weight: 500;
	position: relative;

	a {
		color: white;
		font-weight: 700;
		text-decoration: underline;
	}

	b {
		font-weight: 700;
	}
}

.sbz_popup_cta_btn {
	position: absolute;
	bottom: -30px;
	width: 320px;
	height: 120px;
	font-size: 24px;
	font-weight: 500;
	background-color: $primary;
	cursor: pointer;
	border-radius: 60px;
	color: $white;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 12px 45px;

	&:hover {
		background-color: $blue-600;
		color: #fff;
	}

	span {
		position: absolute;
		top: 27px;
		white-space: nowrap;
	}

	&.left {
		left: -60px;

		span {
			left: 186px;
			transform: translateX(-50%);
		}
	}

	&.right {
		right: -60px;
		span {
			right: 180px;
			transform: translateX(50%);
		}
	}
}

.reflink {
	background: #ffebaf;
	padding: 5px 8px;
	border: 1px dashed var(--yellow);
	border-radius: 3px;
	font-weight: 500;
	color: #41371a;
}

.account_header_layout {
	padding: 0 $spacer * 3;
	height: $header-height;
	background-color: #fafafa;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #dee2e6;
}

.account_manage_container {
	width: 400px;
	height: 260px;
	padding: 10px 20px;
	border: 1px solid #e6e6e6;
	border-radius: 8px;
	position: absolute;
	z-index: $zindex-dropdown;
	left: 10px;
	right: 0;
	top: 50px;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	overflow: auto;
	background-color: #fff;
}

.account_manage_list {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.account_box_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px;
	border: 1px solid #e6e6e6;
	border-radius: 8px;
	width: 45%;
	margin-bottom: 8px;
}

.account_box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10px;
	border-radius: 8px;
	height: 140px;
	width: 100%;
	overflow: hidden;
	cursor: pointer;
	&:hover {
		background-color: #e9ecef;
	}
	.box_name {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: center;
	}
}

.account_box.account_box_disabled {
	filter: grayscale(1);
	cursor: default;
	&:hover {
		background-color: unset;
	}
}

.plan_tag {
	margin: 5px 5px 5px 0;
	padding: 0px 5px;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	border-radius: 15px;
	display: inline-block;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-align: center;
}

.plan_tag__success {
	color: rgb(65, 117, 5);
	background: rgb(227, 234, 218);
	border-color: rgb(198, 214, 180);
}

.plan_tag__primary {
	color: #007aff;
	background: #ddebff;
	border-color: rgb(179, 182, 255);
}

.plan_tag__orange {
	color: rgb(248, 177, 28);
	background: rgb(254, 243, 221);
	border-color: rgb(253, 232, 187);
}

.plan_tag__danger {
	color: #ea3d2f;
	background-color: #f9d6d4;
	border-color: #f7e4e2;
}

.switch_account_item {
	border-bottom: 1px solid $border-color;
	padding: 10px 20px;
}

.agent_profile_body {
	border-bottom: 1px solid $border-color;
}

.agent_profile_item {
	padding: 8px 20px;
	cursor: pointer;
	display: flex;
	align-items: center;
	&:hover {
		background-color: #ededed;
	}
}
.agent_profile_item.active {
	background-color: $gray-200;
}

.text__ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.expired_bar {
	background: #fdcd0d;
	height: 30px;
	padding: 0 20px;
	display: flex;
	align-items: center;
}

.trial_expired_title {
	margin-right: 10px;
	font-size: 14px;
	strong {
		font-weight: 700;
	}
}

.message_internal_actions {
	z-index: 20;
	transition: opacity 150ms ease, visibility 150ms ease;
	visibility: hidden;
	opacity: 0;
	display: flex;
	padding: 2px;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
}

.message_internal_action {
	cursor: pointer;
	color: $gray-800;
	margin-left: 5px;
	margin-right: 5px;

	&:hover {
		color: $primary;
	}
}

.message_editor_quote {
	width: 100%;
	border-bottom: 1px solid $gray-500;
	display: flex;
	padding: 5px 10px;

	&.message_editor_quote_hide {
		display: none;
	}
}

.message_editor_quote_content {
	font-size: 13px;
	padding-right: 10px;
	overflow: hidden;
}

.message_editor_quote_text {
	color: $gray-600;
	display: -webkit-box;
	// overflow in 3 line
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	line-height: 1.25;
	white-space: pre;
}

.policy-view {
	height: 100vh;
	overflow: auto;
}
.privacy-policy {
	width: 900px;
	padding-top: 30px;
	padding-bottom: 20px;
	margin: 0 auto;
	h1 {
		font-size: 2em;
	}
	h3 {
		font-size: 1.17em;
	}
}

.term_of_service_layout {
	height: 100vh;
	overflow: auto;

	.term_of_service_body {
		width: 900px;
		padding-top: 30px;
		padding-bottom: 20px;
		margin: 0 auto;
		h1 {
			font-size: 2em;
		}
		h3 {
			font-size: 1.17em;
		}
	}
}

.trial_warning_modal_wrapper {
	padding: 20px 30px;
	max-height: 100%;

	.subs-package {
		margin-top: 5px;
		display: flex;
		padding: 12px 20px;
		align-items: center;
		justify-content: space-between;
		border: 1px solid $border-color;
		border-radius: 8px;
	}
}
