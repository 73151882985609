.accmgr {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
}

.accmgr_header {
	flex-shrink: 0;
	height: 44px;
	background: #f0f5fb;
	padding-left: 20px;
}
