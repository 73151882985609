.confirm_popup {
	width: 350px;
	position: relative;
	max-height: 600px;
	overflow: auto;
	background: #fff;
	padding: $spacer * 2;
	padding-top: $spacer;
	display: flex;
	flex-direction: column;
}

.confirm_popup__header {
	display: flex;
	border-bottom: 1px solid #eee;
	justify-content: space-between;
	padding: 0 0 $spacer 0;
	font-size: $font-size-lg;
	align-items: center;
}

.confirm_popup__body {
	padding-top: $spacer;
}

.confirm_popup__footer {
	display: flex;
	justify-content: flex-end;
	margin-top: $spacer * 2;
}

// new design confirm popup
.confirm-popup {
	width: 350px;
	max-height: 400px;
	display: flex;
	flex-direction: column;
	position: relative;
	border-radius: 4px;
}

.confirm-popup__head {
	padding: $spacer $spacer * 1.5;
	border-bottom: 1px solid $border-color;
	display: flex;
	font-weight: 500;
	font-size: 1rem;
	background: $gray-100;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
}

.confirm-popup__body {
	padding: $spacer * 1.5;
	font-size: 0.875rem;
	display: flex;
	flex: 1;
}

.confirm-popup__foot {
	background: $gray-100;
	display: flex;
	justify-content: flex-end;
	font-size: 0.875rem;
	padding: $spacer $spacer * 1.5;
	border-top: 1px solid $border-color;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
}

.confirm-popup__icon {
	margin-right: $spacer;
	width: 32px;
	height: 32px;

	&.icon--primary {
		stroke: $primary;
	}
	&.icon--danger {
		stroke: $danger;
	}
	&.icon--warning {
		stroke: $warning;
	}
	&.icon--success {
		stroke: $success;
	}
	&.icon--info {
		stroke: #17a2b8;
	}
}
