//
// Check/radio
//

.form-check {
	display: block;
	min-height: $form-check-min-height;
	padding-left: 1rem;
	margin-bottom: $form-check-margin-bottom;
	position: relative;

	.form-check-input {
		position: absolute;
		margin-left: -1rem;
		//top: -2px;
	}
}

.form-check-input {
	width: $form-check-input-width;
	height: $form-check-input-width;
	margin-top: ($line-height-base - $form-check-input-width) * 0.5; // line-height minus check height
	vertical-align: top;
	background-color: $form-check-input-bg;
	border: $form-check-input-border;
	appearance: none;
	color-adjust: exact; // Keep themed appearance for print

	&[type='checkbox'] {
		cursor: pointer;
		@include border-radius($form-check-input-border-radius);
	}

	&[type='radio'] {
		margin-top: 4px;
		@include border-radius($form-check-radio-border-radius);
	}

	&:active {
		filter: $form-check-input-active-filter;
	}

	&:focus {
		border-color: $form-check-input-focus-border;
		outline: 0;
		box-shadow: $form-check-input-focus-box-shadow;
	}

	&:checked {
		background-color: $form-check-input-checked-bg-color;
		background-repeat: $form-check-input-checked-bg-repeat;
		background-size: 100%;
		background-position: 0 0;
		border-color: $form-check-input-checked-border-color;

		&[type='checkbox'] {
			@if $enable-gradients {
				background-image: escape-svg($form-check-input-checked-bg-image),
					linear-gradient(180deg, lighten($form-check-input-checked-bg-color, 10%), $form-check-input-checked-bg-color);
				background-size: $form-check-input-checked-bg-size, auto;
			} @else {
				background-image: escape-svg($form-check-input-checked-bg-image);
			}
		}

		&[type='radio'] {
			@if $enable-gradients {
				background-image: escape-svg($form-check-radio-checked-bg-image),
					linear-gradient(180deg, lighten($form-check-input-checked-bg-color, 10%), $form-check-input-checked-bg-color);
				background-size: $form-check-input-checked-bg-size, auto;
			} @else {
				background-image: escape-svg($form-check-radio-checked-bg-image);
			}
		}
	}

	&[type='checkbox']:indeterminate {
		background-color: $form-check-input-indeterminate-bg-color;
		background-repeat: $form-check-input-indeterminate-bg-repeat;
		background-position: $form-check-input-indeterminate-bg-position;
		@if $enable-gradients {
			background-image: escape-svg($form-check-input-indeterminate-bg-image),
				linear-gradient(180deg, lighten($form-check-input-checked-bg-color, 10%), $form-check-input-checked-bg-color);
			background-size: $form-check-input-checked-bg-size, auto;
		} @else {
			background-image: escape-svg($form-check-input-indeterminate-bg-image);
			background-size: $form-check-input-indeterminate-bg-size;
		}
		border-color: $form-check-input-indeterminate-border-color;
	}

	&:disabled {
		pointer-events: none;
		filter: none;
		opacity: 0.5;
	}

	// Use disabled attribute in addition of :disabled pseudo-class
	// See: https://github.com/twbs/bootstrap/issues/28247
	&[disabled],
	&:disabled {
		~ .form-check-label {
			opacity: 0.5;
		}
	}

	&.form-check-input--task {
		width: 18px;
		height: 18px;
		margin-top: 1px;
	}

	&.form-check-input--task:checked {
		border-color: #159a00;
		background-color: #159a00;
	}

	&.form-check-input--light:checked {
		background-color: $white;
		&[type='checkbox'] {
			border-color: $secondary;
			background-image: escape-svg($form-check-input-light-checked-bg-image);
		}
		&[type='radio'] {
			border-color: $secondary;
			background-image: escape-svg($form-check-radio-light-checked-bg-image);
		}
	}

	&.form-check-input--bold[type='checkbox']:indeterminate {
		background-color: $white;
		background-repeat: $form-check-input-indeterminate-bg-repeat;
		background-position: $form-check-input-indeterminate-bg-position;
		@if $enable-gradients {
			background-image: escape-svg($form-check-input-bold-indeterminate-bg-image),
				linear-gradient(180deg, lighten($form-check-input-checked-bg-color, 10%), $form-check-input-checked-bg-color);
			background-size: $form-check-input-checked-bg-size, auto;
		} @else {
			background-image: escape-svg($form-check-input-bold-indeterminate-bg-image);
			background-size: $form-check-input-indeterminate-bg-size;
		}
		border-color: $primary;
	}

	&.form-check-input--bold:checked {
		background-color: $white;
		&[type='checkbox'] {
			border-color: $primary;
			background-image: escape-svg($form-check-input-bold-checked-bg-image);
		}
		&[type='radio'] {
			border-color: $primary;
			background-image: escape-svg($form-check-radio-bold-checked-bg-image);
		}
	}
}

.form-check-label {
	margin-bottom: 0;
	padding-left: 0.5rem;
	cursor: $form-check-label-cursor;
}

.form-check-input:disabled ~ .form-check-label {
	cursor: default;
}

.form-check-inline {
	display: inline-block;
	margin-right: $form-check-inline-margin-right;
}
