.pagination {
	display: flex;
}

.pagination_btn {
	padding: 2px 8px;
	margin-left: 5px;
	background: transparent;
	color: #000;
	cursor: pointer;
	border-radius: 4px;
	border: 2px solid transparent;
	text-align: center;

	&:hover {
		color: $primary;
	}
}

.pagination_btn:first-child {
	margin-left: 0px;
}

.pagination_btn.pagination_btn__active {
	border: 2px solid $primary;
	font-weight: bold;
	color: $primary;
}

.pagination_btn.pagination_btn__disabled {
	opacity: 50%;
	pointer-events: none;
	cursor: default;
}

.pagination_btn.pagination_navigator {
	border: none;
	display: flex;
	align-items: center;
	&:first-child {
		padding-left: 0;
	}
	&:last-child {
		padding-right: 0;
		margin-left: 0;
	}
}
