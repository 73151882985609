.spinner1 {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
  background: transparent;
  border-radius: 50%;
  animation: animate .5s linear infinite;
  -webkit-backface-visibility: hidden;
}

.rotate_twinkle {
	animation: animate 3s linear infinite, twinkler 3s linear infinite;
}

.rotate {
	animation: animate 3s linear infinite;
}

@keyframes animate {
  0% {
    transform: rotate(0deg) rotateZ(360deg);
  }
  100% {
    transform: rotate(360deg) rotateZ(360deg);
  }
}

.twinkle {
	animation: twinkler 3s linear infinite;
}

@keyframes twinkler {
	0% {
		color: green;
	}
  30% {
		color: blue;
  }
	60% {
		color: red							;
  }
	90% {
		color: orange;
	}
}
