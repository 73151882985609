.drawer {
	position: absolute;
	top: 0;
	right: -800px;
	z-index: 100;
	height: 100%;
	// Prevent Chrome on Windows from adding a focus outline. For details, see
	// https://github.com/twbs/bootstrap/pull/10951.
	outline: 0;
	// We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
	// gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
	// See also https://github.com/twbs/bootstrap/issues/17695

	display: flex;
	align-items: center;
	justify-content: center;
}

.drawer.drawer__local {
	position: absolute;
}

.drawer__background {
	background: #ffffff;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.06), 0px 4px 32px rgba(0, 0, 0, 0.06), 0px 8px 64px rgba(0, 0, 0, 0.08);
	border-radius: 0px;
	height: 100%;
	overflow: auto;
}

.drawer__background.drawer__background__simple {
	background: $white;
	box-shadow: none;
	border-radius: 0;
}

.drawer_content {
	z-index: $zindex-modal;
	background-color: #fff;
	margin: auto;
	width: 840px;
	border-radius: 5px;
}

.drawer_content__header {
	position: relative;
	height: 50px;
	width: 100%;
	background: #1890ff;
	color: #fff;
	padding: 0 30px;
	line-height: 46px;
	font-size: 16px;
	font-weight: 700;
	border-radius: 2px 2px 0 0;
}

.drawer_content__main {
	position: relative;
	width: 100%;
	padding: 20px 40px;
}

.drawer_content__footer {
	border-top: 1px #ddd solid;
	border-radius: 0px 0px 3px 3px;
	padding: 10px 25px;
	text-align: right;
}

// Animated drawer right, left
.drawer.drawer--right {
	z-index: -999999;
	opacity: 0;
	align-items: center;
}

.drawer.drawer--right {
	right: 0;
	justify-content: flex-end;
	margin-right: 0px;
}

.drawer.drawer--right.drawer--active {
	z-index: 100;
	opacity: 1;
}

.drawer.drawer--right .drawer__background {
	right: 0;
}

.drawer.drawer--right.drawer--active .drawer__background {
	//transform: translateX(0);
}

.drawer.drawer--right.drawer--active.drawer--fadeout .drawer__background {
	transform: translateX(1800px);
}

.drawer__btn_close {
	align-self: start;
	flex-shrink: 0;
	margin-right: 10px;
	margin-top: 10px;
	border-radius: 50%;
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #fff;
	cursor: pointer;
	position: absolute;
	top: 0;
	left: -50px;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.06), 0px 4px 32px rgba(0, 0, 0, 0.06), 0px 8px 64px rgba(0, 0, 0, 0.08);
}

.drawer.drawer--right.drawer--active.drawer--fadeout .drawer__btn_close {
	display: none;
}
