.lead-head__title {
	color: #555;
	padding-left: 20px;
	flex-shrink: 0;
	margin-bottom: $spacer * 0.5;
	font-weight: 400;
	margin-right: 0px;
	pointer-events: none;
	user-select: none;
	font-size: 20px;
}

.lead-actions__tags {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.lead-filter-tag {
	height: 36px;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-right: 8px;
	margin-bottom: 8px;
	padding: 4px 12px;
	display: flex;
	align-items: center;
	border-radius: 4px;
	background-color: #ebf6ff;
	color: $blue-900;
}

.lead-filter-tag__remove {
	cursor: pointer;
	margin-left: 5px;
	width: 16px;
	height: 16px;
	stroke: $blue-900;

	&:hover {
		stroke: $gray-700;
	}
}

.lead-add-filter-btn {
	margin-bottom: 8px;
	border: 1px dashed $link-color;
	color: $link-color;
	display: flex;
	align-items: center;
	align-self: flex-start;
	flex-shrink: 0;
	min-width: 140px;
	justify-content: center;

	&:hover {
		border-color: $link-hover-color;
		color: $link-hover-color;
	}
}

.lead-add-filter-btn__icon {
	margin-right: 5px;
	width: 16px;
	height: 16px;
}

.lead-action-btn {
	border: 1px solid #dee2e6;
	background-color: #fff;
	display: flex;
	align-items: center;
	align-self: flex-start;

	&:hover {
		background-color: rgba(33, 33, 33, 0.02);
		border-color: $gray-500;
	}
}

.lead-action-btn__icon {
	width: 18px;
	height: 18px;
}

.lead-actions__paginate {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	font-size: 1rem;
	flex-shrink: 0;
	align-self: flex-start;
	min-width: 130px;
}

.lead-filter {
	width: 500px;
	border-top-left-radius: 8px !important;
	border-bottom-left-radius: 8px !important;
	padding: 0;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
}

.lead-filter__head {
	padding: 18px 25px;
	font-size: 1rem;
	font-weight: 500;
	border-bottom: 1px solid $border-color;
	display: flex;
	align-items: center;
}

.lead-filter__back {
	color: $link-color;
	margin-left: -4px;
	cursor: pointer;

	&:hover {
		color: $link-hover-color;
	}
}

.lead-filter__content {
	display: flex;
	padding: 25px;
	width: 100%;
	overflow: hidden;
	min-height: calc(100% - 150px);
	position: relative;
}

.lead-filter__tab {
	width: 100%;
	flex-shrink: 0;
	overflow: auto;
	transition: 0.3s;
	position: relative;
	&.lead-filter__tab--inactive {
		left: -600px;
	}

	&:last-child {
		left: 1000px;
		z-index: 1;
		background-color: #fff;

		&.lead-filter__tab--active {
			left: -100%;
		}
	}
}

.lead-filter__preset {
	padding: 8px 4px;
	cursor: pointer;
	border-radius: 4px;
	display: flex;
	align-items: center;
}

.lead-filter__preset-icon {
	margin-right: 15px;
	width: 24px;
	height: 24px;
	opacity: 0.55;
}

.lead-filter__preset:hover .lead-filter__preset-name,
.lead-filter__preset.preset--active .lead-filter__preset-name {
	color: $primary;
}

.lead-filter__preset:hover .lead-filter__preset-value,
.lead-filter__preset.preset--active .lead-filter__preset-value {
	color: $blue-600;
}

.lead-filter__preset-name {
	font-weight: 500;
}

.lead-filter__preset-value {
	color: $gray-600;
	font-size: 14px;
	margin-top: 2px;
}

.lead-filter__preset-check-icon {
	margin-left: auto;
	width: 24px;
	height: 24px;
	stroke: $primary;
}

.lead-filter__form {
	margin: 0 auto 15px;
	border: 1px solid $gray-500;
	border-radius: 4px;
	max-width: 460px;

	&:last-child {
		margin-bottom: 0;
	}
}

.lead-filter__form-head {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 10px;
}

.lead-filter__form-title {
	font-weight: 500;
}

.lead-filter__form-delete {
	margin-left: 5px;
	cursor: pointer;
	stroke: $gray-700;
	width: 16px;
	height: 16px;

	&:hover {
		stroke: $gray-900;
	}
}

.lead-filter__form-content {
	border-top: 1px solid $border-color;
	padding: 10px 10px;
}

.lead-filter__add-cond {
	position: relative;
	margin: $spacer auto;
	max-width: 460px;
}

.lead-filter__add-cond-btn {
	padding: 5px 10px;
	border: 1px dashed;
	border-radius: 4px;
}

.lead-filter__add-cond-dropdown {
	display: flex;
	flex-direction: column;
}

.lead-filter__add-cond-dropdown-input {
	padding: 10px;
}

.lead-filter__add-cond-dropdown-content {
	display: flex;
	flex-direction: column;
	flex: 1 1 0%;
	min-height: 150px;
	max-height: 300px;
	overflow: auto;
}

.lead-filter__foot {
	display: flex;
	align-items: center;
	margin-top: auto;
	padding: 18px 25px;
	border-top: 1px solid $border-color;
}

.lead__export-head {
	display: flex;
	border-bottom: 1px solid #eeeeee;
	justify-content: space-between;
	padding: 0 0 $spacer 0;
	font-size: $font-size-lg;
	font-weight: 500;
	align-items: center;
}

.lead_table__channel_source_img {
	width: 22px;
	height: 22px;
	display: block;
	border-radius: 4px;
	margin-right: $spacer * 0.5;
}

.lead_table__channel_source_type_img {
	position: absolute;
	width: 16px;
	height: 16px;
	border: 2px solid $white;
	border-radius: 50%;
	bottom: -4px;
	right: -2px;
}

// Custom CSS tag input
.lead-filter__tags-input {
	.ti-input {
		padding: 5px;
		border-radius: 4px;
	}

	.ti-input .ti-tag {
		margin: 0;
		margin-right: 5px;
		border-radius: 4px;
		background-color: #ebf6ff;
		color: $blue-900;

		&:hover {
			background-color: #e0f1ff;
		}
	}

	.ti-item.ti-selected-item {
		background-color: #e0f1ff;
		color: $blue-900;
	}
}

// -------------------------

.tab.lead {
	margin-bottom: 0;
}

.tab__item.lead {
	padding-top: 6px;
	padding-bottom: 14px;
}

.tab__item.tab__item__active.lead {
	color: $primary;
}

.lead_mgr {
	display: flex;
	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
	flex: 1;
}

.lead_footer {
	padding: 0 10px;
	height: 36px;
	border-top: 1px solid $border-color;
	align-items: center;
	flex-shrink: 0;
	display: flex;
}

.lead_mgr__header {
	background: whitesmoke;
	padding: 13px 0px 0 0px;
}

.lead_sidebar {
	height: 100%;
	width: 400px;
	display: flex;
	flex-direction: column;
	position: relative;
	border-left: 1px solid #dee2e6;
}

.lead_sidebar__body {
	flex-shrink: 0;
	flex: 1;
	overflow-y: auto;
}

.lead_sidebar__head {
	display: flex;
	align-items: center;
	padding: 10px 20px;
	border-bottom: 1px solid #dee2e6;
}

.lead_sidebar__footer {
	display: flex;
	align-items: center;
	padding: 10px 20px;
	border-top: 1px solid #dee2e6;
}

.lead_condition_section {
	padding: 10px 20px;
	border-bottom: 1px solid #dee2e6;
}

.lead_condition_section__title {
	font-weight: 500;
}

.lead_condition_x {
	margin-left: auto;
	width: 18px;
	height: 18px;
	cursor: pointer;
	padding: 3px;
	background: #dbdbdb;
	border-radius: 50%;
	stroke-width: 3;
	color: #fff;
	&:hover {
		background: #da2e22;
		color: white;
	}
}

.lead_tag_input.vue-tags-input {
	margin-top: 10px;
	background: #324652;
	max-width: unset;

	.ti-new-tag-input {
		margin-top: -2px;
		background: transparent;
		color: black;
	}

	.ti-input {
		min-height: 39px;
		border-radius: 5px;
		transition: border-bottom 200ms ease;
	}

	li.ti-new-tag-input-wrapper {
		margin: 0;
		margin-bottom: $spacer * 0.25;
		margin-top: $spacer * 0.25;
		.ti-new-tag-input {
			font-size: $font-size-base;
			width: 0px;
		}
	}

	input.ti-tag-input {
		font-size: $font-size-base;
		margin-top: -2px;
	}

	.ti-icon-undo {
		display: none;
	}

	.ti-autocomplete {
		background: white;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		border: 1px solid #cacaca;
		border-top: none;
	}

	.ti-item {
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 5px;

		div {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	/* the selected item in the autocomplete layer, should be highlighted */
	.ti-item.ti-selected-item {
		background: #d4eafe;
		color: black;
	}

	/* style the placeholders color across all browser */
	::-webkit-input-placeholder {
		color: #a4b1b6;
	}

	::-moz-placeholder {
		color: #a4b1b6;
	}

	:-ms-input-placeholder {
		color: #a4b1b6;
	}

	:-moz-placeholder {
		color: #a4b1b6;
	}

	.ti-tag.ti-invalid {
		color: #750000;
	}

	.ti-tag.ti-invalid .ti-icon-close {
		color: #fff0f0;
		&:hover {
			color: white;
		}
	}

	.ti-icon-close {
		padding-top: 1px;
		color: #abb8ff;
		&:hover {
			color: black;
		}
	}

	.ti-tag.ti-editing {
		background: #fffbde;
	}

	/* default styles for all the tags */
	.ti-tag {
		display: flex;
		align-items: center;
		border-radius: 7px;
		background: #ebf6ff;
		font-size: $font-size-base;
		color: #010d75;
		position: relative;
		margin: 0;
		margin-right: $spacer * 0.5;
		cursor: pointer;
		margin-top: $spacer * 0.25;
		margin-bottom: $spacer * 0.25;
		&:hover {
			background: #e0f1ff;
		}

		.ti-tag-center {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 200px;
		}
	}

	/* we defined a custom css class in the data model, now we are using it to style the tag */
	.ti-tag.custom-class {
		background: transparent;
		border: 1px solid #ebde6e;
		color: #ebde6e;
		margin-right: 4px;
		border-radius: 0px;
		font-size: 13px;
	}

	/* the styles if a tag is invalid */
	.ti-tag.ti-invalid {
		background-color: #ffcaba;
		&:hover {
			background-color: #ffbaaa;
		}
	}

	/* if the user input is invalid, the input color should be red */
	.ti-new-tag-input.ti-invalid {
		// color: #e88a74;
	}

	.ti-new-tag-input.ti-valid {
		color: #010d75; //same as tag color
	}

	/* if a tag or the user input is a duplicate, it should be crossed out */
	.ti-duplicate span,
	.ti-new-tag-input.ti-duplicate {
		text-decoration: line-through;
	}

	li.ti-tag.ti-deletion-mark {
		background: #ffd7d7;
		color: #750000;
	}

	.ti-tag .ti-actions i {
		font-size: $font-size-base;
	}
}

.pin__off {
	cursor: pointer;
	color: #aaa;
	&:hover {
		color: #777;
	}
}

.pin__active {
	cursor: pointer;
	color: $orange;
}

.pin__disabled {
	color: #ddd;
	cursor: not-allowed;
}

.view_select_lead_edit_btn {
	opacity: 0.3;
	margin-top: -2px;
	cursor: pointer;
	text-align: center;
	&:hover {
		opacity: 1;
	}
}

.lead_edit_btn {
	margin-left: 8px;
	opacity: 0.1;
	margin-top: -2px;
	trasition: opacity 250ms;
}

.tab__item:hover {
	.lead_edit_btn {
		opacity: 0.5;
		&:hover {
			opacity: 1;
		}
	}
}

.lead__table {
	background: white;
	min-width: 100%;
	tr:first-child:hover {
		background: white;
	}

	tr:hover {
		background: #f0f0f0;
	}

	tr {
		th {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			height: 44px;
			border-bottom: 1px solid $border-color;
		}

		th:first-child {
			padding-left: 15px;
		}

		td {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			padding-left: 5px;
			padding-right: 5px;
			border-bottom: 1px solid $border-color;
		}

		td:first-child {
			padding-left: 15px;
		}

		&:hover {
			.lead_column__fullname {
				cursor: pointer;
				text-decoration: underline;
				color: $primary;
			}
			.lead_column__fullname_preview_btn {
				display: block;
			}
		}
	}

	tr:last-child {
		td {
			border-bottom: 1px solid white;
		}
	}
}

.column_select_column {
	display: flex;
	padding-left: 26px;
	padding-right: 10px;
	align-items: center;
	.column_select_swap_icon {
		opacity: 0;
	}
	&:hover {
		background: whitesmoke;
		.column_select_swap_icon {
			opacity: 1;
		}
	}
}

.column_select_swap_icon {
	cursor: pointer;
	color: #999;
	width: 15px;
	height: 15px;
	margin-left: 5px;
	margin-right: 5px;
	&:hover {
		color: #444;
	}
}

.lead_column_header {
	padding-left: 5px;
	padding-right: 5px;
	cursor: pointer;
	background: white;
	&:hover {
		background: #eee;
	}
}

.lead_view_select_label {
	background: #f7f7f7;
	padding: 5px 10px;
	font-size: 14px;
	cursor: default;
	color: #888;
}

.lead_column__fullname {
	width: 200px;
	overflow: hidden;
}

.lead_column__fullname_preview_btn {
	display: none;
	margin-left: 10px;
}

.lead_condition_section.lead_condition_section__disabled {
	pointer-events: none;
	opacity: 0.6;
}

.lead_mgr_wrapper {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
}

.lead_mgr_sidebar {
	//transition: 0.3s;
	width: 220px;
	flex-shrink: 0;
	background-color: #f7f5f2;
	padding: $spacer * 2;
	border-right: 1px solid rgba(166, 145, 113, 0.2);
}

.lead_mgr_sidebar_section {
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
}

.lead_mgr_sidebar_section_title {
	text-transform: uppercase;
	font-weight: 500;
	color: #999999;
	font-size: 14px;
	margin-bottom: 5px;
}

.lead_mgr_sidebar_item {
	display: flex;
	align-items: center;
	cursor: pointer;
	margin-bottom: $spacer * 0.5;

	&:last-child {
		margin-bottom: 0;
	}
}

.lead_mgr_sidebar_item:hover .lead_mgr_sidebar_item_text {
	color: $primary;
}

.lead_mgr_sidebar_item.active .lead_mgr_sidebar_item_text {
	color: $primary;
	font-weight: 500;
}

.lead_mgr_sidebar_item.active .lead_mgr_sidebar_item_badge {
	color: $primary;
	background-color: rgba($primary, 0.15);
}

.lead_mgr_sidebar_item_text {
	flex: 1;
	color: $gray-800;
	margin-right: $spacer;
	@include text-truncate();
	display: flex;
	align-items: center;
}

.lead_mgr_sidebar_item_badge {
	flex-shrink: 0;
	font-size: 13px;
	color: $gray-600;
	background-color: $gray-200;
	border-radius: 4px;
	font-weight: 500;
	padding: 0px 5px;

	&:hover {
		background-color: unset;
	}
}

.lead_mgr_sidebar_item_badge_unedit {
	flex-shrink: 0;
	font-size: 12px;
	color: $gray-600;
	background-color: $gray-200;
	border-radius: 4px;
	padding: 0px 5px;
}

.lead_table__id {
	width: 55px;
	flex-shrink: 0;
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #888;
}

.lead_table__scroll_left .lead_table__row.sticky_col {
	background-color: $white;

	&.no_scroll:after {
		box-shadow: none;
	}

	&:after {
		display: none; // dont use blur effect because table already has border
		box-shadow: inset 5px 0 4px -4px rgb(0 0 0 / 10%);
	}
}

.new_lead_tabe .lead_table__row {
	height: auto;
	min-height: 44px;
	padding-top: 8px;
	padding-bottom: 8px;
}

.lead_table__row {
	display: flex;
	align-items: center;
	flex-shrink: 0;
	overflow: hidden;
	height: 40px;
	border-bottom: 1px solid #00000013;
	border-right: 1px solid #00000013;
	padding: 0 3px;
	flex-grow: 1;

	&.sticky_col {
		z-index: 1;
		position: sticky;
		left: 0;

		&:after {
			position: absolute;
			top: 0;
			right: 5px;
			bottom: -1px;
			width: 5px;
			transform: translate(100%);
			transition: box-shadow 0.3s;
			content: '';
			pointer-events: none;
		}

		&.blur {
			background-color: $white;
		}

		&.blur:after {
			box-shadow: inset 5px 0 4px -4px rgb(0 0 0 / 10%);
		}
	}

	&:first-child {
		padding-left: 10px;
	}

	&:last-child {
		padding-right: 10px;
	}
}

.lead_table__row.lead_table__header {
	font-size: 13px;
	color: #6f6f6f;
	height: 40px;
	text-transform: uppercase;
	font-weight: 500;
	border-top: 1px solid #eaeaea;
}

.lead_mgr_header {
	display: flex;
	align-items: center;
	padding: $spacer * 2 $spacer $spacer $spacer;
}

.lead_mgr_filers {
	padding: 0 $spacer;
	padding-bottom: $spacer * 0.5;
	display: flex;
	flex-wrap: wrap;
}

.lead_mgr_filter_wrapper {
	margin-right: $spacer;
	margin-bottom: $spacer;
	position: relative;

	.order_filter_input {
		height: 100%;
	}
}

.lead_header_action_icon {
	cursor: pointer;
	color: $primary;
	&:hover {
		color: $link-hover-color;
	}
}

.lead_mgr_sidebar_shrink_icon {
	margin-left: auto;
}

.lead_mgr_sidebar.shrink {
	width: 40px;
	padding: 20px 0;

	.lead_mgr_sidebar_header_title {
		display: none;
	}

	.lead_mgr_sidebar_section {
		display: none;
	}

	.lead_mgr_sidebar_shrink_icon {
		margin-left: 8px;
	}
}

.lead_table__row_row {
	display: flex;
	//align-items: center;

	// remember width fit content to work with sticky position
	width: fit-content;
	min-width: 100%;

	&:hover .lead_table__row {
		background-color: #e9ecef;
	}

	&.header:hover .lead_table__row {
		background-color: inherit;
	}

	&.header {
		cursor: auto;
	}
}

.lead_table {
	display: flex;
	flex-direction: column;
	flex: 1;
	overflow: hidden;
	position: relative;
}

.user_attr_star {
	cursor: pointer;
	color: #999;
}

.user_attr_star.active {
	color: #ffc107;

	path:last-child {
		fill: #ffeb3b;
	}
}

.lead_mgr_views {
	padding-left: 10px;
	padding-right: 10px;
	border-bottom: 1px solid $border-color;
	display: flex;
	align-items: center;

	.order_filter_input {
	}

	.date-range-input {
		font-size: 14px;
	}

	.tab {
		border-bottom: none;
		margin-bottom: 0;
	}

	.tab__item {
		margin-right: 10px;
		max-width: 150px;
		overflow: hidden;
		display: inline-flex;
		align-items: center;
		padding: 10px 5px;

		.order_list_tab_badge {
			top: 0;
			opacity: 1;
		}

		&.has_actions .lead_view_tab_action_icon {
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			left: 50%;
			opacity: 0;
			user-select: none;
		}
		&.tab__item__active {
			background: #f3f8ff;
		}
		&.has_actions.tab__item__active {
			.lead_view_tab_action_icon {
				opacity: 1;
			}

			.order_list_tab_badge {
				opacity: 0;
			}
		}
	}

	// workaround for dropdown custom component
	.dropdown_container > div {
		overflow: hidden;
	}

	.tab__item__active {
		color: $primary;

		.order_list_tab_badge {
			top: 0;
			color: $primary;
			background-color: #cce5ff;
		}
	}
}

.lead_table.lead_table__wide .lead_table__row {
	padding-left: 10px;
	padding-right: 10px;
}

.lead_table__row_row.checked .lead_table__row {
	background-color: #ecefff;
}

div.lead_table__row_row.clicked .lead_table__row {
	background-color: #eee;
}

.lead_remove_user_progress_modal {
	background-color: $gray-700;
	position: fixed;
	bottom: 20px;
	right: 80px;
	color: $white;
	display: flex;
	align-items: center;
	width: 300px;
	z-index: 999;
	padding: 8px 15px;
	border-radius: 9999px;
}

.lead_add_user_modal_field {
	margin-bottom: 15px;

	&:last-child {
		margin-bottom: 0;
	}
}

.new_lead_mgr_sidebar_item {
	margin-left: -8px;
	margin-right: -8px;
	padding: 5px 8px;
	padding-right: 3px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	cursor: pointer;

	&:hover {
		color: $gray-800;
	}

	&.active {
		background-color: $gray-700;
		color: $white;

		.lead_mgr_sidebar_item_badge {
			background-color: $gray-700;
			color: $white;
		}

		.lead_mgr_sidebar_item_badge_unedit {
			background-color: $gray-700;
			color: $white;
		}
	}

	&.active_light {
		background-color: $gray-300;
	}
}

.lead_mgr_footer {
	display: flex;
	align-items: center;
	height: 42px;
	position: relative;
	border-top: 1px solid $border-color;
	flex-shrink: 0;
	padding-left: 10px;
	padding-right: 10px;
}

.new_lead_mgr_sidebar {
	width: 300px;
	flex-shrink: 0;
	background-color: #f7f5f2;
	padding: 0;
	border-right: 1px solid rgba(166, 145, 113, 0.2);
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

.new_lead_mgr_sidebar_header {
	padding: 10px 15px;
	border-bottom: 1px solid $border-color;
	display: flex;
	align-items: center;
	height: 44px;
}

.new_lead_mgr_sidebar_content {
	flex: 1;
	overflow: auto;
	padding: 10px 15px;
	position: relative;
}

.send_bulk_email_modal_wrapper {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.send_bulk_email_modal_footer {
	padding: 0px 15px;
	display: flex;
	height: 46px;
	align-items: center;
	flex-shrink: 0;
	border-top: 1px solid $border-color;
}

.send_bulk_email_modal_body {
	flex: 1;
	display: flex;
	overflow: hidden;
}

.send_bulk_email_modal_receivers {
	width: 280px;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	border-left: 1px solid $border-color;
}

.send_bulk_email_modal_content_header {
	height: 48px;
	padding: 0 15px;
	align-items: center;
	display: flex;
	flex-shrink: 0;
	border-bottom: 1px solid $border-color;
}

.send_bulk_email_modal_email_content {
	flex: 1;
	display: flex;
	flex-direction: column;

	.message_editor.message_editor__html {
		flex: 1;
		border: none;
		overflow: hidden;
	}

	.email_header__from {
		margin-left: 15px;
	}

	.email_header__subject {
		margin-left: 15px;
		margin-right: 15px;
		border-bottom: 0;
	}

	.email_header {
		border-bottom: 1px solid $border-color;
	}
}

.send_bulk_email_modal_receiver_item {
	display: flex;
	align-items: center;
	padding: 8px 15px;
}

.segment_setting_icon {
	position: absolute;
	top: -1px;
	right: 0;
	text-align: center;
	visibility: hidden;

	&:hover {
		visibility: visible;
	}
}

.lead_mgr_sidebar_item_badge:hover .segment_setting_icon {
	visibility: visible;
}

.lead_mgr_sidebar_item_badge:hover .segment_numberic {
	visibility: hidden;
}

.new_lead_mgr_sidebar_content_sync {
	background-color: $secondary;
	color: $white;
	font-size: 12px;
	height: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
}
