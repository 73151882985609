.auth-overlay {
	background-color: rgba(9, 38, 116, 0.85);
	content: '';
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.auth-overlay__pattern2 {
	background: url('../img/sign-in/partern-2.svg') no-repeat right top;
	background-size: contain;
	content: '';
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.auth-overlay__pattern3 {
	background: url('../img/sign-in/partern-3.svg') no-repeat left bottom;
	background-size: contain;
	content: '';
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	bottom: 0;
	width: 100%;
}

.auth-intro {
	align-items: center;
	background: url('../img/sign-in/background.jpg') no-repeat center;
	background-size: cover;
	display: flex;
	justify-content: center;
	position: relative;
	width: percentage(0.416);
}

.auth-logo {
	left: percentage(0.083);
	position: absolute;
	top: percentage(0.03);
}

.auth-intro__content {
	color: #fff;
	font-size: 18px;
	padding-left: percentage(0.2);
	padding-right: percentage(0.125);
	padding-top: $spacer;
	z-index: 1;
	position: relative;
}

.auth-intro__content-pattern1 {
	background: url('../img/sign-in/quote-1.svg') no-repeat center;
	background-size: contain;
	display: block;
	width: 25px;
	height: 20px;
	position: absolute;
	top: -$spacer;
	left: calc(20% - 40px);
}
.auth-intro__content-pattern2 {
	background: url('../img/sign-in/quote-2.svg') no-repeat bottom right;
	background-size: contain;
	display: block;
	width: 35px;
	height: 35px;
	position: absolute;
	bottom: 0;
	right: calc(15% - 35px);
}

.auth-intro__check-icon {
	background: url('../img/sign-in/check.svg') no-repeat center;
	background-size: cover;
	display: inline-block;
	height: 18px;
	margin-left: $spacer;
	margin-top: -3px;
	vertical-align: middle;
	width: 18px;
}

.auth-main {
	display: flex;
	height: 100vh;
}

.auth-body {
	display: flex;
	// justify-content: center;
	flex-direction: column;
	flex: 1;
	position: relative;
	overflow: auto;
}

.auth-body__header {
	padding: 35px 50px;
}

.auth-navigator {
	// right: 50px;
	// position: absolute;
	// top: 35px;
	text-align: right;
	color: $gray-600;
	margin-bottom: 0;
}



.auth-body__main {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.auth-form {
	box-shadow: 0px 4px 40px rgba(38, 65, 135, 0.15);
	border-radius: 20px;
	line-height: initial;
	max-width: 600px;
	min-width: 600px;
	width: 100%;
	margin: 0 auto;
	padding: $spacer * 4;
}

.auth-body__footer {
	padding: 50px;
}

.auth-select-lang {
	margin-left: auto;
	// bottom: 50px;
	color: transparent !important;
	height: 40px;
	position: relative;
	// right: 50px;
	width: 150px;
	// overflow: hidden;
}

.auth-select-lang .select-lang__form {
	display: flex;
	flex-direction: column;
}

.auth-select-lang::after {
	background: url('../img/sign-in/chevron-down.svg') no-repeat center;
	background-size: cover;
	display: block;
	content: '';
	height: 16px;
	position: absolute;
	right: 12px;
	top: 50%;
	transform: translate(0, -50%);
	width: 16px;
}

.auth-select-lang .select-lang__item {
	border: solid 1px #e3e3e3;
	border-radius: 4px;
	color: #000;
	cursor: pointer;
	display: block;
	font-size: 14px;
	line-height: 38px;
	padding: 0 $spacer * 1.5;
}
/*
 * Switch Language
 */
.auth-select-lang .select-lang__form .select-lang__item:nth-child(2) {
	display: none;
}
.auth-select-lang:hover .select-lang__form .select-lang__item:nth-child(2) {
	display: block;
}
.auth-select-lang .select-lang__form-reverse {
	display: flex;
	flex-direction: column-reverse;
}
.auth-select-lang .select-lang__form-reverse .select-lang__item:nth-child(1) {
	display: none;
}

.auth-select-lang:hover .select-lang__form-reverse .select-lang__item:nth-child(1) {
	display: block;
}

.auth-select-lang .select-lang__item:hover {
	color: #000;
}
/*
 * End of Switch Language
 */
.auth-select-lang .select-lang__flag::before {
	content: '';
	background-repeat: no-repeat;
	background-position: left center;
	background-size: contain;
	display: inline-block;
	height: 14px;
	width: 20px;
	vertical-align: middle;
	margin-top: -3px;
	margin-right: 5px;
}
.auth-select-lang .select-lang__flag-vi:before {
	background-image: url('../img/vn.svg');
}

.auth-select-lang .select-lang__flag-en:before {
	background-image: url('../img/en.svg');
}

.auth-form__title {
	font-size: 30px;
	font-weight: 700;
}

.auth-form__sub-title {
	font-size: 14px;
	color: $gray-600;
	width: 70%;
	line-height: 1.5;
}

.auth-form__desc {
	color: #2fa84f;
	font-size: 14px;
	margin-bottom: $spacer * 3;
	margin-top: $spacer;
}

.auth-form__desc--black {
	color: $gray-600;
}

.auth-form__btn {
	width: 100%;
	height: 50px;
	font-weight: 500;
}

.auth-form__btn--small {
	width: 50%;
}

.auth-form__row {
	display: flex;
	justify-content: space-between;
}

.auth-form__form-group__equal {
	width: calc(50% - 15px);
}

.auth-form__form-group {
	margin-bottom: $spacer * 2.5;
}

.auth-form__label {
	color: $gray-600;
	font-weight: 500;
	font-size: 16px;
}

.auth-form__asterisk {
	color: #ea3d2f;
}

.auth-form__input {
	height: 50px;
	color: #494949;
}

.auth-form__input::placeholder {
	color: $gray-600;
}

.auth-form__input:focus {
	border-color: $primary;
}

.auth-form__password-input {
	padding-right: $spacer * 8;
}



.auth-form__input.is-invalid {
	background-color: #fee4e2;
	background-image: none;
}

.auth-copyright {
	color: #808080;
	font-size: 14px;
	margin-top: $spacer * 2.5;
	text-align: center;
}

.auth-form__group-btns {
	align-items: center;
	display: flex;
	justify-content: space-between;
}
.auth-form__forgot-btn {
	width: 100%;
	text-align: right;
}

.auth-form__break {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin: $spacer * 3 0;
}
.auth-form__break--none {
	display: none;
}

.auth-form__break-line {
	background-color: #e3e3e3;
	display: block;
	height: 1px;
	width: 100%;
}
.auth-form__break-text {
	color: #808080;
	margin: 0 $spacer * 3;
}

.auth-form__footer-route {
	text-align: center;
}

.auth-form__note {
	color: $gray-600;
	font-size: 12px;
	margin-top: 15px;
}

.auth-form__note-icon {
	background: url('../img/sign-in/lock.svg') no-repeat center;
	background-size: cover;
	display: inline-block;
	width: 14px;
	height: 14px;
	vertical-align: middle;
	margin-top: -3px;
	margin-right: $spacer * 0.5;
}

.auth-link {
	font-weight: 500;
}

#loginWithGoogle {
	//border-radius: 6px;
	//overflow: hidden;
	//box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

	//[role="button"] {
	//border: none;
	//height: 50px;
	//font-size: 16px;
	//}
}

@media (max-width: 1366px) {
	.auth-intro__content {
		font-size: 16px;
	}
	.auth-form {
		padding: $spacer * 3;
		border-radius: 15px;
	}
	.auth-form__title {
		font-size: 24px;
	}
	.auth-form__label {
		font-size: 14px;
	}
	.auth-form__input,
	.auth-form__btn {
		height: 40px;
	}
	.auth-form__form-group {
		margin-bottom: $spacer;
	}
	.auth-copyright {
		margin-top: $spacer * 1.5;
	}
	.auth-navigator {
		font-size: 14px;
	}
}

@media (max-width: 1024px) {
	.auth-intro {
		width: 30%;
	}
	.auth-overlay__pattern1 {
		display: none;
	}
	.auth-intro__content::before {
		display: none;
	}
	.auth-intro__content::after {
		display: none;
	}
	.auth-intro__content {
		padding: 10%;
	}
}
@media (max-width: 992px) {
	.auth-intro {
		display: none;
	}
	.auth-form {
		max-width: calc(100% - 30px);
		min-width: unset;
	}
}

@media (max-width: 768px) {
	.auth-form__sub-title {
		width: 100%;
	}
	.auth-select-lang {
		bottom: 40px;
		height: 30px;
	}
	.auth-select-lang .select-lang__item {
		line-height: 30px;
	}
}
