.referral_container {
	padding: 20px 30px;
	width: 800px;
}

.referral_box {
	background: white;
	padding: 16px 20px;
	border: 2px solid #e4e4e4;
	border-radius: 5px;
	width: 280px;
}

.referral_box__title {
	font-size: 20px;
	font-weight: 500;
}

.referral_box__label {
	font-weight: 500;
	color: #666;
	text-transform: uppercase;
	font-size: 12px;
}

.fpv_balance {
	background-color: #1890ff;
	color: white;
	font-weight: 600;
	border-radius: 6px;
	padding: 12px 16px;
}

.fpv_total_earned {
	background-color: #114490;
	color: white;
	font-weight: 600;
	border-radius: 6px;
	padding: 12px 16px;
}

.referral_table__row {
	display: flex;
	border-top: 1px solid #e9ecef;

	align-items: center;
	padding: $spacer 20px;
	&:first-child {
		background: #fcfcfc;
		font-weight: 400;
		text-transform: uppercase;
		font-size: 14px;
	}
}

.rule_number {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	font-size: 13px;
	font-weight: 500;
	height: 24px;
	background-color: #ededed;
	padding: 4px;
	margin-right: 8px;
	border-radius: 6px;
}
