.taginput_container {
	width: 100%;
	padding: 0;
	padding-top: 4px;
	font-size: $input-font-size;
	font-weight: $input-font-weight;
	line-height: $input-line-height;
	color: $input-color;
	background-color: $input-bg;
	background-clip: padding-box;
	border: $input-border-width solid $input-border-color;
	border-radius: $input-border-radius;
	display: flex;
	flex-wrap: wrap;

	&.focus,
	&:hover {
		color: $input-focus-color;
		background-color: $input-focus-bg;
		border-color: $input-focus-border-color;
		outline: 0;
	}

	&.is-invalid {
		color: $danger;
		border-color: $danger;
	}
}

.taginput_tag {
	display: flex;
	align-items: center;
	border-radius: 4px;
	background-color: $gray-200;
	line-height: 1.5;
	padding: 0 $spacer * 0.5;
	font-size: 14px;
	margin-bottom: 4px;
	margin-left: 4px;
	max-width: 120px;

	&.deletable {
		background-color: #ffd7d7;
	}
}

.taginput_tag_text {
	flex: 1;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipse;
}

.taginput_input_wrapper {
	position: relative;
	flex: 1;
	padding-right: $spacer * 0.5;
}

.taginput_input {
	width: 100%;
	margin-bottom: 4px;
	margin-left: 6px;
	padding-top: 0;
	padding-bottom: 0;
	padding-right: 6px;
	min-width: 100px;
	border: none;
	box-shadow: none;
	outline: none;
	background-color: transparent;

	&::placeholder {
		color: $gray-500;
	}

	&:focus,
	:hover {
		border: none;
		box-shadow: none;
		outline: none;
	}
}
