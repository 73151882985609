.web-plugin-list {
	padding: $spacer * 2;
	padding-bottom: 50px;
  max-height: 100%;
  overflow: auto;
}

.wp-create {
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	max-width: 100%;
}

.wp-create__header {
	border-bottom: 1px solid #d5d5d5;
	display: flex;
	align-items: center;
	overflow: hidden;
	background: $white;
	align-items: center;
	height: 50px;
	padding: 0 $spacer * 2;
	padding-left: 0;
	flex-shrink: 0;
	z-index: 101;
}

.wp-create__main {
	display: flex;
	overflow: hidden;
	flex: 1;
	width: 100%;
	height: 100%;
	background-color: $white;
}

.wp-create__templates {
	padding: $spacer * 2;
	padding-right: 10px;
	padding-top: $spacer * 3;
	display: flex;
	flex-direction: column;
	overflow: auto;
}



.popup_template_card {
	width: 300px;
	cursor: pointer;
	text-align: center;
	overflow: hidden;

  &.card__sm {
    width: 200px;
  }

  &.card__lg {
    width: 400px;
  }
}

.popup_template_card__content {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.popup_template_card__thumb {
	overflow: hidden;
	position: relative;
}

.popup_template_card__desc {
	border-top: none;
	font-weight: bold;
	padding: $spacer 0 $spacer 0;
	text-align: left;
	font-size: 16px;
}

.wp-preview__desktop-layout {
	overflow: hidden;
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #f5f7fa;
}

.wp-create .bounce-leave-active {
	animation: bounce-in 0.1s reverse;
}

.wp-edit-preview {
	flex: 1;
	display: flex;
	flex-direction: column;
	// border-top: 1px solid #d5d5d5;
	overflow: hidden;
	background-image: linear-gradient(
		45deg,
		#fafafa 25%,
		#ffffff 25%,
		#ffffff 50%,
		#fafafa 50%,
		#fafafa 75%,
		#ffffff 75%,
		#ffffff 100%
	);
	background-size: 56.57px 56.57px;
}

.wp-edit-preview__bar {
	background: white;
	height: 50px;
	display: flex;
	flex-shrink: 0;
	align-items: center;
	padding: 0 $spacer * 2;
	border-bottom: 1px solid #d5d5d5;
	justify-content: space-between;
	position: relative;
}

.wp-conditions {
	padding: $spacer * 2 $spacer * 4;
	max-width: 620px;
}

.wp-conditions-section {
	margin-bottom: $spacer * 4;
}

.wp-conditions-section__title {
	margin-bottom: $spacer * 1.5;
	font-size: 20px;
	font-weight: 500;
	line-height: 1.2;
}

.wp-conditions-section__subtitle {
	font-size: 15px;
	color: $gray-600;
	font-weight: normal;
	margin-top: $spacer * 0.5;
}

.wp-conditions-card {
	background-color: $white;
	border: 1px solid $border-color;
	margin-bottom: $spacer;
}

.wp-conditions-card__head {
	display: flex;
	justify-content: space-between;
	font-weight: 500;
	padding: $spacer * 1.5 $spacer * 2;
}

.wp-conditions-card__icon {
	width: 20px;
	margin-right: $spacer;
}

.wp-conditions-card__body {
	margin-top: -5px;
	padding: 0 $spacer * 2 $spacer * 1.5;

	&.negative {
		background-color: $gray-100;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
}

// -----------------------------------------------

.wp_design_sidebar {
	flex-shrink: 0;
	width: 400px;
	display: flex;
	flex-direction: column;
	border-left: 1px solid #d5d5d5;
	// padding: $spacer * 2;
	overflow: hidden;
	position: relative;
}

.wp_sidebar__wrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	transition: all ease-out 150ms;
}

.wp_sidebar__body {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: white;
	display: flex;
	flex-direction: column;
}

.wp_sidebar__header {
	border-bottom: 1px solid #d5d5d5;
	height: 50px;
	padding: $spacer $spacer * 2;
	display: flex;
	align-items: center;
}

.wp_sidebar__footer {
	border-top: 1px solid #d5d5d5;
	height: 50px;
	padding: $spacer $spacer * 2;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.wp_sidebar__content {
	// padding: 20px;
	overflow: auto;
	flex: 1;
}

.wp_sidebar__section {
	padding: 0 $spacer * 2 0 $spacer * 2;
	margin-bottom: $spacer * 2;
}

.wp_sidebar__divider {
	border-top: 1px solid #d5d5d5;
	width: 100%;
}

.wp_sidebar__button {
	padding: 10px;
	border: 1px solid lightgray;
	border-radius: 4px;
	width: 100%;
	position: relative;
	padding-right: 40px;
	cursor: pointer;
	transition: all ease-out 150ms;

	.wp_sidebar__button_icon {
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		width: 20px;
		height: 20px;
		transition: all ease-out 150ms;
		stroke: $gray-500;
	}
	&:hover {
		border-color: $primary;
		.wp_sidebar__button_icon {
			stroke: $primary;
			right: 10px;
		}
	}
}

.wp_name_editor {
	display: flex;
	align-items: center;
}

.wp_name_editor__toggle {
	margin-left: $spacer;
	cursor: pointer;
	opacity: 0;
	transition: 0.15s;
	pointer-events: none;
}

.wp_name_editor:hover .wp_name_editor__toggle {
	opacity: 1;
	pointer-events: all;
	transition: 0s;
}

.popup_image {
	overflow: hidden;
	border-radius: 3px;
	align-items: center;
	width: 110px;
	height: 140px;
	display: flex;
	margin-bottom: $spacer;
	margin-right: $spacer;
	cursor: pointer;
	position: relative;
	transition: all ease-out 150ms;
	// border: 1px solid lightgray;
	background-color: #f0f0f0;
	&:hover {
		background-color: #eee;
		// padding-left: $spacer*2;
		border-color: $black;
		.popup_component_icon {
			background: #eaeaea;
		}

		.popup_component__delete_btn {
			opacity: 1;
		}
	}

	&:nth-child(3n) {
		margin-right: 0;
	}
}

.popup_component {
	overflow: hidden;
	border-radius: 3px;
	align-items: center;
	display: flex;
	border: 1px solid transparent;
	// padding: $spacer;
	margin-bottom: $spacer;
	cursor: pointer;
	position: relative;
	transition: all ease-out 150ms;
	// border-left: 2px solid lightgray;
	&:hover {
		.popup_component_icon {
			background: #eaeaea;
		}

		.popup_component__delete_btn {
			opacity: 1;
		}
	}

	&.dragging {
		opacity: 0.2;
	}

	&.dropable {
		border: 1px dashed $gray-500;
		height: 1px;
	}

	&.disabled {
		//opacity: 0.25;
		//border: 1px dashed $gray-500;
	}

	&.focused {
		background-color: $yellow-100;
	}
}

.popup_component__text_blur {
	color: $gray-600;
}

.popup_component_icon {
	flex-shrink: 0;
	margin-right: 10px;

	cursor: grabbing;
	padding: 10px;
	border-radius: 5px;
	background: #f0f0f0;
	width: 40px;
	height: 40px;
	user-select: none;
}

.popup_component_image {
	width: 100%;
	height: 100%;
	object-fit: contain;
	user-select: none;
	pointer-events: none;
}

.wp_sidebar__label {
	font-weight: 500;
	margin-top: $spacer * 2;
	margin-bottom: $spacer;
}

.popup_component_button {
	border-radius: 3px;
	align-items: center;
	display: flex;
	border: 1px solid lightgray;
	padding: 5px;
	cursor: pointer;
	transition: all ease-out 150ms;
	&:hover {
		border-color: $black;
	}
}

/* ========== Campaign Design Color Picker ======= */
.wp_color_picker {
	display: flex;
	align-items: center;
	position: relative;
}

.wp_color_picker__icon {
	width: 18px;
	height: 18px;
}

.wp_color_picker__preview {
	cursor: pointer;
}

.wp_color_picker__picker {
	position: absolute !important;
	z-index: 10;
	top: 20px;
	left: 0px;
}

.wp_color_picker__picker.wp_color_picker__picker__right {
	left: -200px;
}

.wp_color_picker__picker.wp_color_picker__picker__up {
	top: -190px;
	left: 20px;
}

.wp_color_picker__picker__hide {
	display: none;
}

.wp_sidebar__tabs {
	display: flex;
	margin-top: -$spacer * 2;
	margin-left: -$spacer * 2;
	margin-right: -$spacer * 2;
}

.wp_sidebar__tab {
	flex: 1;
	background-color: $gray-100;
	color: $gray-600;
	border-bottom: 1px solid $border-color;
	font-size: 16px;
	padding: $spacer * 1.5 0;
	border-right: 1px solid $border-color;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:last-child {
		border-right: none;
	}

	&.active {
		border-bottom: 1px solid $white;
		background-color: $white;
	}
}

.size_picker__input {
	outline: none;
	color: $gray-700;
	border: none;
	text-align: center;
	background: transparent;
	padding: 0;
	position: absolute;
	width: 44px;
	&:hover,
	&:active {
		outline: none;
	}
}

.size_picker__content {
	background: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.size_picker {
	position: relative;
	padding: 30px 70px;
	border-radius: 5px;
	background: whitesmoke;
}

.position_picker {
	position: relative;
	background: #f8f8f8;
	padding: 30px 70px;
}

.position_picker__cell {
	cursor: pointer;
	border-radius: 2px;
	transition: all ease-out 200ms;
	background: $gray-200;
	margin: 3px;
	text-align: center;
	height: 40px;

	&:hover {
		background: $gray-400;
	}
}

.position_picker__cell.position_picker__cell__active {
	background: $gray-600;
}

.position_picker__cell.position_picker__cell__disabled {
	background: $gray-100;
	cursor: not-allowed;
}

.style_input__wrapper {
	position: relative;
}

.style_input.form-control {
	padding-right: $spacer * 3;
}

.style_input__unit {
	position: absolute;
	top: 50%;
	right: $spacer;
	transform: translateY(-50%);
	font-size: 12px;
	color: $gray-600;
}

.style_form {
	display: flex;
	align-items: center;
	margin-bottom: $spacer * 1.5;

	&:last-child {
		margin-bottom: 0;
	}
}

.style_form__label {
	font-size: 13px;
	color: $gray-600;
	font-size: 13px;
	color: $gray-600;
	width: 80px;
}

.popup_img_lib__categories {
	display: flex;
	flex-direction: column;
	width: 111px;
	box-shadow: 1px 0 0 $border-color;
}

.popup_img_lib__category {
	cursor: pointer;
	color: $gray-500;
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: $spacer * 0.5 0;
	padding-left: $spacer * 2;

	&:first-child {
		padding-top: $spacer * 2;
	}

	&:hover,
	&.active {
		color: $gray-700;
	}
}

.popup_img_lib {
	display: flex;
	max-height: 100%;
	flex-wrap: wrap;
	flex: 1;
	overflow: auto;
	padding-left: $spacer * 2;
	padding-top: $spacer * 2;
	padding-right: $spacer * 0.5;
	align-self: flex-start;
}

.popup_img_lib__item {
	width: 120px;
	height: 140px;
	border-radius: 4px;
	margin-right: $spacer;
	background-size: contain;
	margin-bottom: $spacer;
	cursor: pointer;
	background-repeat: no-repeat;
	position: relative;
	background-position: center center;
	border: 2px solid white;
	display: flex;
	align-items: center;
	justify-content: center;

	&:nth-child(2n) {
		margin-right: 0;
	}

	&.selected {
		border: 4px solid $primary;
	}

	&:hover {
		.popup_img_lib__item_author {
			opacity: 1;
		}
	}
}

.popup_img_lib__item_author {
	border-top-left-radius: 4px;
	font-size: 10px;
	position: absolute;
	bottom: 0;
	right: 0;
	background: #555;
	color: white;
	padding: 0 5px;
	opacity: 0;
	transition: all 150ms ease-out;
	@include text-truncate();
	max-with: 100%;
	a {
		color: white;
	}
	&:hover {
		background: #222;
		a {
			color: white;
			text-decoration: underline;
		}
	}
}

.position_picker__input {
	outline: none;
	color: $gray-700;
	border: none;
	text-align: center;
	background: transparent;
	padding: 0;
	position: absolute;
	width: 44px;
	&:hover,
	&:active {
		outline: none;
	}
}

.position_picker_box {
	width: 22px;
	height: 22px;
	border-radius: 2px;
	border: 1px solid #ccc;
	position: relative;
	margin-right: $spacer;
	cursor: pointer;
	&:last-child {
		margin-right: 0;
	}
	&.active {
		border: 1px solid $primary;

		.position_picker_box__insider {
			background-color: $primary;
		}
	}
}

.position_picker_box__insider {
	position: absolute;
	background-color: #ccc;

	&.fullscreen {
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
	}

	&.full_right {
		top: 0;
		bottom: 0;
		right: 0;
		width: 50%;
	}

	&.more_right {
		top: 0;
		bottom: 0;
		right: 0;
		width: 65%;
	}

	&.full_left {
		top: 0;
		bottom: 0;
		left: 0;
		width: 50%;
	}

	&.more_left {
		top: 0;
		bottom: 0;
		left: 0;
		width: 65%;
	}

	&.full_top {
		top: 0;
		right: 0;
		left: 0;
		height: 38%;
	}

	&.more_top {
		top: 0;
		right: 0;
		left: 0;
		height: 50%;
	}

	&.left_inside {
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		&:before {
			position: absolute;
			content: '';
			top: 3px;
			left: 0;
			bottom: 3px;
			width: 9px;
			background: $white !important;
		}
	}

	&.right_inside {
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		&:before {
			position: absolute;
			content: '';
			top: 3px;
			right: 0;
			bottom: 3px;
			width: 9px;
			background: $white !important;
		}
	}

	&.fullscreen_inside {
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		&:before {
			position: absolute;
			content: '';
			top: 3px;
			right: 3px;
			bottom: 3px;
			left: 3px;
			background: $white !important;
		}
	}
}

.button_component_container {
	margin-left: 24px;
	margin-top: 10px;
	margin-bottom: $spacer * 2;
	transition: height ease-out 100ms, opacity ease-out 200ms;
	opacity: 1;
	max-height: 500px;
}

.button_component_container.button_component_container__hidden {
	transition: height ease-out 200ms, opacity ease-out 100ms;
	opacity: 0;
	overlay: hidden;
	max-height: 0px;
	overflow: hidden;
}

.wp_setting_btn {
	float: right;
	width: 18px;
	stroke-width: 1.5px;
	height: 18px;
	border-radius: 50%;
	color: #999;
	cursor: pointer;
	transition: all ease-out 150ms;

	&:hover {
		transform: rotate(30deg);
		color: $primary;
	}
}

.wp_countdown {
	margin-bottom: $spacer;
	margin-left: 24px;
	margin-top: $spacer * 2;
	display: block;
}

.wp_countdown.wp_countdown__hidden {
	display: none;
}

.popup_option__drag {
	cursor: grabbing;
	user-select: none;
	color: $gray-500;
	position: absolute;
	top: 50%;
	left: 5px;
	transform: translateY(-50%);
	opacity: 0;
}

.popup_option_input__wrapper:hover .popup_option__drag {
	opacity: 1;
}

.popup_option__remove {
	position: absolute;
	right: 8px;
	top: 50%;
	transform: translateY(-50%);
	cursor: pointer;
	color: $gray-500;

	&:hover {
		color: $gray-700;
	}
}

.popup_option__buttons {
	position: absolute;
	top: -14px;
	left: 0;
	opacity: 0;
	display: flex;
	box-shadow: 0 4px 10px rgba(25, 25, 25, 0.15);
	border-radius: 10px;
	overflow: hidden;
}

.popup_option__action_btn {
	width: 24px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $gray-500;
	background-color: $white;
	cursor: pointer;

	&:hover {
		color: $gray-700;
	}

	&.disabled {
		pointer-events: none;
		background-color: $gray-100;
		color: $gray-400;
	}
}

.popup_option_input__wrapper:hover .popup_option__buttons {
	opacity: 1;
}

.popup_option__input {
	padding-right: 15px;
}

.popup_option_input__wrapper {
	margin-bottom: $spacer;
	position: relative;

	&:last-child {
		margin-bottom: 0;
	}
}

.wp__font_sizes {
	max-height: 170px;
	overflow: auto;
	width: 100%;
}

.wp__font_size_item {
	padding: 2px $spacer;
	cursor: pointer;
	&:hover {
		background: $gray-200;
	}
}

.wp__font_size_item.wp__font_size_item__active {
	background: #666;
	color: $white;
}

.popup_component__text {
	@include text-truncate();
}

.popup_field_component {
	display: flex;
	align-items: center;
	margin-bottom: $spacer * 0.5;
	padding: $spacer * 0.5 0;
	cursor: pointer;
	// border: 1px solid $gray-400;
	background-color: $gray-100;
	background-color: $white;
	border-radius: 4px;
	transition: 0.3s ease;
	position: relative;
	overflow: hidden;

	&.dragging {
		// border: 1px dashed $gray-400;
		background-color: $gray-200;
	}
}

.popup_field_component__drag {
	width: 15px;
	height: 100%;
	top: 0;
	left: 3px;
	cursor: grabbing;
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: 0.3s ease;
	user-select: none;
	color: $gray-600;
}

.popup_field_component:hover .popup_field_component__arrow {
	opacity: 1;
}

.flip-list-move {
	transition: transform 0.15s;
}

.wp__used_icon {
	width: 10px;
	fill: $primary;
	stroke: none;
	margin-top: -2px;
}

.wp__unused_icon {
	width: 10px;
	fill: $gray-300;
	stroke: none;
	margin-top: -2px;
}

.wp__btn {
	background: $gray-300;
	width: 70px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 5px;
}

.plugin_icon {
	width: 18px;
	color: $gray-300;
	cursor: pointer;
	transition: color 150ms ease-out;
	margin-right: $spacer * 0.5;

	&:hover {
		color: $gray-500;
	}
}

.plugin_icon.plugin_icon__active {
	color: $success;
	&:hover {
		color: #1b7f35;
	}
}

.website-chatbot-sidebar {
	height: 100%;
	width: 500px;
	max-width: 80vw;
	display: flex;
	flex-direction: column;
	position: relative;
}

.website-chatbot-sidebar-label {
	margin-top: $spacer * 2;
	margin-bottom: $spacer;
	font-size: 16px;
	font-weight: bold;
	display: flex;
	align-items: center;
}

.bot_boxs {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	overflow: auto;
	padding: 0 $spacer * 2;
}

.bot_box {
	border: 2px solid lightgray;
	border-radius: 5px;
	padding: $spacer;
	margin: $spacer 0;
	width: 220px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	&:hover {
		border-color: $primary;
	}
}

.bot-box__table-cell {
	flex: 1;
	background-color: #fff;
	padding: $spacer;
	white-space: nowrap;
	align-items: center;
	display: flex;
	overflow: hidden;
}

.popup_component__delete_btn {
	opacity: 0;
	transition: all 150ms ease-out;
	background: white;
	position: absolute;
	top: 10px;
	right: 0;
	color: $gray-800;
	cursor: pointer;
	height: 15px;
	width: 15px;
	&:hover {
		color: $danger;
	}
}

.popup_image_edit__image {
	overflow: hidden;
	align-items: center;
	justify-content: center;
	display: flex;
	margin-bottom: $spacer;
	position: relative;
}

.wp_create__sidebar {
	padding-top: $spacer * 2;
	margin-right: $spacer * 3;
	width: 220px;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	background: #f5f5f5;
}

.wp_create__category_item {
	padding: $spacer * 0.5 $spacer * 2;
	font-size: 16px;
	cursor: pointer;

	&.active {
		color: $primary;
		font-weight: 500;
	}

	&:last-child {
		border-bottom: none;
	}
}

.wp_create__keyword_tag {
	margin-right: 8px;
	margin-bottom: 6px;
	padding: 2px 6px;
	border-radius: 4px;
	background-color: $gray-100;
	cursor: pointer;
	font-size: 10px;
	display: flex;
	align-items: center;
	border: 1px solid $gray-300;
}

.popup_template__buttons {
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
}

.popup_template_card:hover .popup_template__buttons {
	opacity: 1;
}

.popup_template_card:hover .popup_template_card__thumb_overlay {
	opacity: 1;
}

.wp_edit_preview__zoom_buttons {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	right: $spacer * 1.5;
}

.wp_sample_profile_img {
	width: 32px;
	height: 32px;
	border-radius: 50%;
	margin-right: 7px;
	margin-bottom: 7px;
	user-select: none;
}

.wp_sample {
	font-weight: 500;
	text-transform: capitalize;
}

.wp_sample_profile_upload {
	border: 2px dashed $gray-400;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	cursor: pointer;
	background-size: 100% 100%;
	&:hover {
		border-color: $gray-500;
	}
}

.wp_notif_background_overlay {
	z-index: 0;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: $primary;
	opacity: 0;
}

.wp_notif_background {
	position: relative;
	display: flex;
	justify-content: center;
	margin-top: 10px;
	padding: 2px;
	border: 2px solid transparent;
	cursor: pointer;
	width: 170px;
	height: 57px;
	display: flex;
	align-items: center;
	overflow: hidden;
	&:hover {
		.wp_notif_background_overlay {
			opacity: 0.2;
		}

		.wp_notif_background_upload_icon {
			opacity: 1;
		}
	}
}

.wp_notif_background.wp_notif_background__active {
	border-color: $primary;
}

.wp_notif_background_img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.wp_notif_backgrounds {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.wp_notif_background_upload_icon {
	width: 26px;
	height: 26px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	padding: 2px;
	background: #fff;
	z-index: 1;
	opacity: 0.5;
}

.wp_sample_profile {
	position: relative;
	&:hover {
		.wp_sample_profile__x {
			opacity: 1;
		}
	}
}

.wp_sample_profile__x {
	background: white;
	border-radius: 50%;
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
	opacity: 0;
	position: absolute;
	top: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 18px;
	color: #aaa;
	stroke: #aaa;
	height: 18px;
	cursor: pointer;
	&:hover {
		color: #000;
		stroke: #000;
	}
}

.popup_template_card__thumb_overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.65);
	opacity: 0;
}

.popup_template__category_section {
	margin-bottom: $spacer * 3;

	&:last-child {
		margin-bottom: 0;
	}
}

.popup_template__category_title {
	font-size: 26px;
	font-weight: 500;
	margin-bottom: $spacer * 2;
	color: $primary;
}

.popup_template__img_wrapper {
	width: 320px;
	height: 200px;
	padding-right: $spacer * 2;
	margin-bottom: $spacer * 2;
	flex-shrink: 0;
}

.popup_template__row {
	display: flex;
	flex-wrap: wrap;
}

.popup_size_input_unit {
	position: absolute;
	top: 0;
	right: 0;
	cursor: pointer;
	font-size: 12px;
	width: 22px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $gray-600;
	user-select: none;

	&:hover {
		color: $gray-700;
	}
}

.popup_size_input {
	padding-right: 25px;
	max-width: 100px;
}

.popup_com_dropdown_section {
	padding-top: $spacer;
	padding-bottom: $spacer;
	padding-left: $spacer * 2;
}

.popup_com_dropdown_divider {
	height: 1px;
	width: 100%;
	background: #d5d5d5;
	opacity: 0.7;
}

.popup_com_dropdown_section__title {
	font-size: 13px;
	text-transform: uppercase;
	color: $gray-600;
	margin-bottom: 8px;
	font-weight: 500;
}

.popup_com_dropdown_section__content {
	display: flex;
	flex-wrap: wrap;
}

.popup_com_dropdown__item {
	margin-right: $spacer;
	margin-bottom: $spacer;
	font-size: 13px;
	display: flex;
	justify-content: center;
	align-self: flex-start;
	align-items: center;
	flex-direction: column;
	text-align: center;
	width: 58px;
	cursor: pointer;

	&:hover .popup_component_icon {
		background: #eaeaea;
	}

	&:hover .popup_com_dropdown__item_icon {
		background: #e6fcff;
	}
}

.popup_com_dropdown__item_icon {
	width: 40px;
	height: 40px;
	border-radius: $spacer * 0.5;
	user-select: none;
	background: #f0f0f0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.wp_status_text {
	display: flex;
	font-size: 12px;
	line-height: 1;

	&:hover .wp_status_text__action {
		opacity: 1;
		pointer-events: auto;
	}
}

.wp_status_text__action {
	opacity: 0;
	pointer-events: none;
	color: $gray-600;

	&:hover {
		color: $gray-700;
		text-decoration: underline;
	}
}

.wp_screen_display {
	background-color: #f5f7fa;
	min-width: 1024px;
	width: 100%;
	height: 100%;
	max-height: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	&.mobile {
		width: 375px;
		height: 720px;
		min-width: unset;
	}
}

.wp_introduction {
  margin: 10px auto 0;
  max-width: 1140px;
}

.wp_introduction_section {
  display: flex;
  width: 100%;
}

.wp_introduction_section__left {
  margin-right: 30px;
  max-width: 650px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wp_introduction_section__right {
  margin-left: 30px;
  max-width: 650px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wp_introduction_section__title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 15px;
}

.wp_introduction_section__paragraph {
  font-size: 16px;
}

.wp_intro_cta_btn {
  width: fit-content;
  font-weight: 500;
  padding-left: 20px;
  padding-right: 20px;
}

.wp_lang_alert {
  padding-right: $spacer*0.5;
  position: relative;
}

.wp_lang_alert:hover .wp_lang_alert__content {
  display: block;
}
.wp_lang_alert__content {
  z-index: 99;
  min-width: 250px;
  position: absolute;
  top: 0;
  left: 18px;
  background: $white;
  border-radius: 4px;
  box-shadow: 0 10px 30px rgba(0,0,0,0.15);
  display: none;
}

.wp_lang_alert__head {
  padding: $spacer*0.5 $spacer;
  font-weight: 500;
}

.wp_lang_alert__item {
  padding: $spacer*0.5 $spacer;
  padding-left: 15px;
  cursor: pointer;

  &:hover {
    background-color: $gray-100;
  }
}
