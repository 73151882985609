.ticket_list_page {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.ticket_layout_sidebar {
	width: 260px;
	flex-shrink: 0;
	background-color: #f7f5f2;
	height: 100%;
	border-right: 1px solid $border-color;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.ticket_layout_sidebar_header {
	padding: 12px 20px;
	flex-shrink: 0;
}

.ticket_layout_sidebar_content {
	padding: 6px 20px;
	flex: 1;
	overflow: auto;
}

.ticket_layout_sidebar_item_section {
	margin-bottom: 10px;

	&:last-child {
		margin-bottom: 0;
	}
}

.ticket_layout_sidebar_item_icon_actions_wrapper {
	width: 20px;
	height: 20px;
	flex-shrink: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;

	&.triggered {
		.ticket_layout_sidebar_item_shrink_icon {
			display: none;
		}
		.ticket_layout_sidebar_item_settings_icon {
			display: flex;
		}
	}
}

.ticket_layout_sidebar_item_shrink_icon {
	display: block;
	color: $secondary;
}
.ticket_layout_sidebar_item_settings_icon {
	display: none;
	color: $secondary;
	width: 20px;
	height: 20px;
	align-items: center;
	justify-content: flex-end;
}

.ticket_layout_sidebar_item {
	display: flex;
	margin-left: -8px;
	margin-right: -8px;
	cursor: pointer;
	align-items: center;
	padding: 5px 8px;
	padding-right: 3px;
	border-radius: 5px;
	margin-bottom: 2px;

	&.danger {
		color: $danger;
	}

	&.secondary .icon-tabler:first-child {
		color: $secondary;
		stroke-width: 1;
	}

	&.child {
		padding-left: 25px;
		padding-top: 3px;
		padding-bottom: 3px;
		margin-bottom: 0;
	}

	&.hidden {
		display: none;
	}

	.tabler-icon {
		flex-shrink: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	&:hover {
		background-color: #ececec;
	}

	&.active {
		background-color: $gray-700;
		color: $white;

		.ticket_layout_sidebar_item_number_badge {
			background-color: $gray-700;
			color: $white;
		}
	}

	&.unread {
		font-weight: 700;
		color: #000;

		.ticket_layout_sidebar_item_number_badge {
			background-color: $danger;
			font-weight: 700;
			color: $white;
		}
	}
}

.ticket_layout_sidebar_item_number_badge {
	flex-shrink: 0;
	font-size: 12px;
	color: $gray-600;
	background-color: $gray-200;
	border-radius: 4px;
	font-weight: 500;
	padding: 0px 5px;
}

.ticket_layout_sidebar_item_text {
	flex: 1;
	margin-left: 8px;
	margin-right: 10px;

	@extend .text__truncate;
}

.ticket_list_header {
	display: flex;
	align-items: center;
	padding: 10px 15px 0;
}

.ticket_list_header_title_container {
	flex: 1;
	display: flex;
}

.ticket_list_header_title {
	max-width: 350px;
	font-size: 18px;
	@extend .text__truncate;
}

.ticket_list_header_subtext {
	font-size: 14px;
	color: $gray-600;
	margin-left: 5px;
	position: relative;
	top: 4px;
}

.ticket_list_header_actions {
	margin-left: 20px;
	flex-shrink: 0;
	display: flex;
}

.ticket_list_filters {
	padding: 10px 15px 0;
	display: flex;
	margin-bottom: 5px;
	flex-wrap: wrap;
}

.ticket_page_layout {
	display: flex;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.ticket_page_layout_content {
	flex: 1;
	overflow: hidden;
}

.ticket_detail_page {
	width: 100%;
	background: whitesmoke;
	height: 100%;
	overflow: hidden;
	position: relative;
	display: flex;

	&.inconvo {
		border-left: 1px solid $border-color;
	}
}

.ticket_detail_page_left_header {
	flex-shrink: 0;
	height: 55px;
	border-bottom: 1px solid $border-color;
	display: flex;
	align-items: center;
	padding: 0 20px;
}

.ticket_detail_page_left_inconvoheader {
	flex-shrink: 0;
	height: 64px;
	border-bottom: 1px solid $border-color;
	display: flex;
	align-items: center;
	padding: 0 20px;
}

.ticket_detail_page_left_col {
	flex: 1;
	padding: 12px 20px;
	overflow-y: scroll;
}

.ticket_detail_page_header_title_text {
	font-size: 20px;
	font-weight: 500;
}

.ticket_detail_page_subtitle_seperator {
	margin-left: 10px;
	margin-right: 10px;
	width: 1px;
	height: 14px;
	background-color: $border-color;
}

.ticket_detail_page__container.ticket_detail_page__container__inconvo {
	border-radius: 10px;
	margin: 10px;
	border: 1px solid $border-color;
}

.ticket_detail_page__container {
	background: white;
	border-right: 1px solid $border-color;
	flex: 2;
	display: flex;
	flex-direction: column;
	overflow-y: hidden;
}

.ticket_detail_page_right_col.ticket_detail_page_right_col__incovo {
	border-left: 1px solid $border-color;
	padding-top: 0px;
}

.ticket_detail_page_right_col {
	background: white;
	flex-shrink: 0;
	padding-top: 11px;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	// see .profile
	overflow-x: hidden;
	min-width: 200px;
	max-width: 400px;
	flex: 3;
	position: relative;
	min-height: 100%;
	transition: 0.3s ease;

	&.expand {
		width: unset;
		flex: 3;
		max-width: unset;
	}
}

.ticket_detail_page_right_col .tab {
	margin-bottom: 0;
	padding-left: 20px;
	flex-shrink: 0;
}

.ticket_detail_page_right_col_wrapper {
	flex: 1;
	overflow: hidden;
	position: relative;
}

.ticket_detail_page_right_col_inner {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	position: absolute;
	overflow-y: auto;
	transition: 0.3s;

	.profile {
		overflow: hidden;
		border-left: none;
	}
}

.ticket_detail_page_info_section {
	padding: 12px 20px;
	border-bottom: 1px solid $border-color;
}

.ticket_detail_page_info_section_title {
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 500;
	@extend .text__muted;
}

.ticket_cell.ticket_cell__status {
	width: 110px;
	flex-shrink: 0;
}

.ticket_cell {
	border-bottom: 1px solid #dee2e6;
	padding: 10px 8px;
	overflow: hidden;
	display: flex;
	align-items: center;
}

.ticket_cell.ticket_cell__sm {
	width: 125px;
	flex-shrink: 0;
}

.item-row:hover .ticket_cell {
	background-color: #f8f9fa;
}

.order_list_row.order_list_row__checked:hover .ticket_cell {
	background-color: #dfebf4;
}

.edit_ticket_modal_header {
	padding: 12px 20px;
	border-bottom: 1px solid $border-color;
}

.edit_ticket_modal_form_main {
	padding: 12px 20px;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.edit_ticket_modal_form_sidebar {
	width: 280px;
	padding: 12px 20px;
	border-left: 1px solid $border-color;
	height: 100%;
	overflow-y: auto;
}

.edit_ticket_modal_form_sidebar_field {
	margin-bottom: 15px;

	&:last-child {
		margin-bottom: 0;
	}
}

.edit_ticket_modal_form_sidebar_field_label {
	font-size: 14px;
	color: $secondary;
}

.edit_ticket_modal_form {
	display: flex;
	height: 100%;
	width: 100%;
	overflow: hidden;
	flex: 1;
	position: relative;
}

.edit_ticket_modal_form_title_input {
	padding: 2px 0;
	font-size: 18px;
	font-weight: 600;
	outline: none;
	border-radius: 4px;
	resize: none;
	border: none;
	background: transparent;
	display: block;
	width: 100;
	overflow: hidden;
	overflow-wrap: break-word;
	margin-bottom: 5px;
}

.ticket_detail_page .edit_ticket_modal_form_desc_editor.lexical_editor_tabs_wrapper {
	min-height: unset;

	.lexical_editor_input {
		min-height: 56px;
	}
	.lexical_editor_attachments {
		border: none;
	}
}

.ticket_detail_page .ticket_detail_activities {
	.lexical_editor_wrapper {
		box-shadow: rgba(0, 0, 0, 0.09) 0px 1px 4px;
		border-radius: 8px;
		border: 1px solid $border-color;
	}

	.lexical_editor_input {
		padding: 8px 15px;
		min-height: 56px;
	}

	.lexical_editor_placeholder {
		left: 15px;
		top: 8px;
	}

	.lexical_editor_toolbar {
		border-top: none;
	}

	.lexical_editor_toolbar_btn_group {
		border-right: none;

		&:not(:last-child) {
			padding-right: 0;
		}
	}
}

.ticket_detail_page .ticket_detail_activities .ticket_message_sent_editable {
	.lexical_editor_wrapper {
		box-shadow: none;
		border-radius: 0;
		border-color: $gray-500;
	}

	.lexical_editor_input {
		padding: 5px 10px;
		min-height: 56px;
	}

	.lexical_editor_placeholder {
		left: 10px;
		top: 5px;
	}
}

.edit_ticket_modal_form_desc_editor.lexical_editor_tabs_wrapper {
	min-height: 42px;
	flex: 1;
	overflow: hidden;

	.lexical_editor_toolbar {
		display: none;
	}

	.lexical_editor_input {
		padding-left: 0;
		padding-right: 0;
		max-height: unset;
		height: 100%;
	}

	.lexical_editor_attachments {
		padding-left: 0;
		padding-right: 0;
	}

	.lexical_editor_wrapper {
		border: none;
		height: 100%;
	}

	.lexical_editor_placeholder {
		left: 0;
	}

	.lexical_editor_input_wrapper {
		overflow: hidden;
		flex: 1;
	}
}

.edit_ticket_modal_footer {
	padding: 12px 20px;
	border-top: 1px solid $border-color;
}

.ticket_status {
	font-size: 14px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	gap: 5px;
	@extend .text__truncate;
	color: $secondary;
}

.ticket_id_col {
	// font-family: $font-family-monospace;
	font-size: 14px;
	color: $secondary;
}

.order_list_row__unread {
	.ticket_id_col,
	.ticket_status {
		color: $gray-700;
	}
}

.square {
	width: 14px;
	height: 14px;
	border-radius: 3px;
}

.square.square__success {
	background: $success;
}

.square.square__warning {
	background: $warning;
}

.square.square__primary {
	background: $primary;
}

.square.square__secondary {
	background: $secondary;
}

.ticket_detail_event_wrapper {
	margin-bottom: 15px;

	&:last-child {
		margin-bottom: 0;
	}
}

.ticket_detail_event_inner {
	padding-left: 16px;
	padding-right: 16px;
}

.ticket_detail_event_inner_avatar_wrapper {
	position: relative;
	top: 2px;
	flex-shrink: 0;
	line-height: 1;
}

.ticket_detail_event_message_sent {
	border: 1px solid $border-color;
	padding: 10px 15px;
	border-radius: 8px;
	background-color: $white;
	box-shadow: rgba(0, 0, 0, 0.09) 0px 1px 4px;
	position: relative;

	&.danger {
		border-color: $danger;
	}
}

.ticket_detail_event_message_sent_status {
	position: absolute;
	z-index: 1;
	font-size: 12px;
	bottom: 10px;
	right: 10px;
}

.ticket_rating_star_icon_wrapper {
	cursor: pointer;
	margin-right: 5px;
	transition: transform 0.35s ease;
	line-height: 1;
	@extend .text__secondary;

	&.readonly {
		cursor: auto;
	}

	&.active .ticket_rating_star_icon {
		@extend .text__warning;

		path:last-child {
			fill: currentColor;
		}
	}

	&:hover {
		transform: scale(1.3);
		transform-origin: 50% 50%;
	}

	&:not(.readonly):hover .ticket_rating_star_icon {
		@extend .text__warning;

		path:last-child {
			fill: currentColor;
		}
	}

	&.readonly:hover {
		transform: none;
	}

	&:last-child {
		margin-right: 0;
	}
}

.ticket_rating_star_icon {
	stroke-width: 1;
}

.ticket_detail_priority_wrapper:hover .ticket_detail_priority_action {
	display: inline;
}

.ticket_detail_priority_action {
	display: none;
}

.user_ticket_row {
	display: block;
	margin-bottom: 8px;
	border: 1px solid $border-color;
	color: #222;
	border-radius: 8px;
	padding: 10px 15px;
}

.user_ticket_row:hover {
	background-color: $gray-100;
}

a[href].user_ticket_row:hover {
	color: #222;
}

.ticket_detail_delete_notif {
	color: $danger;

	a {
		display: none;
	}

	&:hover a {
		display: inline;
	}
}
.edit_ticket_template_lexical {
	display: flex;
	overflow-y: auto;
	flex-direction: column;
	flex: 1;
	position: relative;
	height: 220px;

	.lexical_editor_tabs_wrapper {
		min-height: 42px;
		flex: 1;
		overflow: hidden;

		.lexical_editor_toolbar {
			display: none;
		}

		.lexical_editor_input {
			padding-left: 10px;
			padding-right: 0;
			max-height: unset;
			height: 100%;
		}

		.lexical_editor_wrapper {
			height: 100%;
		}

		.lexical_editor_placeholder {
			left: 10px;
		}
	}
}

.resource_members_box {
	border: 1px solid $border-color;
	border-radius: 8px;
	overflow: hidden;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.resource_members_box_header {
	flex-shrink: 0;
	padding: 10px;
	background-color: whitesmoke;
	border-bottom: 1px solid $border-color;
}
.resource_members_box_footer {
	flex-shrink: 0;
	padding: 6px 10px;
	border-top: 1px solid $border-color;
}

.resource_members_box_content {
	flex: 1;
	overflow-y: auto;
	overflow-x: hidden;
}

.resource_members_table_row {
	display: flex;
	align-items: center;
	overflow: hidden;
	padding: 8px 10px;
	border-bottom: 1px solid $border-color;
}

.resource_members_table_row_emoji_wrapper {
	width: 32px;
	height: 32px;

	.emoji {
		width: 100%;
		height: 100%;
	}
}

.ticket_list_page .order_list_row.order_list_row__closed {
	filter: none;
}

.ticket_detail_custom_attr_row {
	display: flex;
	font-size: 14px;
	padding-top: 5px;
	padding-bottom: 5px;
	border-bottom: 1px solid $border-color;
	overflow: hidden;

	&:last-child {
		border-bottom: none;
	}
}

.edit_sla_target_box {
	border: 1px solid $border-color;
	border-radius: 8px;
	overflow: hidden;
}

.edit_sla_target_box_row {
	padding: 8px 0;
	display: flex;
	font-size: 14px;
	border-bottom: 1px solid $border-color;
	&.header {
		background-color: $gray-100;
		font-weight: 500;
		color: $gray-600;
	}

	&:last-child {
		border-bottom: none;
	}
}

.edit_sla_target_box_cell {
	flex: 1;
	padding: 0 10px;
	display: flex;
	align-items: center;

	&.priority {
		flex: unset;
		width: 100px;
		flex-shrink: 0;
	}
}

.comment_editor_wrapper {
	border: 1px solid #cacaca;
	border-radius: 0.25rem;
	position: relative;
}

.comment_prose_editor {
	.ProseMirror {
		outline: none;
		padding: 5px 10px;
		min-height: var(--editor-height);
		.ProseMirror-widget {
			opacity: 0.5;
		}
	}

	p {
		margin: 0;
	}
}
