// stylelint-disable selector-no-qualifying-type

// Make the div behave like a button
.btn__switch {
	position: relative;
	display: inline-flex;
	vertical-align: middle; // match .btn alignment given font-size hack above

	> .btn {
		-webkit-box-shadow: inset 0 2px 3px rgb(0 0 0 / 30%);
		box-shadow: inset 0 2px 3px rgb(0 0 0 / 30%);
		padding: 0.1rem 0.5rem;
		font-size: 14px;
		border-radius: 4px;

		position: relative;
		flex: 1 1 auto;

		// Bring the hover, focused, and "active" buttons to the front to overlay
		// the borders properly
		&:hover,
		&:focus,
		&:active,
		&.active {
			z-index: 1;
		}
	}

	> .btn.btn__outline_primary {
		-webkit-box-shadow: none;
		box-shadow: none;
		&:hover {
			background: $white;
			color: $primary;
		}
	}

	> .btn.btn__outline_secondary {
		-webkit-box-shadow: none;
		box-shadow: none;
		&:hover {
			background: $white;
			color: $black;
		}
	}
}

.btn__switch {
	// Prevent double borders when buttons are next to each other
	> .btn:not(:first-child),
	> .btn__switch:not(:first-child) {
		margin-left: -$btn-border-width;
	}

	// Reset rounded corners
	> .btn:not(:last-child):not(.dropdown__toggle),
	> .btn__switch:not(:last-child) > .btn {
		@include border-right-radius(0);
	}

	> .btn:not(:first-child),
	> .btn__switch:not(:first-child) > .btn {
		@include border-left-radius(0);
	}
}

// Sizing
//
// Remix the default button sizing classes into new ones for easier manipulation.

.btn__switch_sm > .btn {
	@extend .btn__sm;
}
.btn__switch_lg > .btn {
	@extend .btn__lg;
}

.btn__switch.btn__switch__light {
	.btn {
		border: 0;
		border-radius: 2px;
		-webkit-box-shadow: none; // inset 0 2px 3px rgb(0 0 0 / 30%);
		box-shadow: none; //inset 0 2px 3px rgb(0 0 0 / 30%);
		border: 1px solid transparent;

		&:hover {
			border: 1px solid #e9ecef;
		}
	}
}

.btn__switch.btn__switch__white.btn__switch__xs {
	background-color: whitesmoke;

	&.btn__switch__xs {
		border-radius: $border-radius-xs;

		.btn:not(:last-child):not(.dropdown__toggle) {
			border-top-left-radius: $border-radius-xs;
			border-bottom-left-radius: $border-radius-xs;
		}

		.btn:not(:first-child):not(.dropdown__toggle) {
			border-top-right-radius: $border-radius-xs;
			border-bottom-right-radius: $border-radius-xs;
		}
	}

	.btn {
		box-shadow: none;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 1px;

		&:last-child {
			margin-right: 0;
		}
	}

	.btn__white {
		pointer-events: none;
		box-shadow: rgba(0, 0, 0, 0.09) 0px 1px 4px;
		cursor: auto;
		&:hover,
		&:focus,
		&:active {
			background-color: $white;
		}
	}
}
