$tooltip-bg: $gray-900;

.tooltip {
	//pointer-events: none;
	max-width: 400px;

	display: block !important;
	z-index: 10000;

	.tooltip-inner {
		background: $tooltip-bg;
		color: white;
		border-radius: 6px;
		padding: 4px 10px;
		font-size: 14px;
		font-weight: 500;
	}

	.tooltip-arrow {
		width: 0;
		height: 0;
		border-style: solid;
		position: absolute;
		margin: 5px;
		border-color: $tooltip-bg;
	}

	&[x-placement^='top'] {
		margin-bottom: 5px;

		.tooltip-arrow {
			border-width: 5px 5px 0 5px;
			border-left-color: transparent !important;
			border-right-color: transparent !important;
			border-bottom-color: transparent !important;
			bottom: -5px;
			left: calc(50% - 5px);
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^='bottom'] {
		margin-top: 5px;

		.tooltip-arrow {
			border-width: 0 5px 5px 5px;
			border-left-color: transparent !important;
			border-right-color: transparent !important;
			border-top-color: transparent !important;
			top: -5px;
			left: calc(50% - 5px);
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	&[x-placement^='right'] {
		margin-left: 5px;

		.tooltip-arrow {
			border-width: 5px 5px 5px 0;
			border-left-color: transparent !important;
			border-top-color: transparent !important;
			border-bottom-color: transparent !important;
			left: -5px;
			top: calc(50% - 5px);
			margin-left: 0;
			margin-right: 0;
		}
	}

	&[x-placement^='left'] {
		margin-right: 5px;

		.tooltip-arrow {
			border-width: 5px 0 5px 5px;
			border-top-color: transparent !important;
			border-right-color: transparent !important;
			border-bottom-color: transparent !important;
			right: -5px;
			top: calc(50% - 5px);
			margin-left: 0;
			margin-right: 0;
		}
	}

	&[aria-hidden='true'] {
		visibility: hidden;
		opacity: 0;
		transition: opacity 0.02s linear 0.2s, visibility 0.02s linear 0.2s;
	}

	&[aria-hidden='false'] {
		visibility: visible;
		opacity: 1;
		transition: opacity 0.02s linear 0.2s, visibility 0.02s linear 0.2s;
	}
}
