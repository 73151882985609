::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background: #fafafa;
}

::-webkit-scrollbar-track {
	border-radius: 0px;
}

::-webkit-scrollbar-thumb {
	border-radius: 3px;
	background: #ddd;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #bbb;
}
