.task_row {
	height: 28px;
}

.task_general_info_label {
	width: 90px;
	white-space: nowrap;
	overflow: hidden;
	margin-right: 10px;
	text-overflow: ellipsis;
	color: $gray-600;
	flex-shrink: 0;
}

.task_form_field {
	margin-bottom: 5px;
	display: flex;
	align-items: center;
	height: 28px;

	&:last-child {
		margin-bottom: 0;
	}
}

.task_form_label {
	width: 150px;
	padding-right: 10px;
	flex-shrink: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	color: $gray-600;
}

.task_form_field_edit {
	width: 180px;
	height: 30px;
	border: none;
	outline: none;
	background: #f3f3f3;
	padding: 5px 2px 2px 8px;
	border-radius: 4px;
	&:hover,
	&:focus,
	&:active {
		background: #e8e8e8;
	}

	&:hover {
		color: #0d6efd;
	}
}

.task_footer {
	display: flex;
	flex-shrink: 0;
	justify-content: flex-end;
	font-weight: 500;
	align-items: center;
}

.tasks__row.task__row__done {
	background: whitesmoke;
	// border: 1px solid transparent;
	&:hover {
		background: #ebebeb;
		// border: 1px solid transparent;
	}
}

.tasks__row {
	cursor: pointer;
	display: flex;
	width: 100%;
	flex-shrink: 0;
	flex-direction: row;
	// border-bottom: 1px solid #f0f0f0;
	// border: 1px solid #e9e9e9;
	//align-items: center;
	// margin-top: 5px;
	padding: 7px 12px 7px 20px;
	background: white;
	// border-radius: 10px;
	&:hover {
		background: #0d6efd12;
		// border: 1px solid #99c9ff;
	}
}

.tasks__row.tasks__row__active {
	background: #ddeafd;
	&:hover {
		background: #cfe0fa;
	}
}

.task_pinned_icon {
	opacity: 0.7;
	margin-right: 10px;
	cursor: pointer;

	&:hover {
		opacity: 1;
	}
}

.task_pinned_icon.task_pinned_icon__hidden {
	opacity: 0.5;
	&:hover {
		opacity: 1;
		color: $gray-700;
	}
}

.tasks__row:hover {
	.task_pinned_icon.task_pinned_icon__hidden {
		//visibility: visible;
		//opacity: 0.7;
	}
}

.task_footer:hover .pinned_icon {
	visibility: visible;
	// display: block;
}

.task_not_seen {
	font-weight: 600;
}

.task_input_title {
	border-radius: 4px;
	border: none;
	background: #f8f8f8;
	font-size: 18px;
	font-weight: 500;
	padding: 5px 10px;
	outline: none;
	&:hover {
		background: #f1f1f1;
	}
}

.task_modal_header {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 55px;
}

.task_modal_footer {
	height: 48px;
	display: flex;
	border-top: 1px solid #f1f1f1;
	align-items: center;
}

.task_checkbox {
	display: flex;
	flex-shrink: 0;
	align-items: center;
	justify-content: center;

	width: 24px;
	height: 24px;
	border-radius: 6px;
	border: 2px solid #00000055;
	background: white;
	cursor: pointer;

	&:hover {
		border: 2px solid #00000099;
	}

	&[disabled] {
		opacity: 0.5;
		cursor: default;

		&:hover {
			border: 2px solid #00000055;
		}
	}
}

.task_checkbox.task_checkbox__done {
	color: $success;
	border: 2px solid $success;
	&:hover {
		border: 2px solid #00000099;
	}
}

.task_form_title {
	font-size: 18px;
	font-weight: 500;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	padding: 6px 10px;
	margin-left: -10px;
}

.task_form_title_input.form-control {
	font-size: 18px;
	margin-left: -10px;
}

.task_modal_content {
	flex: 1;
	overflow: hidden;
	background: #f5f5f5;
	flex-shrink: 0;
	padding: 0px 0 0px 0px;
}

.task_activities {
	padding: 10px 20px 0px 20px;
	border-top: 1px solid $border-color;
}

.task_activities_inner {
	overflow-y: auto;
	margin-right: -20px;
	padding-right: 10px;
	padding-top: 10px;
	flex: 1;
}

.message_editor.message_editor__plain.task_message_editor {
	border-radius: 10px;
	.message_editor__actions {
		position: absolute;
		bottom: -3px;
	}

	.message_editor__input {
		font-family: unset;
		font-size: unset;
		width: 100%;
		overflow: auto;

		padding-left: 10px;
		padding-right: 60px;
		padding-top: 6px;
		padding-bottom: 6px;
		border-radius: 20px;
		background-color: #f4f4f4;
		&:hover {
			background-color: #eee;
		}
		.ProseMirror {
			outline: none;
			min-height: 30px;
			.ProseMirror-widget{
				opacity: 0.5;
			}
		}
	}
}

.task_history__entry {
	display: flex;
}

.task_history__entry__logo {
	width: 30px;
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	align-items: center;
	position: relative;
	margin-right: 10px;
}

.task_history__vline {
	position: absolute;
	left: 14px;
	top: 38px;
	bottom: 10px;
	width: 1px;
	border-left: 1px solid #c6c6c6;
	z-index: 1;
}

.task_history__entry__logo_img {
	z-index: 2;
	position: relative;
	margin-top: 0;
}

.task_history_entry__body {
	flex: 1;
	overflow: hidden;
	margin-bottom: 10px;
}

.task_list_header {
	display: flex;
	border-bottom: 1px solid #e6e6e6;
	position: relative;
	align-items: center;
	margin-bottom: 0;
	padding-right: 20px;

	.tab {
		margin-bottom: 0;
		border-bottom: none;
		flex: 1;
	}
	.tab__item {
		padding-top: 14px;
	}
}

.instant_noti__noti.in_list_task_noti {
	padding-left: 20px;
	padding-right: 12px;
	padding-top: 8px;
	padding-bottom: 6px;
	overflow: hidden;
	border-bottom: 1px solid #f0f0f0;
	align-items: flex-start;
	&:hover {
		background: #f8f8f8;
	}
}

.in_list_task_noti {
	.instant_noti__noti_content {
		-webkit-line-clamp: 2; // because in this list, line is very long
	}

	.instant_noti__noti_body {
		font-size: 16px;
		line-height: 1.3;
	}
	.instant_noti__time {
		font-size: 14px;
		color: #00000077;
	}
}

.instant_noti__noti.in_list_task_noti.in_list_task_noti__active {
	background: #ddeafd;
	&:hover {
		background: #cfe0fa;
	}
}

.task_noti___body {
	overflow: auto;
	padding: 0 0;
	flex: 1;
}

.task_detail_header {
	padding: 8px 20px;
	border-bottom: 1px solid $border-color;
	display: flex;
	align-items: center;
	flex-shrink: 0;
}

.task_detail_content_inner {
	max-width: 420px;
	margin: 0 auto;
}

.task_detail_footer {
	flex-shrink: 0;
}

.task_detail_footer_inner {
	max-width: 420px;
	margin: 0 auto;
	padding: 10px 20px;
	border-top: 1px solid $border-color;
	flex-shrink: 0;
}

.task_detail_main_fields {
	padding: 10px 20px;
}

.task_detail_sub_fields {
	padding: 10px 20px;
	border-top: 1px solid $border-color;
	display: flex;
	justify-content: space-between;
}

.task_detail_content_wrapper {
	overflow-y: auto;
	overflow-x: hidden;
	flex: 1;
}

.task_create_form {
	.task_detail_content_inner {
		max-width: 420px;
	}
	.task_detail_footer_inner {
		max-width: 420px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}

.task_title_input {
	margin-left: -5px;
	margin-right: -5px;
	margin-top: -2px;
	padding: 2px 5px;
	font-size: 18px;
	font-weight: 600;
	outline: none;
	border-radius: 4px;
	resize: none;
	border: none;
	background: transparent;
	display: block;
	width: calc(100% + 10px);
	overflow: hidden;
	overflow-wrap: break-word;
	margin-bottom: 8px;

	&:focus {
		box-shadow: 0 0 0 1px $input-border-color;
		&[readonly] {
			box-shadow: none;
		}
		//background-color: #eee;
	}

	&.no_border:focus {
		box-shadow: none;
	}
}

.task__modal.task__modal__hide {
	display: none;
}

.task__modal_close {
	position: absolute;
	top: 0;
	left: -50px;
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 110;
	border-radius: 8px;
	box-shadow:
		0px 1px 8px rgb(0 0 0 / 10%),
		0px 4px 32px rgb(0 0 0 / 10%),
		0px 8px 64px rgb(0 0 0 / 12%);

	background: $white;
	border: 1px solid #c6c6c6;
	color: $gray-500;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:hover {
		color: $gray-600;
	}

	// still close task modal when click outside x
	&:after {
		content: '';
		position: absolute;
		inset: -10px;
		background-color: transparent;
	}
}

.task__modal_inner {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	border-radius: 8px;
	overflow: hidden;
}

.task__modal {
	display: flex;
	height: 650px;
	width: 750px;

	cursor: default;
	flex-direction: column;
	position: absolute;
	background: white;

	//overflow: hidden;
	border-radius: 8px;
	// zindex modal over quickview
	z-index: 210;
	box-shadow:
		0px 1px 8px rgb(0 0 0 / 10%),
		0px 4px 32px rgb(0 0 0 / 10%),
		0px 8px 64px rgb(0 0 0 / 12%);
	border: 1px solid #c6c6c6;

	height: 500px;
	right: 20px;
	top: 60px;

	// &:after {
	// 	content: '';
	// 	position: absolute;
	// 	top: -18px;
	// 	right: 199px;
	// 	border: 9px solid transparent;
	// 	border-bottom-color: #c6c6c6;
	// 	width: 0;
	// 	height: 0;
	// }

	// &:before {
	// 	z-index: 1;
	// 	content: '';
	// 	position: absolute;
	// 	top: -16px;
	// 	right: 200px;
	// 	border: 8px solid transparent;
	// 	border-bottom-color: $white;
	// 	width: 0;
	// 	height: 0;
	// }
}

.message_text.message_text__agent.task_note_text_meessage {
	margin: 0;
	padding: 0;
	font-size: 14px;

	// work for hyperlink delta
	.avatar {
		width: 14px !important;
		height: 14px !important;
	}

	&.has_overflow {
		width: 100%;
		overflow: hidden;
		max-height: 105px; // 4 lines is 84 + 1 like for show more text is 105

		.view_more {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			padding: 0;
			cursor: pointer;
			font-size: 14px;
			color: $gray-600;
			bottom: 0;
			background-color: $white;

			&:hover {
				color: $gray-700;
			}
		}
		.view_more:before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			height: 24px;
			bottom: 20px;
			background: linear-gradient(rgba(255, 255, 255, 0.5), #fff);
			width: 100%;
		}
	}
}

.message_editor.message_editor__plain.task_note_editor {
	margin-left: -5px;
	margin-right: -5px;
	margin-top: -2px;
	margin-bottom: -2px;
	padding: 2px 5px;
	border-radius: 4px;
	background-color: $white;
	box-shadow: 0 0 0 1px $input-border-color;

	&.edit_mode {
		margin: 0;
	}

	&.no_border {
		box-shadow: none;
		border: none;
	}
}

.task_detail_attachment {
	width: 80px;
	height: 80px;
	border-radius: 8px;
	margin-right: 15px;
	margin-bottom: 15px;
	position: relative;
	object-fit: cover;
	border: 1px solid $border-color;
	display: flex;
	align-items: center;
	justify-content: center;

	&.add_attachment {
		border: 2px solid $border-color;
		color: $border-color;
		cursor: pointer;

		&:hover {
			border-color: $gray-500;
			color: $gray-500;
		}
	}

	&.error {
		border-color: $danger;
		color: $danger;

		.task_detail_attachment_x {
			border-color: $danger;
			color: $danger;

			&:hover {
				color: $red-700;
			}
		}
	}
}

.task_detail_attachment_x {
	width: 20px;
	height: 20px;
	border-radius: 9999px;
	background-color: $white;
	color: $gray-500;
	cursor: pointer;
	position: absolute;
	top: -8px;
	right: -8px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid $border-color;

	&:hover {
		color: $gray-600;
	}
}

.tasks__row.recent_seen_task {
	background: #e6f0ff;
}

.user_task_row {
	margin-bottom: 8px;
	display: flex;
	align-items: center;

	&:last-child {
		margin-bottom: 0;
	}
}

.noti_task_expired_item {
	flex-direction: column;
	justify-content: space-between;
	position: absolute;
	width: 100%;
	height: 95px;
	background: #ffffff;
	box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
	border-radius: 12px;
	padding: 15px;
}

.option_delay_task {
	width: 105px;
	height: 24px;
	background: #d9d9d9;
	border-radius: 3px;
	justify-content: space-between;
	align-items: center;

	&:hover {
		background: #cfcfcf;
	}
}

.task_list_filters_container {
	display: flex;
	flex-wrap: wrap;
	flex: 1;
}

.task_list_actions_container {
	padding: 10px 20px;
	padding-bottom: 0;
	border-bottom: 1px solid #e6e6e6;
	display: flex;
	align-items: flex-start;
}

.task_due_status_badge {
	display: inline-flex;
	align-items: center;
	position: relative;
	border-radius: 4px;
	font-size: 14px;
	background-color: transparent;

	&.overdue {
		padding: 2px 6px;
		background-color: $danger;
		color: $white;
		font-size: 12px;
	}

	&.due_today {
		padding: 2px 6px;
		background-color: #fff3cd;
		color: $gray-700;
		font-size: 12px;
	}
}

.contain_task_message {
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	padding-right: 50px;
	padding-left: 50px;
}

.task_message {
	display: flex;
	opacity: 1;
	align-items: center;
	border-radius: 12px;
	background-color: #00000009;
	padding: 5px 10px;
	color: #000000;
	flex-direction: row;
}

.text__muted.task_message:hover {
	background-color: #00000018;
}

.icon_status_task_message {
	display: flex;
	border: 2px solid $success;
	border-radius: 6px;
	width: 20px;
	height: 20px;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
}

.icon_status_task_message.reopen {
	border: 2px solid #00000055;
}

.done_task_icon {
	display: block;
	color: $success;
}

.reopen_task_icon {
	color: #00000055;
	display: block;
}

// override messagfe image attachmests cs
.task_history_entry__body {
	.message_container.message_container__agent.message_container__image {
		display: grid;
		width: 100%;
		padding-left: 0;
		padding-right: 0;
		grid-gap: 2px;

		&.grid_columns_1 {
			grid-template-columns: repeat(1, 1fr);
		}

		&.grid_columns_2 {
			grid-template-columns: repeat(2, 1fr);
		}

		&.grid_columns_3 {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.grid_columns_3,
	.grid_columns_2 {
		.message_image {
			position: relative;
			padding-top: 100%;
			margin-top: 0;

			.message_image__img {
				position: absolute !important;
				inset: 0;
				max-height: unset;
				border-radius: 4px;
				min-width: unset;
        width: 100%;
			}
		}
	}
}

.task_note_editor {
	&.lexical_editor_tabs_wrapper {
		margin-left: -8px;
		min-height: 42px;
		margin-bottom: 10px;
	}

	.lexical_editor_wrapper {
		border: none;
	}
	.lexical_editor_input {
		font-size: 14px;
	}

	.lexical_editor_toolbar {
		display: none;
	}

	.message_editor__input {
		width: 100%;
		overflow: auto;

		padding-left: 10px;
		padding-right: 60px;
		padding-top: 6px;
		padding-bottom: 6px;

		.ProseMirror {
			outline: none;
			min-height: 30px;

			p {
				margin-bottom: 6px;
			}
			.ProseMirror-widget{
				opacity: 0.5;
			}
		}
	}
}
