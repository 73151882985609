.segment_container {
	padding-top: $spacer * 2;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.segment_header {
	justify-content: space-between;
	width: 100%;
}

.segment_content {
	width: 100%;
	flex-direction: row;
	flex: 1 1 0%;
	overflow: hidden;
}

.folder_plus_icon {
	color: white;
}

.create_segment_header {
	padding: 10px 25px;
	font-size: 20px;
	font-weight: 500;
}

.create_segment_body {
	padding: 25px 0;
	flex-direction: column;
}

.create_segment_footer {
	justify-content: flex-end;
	padding: 0 25px;
}

.segment_type_option {
	display: flex;
	flex-direction: row;
	width: 270px;
	background: #ffffff;
	border: 1px solid $border-color;
	border-radius: 9px;
	padding: 10px 10px 10px 20px;
	cursor: pointer;

	&.active {
		border-color: $primary;
		box-shadow: 0 0 0 1px $primary;
	}

}

.segment_name {
	&:hover {
		cursor: pointer;
	}
}
