.audio_player_wrapper {
	display: inline-flex;
	align-items: center;
}

.audio_player_graphs {
	display: flex;
	align-items: center;
	position: relative;
	margin-left: 15px;
	height: 30px;
}

.audio_player_btn {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	color: $white;
	border: 3px solid $white;
	cursor: pointer;

	// work around for tabler icon
	.icon-tabler-player-pause {
		rect {
			fill: currentColor;
		}
	}

	.icon-tabler-player-play {
		path:last-child {
			fill: currentColor;
		}
	}
}

.audio_player_graph_wrapper {
	padding: 0 2px;
	height: 100%;
	display: flex;
	align-items: center;
	cursor: pointer;

	&.active .audio_player_graph {
		background-color: $white;
	}

	&.active .audio_player_graph.highlight {
		background-color: $green;
	}
}

.audio_player_graph {
	width: 2px;
	border-radius: 6px;
	background-color: $gray-500;
}

.audio_player_graph.highlight {
	width: 2px;
	border-radius: 9999px;
	background-color: $green-700;
}

.audio_player_wrapper.simple {
	min-width: 95px;
	padding: 3px 6px;
	padding-right: 12px;
	border-radius: 9999px;
	background-color: $gray-200;
	cursor: pointer;

	.audio_player_btn {
		width: 20px;
		height: 20px;
		border: none;
		background-color: #353b49;
		color: $white;
	}
}

.audio_player_wrapper.simple.active {
	color: $white;
	background-color: $primary;

	.audio_player_btn {
		background-color: $white;
		color: $primary;
	}
}

.global_audio_card {
	position: fixed;
	z-index: 1000;
	background-color: #33395e;
	color: $white;

	right: 40px;
	bottom: 40px;
	border-radius: 8px;
	box-shadow: 0px 1px 8px rgb(0 0 0 / 10%), 0px 4px 32px rgb(0 0 0 / 10%), 0px 8px 64px rgb(0 0 0 / 12%);
	display: none;
	align-items: center;
	padding: 15px;

	&.active {
		display: flex;
	}
}

.global_audio_card_header {
	display: flex;
	font-size: 18px;
	padding: 15px;
}

.global_audio_card_x_button {
	background-color: $white;
	color: $gray-700;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	position: absolute;
	top: -28px;
	right: 0;
	padding: 3px 10px;
	box-shadow: 0px 1px 8px rgb(0 0 0 / 10%), 0px 4px 32px rgb(0 0 0 / 10%), 0px 8px 64px rgb(0 0 0 / 12%);
	background-color: #33395e;
	color: $white;

	&:hover {
		background-color: $gray-700;
	}
}

.global_audio_rails {
	width: 100%;
	height: 5px;
	position: relative;
	background-color: rgba($white, 0.7);
	cursor: pointer;
}

.global_audio_progress {
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: $white;
}

.global_audio_actions {
	flex-shrink: 0;
	display: flex;
	align-items: center;
}

.global_audio_time {
	flex-shrink: 0;
	font-size: 13px;
	margin-left: $spacer;
}

.global_audio_actions .icon-tabler-player-play path:last-child {
	fill: currentColor;
}
.global_audio_actions .icon-tabler-player-pause rect {
	fill: currentColor;
}

.global_audio_slider {
	width: 100%;
	-webkit-appearance: none; /* Override default CSS styles */
	appearance: none;
	height: 4px;
	background-color: whitesmoke;
	outline: none;
	cursor: pointer;
	border-radius: 10px;
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.global_audio_slider::-webkit-slider-thumb {
	-webkit-appearance: none; /* Override default look */
	appearance: none;
	width: 0px; /* Set a specific slider handle width */
	height: 0px; /* Slider handle height */
	background: $white; /* Green background */
	cursor: pointer; /* Cursor on hover */
}

.global_audio_slider::-moz-range-thumb {
	width: 0px; /* Set a specific slider handle width */
	height: 0px; /* Slider handle height */
	background: $white; /* Green background */
	cursor: pointer; /* Cursor on hover */
}

.global_audio_slider_wrapper {
	display: flex;
	align-items: center;
	width: 140px;
}

.global_audio_slider_wrapper:hover .global_audio_slider {
	height: 5px;
}

.global_audio_slider_wrapper:hover .global_audio_slider::-webkit-slider-thumb {
	width: 10px; /* Set a specific slider handle width */
	height: 10px; /* Slider handle height */
	border-radius: 9999px;
}

.global_audio_slider_wrapper:hover .global_audio_slider::-moz-range-thumb {
	width: 10px; /* Set a specific slider handle width */
	height: 10px; /* Slider handle height */
	border-radius: 9999px;
}
