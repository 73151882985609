.automation_action_box_wrapper {
	width: 500px;
	margin-bottom: 20px;
	position: relative;

	&:last-child {
		margin-bottom: 0;
	}

	&:hover .automation_action_box_drag_inner {
		display: flex;
	}
}

.automation_action_box_drag_section {
	position: absolute;
	width: 30px;
	height: 100%;
	justify-content: center;
	color: $gray-500;
	top: 0;
	right: 100%;
}

.automation_action_box_drag_inner {
	display: none;
	width: 30px;
	height: 40px;
	justify-content: center;
	padding-top: 4px;
	cursor: grab;
}

.automation_action_box {
	width: fit-content;
	background-color: #00000010;
	border-radius: 4px;
	padding: 5px 10px;
	cursor: pointer;

	&.no_hover {
		cursor: auto;
		&:hover {
			background-color: #00000010;
		}
	}

	&:hover {
		//box-shadow: inset 0 0 0 1px $border-color;
		background-color: #00000018;
	}

	&.edit {
		background-color: #f1f8ff;
		box-shadow: inset 0 0 0 2px $primary;

		&.static_width {
			width: 100%;
		}
	}
}

.automation_action_message_box {
	max-width: 400px;
	min-width: 200px;
	min-height: 54px; // 2 line of line height 24px
	max-height: 102px; //4 line
	overflow: hidden;
	border-radius: 4px;
	border: 1px solid #cecece;
	margin-bottom: 4px;
	padding: 3px 8px;
}

.tab.automation_detail_tab {
	margin-bottom: 0;
	display: inline-flex;
	height: 51px;
	border-bottom-color: transparent;

	.tab__item:not(.tab__item__active):after {
		border-bottom-color: transparent;
	}

	.tab__item {
		display: flex;
		align-items: center;
		height: 100%;
	}
}

.automation_report_box_sub_head {
	width: 180px;
	padding: 0 15px;
	border-right: 1px solid $border-color;

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		border-right: none;
	}
}

.item-row.workflow_row {
	&:hover {
		background: whitesmoke;
	}
}

.item-row.workflow_row.workflow_row__disabled {
	filter: grayscale(1);
	opacity: 0.8;
}

.automation_action_sidebar_head {
	padding: 12px 20px;
	border-bottom: 1px solid $border-color;
	flex-shrink: 0;
}

.automation_action_sidebar_body {
	flex: 1;
	padding: 12px 20px;
	overflow-x: hidden;
	overflow-y: auto;
}

.automation_template_card {
	width: 320px;
	height: 280px;
	display: flex;
	flex-direction: column;
	margin-right: 30px;
	margin-bottom: 30px;
	overflow: hidden;
	cursor: pointer;

	&:hover {
		box-shadow: 0 0 0 1px $border-color;
	}

	&.small {
		width: 240px;
		height: 240px;
		margin-right: 20px;
		margin-bottom: 20px;

		.automation_template_card_name {
			font-size: 16px;
		}

		.automation_template_card_content {
			padding: 15px 20px;
		}

		&:nth-child(3n) {
			margin-right: 0;
		}
	}
}

.automation_template_card_img {
	width: 100%;
	height: 180px;
	overflow: hidden;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.automation_template_card.small .automation_template_card_img {
	height: 150px;
}

.automation_template_card_content {
	padding: 20px 25px;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.automation_template_card_name {
	font-weight: 500;
	font-size: 20px;
	margin-bottom: 5px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre-wrap;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.automation_template_card_desc {
	@extend .text__muted;
	font-size: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre-wrap;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.automation_condition_input {
	display: flex;
	align-items: center;
	position: relative;

	&.order_filter_input {
		padding-right: 30px;
	}
}

.automation_condition_x {
	display: none;
	position: absolute;
	width: 18px;
	height: 18px;
	top: calc(50% - 9px);
	right: 5px;
}

.automation_condition_input:hover .automation_condition_x {
	display: block;
}

.automation_action_box .x-icon {
	width: 18px;
	height: 18px;
}

.automation_action_box:not(:hover) .x-icon {
	visibility: hidden;
}

.automation_edit_section {
	display: flex;
	width: 100%;

	&:last-child {
		.automation_edit_section_left {
			padding-bottom: 0;
		}

		.automation_edit_section_right {
			padding-bottom: 0;
		}
	}
}

.automation_edit_section_left {
	width: 200px;
	flex-shrink: 0;
	padding-top: 5px;
	padding-bottom: 30px;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 35px;
		left: 8px;
		bottom: 0;
		width: 1px;
		background-color: $gray-200;
	}
}

.automation_edit_section_right {
	flex: 1;
	overflow: hidden;
	padding-bottom: 30px;
	padding-top: 5px;
	padding-left: 30px; // position for grab dragdrop icon in action
}

// set max height for text editor in edit mode
.automation_send_message_editor .bot-textarea .ProseMirror[contenteditable] {
	max-height: 100px;
}

.automation_session_table {
	.scroll-table tr th:first-child,
	.scroll-table tr td:first-child {
		padding-left: 20px;
	}

	.scroll-table tr th:last-child,
	.scroll-table tr td:last-child {
		padding-right: 20px;
	}
}

.dropdown_item.workflow_add_action_dropdown_item.active {
	background-color: whitesmoke;
}
.dropdown_item.workflow_add_action_dropdown_item .dropdown_item_text {
	font-weight: 500;
}

.automation_action_box_wrapper.just_focus .automation_action_box {
	background-color: #f1f8ff;
}

.ai_agent_table_row_desc {
	@extend .text__muted;
	font-size: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

.ai_agent_intent_box_line {
	border-bottom: 1px solid $border-color;

	&:last-child {
		border-bottom: none;
	}
}

.ai_agent_intent_box_head {
	padding: 8px 15px;
	display: flex;
	align-items: center;

	&:hover .ai_agent_intent_box_head_text {
		text-decoration: underline;
	}
}

.ai_agent_intent_box_head_text {
	@extend .text__truncate;
	@extend .text__semibold;
	flex: 1;
}

.ai_agent_intent_box_content {
	padding: 8px 15px;
}

.ai_agent_intent_box_example {
	border: 1px solid $border-color;
	padding: 2px 10px;
	border-radius: 9999px;
	width: fit-content;
	margin-bottom: 5px;

	&:last-child {
		margin-bottom: 0;
	}
}

.ai_agent_modal_tab.tab {
	margin-bottom: -15px;
	border-bottom-color: transparent;

	.tab__item {
		font-size: 16px;
	}

	.tab__item:not(.tab__item__active):after {
		border-bottom-color: transparent;
	}
}

.ai_preview_section {
	flex: 1;
	overflow: hidden;
}

.ai_preview_section div#fakechatbox1 {
	height: 100% !important;
	display: block !important;
}

.ai_preview_section #fakechatbox1 .desktop {
	height: 100% !important;
}

.ai_preview_section #fakechatbox1 .widget-container.widget-container--full {
	border-radius: 0 !important;
	box-shadow: none !important;
	background: whitesmoke !important;
}

.ai_preview_section #fakechatbox1 .widget-header {
	display: none !important;
}

.ai_preview_section #fakechatbox1 .widget-body--content {
	background: whitesmoke !important;
	padding-bottom: 20px !important;

	.poweredby {
		display: none !important;
	}
}

.ai_preview_section #fakechatbox1 .message-input {
	border: 1px solid #e3e3e3 !important;
	border-radius: 8px !important;
	background: white !important;
}

.edit_entry_modal_table {
	.sbz_table_cell {
		padding: 8px;

		&:first-child {
			padding-left: 10px;
		}

		&:last-child {
			padding-right: 10px;
		}
	}

	.sbz_common_table_footer {
		padding: 8px;
		font-size: 14px;
	}
}
