$editor-width: 800px !default;

.prose_email_editor {
	position: relative;
	border: 1px solid $border-color;
	background: $white;

	.ProseMirror {
		word-wrap: break-word;
		white-space: pre-wrap;
		white-space: break-spaces;
		-webkit-font-variant-ligatures: none;
		font-variant-ligatures: none;
		padding: 4px 8px 4px 14px;
		line-height: 1.2;
		outline: none;
		min-height: 120px;
		max-height: 360px;
		overflow: auto;
	}

	.ProseMirror p {
		margin-bottom: 0;
	}

	.ProseMirror img {
		max-width: 100%;
	}

	.ProseMirror strong,
	.ProseMirror b {
		font-weight: 700;
	}
}

.kb_editor {
	.ProseMirror {
		position: relative;
		word-wrap: break-word;
		white-space: pre-wrap;
		white-space: break-spaces;
		-webkit-font-variant-ligatures: none;
		font-variant-ligatures: none;
		font-feature-settings: 'liga' 0;
		flex: 1;
		padding: 4px 8px;
		line-height: 1.2;
		outline: none;
		height: 100%;
		/* the above doesn't seem to work in Edge */
	}

	.ProseMirror li {
		position: relative;
	}

	.ProseMirror-hideselection *::selection {
		background: transparent;
	}

	.ProseMirror-hideselection *::-moz-selection {
		background: transparent;
	}

	.ProseMirror-hideselection {
		caret-color: transparent;
	}

	/* See https://github.com/ProseMirror/prosemirror/issues/1421#issuecomment-1759320191 */
	.ProseMirror [draggable][contenteditable='false'] {
		user-select: text;
	}

	.ProseMirror-selectednode {
		outline: none;
	}

	/* Make sure li selections wrap around markers */

	li.ProseMirror-selectednode {
		outline: none;
	}

	li.ProseMirror-selectednode:after {
		content: '';
		position: absolute;
		left: -32px;
		right: -2px;
		top: -2px;
		bottom: -2px;
		border: 2px solid #8cf;
		pointer-events: none;
	}

	/* Protect against generic img rules */
	.ProseMirror-gapcursor {
		display: none;
		pointer-events: none;
		position: absolute;
	}

	.ProseMirror-gapcursor:after {
		content: '';
		display: block;
		position: absolute;
		top: -2px;
		width: 20px;
		border-top: 1px solid black;
		animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
	}

	@keyframes ProseMirror-cursor-blink {
		to {
			visibility: hidden;
		}
	}

	.ProseMirror-focused .ProseMirror-gapcursor {
		display: block;
	}

	/* Add space around the hr to make clicking it easier */

	.ProseMirror ul,
	.ProseMirror ol {
		padding-left: 30px;
	}

	.ProseMirror blockquote {
		padding-left: 1em;
		border-left: 3px solid #eee;
		margin-left: 0;
		margin-right: 0;
	}

	.ProseMirror p {
		margin-bottom: 8px;
		word-break: break-word;
		line-height: 1.5;

		code {
			background-color: #f8f8f8;
			border: 1px solid #cacaca;
			padding: 2px 4px;
			border-radius: 4px;
		}
	}

	strong {
		font-weight: 600;
	}

	.ProseMirror table {
		margin: 10px 0;
		border: 1px solid;
	}

	.ProseMirror th {
		min-width: 80px;
		padding: 8px 10px;
		background-color: #f2f2f2;
		border: 1px solid;
	}

	.ProseMirror tr {
		border: 1px solid;
	}

	.ProseMirror td {
		min-width: 80px;
		padding: 8px 10px;
	}

	.ProseMirror img {
		max-width: 100%;
		max-height: 100%;
	}

	.ProseMirror pre {
		padding: 10px 20px;
		white-space: pre-wrap;
		overflow-x: auto;
		border: 1px solid #cacaca;
		background-color: #e9ecef;
	}
}

.kb_page {
	padding: 20px 60px;
	height: 100%;
	display: flex;
	flex-direction: column;
}

.kb_header {
	margin-bottom: 36px;

	.kb_title {
		font-size: 20px;
		font-weight: 600;
	}
}

.kb_list {
	display: flex;
	flex-wrap: wrap;
}

.kb_card_content {
	border-bottom: 1px solid #dee2e6;
	display: flex;
	padding: 10px 0;
	align-items: center;
	transition: all 150ms ease-out;

	&:hover {
		background: whitesmoke;
	}
}

.kb_edit_content {
	padding: 12px 0px;
	width: 500px;
	max-height: 700px;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.kb_edit_body {
	padding: 0 24px;
	flex: 1;
	overflow-y: auto;
}

.kb_edit_footer {
	padding: 0 24px;
	display: flex;
	justify-content: end;
}

.kb_detail_container {
	height: 100%;
	display: flex;

	.kb_detail_sidebar {
		min-width: 300px;
		width: 25%;
		background-color: #f9fafc;
		height: 100%;
		display: flex;
		padding: 8px 0;
		flex-direction: column;
		border-right: 1px solid #e5e5e5;

		.art_sidebar_header {
			padding: 4px 20px;
			border-bottom: 1px solid #e5e5e5;
			background-color: $gray-100;
		}

		.sidebar_article {
			display: flex;
			align-items: center;
			position: relative;
			padding: 0 20px;
			min-height: 30px;
			margin-top: 6px;
		}
	}

	.kb_article {
		flex: 1;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
}

.cate_sidebar_item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 4px 6px;
	border-radius: 4px;

	.cate_sidebar_item_title {
		display: flex;
		align-items: center;
		cursor: pointer;
	}

	.cate_sidebar_item_action {
		visibility: hidden;
		display: flex;
		align-items: center;
	}

	&:hover,
	&:focus {
		background-color: #ececec;

		.cate_sidebar_item_action {
			visibility: visible;
		}
	}
}

.kb_article_header {
	display: flex;
	padding: 10px 16px;
	border: 1px solid #e5e5e5;
}

.article_title {
	padding: 4px 6px;
	cursor: pointer;
	display: flex;
	align-items: center;
	border-radius: 4px;

	.article_sidebar_action {
		display: none;
	}

	&:hover {
		background-color: #ececec;

		.article_sidebar_action {
			display: flex;
			align-items: center;
		}
	}
}

.article_title.active {
	background-color: #0d6efd;
	color: white;
}

.article_action {
	display: flex;
	align-items: center;
}

.input_article_title {
	border: none;
	outline: none;
	padding: 0px 6px;
	transition: border 0.3s ease;
	min-width: 300px;
}

.input_article_title:hover {
	border: 1px solid;
	border-radius: 4px;
}

.input_article_title:focus {
	border: 2px solid;
	border-radius: 4px;
}

.typo_color_dropdown {
	position: absolute;
	top: 30px;
	left: 10px;
	width: 200px;
	padding: 10px 4px;
	border-radius: 8px;
	background-color: #fff;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	max-height: 300px;
	overflow-y: auto;
}

.text_color_item {
	padding: 4px 8px;
	border-radius: 6px;
	cursor: pointer;

	&:hover {
		background-color: #f3f1ec;
	}

	.typo {
		width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
		border: solid 1px #cacaca;
	}
}

.editor_command_menu {
	position: absolute;
	width: 400px;
	padding: 10px 4px;
	border-radius: 8px;
	background-color: #fff;
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1), 0px 4px 32px rgba(0, 0, 0, 0.1), 0px 8px 64px rgba(0, 0, 0, 0.12);
	max-height: 300px;
	overflow-y: auto;

	.item_block_active {
		background-color: #f3f1ec;
	}
}

.upload_image_kb {
	position: absolute;
	top: 50%;
	left: 50%;
	background-color: white;
	border: 1px solid #e5e5e5;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	width: 400px;
	height: 100px;
	border-radius: 8px;
}

.upload_kb_file {
	margin: 12px 10px;
	padding: 4px 0;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	border: 1px solid #e5e5e5;
	cursor: pointer;

	&:hover {
		background-color: #f3f1ec;
	}
}

.table_kb_option {
	position: absolute;
	background-color: white;
	border: 1px solid #e5e5e5;
	border-radius: 4px;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

	&:hover {
		background-color: #f3f1ec;
	}
}

.resize-handle {
	display: none;
	width: 8px;
	height: 40px;
	background-color: #000;
	position: absolute;
	z-index: 10;
	top: 50%;
	transform: translateY(-50%);
	cursor: ew-resize;
	border-radius: 8px;
	border: 0.5px solid white;
}

.resize-handle.left {
	left: 5px;
}

.resize-handle.right {
	right: 5px;
}

.image_kb_menu {
	position: absolute;
	top: 5px;
	right: 5px;
	display: none;
}

.prose_img_wrapper {
	position: relative;
	display: inline-block;
	margin-top: 6px;
	margin-bottom: 6px;
	text-align: center;

	&:hover {
		border-radius: 4px;

		.resize-handle {
			display: block;
		}

		.image_kb_menu {
			display: flex;
		}
	}

	img {
		&:hover {
			outline: 1px solid #8cf;
		}
	}

	.prose_img {
		max-width: 100%;
		max-height: 100%;
	}
}

.bg_loading_img {
	position: absolute;
	inset: 0;
	z-index: 10;
	background-color: rgba(255, 255, 255, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
}

.prose_loading {
	border: 4px solid #f3f3f3;
	border-radius: 50%;
	border-top: 4px solid #3498db;
	width: 20px;
	height: 20px;
	-webkit-animation: spin 2s linear infinite;
	/* Safari */
	animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.kb_editor {
	flex: 1;
	width: $editor-width;
	position: relative;
}

.article_footer {
	margin-top: 10px;
	margin-bottom: 20px;
	width: $editor-width;
	padding: 4px 8px;
}

.article_sidebar_footer {
	height: 50px;
	padding: 0 20px;
	display: flex;
	align-items: center;
	border-top: 1px solid #e5e5e5;
}

.art_sidebar_header_item {
	display: flex;
	align-items: center;
	padding: 8px 10px;
	border-radius: 4px;
	cursor: pointer;

	&:hover {
		background-color: #ececec;
	}
}

.art_sidebar_header_item.active {
	background-color: #0d6efd;
	color: white;
}

.kb_card_name {
	padding-left: 6px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	max-width: 220px;
}

.save_kb {
	display: flex;
	align-items: center;
	margin-left: 8px;
	font-size: 16px !important;
	opacity: 0.7;
}

.topic_page {
	flex: 1;
	padding: 30px 50px;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;

	.topic_table {
		margin-top: 30px;
		flex: 1;
		overflow: auto;

		.topic_row {
			border-bottom: 1px solid #dee2e6;
			display: flex;
			padding: 10px 0;
			align-items: center;
			transition: all 150ms ease-out;

			&:hover {
				background: whitesmoke;
			}
		}
	}
}

.topic_modal {
	padding: 12px 24px;
	width: 350px;
	max-height: 400px;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.kb_workspace {
	width: 100%;
	display: flex;
	align-items: center;
	padding: 6px 12px;
	border-radius: 6px;
	border: 1px solid #cacaca;
	cursor: pointer;
	position: relative;
	background-color: white;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}

.kb_manage {
	border-top: 1px solid #cacaca;
	display: flex;
	align-items: center;

	&:hover {
		background-color: $gray-200;
	}
}

.doc_event_page {
	flex: 1;
	height: 100%;
	padding: 20px 0 0 40px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.doc_event {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 10px 20px;
	height: 100%;
	width: 600px;

	&:hover {
		background-color: $gray-100;
	}

	.content {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.doc_line_update {
	margin-left: 10px;
	height: 100%;
	width: 2px;
	background-color: #cacaca;
	margin-right: 20px;
	position: relative;

	.dot {
		position: absolute;
		height: 8px;
		width: 8px;
		border-radius: 50%;
		background-color: $gray-600;
		top: 50%;
		left: -3px;
	}

	.dot_end {
		position: absolute;
		height: 8px;
		width: 8px;
		border-radius: 50%;
		background-color: $gray-600;
		bottom: -3px;
		left: -3px;
	}

	.dot_start {
		position: absolute;
		height: 8px;
		width: 8px;
		border-radius: 50%;
		background-color: $gray-600;
		top: -3px;
		left: -3px;
	}
}

.article_state {
	border: 1px solid #cacaca;
	border-radius: 4px;
	cursor: pointer;
	padding: 6px 8px;
	display: flex;
	align-items: center;

	.state {
		display: flex;
		align-items: center;
		min-width: 110px;
	}
}

.article_state.disabled {
	color: $gray-500;
	pointer-events: none;
	opacity: $btn-disabled-opacity;
}

.state_article_option {
	position: absolute;
	z-index: 200;
	right: 0;
	top: 100%;
	width: 400px;
	border-radius: 6px;
	background-color: white;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
}

.state_item {
	display: flex;
	padding: 10px 15px;
	cursor: pointer;
	background-color: $white;

	&:hover {
		background-color: $gray-200;
	}
}

.state_item.disabled {
	color: $gray-500;
	pointer-events: none;
	opacity: 0.7;
}

.header_article_item {
	padding: 0 15px;
}

.header_article_line {
	height: 30px;
	width: 1px;
	background-color: $border-color;
}

.history_article_icon {
	display: flex;
	align-items: center;
	padding: 5px;
	border-radius: 4px;
	cursor: pointer;

	&:hover {
		background-color: $gray-200;
	}
}

.article_url {
	width: $editor-width;
	padding: 4px 8px;
	display: flex;
	align-items: center;
	min-height: 30px;
}

.article_history_content {
	flex: 3;
	overflow: auto;
	padding: 10px 20px;
	height: 100%;

	img {
		max-width: 90%;
		max-height: 400px;
	}
}

.article_history_sidebar {
	flex: 1;
	overflow-y: auto;
	border-left: 1px solid #cacaca;
	padding: 10px 20px;
}

.art_version_item {
	padding: 6px 10px;
	border-radius: 4px;
	position: relative;
	cursor: pointer;

	.menu_item {
		position: absolute;
		right: 0;
		z-index: 100;
		bottom: 5px;
		display: flex;
		align-items: center;
		visibility: hidden;
		background-color: white;
		border-radius: 4px;
		padding: 6px 8px;
	}

	&:hover {
		background-color: $gray-200;

		.menu_item {
			visibility: visible;
		}
	}
}

.menu_item_icon {
	padding: 4px;
	cursor: pointer;
	border-radius: 2px;
	display: flex;
	align-items: center;

	&:hover {
		background-color: $gray-200;
	}
}

.art_version_item.active {
	background-color: #deddee;
}

.dropdown_text_type {
	border: none;
	padding-right: 35px;
}

.kb_nodes_wrapper {
	padding: 0 12px;
	overflow: auto;
}

.kb_single_node {
	width: 100%;
	padding: 4px 0;

	&.dragenter {
		background: $gray-500;
	}
}

.kb_node_dragable_zone {
	height: 0;

	&.dragenter {
		background: $gray-500;
	}
}

.kb_single_node .article_title {
	padding-top: 2px;
	padding-bottom: 2px;
}

.kb_single_node .cate_sidebar_item {
	padding-top: 2px;
	padding-bottom: 2px;
}

.kb_nodes_wrapper.dragging {
	.kb_single_node {
		padding-top: 0;
		padding-bottom: 0;
	}

	.kb_single_node:first-child {
		padding-top: 4px;
	}

	.kb_node_dragable_zone {
		height: 4px;
	}
}
