@use "sass:color";

// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

@mixin button-variant(
  $background,
  $border,
  $color: color-yiq($background),
  $hover-background: color.adjust($background, $lightness: -7.5%),
  $hover-border: color.adjust($border, $lightness: -10%),
  $hover-color: color-yiq($hover-background),
  $active-background: color.adjust($background, $lightness: -10%),
  $active-border: color.adjust($border, $lightness: -12.5%),
  $active-color: color-yiq($active-background)
) {
  color: $color;
  @include gradient-bg($background);
  border-color: $border;


  &:hover {
    color: $hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    color: $hover-color;
    @include gradient-bg($hover-background);
    border-color: $hover-border;
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $active-color;
    background-color: $active-background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $active-border;
  }

  &:disabled,
  &.disabled {
    color: $color;
    background-color: $background;
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: $border;
  }
}

@mixin button-outline-variant(
  $color,
  $color-hover: color-yiq($color),
  $active-background: $color,
  $active-border: $color,
  $active-color: color-yiq($active-background)
) {
  color: $color;
  border-color: $color;

  &:hover {
    color: $color-hover;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: $active-color;
    background-color: $active-background;
    border-color: $active-border;
  }

  &:disabled,
  &.disabled {
    color: $color;
    background-color: transparent;
  }
}

// Button sizes
@mixin button-size($padding-y, $padding-x, $font-size, $border-radius) {
  padding: $padding-y $padding-x;
  font-size: $font-size;
  // Manually declare to provide an override to the browser default
  @include border-radius($border-radius, 0);
}
