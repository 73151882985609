.bill__invoice__iframe {
	transform: scale(0.6);
	transform-origin: 0 0;

	&.frame--big {
		transform: scale(0.75);
	}
}

.subscription-root {
	margin-top: 0;
	width: 100%;
	height: 100%;
	overflow-y: auto;
	background: $gray-100;
	padding-bottom: 20px;
}

.credit_box {
	margin-top: 10px;
	padding: 10px 15px;
	background-color: #fff;
	border-radius: 7px;
	position: relative;
	border: 2px solid $border-color;
	cursor: pointer;
	display: flex;
	align-items: center;
	overflow: hidden;
}

.credit_balance_box {
	margin-top: 10px;
	padding: 10px 15px;
	background-color: whitesmoke;
	border-radius: 7px;
	position: relative;
	align-items: center;
	overflow: hidden;
	width: 200px;
}

.credit_balance_box_balance {
	font-size: 20px;
	font-weight: 50px;
	margin-top: 5px;
	font-weight: 500;
}

.credit_box_name {
	text-transform: uppercase;
	font-weight: 500;
	font-size: 13px;
	color: #666;
	flex: 1;
	@include text-truncate();
}

.credit_box_credit {
}

.credit_box:hover {
	border-color: #555;
}

.credit_box.credit_box__active {
	border-color: $primary;
}

.payment_box {
	padding: $spacer * 3;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	border-radius: 7px;
	position: relative;
	box-shadow: $box-shadow;
}

table.invoices__table {
	border: none;

	tr:last-child td {
		border-bottom: none;
	}

	thead tr:hover {
		background: transparent;
	}
}

.invoices__table thead th,
.invoices__table tbody td {
	width: auto;
	padding: 12px 8px;
	vertical-align: middle;
}

.invoices__table thead th {
	font-weight: 500;

	&.money {
		text-align: right;
	}
}

.invoices__table tbody td.money {
	font-size: 18px;
	font-weight: 300;
	text-align: right;
}

.invoice-detail {
	position: relative;
	padding-top: 0;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}

.invoice-detail__bg {
	min-width: 600px;
	padding-top: 0;
}

.invoice-detail__action {
	background-color: #fff;
	padding: 0.5rem 0;
	justify-content: space-between;
	align-items: center;
	flex-shrink: 0;
	display: flex;
	flex: 1;
}

.invoice-detail__bank {
	background-color: $gray-200;
	padding: 12px;
	font-size: 14px;
	border-radius: 3px;

	code {
		font-size: 14px;
	}
}

.invoice-detail__container {
	max-height: 500px;
	overflow-x: hidden;
	overflow-y: auto;
}

.payment__dropdown {
	align-items: center;
	width: 100%;
}

.cycle__radio {
	margin-bottom: $spacer * 0.5;

	&:last-child {
		margin-bottom: 0;
	}
}

.subscription_info_card {
	padding: 10px 15px;
	border-radius: 8px;
	border: 1px solid $border-color;
	flex: 1;
	overflow: hidden;
	display: flex;
	flex-direction: column;

	&.danger {
		background-color: $danger;
		color: $white;
	}

	&:first-child {
		margin-right: 40px;
	}
}

.subscription_info_card_title {
	font-weight: 500;
	font-size: 20px;
}

.subscription_info_card_subtitle {
	font-size: 14px;
	height: 52px; // only display 2 line
	@extend .text__muted;
}

.subscription_info_card_payment_status {
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 14px;

	&.done {
		color: $success;
	}
	&.warning {
		color: $warning;
	}
}

.subscription_info_card_process_section {
	font-size: 14px;
	display: flex;
	flex-direction: column;
	height: 35px;

	.progress {
		height: 10px;
		margin-top: auto;
	}
}

.subscription_info_card_price {
	font-size: 24px;
	line-height: 30px;
	font-weight: 700;

	&.success {
		color: $success;
	}
}

.subscription_info_card_cta_section {
	margin-top: 15px;
	display: flex;
	justify-content: flex-end;

	.btn {
		font-weight: 500;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.subscription_info_card.danger {
	.subscription_info_card_price {
		color: $white;

		&.success {
			color: $white;
		}
	}

	.subscription_info_card_process_section .progress-bar {
		background-color: $white;
	}

	.subscription_info_card_cta_section .btn.btn__white {
		color: $danger;
		border-color: transparent;
	}

	.subscription_info_card_cta_section .btn.btn__outline_white:hover {
		color: $danger;
	}
}

.payment_modal_qr_card {
	width: 200px;
	border: 1px solid $border-color;
	flex-shrink: 0;
	margin-right: 20px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0 15px;
}

.payment_modal_bank_info_card {
	flex: 1;
	background-color: whitesmoke;
	padding: 10px 15px;
	border-radius: 8px;
}

.payment_modal_note_container {
	width: 100%;
	margin-top: 20px;
	border-radius: 8px;
	background-color: #fff6e3;
	padding: 15px;
}

.payment_modal_edit_amount_actions {
	display: flex;
	position: absolute;
	top: 50%;
	right: 10px;
	line-height: 1;
	transform: translateY(-50%);
}

.trial_subscription_page_header {
	text-align: center;
	margin: 0 30px 30px 30px;
}

.trial_subscription_page_header_text {
	font-size: 32px;
	font-weight: 700;
}
.trial_subscription_page_header_desc {
	@extend .text__muted;
}

.plan_tab {
	display: flex;
	align-items: center;
	gap: 15px;
	background-color: #efefef;
	border-radius: 8px;
	padding: 4px 15px;
}

.plan_tab_item {
	padding: 5px 10px;
	cursor: pointer;
	line-height: 19px;

	&.active {
		background-color: #ffffff;
		border-radius: 5px;
	}

	&:hover {
		border-radius: 5px;
		background-color: #f7f5f5;
	}
}

.trial_subscription_page {
	padding: 30px 0;
}

.trial_subscription_page_left_col {
	width: 300px;
	margin-right: 40px;
	flex-shrink: 0;
}

.trial_subscription_draft_invoice_box {
	padding: 0 20px;
	border: 1px solid $border-color;
	overflow: hidden;
	border-radius: 8px;
	background-color: $white;
	position: relative;
}

.trial_subscription_draft_invoice_box_header {
	padding: 20px 0;
	border-bottom: 1px solid $border-color;
}

.trial_subscription_draft_invoice_box_body {
	padding: 20px 0;
}

.trial_subscription_draft_invoice_box_footer {
	padding: 20px 0;
	border-top: 1px solid $border-color;
}

.trial_subscription_draft_invoice_box_plan_img {
	width: 48px;
	height: 48px;
	flex-shrink: 0;
	margin-right: 15px;
}

.compare_package {
	max-width: 1200px;
	margin: 20px auto;
}

.package_list {
	display: flex;
}

.package_item_container {
	flex: 1;
	margin-right: 40px;
	display: flex;
	flex-direction: column;

	&:last-child {
		margin-right: 0;
	}

	.package_item {
		height: 220px;
		padding: 20px;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: #fff;
		border: 1px solid $border-color;
		border-radius: 8px;
	}

	.package_item_info {
		margin-top: 30px;
		display: flex;
		flex-direction: column;
		align-items: start;
		padding-left: 20px;
		gap: 3px;
	}

	&.disabled {
		opacity: 0.7;
		pointer-events: none;
		filter: grayscale(1);
	}
}

.package_item_desc {
	display: -webkit-box;
	-webkit-line-clamp: 2; // Limit to 2 lines
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	word-wrap: break-word;
}

.contact_support_subs {
	margin: 0 auto;
	width: 370px;
	padding: 30px 10px 10px 10px;
}

.discount_tag {
	border-radius: 6px;
	font-size: 14px;
	padding: 4px 8px;
	color: #19b600;
	background-color: #baffaf;
	font-weight: 700;
}

.trial_subscription_left_container {
	flex: 3;
	background-color: $white;
	border-right: 1px solid $border-color;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 30px 40px;
}

.trial_subscription_right_container {
	flex: 2;
	height: 100%;
	overflow: hidden;
	padding: 40px;
}

.promote_plan_card {
	background-color: $white;
	border: 1px solid $border-color;
	margin-right: 50px;
	padding: 20px 30px;
	border-radius: 8px;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-right: 0;
  }

	&.primary {
		background-color: #ecefff;
		border-color: #ecefff;
	}
}

.promote_plan_card_title {
  font-weight: 500;
  font-size: 18px;
}

.promote_plan_card_subtitle {
  color: $secondary;
  max-width: 270px;
}

.promote_plan_card_price {
  font-weight: 500;
  font-size: 40px;
}

.promote_plan_card_price_unit {
  font-size: 14px;
  color: $secondary;
}

