a[href^='http']:not(.btn),
a[href^='https']:not(.btn) {
	text-decoration: underline;
}

.link {
	cursor: pointer;
	color: $link-color;
	stroke: $link-color;
	text-decoration: underline;

	&:hover {
		color: $link-hover-color;
		stroke: $link-hover-color;
	}
}

.link.action {
	text-decoration: none;
}

.link.link__secondary {
	color: $secondary;
	stroke: $secondary;
	&:hover {
		color: $gray-800;
		stroke: $gray-800;
	}
}

.link.link__primary {
	color: $blue-500;
	stroke: $blue-500;
	&:hover {
		color: $blue-700;
		stroke: $blue-700;
	}
}

.link.link__success {
	color: $success;
	stroke: $success;
	&:hover {
		color: $green-600;
		stroke: $green-600;
	}
}

.link.link__danger {
	color: $red-500;
	stroke: $red-500;
	&:hover {
		color: $red-700;
		stroke: $red-700;
	}
}

.link.link__light {
	color: $gray-500;
	stroke: $gray-500;
	&:hover {
		color: $gray-700;
		stroke: $gray-700;
	}
}

.link.link__white {
	color: $gray-300;
	stroke: $gray-300;
	&:hover {
		color: $white;
		stroke: $white;
	}
}

.link.link__underline:hover {
	text-decoration: underline;
}

.link.dropdown_link_selected {
	border-bottom: none;
	text-decoration: none;
}
