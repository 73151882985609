.report_number_metrics {
	display: flex;
}

.report_number_metric {
	margin-right: 40px;
	&:last-child {
		margin-right: 0;
	}
}

.report_number_metric__label {
	border-bottom: 2px solid $gray-200;
	width: fit-content;
}

.report_number_metric__number {
	font-size: 30px;
	display: flex;
	align-items: center;
	font-weight: 400;
}

.report_number_metric__trend {
	font-size: $font-size-sm;
	margin-left: $spacer;
	font-weight: normal;
}

.report_number_metric__trend.report_number_metric__trend__up {
	color: $success;
}

.report_number_metric__trend.report_number_metric__trend__down {
	color: $danger;
}

.report_number_metric__trend.report_number_metric__success {
	color: $success;
}

.report_number_metric__trend.report_number_metric__failure {
	color: $danger;
}

.report_user_table__row {
	display: flex;
	border-left: 1px solid #e9ecef;
	border-right: 1px solid #e9ecef;
	border-top: 1px solid #e9ecef;

	align-items: center;
	padding: $spacer * 0.5 $spacer;
	&:first-child {
		background: #e9ecef;
		font-weight: 500;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;

		.report_user_table__channel_number {
			font-size: 15px;
		}
	}

	&:last-child {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		border-bottom: 1px solid #e9ecef;
	}
}

.report_user_table__channel_name {
	display: flex;
	align-items: center;
	flex-shink: 0;
	// width: 100px;
}

.report_user_table {
	border-radius: 4px;
	flex: 1;
	//margin-right: $spacer*4;
	min-width: 300px;
}

.report_user_table__channel_numbers {
	flex: 1;
	justify-content: flex-end;
	display: flex;
}

.report_user_table__channel_number {
	width: 135px;
	//text-align: end;
	display: flex;
	margin-left: $spacer * 2;
	align-items: center;
	font-size: 16px;
	//justify-content: space-between;
}

.report__section {
	display: flex;
	margin-top: $spacer * 2;
}

.report_user_filters {
	display: flex;
}

.report_user_filter {
	margin-right: $spacer * 3;
	min-width: 200px;
}

.report__label {
	font-size: $font-size-lg;
	margin-bottom: $spacer;
	margin-top: $spacer * 2;
}

.report__main {
	padding: $spacer * 2;
}

.report__channel_source {
	display: flex;
	position: relative;
	align-items: center;
	margin-right: $spacer * 2;
}

.report__channel_source_img {
	width: 20px;
	height: 20px;
	display: block;
	border-radius: 4px;
	margin-right: $spacer * 0.5;
}

.report__channel_source_type_img {
	position: absolute;
	width: 16px;
	height: 16px;
	border: 2px solid $white;
	border-radius: 50%;
	bottom: -4px;
	right: -2px;
}

.report_user_wrapper {
	flex: 1;
	margin-right: 40px;
	position: relative;

	&:last-child {
		margin-right: 0;
	}
}

@media only screen and (max-width: 1280px) {
	.report_user_wrapper {
		width: 100%;
		margin-right: 0;
		margin-bottom: $spacer * 2;
		flex: unset;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.report__section_tables {
		flex-wrap: wrap;
	}
}

.subiz_print_logo {
	display: none;
	opacity: 0.8;
	width: 80px;
	margin-bottom: $spacer;
}

@media only print {
	// print all page
	body,
	.root,
	.root__body,
	.root_content,
	.root_content_inner,
	.main,
	.setting,
	.setting__main {
		overflow: visible !important;
	}
	@page {
		size: portrait;
	}

	.subiz_print_logo {
		display: block;
		margin-left: 20px;
	}
	body,
	html {
		//width: 210mm;
	}

	.header_bar {
		display: none;
	}

	.global_email_button {
		display: none;
	}

	.global_help_button {
		display: none;
	}

	.global_call_button {
		display: none;
	}

	.root__header {
		display: none;
	}

	.report__main {
		width: 100%;
	}
	// hide sidebar when print
	.sidebar {
		display: none !important;
	}

	.recent_users {
		display: none !important;
		border: none !important;
	}

	.sidebar_setting {
		display: none !important;
	}

	.report_user_wrapper {
		width: 100%;
		margin-right: 0;
		margin-bottom: $spacer * 2;
		flex: unset;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.report__section_tables {
		flex-wrap: wrap;
	}
}

.report_user_table__channel_number_detail {
	margin-left: $spacer;
	opacity: 0;
	transition: 0.2s;
}

.report_user_table__row:hover .report_user_table__channel_number_detail {
	opacity: 1;
}

.report_user_table__row:not(:first-child):hover {
	background-color: $gray-100;
}

.report_agent_table {
	width: 100%;
	overflow: auto;
	border-radius: 5px;
	border: 1px solid $border-color;
}

.report_agent_table_row {
	display: flex;
	border-bottom: none;
	width: fit-content;
	min-width: 100%;

	&:first-child {
		.report_agent_table_cell {
			background-color: $gray-200;
			font-weight: 500;
			border-top: none;
		}
	}

	&:last-child {
		.report_agent_table_cell {
		}
	}

	&:not(:first-child):hover .report_agent_table_cell {
		background-color: $gray-100;
	}
}

.report_agent_table_cell {
	background-color: $white;
	padding: $spacer * 0.5 $spacer;
	display: flex;
	align-items: center;
	flex-shrink: 0;
	border-top: 1px solid $border-color;
	overflow: hidden;
}

.report_agent_table_sales_col {
	width: 105px;
}

.report_agent_table_order_col {
	width: 105px;
}

.report_agent_table_agent_col {
	width: 180px;
	position: sticky;
	left: 0;
	z-index: 9;

	&:after {
		position: absolute;
		top: 0;
		right: 5px;
		bottom: -1px;
		width: 5px;
		transform: translate(100%);
		transition: box-shadow 0.3s;
		content: '';
		pointer-events: none;
	}

	&.blur:after {
		box-shadow: inset 5px 0 4px -4px rgba(0, 0, 0, 0.1);
	}
}

.report_agent_table_great_convo_col {
	flex: 1;
	min-width: 215px;
}

.report_agent_table_first_reply_col {
	flex: 1;
	min-width: 230px;
}

.report_agent_table_messages_col {
	flex: 1;
	min-width: 100px;
}

.report_agent_table_replied_convo_col {
	flex: 1;
	min-width: 165px;
}

.report_agent_table_duration_col {
	flex: 1;
	min-width: 180px;
}

.report_user_table_product_image {
	width: 24px;
	height: 24px;
	border-radius: 5px;
}

.report_new_wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	border-right: 1px solid #cecece;

	.report_new_header {
		background-color: #fff;
		border-bottom: solid 1px #cecece;
		flex-shrink: 0;
		padding: 10px 20px;
		z-index: 10;

		.date_filter {
			width: 500px;
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;

			button {
				width: 80px;
			}
		}
	}

	.report_new_body {
		background-color: #fff;
		height: fit-content;
		overflow: auto;
		padding-top: 15px;

		.report_new_session {
			width: 830px;
			margin: auto;
			padding: 0 20px 60px 20px;

			.report_new_session_header {
				margin-bottom: 20px;

				.header_title {
					font-size: 24px;
					font-weight: 700;
				}

				.filter {
					display: flex;
					background-color: rgba(168, 203, 254, 0.35);
					padding: 2px 5px;
					border-radius: 3px;
					width: fit-content;
					max-width: 400px;
					align-items: center;
					justify-content: space-between;
				}
			}

			.report_new_session_metric {
				.report_number {
					font-size: 30px;
					font-weight: 500;
				}
			}

			.report_new_counter {
				border-collapse: collapse;
				vertical-align: text-top;

				.report_new_counter_item {
					width: 50%;
					padding: 10px 15px;
					border: solid 1px #cecece;

					.report_new_counter_label {
						font-size: 18px;
						font-weight: 500;
						line-height: 24px;
						margin-top: 5px;
						margin-bottom: 15px;
						display: flex;
						justify-content: space-between;
					}

					.counter_item {
						justify-content: space-between;
						align-items: center;
						margin: 10px 0;
						width: 360px;

						.item_label {
							width: 170px;
							margin-right: 5px;
						}

						.item_qty {
							color: #0265ff;
							margin-right: 10px;
							width: 50px;
							font-weight: 400;
						}

						.report-actions {
							flex: 1;
							display: none;

							.btn__sm {
								width: 24px;
								height: 24px;
								padding: 0;
							}
						}

						&:hover .report-actions {
							display: flex;
							justify-content: space-around;
						}

						&:hover .hide {
							display: none;
						}
					}
				}
			}
		}
	}
}

.ticket_report_box {
	border: 1px solid $border-color;
	padding: 12px 20px;
	margin-right: 30px;
	margin-bottom: 30px;
	position: relative;
  width: calc(50% - 15px);

	&:nth-child(2) {
		margin-right: 0;
	}
}

.ticket_report_box_row {
	display: flex;
	align-items: center;
	margin-bottom: 8px;

	&:last-child {
		margin-bottom: 0;
	}
}
