// stylelint-disable at-rule-no-vendor-prefix, declaration-no-important, selector-no-qualifying-type, property-no-vendor-prefix

// Reboot
//
// Normalization of HTML elements, manually forked from Normalize.css to remove
// styles targeting irrelevant browsers while applying new styles.
//
// Normalize is licensed MIT. https://github.com/necolas/normalize.css

// Document
//
// Change from `box-sizing: content-box` so that `width` is not affected by `padding` or `border`.

*,
*::before,
*::after {
	box-sizing: border-box;
}

// Root
//
// Ability to the value of the root font sizes, affecting the value of `rem`.
// null by default, thus nothing is generated.

:root {
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Prevent adjustments of font size after orientation changes in IE on Windows Phone and in iOS.
// 4. Change the default tap highlight to be completely transparent in iOS.

body {
	overflow: hidden; // weird chrome bug that allow fixed element to create extra white region
	margin: 0; // 1
	font-family: $font-family-base;
	font-size: $font-size-base;
	font-weight: $font-weight-base;
	line-height: $line-height-base;
	color: $body-color;
	text-align: $body-text-align;
	background-color: $body-bg; // 2
	-webkit-text-size-adjust: 100%; // 3
	-webkit-tap-highlight-color: rgba($black, 0); // 4

	//  text-rendering: optimizeLegibility;
	 -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	// letter-spacing: -0.015em;
}

// Future-proof rule: in browsers that support :focus-visible, suppress the focus outline
// on elements that programmatically receive focus but wouldn't normally show a visible
// focus outline. In general, this would mean that the outline is only applied if the
// interaction that led to the element receiving programmatic focus was a keyboard interaction,
// or the browser has somehow determined that the user is primarily a keyboard user and/or
// wants focus outlines to always be presented.
// See https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
// and https://developer.paciellogroup.com/blog/2018/03/focus-visible-and-backwards-compatibility/

[tabindex='-1']:focus:not(:focus-visible) {
	outline: 0 !important;
}

// Content grouping
//
// 1. Reset Firefox's gray color
// 2. Set correct height and prevent the `size` attribute to make the `hr` look like an input field
//    See https://www.w3schools.com/tags/tryit.asp?filename=tryhtml_hr_size

hr {
	margin: $hr-margin-y 0;
	color: $hr-color; // 1
	background-color: currentColor;
	border: 0;
	opacity: $hr-opacity;
}

hr:not([size]) {
	height: $hr-height; // 2
}

// Typography
//
// 1. Remove top margins from headings
//    By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
//    margin for easier control within type scales as it avoids margin collapsing.

%heading {
	margin-top: 0; // 1
	margin-bottom: $headings-margin-bottom;
	font-family: $headings-font-family;
	font-style: $headings-font-style;
	font-weight: $headings-font-weight;
	line-height: $headings-line-height;
	color: $headings-color;
}

h1 {
	@extend %heading;
	font-size: $h1-font-size;
}

h2 {
	@extend %heading;
	font-size: $h2-font-size;
}

h3 {
	@extend %heading;
	font-size: $h3-font-size;
}

h4 {
	@extend %heading;
	font-size: $h4-font-size;
}

h5 {
	@extend %heading;
	font-size: $h5-font-size;
}

h6 {
	@extend %heading;
	font-size: $h6-font-size;
}

// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.

p {
	margin-top: 0;
	margin-bottom: $paragraph-margin-bottom;
}

// Abbreviations
//
// 1. Duplicate behavior to the data-* attribute for our tooltip plugin
// 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
// 3. Add explicit cursor to indicate changed behavior.
// 4. Prevent the text-decoration to be skipped.

abbr[title],
abbr[data-original-title] {
	// 1
	text-decoration: underline; // 2
	text-decoration: underline dotted; // 2
	cursor: help; // 3
	text-decoration-skip-ink: none; // 4
}

// Address

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

// Lists

ol,
ul {
	padding-left: 2rem;
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

dt {
	font-weight: $dt-font-weight;
}

// 1. Undo browser default

dd {
	margin-bottom: 0.5rem;
	margin-left: 0; // 1
}

// Blockquote

blockquote {
	margin: 0 0 1rem;
}

// Strong
//
// Add the correct font weight in Chrome, Edge, and Safari

b,
strong {
	font-weight: $font-weight-bolder;
}

// Small
//
// Add the correct font size in all browsers

small {
	font-size: $small-font-size;
}

// Mark

mark {
	padding: $mark-padding;
	background-color: $mark-bg;
}

// Sub and Sup
//
// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.

sub,
sup {
	position: relative;
	font-size: $sub-sup-font-size;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}
sup {
	top: -0.5em;
}

// Links

a {
	color: $link-color;
	text-decoration: $link-decoration;

	&:hover {
		color: $link-hover-color;
		text-decoration: $link-hover-decoration;
	}
}

// And undo these styles for placeholder links/named anchors (without href).
// It would be more straightforward to just use a[href] in previous block, but that
// causes specificity issues in many other styles that are too complex to fix.
// See https://github.com/twbs/bootstrap/issues/19402

a:not([href]) {
	&,
	&:hover {
		color: inherit;
		text-decoration: none;
	}
}

// Code

pre,
code,
kbd,
samp {
	font-family: $font-family-monospace;
	font-size: 1em; // Correct the odd `em` font sizing in all browsers.
}

// 1. Remove browser default top margin
// 2. Reset browser default of `1em` to use `rem`s
// 3. Don't allow content to break outside

pre {
	display: block;
	margin-top: 0; // 1
	margin-bottom: 1rem; // 2
	overflow: auto; // 3
	font-size: $code-font-size;
	color: $pre-color;

	// Account for some code outputs that place code tags in pre tags
	code {
		font-size: inherit;
		color: inherit;
		word-break: normal;
	}
}

code {
	font-size: $code-font-size;
	color: $code-color;
	word-wrap: break-word;

	// Streamline the style when inside anchors to avoid broken underline and more
	a > & {
		color: inherit;
	}
}

kbd {
	padding: $kbd-padding-y $kbd-padding-x;
	font-size: $kbd-font-size;
	color: $kbd-color;
	background-color: $kbd-bg;
	border-radius: $border-radius-sm;

	kbd {
		padding: 0;
		font-size: 1em;
		font-weight: $nested-kbd-font-weight;
	}
}

// Figures
//
// Apply a consistent margin strategy (matches our type styles).

figure {
	margin: 0 0 1rem;
}

// Images and content

img {
	vertical-align: middle;
}

// 1. Workaround for the SVG overflow bug in IE 11 is still required.
//    See https://github.com/twbs/bootstrap/issues/26878

svg {
	overflow: hidden; // 1
	vertical-align: middle;
}

// Tables
//
// Prevent double borders

table {
	border-collapse: collapse;
}

caption {
	padding-top: $table-cell-padding;
	padding-bottom: $table-cell-padding;
	color: $table-caption-color;
	text-align: left;
	caption-side: bottom;
}

// 1. Matches default `<td>` alignment by inheriting `text-align`.
// 2. Fix alignment for Safari

th {
	text-align: inherit; // 1
	text-align: -webkit-match-parent; // 2
}

// Forms
//
// 1. Allow labels to use `margin` for spacing.

label {
	display: inline-block; // 1
	margin-bottom: $label-margin-bottom;
}

// Remove the default `border-radius` that macOS Chrome adds.
// See https://github.com/twbs/bootstrap/issues/24093

button {
	// stylelint-disable-next-line property-blacklist
	border-radius: 0;
}

// Work around a Firefox/IE bug where the transparent `button` background
// results in a loss of the default `button` focus styles.
// Credit https://github.com/suitcss/base/

button:focus {
	outline: 1px dotted;
	outline: 5px auto -webkit-focus-ring-color;
}

// 1. Remove the margin in Firefox and Safari

input,
button,
select,
optgroup,
textarea {
	margin: 0; // 1
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

// Show the overflow in Edge

button,
input {
	overflow: visible;
}

// Remove the inheritance of text transform in Firefox

button,
select {
	text-transform: none;
}

// Remove the inheritance of word-wrap in Safari.
// See https://github.com/twbs/bootstrap/issues/24990

select {
	word-wrap: normal;
}

// Remove the dropdown arrow in Chrome from inputs built with datalists.
// See https://stackoverflow.com/a/54997118

[list]::-webkit-calendar-picker-indicator {
	display: none;
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
// 3. Opinionated: add "hand" cursor to non-disabled button elements.

button,
[type="button"], // 1
[type="reset"],
[type="submit"] {
	-webkit-appearance: button; // 2

	@if $enable-pointer-cursor-for-buttons {
		&:not(:disabled) {
			cursor: pointer; // 3
		}
	}
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.

::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

// Remove the default appearance of temporal inputs to avoid a Mobile Safari
// bug where setting a custom line-height prevents text from being vertically
// centered within the input.
// See https://bugs.webkit.org/show_bug.cgi?id=139848
// and https://github.com/twbs/bootstrap/issues/11266

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
	-webkit-appearance: textfield;
}

// 1. Remove the default vertical scrollbar in IE.
// 2. Textareas should really only resize vertically so they don't break their (horizontal) containers.

textarea {
	overflow: auto; // 1
	resize: vertical; // 2
}

// 1. Browsers set a default `min-width: min-content;` on fieldsets,
//    unlike e.g. `<div>`s, which have `min-width: 0;` by default.
//    So we reset that to ensure fieldsets behave more like a standard block element.
//    See https://github.com/twbs/bootstrap/issues/12359
//    and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
// 2. Reset the default outline behavior of fieldsets so they don't affect page layout.

fieldset {
	min-width: 0; // 1
	padding: 0; // 2
	margin: 0; // 2
	border: 0; // 2
}

// 1. By using `float: left`, the legend will behave like a block element
// 2. Correct the color inheritance from `fieldset` elements in IE.
// 3. Correct the text wrapping in Edge and IE.

legend {
	float: left; // 1
	width: 100%;
	padding: 0;
	margin-bottom: $legend-margin-bottom;
	font-size: $legend-font-size;
	font-weight: $legend-font-weight;
	line-height: inherit;
	color: inherit; // 2
	white-space: normal; // 3
}

// Fix height of inputs with a type of datetime-local, date, month, week, or time
// See https://github.com/twbs/bootstrap/issues/18842

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
	padding: 0;
}

::-webkit-inner-spin-button {
	height: auto;
}

// 1. Correct the outline style in Safari.
// 2. This overrides the extra rounded corners on search inputs in iOS so that our
//    `.form-control` class can properly style them. Note that this cannot simply
//    be added to `.form-control` as it's not specific enough. For details, see
//    https://github.com/twbs/bootstrap/issues/11586.

[type='search'] {
	outline-offset: -2px; // 1
	-webkit-appearance: textfield; // 2
}

// Remove the inner padding in Chrome and Safari on macOS.

::-webkit-search-decoration {
	-webkit-appearance: none;
}

// Remove padding around color pickers in webkit browsers

::-webkit-color-swatch-wrapper {
	padding: 0;
}

// 1. Change font properties to `inherit` in Safari.
// 2. Correct the inability to style clickable types in iOS and Safari.

::-webkit-file-upload-button {
	font: inherit; // 1
	-webkit-appearance: button; // 2
}

// Correct element displays

output {
	display: inline-block;
}

// Summary
//
// 1. Add the correct display in all browsers

summary {
	display: list-item; // 1
	cursor: pointer;
}

// Template
//
// Add the correct display for template & main in IE 11

template {
	display: none;
}

// Main

main {
	display: block;
}

// Progress
//
// Add the correct vertical alignment in Chrome, Firefox, and Opera.

progress {
	vertical-align: baseline;
}

// Hidden attribute
//
// Always hide an element with the `hidden` HTML attribute.

[hidden] {
	display: none !important;
}
