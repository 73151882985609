@import 'forms/form-control';
@import 'forms/form-check';
@import 'forms/validation';

td.xcel {
	border: 1px solid #ddd;
	padding: 0;
}

th.xcel {
	background: #f8f8f8;
	border: 1px solid #ddd;
	padding: 5px;
}

tr:hover {
	td.xcel {
		background: #f7f7f7;
	}
}

.form-control.form-control-xcel {
	min-height: 33px;
	border: 1px solid #00000000;
	border-radius: 0;
	outline: none;
	width: 100%;
	background: transparent;
	height: 100%;
	padding: 5px;
	&:active {
		border: 1px solid #bbb;
	}
	&:focus {
		border: 1px solid #bbb;
	}
}

.xcel.xcel__new {
	height: 34px;
}

.form-control.form-control__light {
	border: none;
	outline: none;
	background-color: #f3f3f3;
	//padding: 2px 2px 2px 8px;
	//border-radius: 4px;

	&:hover,
	&:focus,
	&:active {
		border: none;
		outline: none;
		background-color: #e8e8e8;
	}

	&.form-control__primary {
		background-color: #dceaff;
		color: $primary;

		&:hover,
		&:focus,
		&:active {
			border: none;
			outline: none;
			background-color: #dceaff;
		}
	}

	&.form-control__success {
		background-color: #d4edda;
		color: $success;

		&:hover,
		&:focus,
		&:active {
			border: none;
			outline: none;
			background-color: #d4edda;
		}
	}
}
