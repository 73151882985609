.switch {
	display: flex;
	position: relative;
	text-indent: -9999px;
	width: max-content;
	margin: 0;
	height: max-content;
	cursor: pointer;
}

.switch.switch__disabled {
	opacity: 0.6;
	pointer-events: none;
}

.switch__track {
	width: 100%;
	height: 100%;
	display: block;
	position: relative;
	border: 1px solid #b3b3b3;
	background: #b3b3b3;
	border-radius: 16px;
	overflow: hidden;
	box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.09);
}

.switch__thumb {
	position: absolute;
	content: '';
	top: 1px;
	left: 1px;
	background: #fff;
	border-radius: 16px;
	width: 12px;
	transition: 0.3s;
	bottom: 1px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24), 0 1px 1px 0 rgba(0, 0, 0, 0.05);
}

.switch__input {
	width: 0;
	height: 0;
	opacity: 0;
}

.switch__input:checked + .switch__track {
	background: $primary;
	border-color: $primary;

	&.switch__track--light {
		background: $blue-200;
		border-color: $blue-200;
	}
}

.switch__input:checked + .switch__track.switch__track__green {
	background: $green;
	border-color: $green;

	&.switch__track--light {
		background: $green-200;
		border-color: $green-200;
	}
}

.switch__input:checked + .switch__track.switch__track__yellow {
	background: $yellow;
	border-color: $yellow;

	&.switch__track--light {
		background: $yellow-200;
		border-color: $yellow-200;
	}
}

.switch__input:checked + .switch__track.switch__track__dark {
	background: $dark;
	border-color: $dark;

	&.switch__track--light {
		background: $black-700;
		border-color: $black-700;
	}
}

.switch__input:checked + .switch__track .switch__thumb {
	left: calc(100% - 1px);
	transform: translateX(-100%);
}
