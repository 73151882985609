.agent-avatar__upload {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.5);
	color: rgba(255, 255, 255, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	opacity: 0;
	z-index: 7;
}

.agent-avatar__upload-container {
	position: relative;
}

.agent-avatar__upload-container:hover .agent-avatar__upload {
	opacity: 1;
}
