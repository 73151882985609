.input2 {
	transition: all 0.2s;
	position: relative;
	border-radius: 5px;
	display: flex;
	flex: 1;
	align-items: center;
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	&:hover {
		.input2__text {
			text-decoration: underline;
		}

		.input2__placeholder {
			color: $gray-500;
		}
	}
}

.input2.input2__loading {
	cursor: default;
	background-color: $white;
	&:hover {
		background-color: $white;
	}
	.input2__input {
		cursor: default;
	}
}

.input2__placeholder {
	color: $gray-400;
}

.input2__icon__pencil {
	stroke: $gray-700;
	flex-basis: 20px;
	width: 25px;
	opacity: 0;
	transition: all 0.3s;
}

.input2__text {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.input2:hover .input2__icon__pencil {
	opacity: 1;
}

.input2__icon__close {
	flex-basis: 30px;
	width: 40px;
	width: 18px;
	margin-left: $spacer * 0.5;

	fill: $white;
	stroke: $gray-400;
	cursor: pointer;
	transition: all 0.3s;
	&:hover {
		fill: $orange;
		stroke: $black;
	}
}

.input2__icon__loader {
	flex-basis: 30px;
	width: 40px;
	width: 18px;
	margin-right: $spacer * 0.5;
	margin-left: $spacer * 0.5;

	stroke: $yellow;
}

.input2__icon__save {
	flex-basis: 30px;
	width: 40px;
	width: 18px;
	margin-left: $spacer * 0.5;

	cursor: pointer;
	stroke: $green;
	fill: $white;
	transition: all 0.3s;
	&:hover {
		fill: $green;
		stroke: $black;
	}
}

.input2__input {
	padding: 0;
	border: none;
	flex: 1;
	width: 20px;
	background-color: transparent;
	&:focus {
		outline-width: 0;
	}
}

.input2__date.form-control.input {
	border: none;
	height: 22px;
	padding: 0;
	min-height: 0;
	background: transparent;

	&:focus {
		box-shadow: none;
		outline-width: 0;
	}
}
