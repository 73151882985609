//
// Basic Bootstrap table
//

.table {
	width: 100%;
	// margin-bottom: $spacer;
	color: $table-color;
	vertical-align: $table-cell-vertical-align;
	background-color: $table-bg; // Reset for nesting within parents with `background-color`.
	border: 1px solid #dee2e6;

	th,
	td {
		padding: $table-cell-padding;
		border-bottom: $table-border-width solid $table-border-color;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 400px;
		overflow: hidden;
	}
	tr {
		&:hover {
			color: $table-hover-color;
			background-color: $table-hover-bg;
		}
	}

	tbody {
		vertical-align: inherit;
	}

	thead th {
		vertical-align: bottom;
		// border-bottom-color: $table-head-border-color;
	}

	tbody + tbody {
		border-top: (2 * $table-border-width) solid $table-border-color;
	}
}

//
// Condensed table w/ half padding
//

.table-sm {
	th,
	td {
		padding: $table-cell-padding-sm;
	}
}

// Border versions
//
// Add or remove borders all around the table and between all the columns.

.table-bordered {
	border: $table-border-width solid $table-border-color;

	th,
	td {
		border: $table-border-width solid $table-border-color;
	}

	thead {
		th,
		td {
			border-bottom-width: 2 * $table-border-width;
		}
	}
}

.table-borderless {
	th,
	td,
	thead th,
	tbody + tbody {
		border: 0;
	}
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
	tbody tr:nth-of-type(#{$table-striped-order}) {
		background-color: $table-accent-bg;
	}
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
	tbody tr {
		&:hover {
			color: $table-hover-color;
			background-color: $table-hover-bg;
		}
	}
}

// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

@each $color, $value in $theme-colors {
	@include table-row-variant($color, color-level($value, $table-bg-level), color-level($value, $table-border-level));
}

@include table-row-variant(active, $table-active-bg);

.table_scroll__wrap {
	overflow: auto;
	display: block;
}

.table_scroll {
	overflow: auto;
	width: 100%;
	color: $table-color;
	vertical-align: $table-cell-vertical-align;
	background-color: $table-bg; // Reset for nesting within parents with `background-color`.
	border: 1px solid $table-border-color;
	th,
	td {
		padding: $table-cell-padding;
		border-bottom: $table-border-width solid $table-border-color;
		white-space: nowrap;
		text-overflow: ellipsis;
		min-width: 100px;
		max-width: 400px;
		overflow: hidden;
	}
	tr {
		&:hover {
			cursor: pointer;
			color: $table-hover-color;
			background-color: $table-hover-bg;
		}
	}
	// thead th {
	//   position: sticky;
	//   background-color: $white;
	//   z-index: $zindex-sticky;
	//   top: 0;
	// }
}

.scroll-table-wrapper {
	position: relative;
	max-height: 100%;
	overflow: auto;
	width: 100%;
}

.scroll-table {
	border: none;
	width: 100%;
	color: $gray-900;
}

.scroll-table__th {
	background-color: $gray-100;
	padding: $spacer;
	font-weight: 500;
	border-bottom: none;
	position: sticky;
	top: 0;
	z-index: 3;
	white-space: nowrap;
	box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.15);

	&.cell--fixed-left {
		left: 0;
		z-index: 6;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			background-color: transparent;
			right: -30px;
			bottom: -1px;
			width: 30px;
			pointer-events: none;
			transition: 0.3s;
		}

		&.cell--blur:after {
			box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
		}
	}

	&.cell--fixed-right {
		right: 0;
		z-index: 6;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			background-color: transparent;
			left: -30px;
			bottom: -1px;
			width: 30px;
			pointer-events: none;
			transition: 0.3s;
		}

		&.cell--blur:after {
			box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
		}
	}
}

.scroll-table__td {
	background-color: #fff;
	font-size: 0.875rem;
	vertical-align: middle;
	border-bottom: 1px solid $border-color;
	padding: $spacer;
	white-space: nowrap;

	&.cell--fixed-left {
		position: sticky;
		left: 0;
		z-index: 5;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			background-color: transparent;
			right: -30px;
			bottom: -1px;
			width: 30px;
			pointer-events: none;
			transition: 0.3s;
		}

		&.cell--blur:after {
			box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
		}
	}

	&.cell--fixed-right {
		position: sticky;
		right: 0;
		z-index: 5;

		&:after {
			content: '';
			position: absolute;
			top: 0;
			background-color: transparent;
			left: -30px;
			bottom: -1px;
			width: 30px;
			pointer-events: none;
			transition: 0.3s;
		}

		&.cell--blur:after {
			box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
		}
	}
}

.table_blank {
	width: 100%;
	height: 50vh;
	text-align: center;
	color: #585d68;
	background-color: $white;
	tr,
	td:hover {
		background-color: $white;
		cursor: default;
	}
}
.table_blank__icon {
	font-size: 80px;
	color: #abaeb3;
	padding-bottom: 20px;
}

.sbz_common_table_container {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	flex: 1;
	height: 100%;
}

.sbz_common_table_header {
	flex-shrink: 0;
	overflow-y: scroll;
}

.sbz_common_table_content_wrapper {
	flex: 1;
	position: relative;
	overflow: hidden;
}

.sbz_common_table_content {
	overflow-y: scroll;
	height: 100%;
}

.sbz_common_table_footer {
	border-top: 1px solid $border-color;
	flex-shrink: 0;
	padding: 10px 20px;
}

.sbz_table_row {
	border-bottom: 1px solid #dee2e6;
	display: flex;
	position: relative;
	//transition: all 150ms ease-out;

	&:hover {
		background-color: #f8f9fa;
	}

	&.selected {
		background-color: #ecefff;
		&:hover {
			background-color: #ecefff;
		}
	}

	&.focused {
		background-color: #ecefff;
		&:hover {
			background-color: #ecefff;
		}
	}

	&.no_hover {
		&:hover {
			background-color: transparent;
		}
	}

	&.disabled {
		background: whitesmoke;
		&:hover {
			background-color: #f8f9fa;
		}
	}

	&.dragging,
	&.dragging:hover {
		background-color: #d5d5d5;
	}
}

.sbz_table_row_dragdrop_wrapper {
	width: 100%;
	position: relative;

	&:first-child .sbz_table_row_dragable_line.before {
		top: 0;
	}
	&:first-child .sbz_table_row_dragable_line.after {
		bottom: 0;
	}
}

.sbz_table_row.header {
	user-select: none;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	color: $text-muted;

	&:hover {
		background-color: transparent;
	}
}

.sbz_table_row.dragging {
	background-color: whitesmoke;
}

.sbz_table_row_dragdrop_wrapper:last-child {
	.sbz_table_row_dragable_line.after {
		bottom: 0;
	}
}
.sbz_table_row_dragdrop_wrapper:first-child {
	.sbz_table_row_dragable_line.before {
		top: 0;
	}
}

.sbz_table_row_dragable_line {
	position: absolute;
	width: 100%;
	left: 0;
	height: 3px;
	top: 0;
	bottom: 0;
	background-color: blue;
	z-index: 1;

	&.before {
		top: -1.5px;
		bottom: unset;
	}

	&.after {
		top: unset;
		bottom: -1.5px;
	}
}

.sbz_table_loading_inner {
	padding: 20px 40px;
	border-radius: 8px;
	background-color: rgba(255, 255, 255, 0.85);
	text-align: center;
	width: 280px;
	box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
}

.sbz_table_cell {
	padding: 10px 10px;
	overflow: hidden;
	display: flex;
	align-items: center;

	&:first-child {
		padding-left: 20px;
	}

	&:last-child {
		padding-right: 20px;
	}

	&.cell_grip {
		padding-left: 0;
		width: 25px;
	}
}
