//
// Base styles
//

.btn {
	display: inline-block;
	font-family: $btn-font-family;
	font-weight: $btn-font-weight;
	line-height: $btn-line-height;
	color: $body-color;
	text-align: center;
	text-decoration: if($link-decoration == none, null, none);
	//white-space: $btn-white-space;
	vertical-align: middle;
	cursor: if($enable-pointer-cursor-for-buttons, pointer, null);
	user-select: none;
	background-color: transparent;
	border: $btn-border-width solid transparent;
	transition: all ease-out 150ms;
	overflow: hidden;
	@include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);

	// make buton dont wrap
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;

	&:hover {
		color: $body-color;
		text-decoration: none;
		.btn__icon__next {
			margin-right: 0;
			margin-left: $spacer * 0.25 * 3;
		}
	}

	&:focus,
	&.focus {
		outline: 0;
	}

	&:disabled,
	&.disabled,
	fieldset:disabled & {
		// stylelint-disable-line selector-no-qualifying-type
		pointer-events: none;
		opacity: $btn-disabled-opacity;
		@include box-shadow(none);
	}

	.btn__icon__next {
		width: 1em;
		height: 1em;
		transition: all 150ms ease-out;
		margin-right: $spacer * 0.5;
		margin-left: $spacer * 0.25;
	}
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
	.btn__#{$color} {
		@include button-variant($value, $value);
	}
}

@each $color, $value in $theme-colors {
	.btn__outline_#{$color} {
		@include button-outline-variant($value);
	}
}

//
// Button Sizes
//

.btn__lg {
	@include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-border-radius-lg);
}

.btn__sm {
	@include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);
}

.btn__xs {
	@include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-border-radius-xs);
}

.btn__bg {
	@include button-size($btn-padding-y-bg, $btn-padding-x-bg, $btn-font-size-bg, $btn-border-radius-bg);
}

//
// Block button
//

.btn__close {
	width: 24px;
	height: 24px;
	cursor: pointer;

	background: #00000011;
	border-radius: 50%;
	padding: 3px;
	stroke: #00000055;

	outline: none;

	&:hover {
		stroke: #000000aa;
		background: $gray-400;
	}
}

.btn__close.btn__close_noti {
	background: white;
	box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn__close.btn__close_light {
	background: #00000033;
	border-radius: 50%;
	padding: 5px;
	stroke: #ffffff88;
	outline: none;

	&:hover {
		stroke: #ffffff;
		background: #00000055;
	}
}

.btn.btn--padding-lg {
	padding-right: 30px;
	padding-left: 30px;
}

.btn.btn--padding-md {
	padding-right: 20px;
	padding-left: 20px;
}

.btn.btn__dashed_primary {
	background-color: $white;
	border-color: $primary;
	color: $primary;
	border: 1px dashed;

	&:hover {
		color: #0047b5;
		border-color: #0047b5;
	}
}

.btn.btn__dashed_warning {
	background-color: $white;
	border-color: $warning;
	color: $warning;
	border: 1px dashed;

	&:hover {
		color: #d39e00;
		border-color: #d39e00;
	}
}

.btn.btn__dashed_success {
	background-color: $white;
	border-color: $success;
	color: $success;
	border: 1px dashed;

	&:hover {
		color: #1e7e34;
		border-color: #1e7e34;
	}
}

.btn.btn__dashed_danger {
	background-color: $white;
	border-color: $danger;
	color: $danger;
	border: 1px dashed;

	&:hover {
		color: #bd2130;
		border-color: #bd2130;
	}
}

.btn.btn__dashed_secondary {
	background-color: $white;
	border-color: $gray-600;
	color: $gray-600;
	border: 1px dashed;

	&:hover {
		color: $gray-700;
		border-color: $gray-700;
	}
}

.btn.btn__white {
	border-color: #dfe1e4;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 1px 4px;
}

.btn_caret {
	padding-left: 5px;
	padding-right: 5px;
	.icon-tabler-chevron-down path:last-child {
		fill: currentColor;
	}
}

.button_option {
	border-radius: 5px;
	margin-bottom: 10px;
	padding: 8px 12px;
	cursor: pointer;
	border-width: 1px;
	border-style: solid;
	@include button-outline-variant($primary);
}

.btn.btn__transparent {
  background-color: transparent;
  border-color: transparent;

  &:hover {
    background-color: $gray-200;
    border-color: $gray-200;
  }
}
