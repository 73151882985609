.error-main {
    padding: 0 $spacer * 4;
}
.error-logo {
    text-align: center;
    padding-top: $spacer * 2.5;
}
.error-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.error-content {
    padding-top: $spacer * 8;
    padding-bottom: $spacer * 6;
    text-align: center;
}
.error-content__image {
    padding: 0 $spacer;
}
.error-content__image img {
    width: 100%;
}
.error-content__title {
    margin-top: $spacer * 2.5;
    font-size: 17px;
    line-height: 22px;
    color: $blue-900;
    font-weight: 700;
}
.error-content__description {
    margin-top: 7px;
    font-size: 16px;
    line-height: 20px;
    color: $gray-600;
}
.error-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.error-btn-download {
    display: block;
    width: calc(50% - 3.5px);
}
.error-btn-download img {
    width: 100%;
}
